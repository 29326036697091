import "./Navbar.css";

import React, {Component} from "react";
import PropTypes from "prop-types";
import {connect} from "react-redux";

import Api from "components/Api";
import ContextEnhancer from "components/ContextEnhancer";
import AdminMenuButton from "components/ui/navbar/AdminMenuButton";
import SupportMenuButton from "components/ui/navbar/SupportMenuButton";
import CreateObjectMenuButton from "components/ui/navbar/CreateObjectMenuButton";
import UserMenuButton from "components/ui/navbar/UserMenuButton";
import ProjectMenuButtonContainer from "components/ui/navbar/ProjectMenuButtonContainer";
import PublisherMenuButtonContainer from "components/ui/navbar/PublisherMenuButtonContainer";
import PresentationMenuButtonContainer from "components/ui/navbar/PresentationMenuButtonContainer";
import CentralMonitoringMenuButtonContainer from "components/ui/navbar/CentralMonitoringMenuButtonContainer";
import NotificationMenuButtonContainer from "components/ui/navbar/NotificationMenuButtonContainer";
import ObjectSearchContainer from "components/ui/navbar/ObjectSearchContainer";
import FloatingNotificationAlert from "components/ui/navbar/FloatingNotificationAlert";
import ManageEnvironment from "./ManageEnvironment";
import SpecialistDropdown from "components/ui/navbar/SpecialistDropdown";
import EvaluationPeriodDropdown from "components/ui/navbar/EvaluationPeriodDropdown";
import NavRoundButton from "components/ui/navbar/NavRoundButton"
import Utils from 'components/Utils';
import {MODALS} from 'components/ui/redux/Actions';
import ActivateBimDialog from 'components/ui/navbar/ActivateBimDialog';
import AddonActivateDialog from "components/bng/accounts/addons/AddonActivateDialog";
import AddonNavButton from "components/ui/navbar/addons/AddonNavButton";
import ExportMenuButton from "components/ui/navbar/ExportMenuButton";
import { VIEW } from 'bng/routes';

const ACTIVATE_BIMACHINE_DIALOG_QUERY_PARAM = 'activateBIMachineDialog';
const ACTIVATE_ADDON_DIALOG_QUERY_PARAM = 'activateAddonDialog'

class Navbar extends Component {

    static propTypes = {
        info: PropTypes.shape({
            counts: PropTypes.shape({
                notifications: PropTypes.any.isRequired,
            })
        })
    };

    static defaultProps = {
        info: {
            counts: {
                projects: 0,
                notifications: 0,
                presentations: 'I',
                schedulings: 0,
                publications: 0,
            }
        }
    };

    state = {
        searchExpanded: false
    };

    constructor(props) {
        super(props);
    }

    openBimStore = () => {
        Api.Cockpit.accessStore();
    };

    accessCockpit = () => {
        Api.Cockpit.accessCockpit();
    };

    accessProject = () => {
        Api.Cockpit.accessDiscover();
    };

    openMarketplace = () => {
        return Api.Cockpit.accessMarketplace(this.props.context.project.name);
    }

    accessProjectFromManage = () => {
        j('.access-box-button').click();
    };

    componentDidMount () {
        const queryParams = Utils.History.currentUrlSearchParams();
        if (queryParams.has(ACTIVATE_BIMACHINE_DIALOG_QUERY_PARAM)
            && Utils.Project.isDemo(this.props.context.project)) {
            this.props.dispatch(MODALS.open(ActivateBimDialog));
        } else if (queryParams.has(ACTIVATE_ADDON_DIALOG_QUERY_PARAM)) {
            this.props.dispatch(MODALS.open(AddonActivateDialog));
        }
    }

    toggleSearchBar = () => this.setState({searchExpanded: !this.state.searchExpanded});

    buildRoundButton = () => {
        const isDemo = Utils.Project.isDemo(this.props.context.project);

        if (isDemo) {
            const activeMessage = this.props.context.msg.t(this.props.context.projectActivated ? 'project.activated' : 'activate.now.link')
            return {
                DropdownComponent: EvaluationPeriodDropdown,
                label: this.props.context.msg.t('evaluation.days.left', [this.props.context.demoExpirationDate > 0 ? this.props.context.demoExpirationDate : '0', activeMessage]),
                isNovelty: false,
            };
        } else if (this.props.context.showHireSpecialist && this.props.context.isAccessingProject) {
            return {
                DropdownComponent: SpecialistDropdown,
                label: this.props.context.msg.t('need.specialist'),
                isNovelty: true,
            };
        }

        return null;
    }

    render() {

        const {context, info} = this.props;

        let logoUrl = '/resources/images/logo-new.png';

        if (context.addons.includes('VISUAL_IDENTITY') && context.theme?.toolbarLogo) {
            logoUrl = `${context.contextPath}/${context.theme.toolbarLogo}`;
        }

        const isExternalPage = Utils.ExternalPages.isExternalPage();

        const counts = info.counts;
        const perm = context.permissions;
        const navBarClass = context.isAccessingProject ? 'navbar-new navbar no-print' : 'navbar-new navbar no-print project-info-panel';
        const isCockpit = this.props.view
          ? this.props.view === VIEW.Cockpit
          : (context.cockpitEnabled && !isExternalPage);
        const isDiscover = this.props.view
          ? this.props.view === VIEW.Discover
          : (context.cockpitEnabled === false && !isExternalPage);
        const isStore = this.props.view
          ? this.props.view === VIEW.Store
          : (window.location.pathname.includes('/spr/bng/store'));
        const isMarketplace = this.props.view
          ? this.props.view === VIEW.Marketplace
          : (window.location.pathname.includes('/bng/marketplace'));

        const roundButtonProps = this.buildRoundButton();
        const showMarketplace = this.props.context.accountEnabledForBilling &&
          this.props.context.bimResources.marketplace;

        const showDiscoverButtons = (this.props.view && this.props.view === VIEW.Discover)
          || (this.props.context.isAccessingProject && !this.props.context.cockpitEnabled);
        return (
            <div className={navBarClass} style={{marginBottom: 'auto'}}>
                <div className="navbar-inner">
                    <div className="container-fluid" style={{height: '0px'}}>

                        {(this.props.context.isAccessingProject)?
                            <a href={ this.props.context.cockpitEnabled || window.location.href.includes('/store') ? window.location.href : `${Api.baseUrl()}/pages/personal-page/index.iface`} className="brand" id="logo-project">
                                <img src={logoUrl} className="navbarLogo" alt="logo"/>
                            </a>
                            :
                            <div className="brand">
                                <img src={logoUrl} className="navbarLogo" alt="logo" onClick={this.accessProjectFromManage} style={{cursor: 'pointer'}}/>
                            </div>
                        }

                        <div className={!this.state.searchExpanded ? `type-view-buttons` : `type-view-buttons hidden-top`}>
                            {(this.props.context.isAccessingProject && this.props.context.showCockpitToggler) &&
                            <div
                                className={`type-view-button type-view-button-cockpit ${isCockpit ? 'active disabled' : 'hover'}`}
                                onClick={isCockpit ? _.noop : this.accessCockpit}>
                                cockpit
                            </div>
                            }
                            {(this.props.context.isAccessingProject && !this.props.context.userIsOnlyCockpit) &&
                            <div id='discover-button-enable'
                                className={`type-view-button type-view-button-discover ${isDiscover ? 'active disabled' : 'hover'}`}
                                onClick={isDiscover ? _.noop : this.accessProject}>
                                discover
                            </div>
                            }
                            {(this.props.context.isAccessingProject && 'store' in this.props.context &&
                                !this.props.context.userIsOnlyCockpit && !showMarketplace &&
                                    this.props.context.store.currentProjectAccount.storeConfig.enabled) &&
                            <div
                                className={`type-view-button type-view-button-store ${isStore ? 'active disabled' : 'hover'}`}
                                onClick={isStore ? _.noop : this.openBimStore}>
                                store
                            </div>
                            }
                            {this.props.context.isAccessingProject && showMarketplace && (
                                <div
                                  className={`type-view-button type-view-button-marketplace ${isMarketplace ? 'active disabled' : 'hover'}`}
                                  onClick={isMarketplace ? _.noop : this.openMarketplace}
                                >
                                    marketplace
                                </div>
                              )
                            }
                            {roundButtonProps &&
                                <NavRoundButton {...roundButtonProps}/>
                            }

                        </div>

                        <ul className="nav ace-nav pull-right">

                            {showDiscoverButtons &&
                            <ObjectSearchContainer onToggle={this.toggleSearchBar}
                                                   searching={this.state.searchExpanded}/>
                            }

                            <li>
                                <div className="navBarSeparator navBarItem"/>
                            </li>

                            {this.props.context.user.superUser &&
                            <AdminMenuButton/>
                            }

                            {this.props.context.permissions.canManageMonitors() && showDiscoverButtons &&
                            <CentralMonitoringMenuButtonContainer/>
                            }

                            {this.props.context.showSupportMenu &&
                            <SupportMenuButton/>
                            }

                            {this.props.context.permissions.isAdmin() &&
                            <PublisherMenuButtonContainer  badgeValue={counts.publications}/>
                            }

                            <ProjectMenuButtonContainer badgeValue={counts.projects}/>

                            {showDiscoverButtons &&
                            <ExportMenuButton badgeValue={counts.schedulings}/>
                            }

                            {this.props.context.isAccessingProject &&
                            <PresentationMenuButtonContainer badgeValue={counts.presentations}/>
                            }

                            <li>
                                <div className="navBarSeparator"/>
                            </li>

                            {perm.canCreateObjects() && showDiscoverButtons &&
                            <CreateObjectMenuButton/>
                            }

                            <NotificationMenuButtonContainer badgeValue={counts.notifications}/>

                            {this.props.context.accountId && !this.props.context.hideAddonsPage &&
                                <AddonNavButton />
                            }

                            {this.props.context.canManageCurrentProject &&
                            <ManageEnvironment  projectId={this.props.context.project.id}/>
                            }
                            <UserMenuButton/>
                        </ul>

                        <FloatingNotificationAlert notificationCount={counts.notifications}/>

                    </div>
                </div>
            </div>
        );
    }
}

export default connect()(ContextEnhancer(Navbar));