import styles from './AccFinanceTab.module.css';
import React, { useEffect, useMemo, useState } from 'react';

import useBimContext from 'components/hooks/useBimContext';
import Api from 'components/Api';
import UiBlocker from 'components/bng/ui/UiBlocker';
import UiMsg from 'components/ui/UiMsg';
import EnabledPill from 'components/ui/accounts/EnabledPill';
import Button from 'components/ui/Button';
import Icon from 'components/ui/common/Icon';
import BngTable from 'components/bng/ui/BngTable';
import Utils from 'components/Utils';
import { MODALS } from 'components/ui/redux/Actions';
import ActivateBimDialog from 'components/ui/navbar/ActivateBimDialog';
import useReduxDispatch from 'components/hooks/useReduxDispatch';
import { proposalUtils } from 'components/service/bng/AccountApi';
import BngDropdown from 'components/bng/ui/BngDropdown';
import ProjectionDetailsDialog from 'components/ui/accounts/tabs/ProjectionDetailsDialog';
import AddonInfoAd from 'components/ui/navbar/addons/AddonInfoAd';

function findTranslation(object, key) {
  const langs = object?.props?.translations?.langs || object.langs;
  if (langs) {
    let lang = langs[window.__USER_LANG];
    if (!lang) {
      lang = langs[Object.keys(langs)[0]];
    }
    if (lang) {
      const val = _.get(lang, key);
      if (val) {
        return val;
      }
    }
  }
  return '-';
}

function PlanCard({ planInfo, planPricing, agreedReviewRates, onChange }) {
  const context = useBimContext();
  const dispatch = useReduxDispatch();

  let pricing = planPricing?.agreedPricing || 0;
  agreedReviewRates.forEach((reviewRate) => {
    if (new Date(reviewRate.activeDate) < new Date()) {
      const percentValue = reviewRate.agreedReviewRate / 100 + 1;
      pricing = (pricing * percentValue).toFixed(2);
    }
  });

  const openPlanSelectionDialog = () => {
    dispatch(
      MODALS.open(ActivateBimDialog, {
        changePlan: true,
        currentPlan: planInfo,
        beforeClose: onChange,
      }),
    );
  };
  const planFeatures = _.uniqBy(planInfo.planFeatures, (planFeature) => {
    return planFeature.features.id;
  });

  return (
    <div className={`PlanCard ${styles.PlanCard}`}>
      <div className={styles.planHeader}>
        <div className={styles.planTitleWrapper}>
          <span className={styles.planTitle}>{findTranslation(planInfo, 'description')}</span>
          <EnabledPill enabled />
        </div>
      </div>

      <div className={styles.planFeatures}>
        {planFeatures.length > 0 &&
          planFeatures.map((planFeature) => {
            const hasDisplayName = !_.isEmpty((planFeature.displayName?.langs[window.__USER_LANG]?.value ?? '').trim());
            if (planFeature.value.value) {
              return (
                <div key={`plan-feature-${planFeature.id}`} className={styles.featureItem}>
                  <Icon icon={`done`} />
                  {hasDisplayName
                    ? findTranslation(planFeature.displayName, 'value')
                    : findTranslation(planFeature.features, 'description')}
                </div>
              );
            }
          })}
        {planFeatures.length === 0 && context.msg.t('no.plan.info')}
      </div>

      <div className={styles.planFooter}>
        <Button className={styles.changePlanBtn} onClick={openPlanSelectionDialog} disabled={true}>
          {context.msg.t('change.plan')}
        </Button>
      </div>
    </div>
  );
}

function ContractsTable({ additionalPricings = [], servicePricings = [], planPricing = [], onSelectTab }) {
  const context = useBimContext();
  const cols = useMemo(() => buildContractsColumns(onSelectTab), [additionalPricings, servicePricings]);
  const rows = useMemo(
    () =>
      additionalPricings
        .filter((a) => a.proposalStatus === 'ACCEPTED')
        .concat(servicePricings.filter((s) => s.proposalStatus === 'ACCEPTED'))
        .concat(planPricing),
    [additionalPricings, servicePricings, planPricing],
  );
  return (
    <div className={`ContractsTable ${styles.ContractsTable}`}>
      <BngTable
        cols={cols}
        rows={rows}
        stickyHeader={true}
        showEmptyAlert={true}
        emptyAlertProps={{ message: context.msg.t('no.additional.contracts') }}
      />
    </div>
  );
}

const buildContractsColumns = (onSelectTab) => {
  const context = useBimContext();

  return [
    {
      key: 'id',
      label: context.msg.t('proposal.id'),
      sortable: false,
      render: (row) => {
        return (
          <div className={styles.proposalId}>
            <span>{row.proposalId}</span>
            <Icon
              className={styles.clickableIcon}
              icon={'open_in_new'}
              onClick={() => {
                onSelectTab('proposals', null);
              }}
            />
          </div>
        );
      },
    },
    {
      key: 'quantity',
      label: context.msg.t('quantity'),
      render: (row) => {
        return (
          <div>
            <span>{row.quantity}</span>
          </div>
        );
      },
    },
    {
      key: 'name',
      label: context.msg.t('name'),
      render: (row) => {
        const translatedProps = proposalUtils.translationsForProposalPricing(row);
        const planKey = row.pricing.planKey;
        return (
          <div>
            <span>{`${translatedProps?.name ?? proposalUtils.salesFromProposalPricing(row).name} ${
              context.msg.translateIfHasKey(`${planKey}.card.title`) ?? ''
            }`}</span>
          </div>
        );
      },
    },
    {
      key: 'type',
      label: context.msg.t('service.type'),
      render: (row) => {
        const salesObj = proposalUtils.salesFromProposalPricing(row);
        let type = salesObj.feature?.classification;
        type = !!type ? type : salesObj.planFeatures ? 'PLAN' : 'SERVICE';
        return (
          <div>
            <span>{context.msg.translateIfHasKey(`type.${type}`) || context.msg.t(`type.ADDITIONAL`)}</span>
          </div>
        );
      },
    },
    {
      key: 'contractDate',
      label: context.msg.t('contract.date'),
      render: (row) => {
        return (
          <div>
            <span>{Utils.Date.formatDate(row.proposalAcceptDate)}</span>
          </div>
        );
      },
    },
    {
      key: 'recurrence',
      label: context.msg.t('recurrence'),
      render: (row) => {
        return (
          <div>
            <span>{context.msg.t(`recurrence.${proposalUtils.billingFormat(row)}`)}</span>
          </div>
        );
      },
    },
  ];
};

function ConsumptionItems({ planInfo = {}, accountMetrics = {}, additionals = [], onSelectTab }) {
  const context = useBimContext();

  const metrics = {
    userSeats: 0,
    usedUsers: 0,
    structuresSlots: 0,
    usedStructures: 0,
    availableQuota: 0,
    usedQuota: 0,
    addonCount: additionals.filter((ap) => ap.pricing.additional.feature.classification === 'ADDON').length,
  };

  planInfo.planFeatures?.forEach((planFeature) => {
    switch (planFeature.features.classification) {
      case 'USER':
        metrics.userSeats += planFeature.value.value;
        break;
      case 'STRUCTURE':
        metrics.structuresSlots += planFeature.value.value;
        break;
      case 'QUOTA':
        metrics.availableQuota += planFeature.value.value;
    }
  });

  accountMetrics.all.forEach((metric) => {
    switch (metric.resource) {
      case 'USERS':
        metrics.usedUsers += metric.currentUsage;
        break;
      case 'STRUCTURES':
        metrics.usedStructures += accountMetrics.account?.countDataStructures
          ? accountMetrics.origins.length
          : metric.currentUsage;
        break;
      case 'QUOTA':
        const usageValues = Object.values(metric.projectUsage);
        metrics.usedQuota += usageValues.length ? Math.max.apply(null, usageValues) : 0;
        break;
    }
  });

  additionals.forEach((additionalProposalPricing) => {
    if (additionalProposalPricing.proposalStatus === 'ACCEPTED') {
      const additional = additionalProposalPricing.pricing.additional;
      const featureValue = additional?.featureValue.value ?? 1;

      switch (additional.feature.classification) {
        case 'USER':
          metrics.userSeats += additionalProposalPricing.quantity * featureValue;
          break;
        case 'STRUCTURE':
          metrics.structuresSlots += additionalProposalPricing.quantity * featureValue;
          break;
        case 'QUOTA':
          metrics.availableQuota += additionalProposalPricing.quantity * featureValue;
          break;
      }
    }
  });

  return (
    <div className={`ConsumptionItems ${styles.ConsumptionItems}`}>
      <ConsumptionKpi
        label={context.msg.t('user.accents')}
        icon="person"
        color="#4D8EFF"
        value={metrics.usedUsers}
        target={metrics.userSeats}
        link="users"
        onSelectTab={onSelectTab}
        marketplaceLink={Api.buildUrl('/spr/bng/marketplace', {
          currentTab: 'item',
          addon: 'USER_PACKAGE',
        })}
      />
      <ConsumptionKpi
        label={context.msg.t('structures.slots')}
        icon="storage"
        color="#00A355"
        value={metrics.usedStructures}
        target={metrics.structuresSlots}
        link="structures"
        onSelectTab={onSelectTab}
        marketplaceLink={Api.buildUrl('/spr/bng/marketplace', {
          currentTab: 'item',
          addon: 'STRUCTURE_PACKAGE',
        })}
      />
      <ConsumptionKpi
        label={context.msg.t('data.consumption')}
        icon="cloud_upload"
        color="#FAA133"
        value={Utils.formatBytesToGb(metrics.usedQuota)}
        target={metrics.availableQuota}
        link="structures"
        suffix="GB"
        onSelectTab={onSelectTab}
        marketplaceLink={Api.buildUrl('/spr/bng/marketplace', {
          currentTab: 'item',
          addon: 'TRANSFER_QUOTA',
        })}
      />
      <ConsumptionKpi
        label={context.msg.t('addons')}
        icon="extension"
        color="#BA68ED"
        value={metrics.addonCount}
        suffix={context.msg.t('addons')}
        onSelectTab={onSelectTab}
        marketplaceLink={Api.buildUrl('/spr/bng/marketplace', {
          currentTab: 'addons',
        })}
      />
    </div>
  );
}

function ConsumptionKpi({ label, icon = '', color, value, target, link, suffix = '', onSelectTab, marketplaceLink }) {
  const context = useBimContext();

  const redirectTo = (link) => {
    onSelectTab(link, null);
  };

  return (
    <div className={`ConsumptionKpi ${styles.ConsumptionKpi}`}>
      <div className={styles.kpiLabel}>
        <Icon icon={icon} className={styles.kpiIcon} style={{ backgroundColor: color }} />
        <span>{label}</span>
      </div>
      <div className={styles.valueWrapper}>
        <a href={marketplaceLink}>
          <span className={styles.link}>{context.msg.t('contract.now')}</span>
        </a>
        <div className={styles.consumptionValue}>
          {`${value} ${suffix}`}
          {!!target ? ` ${context.msg.t('of')} ${target} ${suffix}` : ''}
          {link && <Icon icon={'open_in_new'} className={styles.valueRedirectIcon} onClick={() => redirectTo(link)} />}
        </div>
        {!!target && (
          <>
            <svg width="100" height="7" viewBox="0 0 100 7" fill="none" xmlns="http://www.w3.org/2000/svg">
              <rect width="100" height="7" rx="3.5" fill="#D9D9D9" />
              <rect width={value > target ? 100 : (100 * value) / target} height="7" fill={color} rx="3.5" />
            </svg>
            <span className={styles.valuePercent}>{`${((100 * value) / target).toFixed()}% ${context.msg.t(
              'of.plan',
            )}`}</span>
          </>
        )}
      </div>
    </div>
  );
}

function InvoicesTable({ invoices = [], account }) {
  const context = useBimContext();
  const dispatch = useReduxDispatch();
  const cols = useMemo(() => buildInvoicesColumns({ context, dispatch, account }), [account]);
  return (
    <BngTable
      cols={cols}
      rows={invoices.sort((a, b) => b.invoiceProjection.referenceMonth - a.invoiceProjection.referenceMonth)}
      stickyHeader={true}
      showEmptyAlert={true}
      emptyAlertProps={{ message: context.msg.t('no.additional.contracts') }}
      className={`InvoicesTable ${styles.InvoicesTable}`}
    />
  );
}

const SITUATION = Object.freeze({
  projected: 'projected',
  paid: 'paid',
  open: 'open',
});

const formatPricing = (pricing, row) => {
  const formattedPricing = (Math.round(pricing * 100) / 100).toFixed(2);
  const currencySymbol = row.invoiceProjection.contract.currency.symbol;
  return proposalUtils.formatPricing(formattedPricing, currencySymbol);
};

const getInvoiceSituation = (row) => {
  const isCurrentMonth = row.invoiceProjection.referenceMonth.getMonth() === new Date().getMonth();
  const isCommited = row.invoiceProjection.status === 'COMMITTED';
  let situation = isCommited ? SITUATION.open : SITUATION.projected;
  const paidPricing = calculatePaidPricing(row);
  if (row.invoiceProjection.nominalPricing === paidPricing) {
    situation = SITUATION.paid;
  } else if (isCurrentMonth && isCommited) {
    situation = SITUATION.open;
  }
  return situation;
};

const calculatePaidPricing = (row) => {
  let paidPricing = 0;
  row.invoiceProjectionItems.forEach((item) => {
    if (item.paid && !item.ignored) {
      paidPricing += Math.round(item.pricing * 100) / 100;
    }
  });
  paidPricing = Math.round((paidPricing + Number.EPSILON) * 100) / 100;
  return parseFloat(paidPricing.toFixed(2));
};

const buildInvoicesColumns = ({ context, dispatch, account }) => {
  return [
    {
      key: 'invoices',
      label: context.msg.t('id.projection'),
      sortable: false,
      render: (row) => {
        return (
          <div className={styles[getInvoiceSituation(row)]}>
            <span>{row.invoiceProjection.id}</span>
          </div>
        );
      },
    },
    {
      key: 'emissionDate',
      label: context.msg.t('emission.date'),
      sortable: false,
      render: (row) => {
        let emissionDateInvoice = null;
        if (row.invoices && row.invoices.length > 0) {
          emissionDateInvoice = row.invoices.find(
            (invoice) => invoice.invoiceProjection.id === row.invoiceProjection.id,
          );
        }
        return (
          <div className={styles.emissionWrapper}>
            <span>{Utils.Date.formatDate(emissionDateInvoice?.date)}</span>
            {row.invoiceProjection.type === 'PARTIAL' && (
              <Icon
                className={`${styles[getInvoiceSituation(row)]} ${styles.partialIcon}`}
                title={context.msg.t('partial.invoice')}
                icon={'event_repeat'}
              />
            )}
          </div>
        );
      },
    },
    {
      key: 'payment',
      label: context.msg.t('payment'),
      sortable: false,
      render: (row) => {
        return (
          <div className={styles[getInvoiceSituation(row)]}>
            <span>{context.msg.t('bank.slip')}</span>
          </div>
        );
      },
    },
    {
      key: 'dueTime',
      label: context.msg.t('due.time'),
      sortable: false,
      render: (row) => {
        const dueDate = row.invoiceProjection.referenceMonth;
        return (
          <div>
            <span>{Utils.Date.formatDate(dueDate)}</span>
          </div>
        );
      },
    },
    {
      key: 'openPricing',
      label: context.msg.t('open.pricing'),
      sortable: false,
      render: (row) => {
        const paidPricing = calculatePaidPricing(row);
        const openPricing = row.invoiceProjection.nominalPricing - paidPricing;
        return (
          <div>
            <span>{formatPricing(openPricing, row)}</span>
          </div>
        );
      },
    },
    {
      key: 'closedPricing',
      label: context.msg.t('closed.pricing'),
      sortable: false,
      render: (row) => {
        const paidPricing = calculatePaidPricing(row);
        return (
          <div>
            <span>{formatPricing(paidPricing, row)}</span>
          </div>
        );
      },
    },
    {
      key: 'nominalPricing',
      label: context.msg.t('nominal.pricing'),
      sortable: false,
      render: (row) => {
        return (
          <div>
            <span>{formatPricing(row.invoiceProjection.nominalPricing, row)}</span>
          </div>
        );
      },
    },
    {
      key: 'situation',
      label: context.msg.t('situation'),
      sortable: false,
      render: (row) => {
        const situation = getInvoiceSituation(row);

        return (
          <div className={styles[situation]}>
            <span>{context.msg.t(`situation.${situation}`)}</span>
          </div>
        );
      },
    },
    {
      key: 'actions',
      label: context.msg.t('actions'),
      sortable: false,
      render: (row) => {
        return (
          <BngDropdown
            popperOpts={{ placement: 'bottom-end' }}
            btnIconProps={{ className: styles.actionsButton }}
            options={[
              {
                label: context.msg.t('see.details'),
                icon: 'receipt_long',
                onClick: () => {
                  dispatch(
                    MODALS.open(ProjectionDetailsDialog, {
                      accountId: account.id,
                      invoiceProjectionId: row.invoiceProjection.id,
                    }),
                  );
                },
              },
            ]}
          />
        );
      },
    },
  ];
};

export default function AccFinanceTab({ account, onSelectTab }) {
  const context = useBimContext();
  const [loading, setLoading] = useState(false);
  const [accountInfo, setAccountInfo] = useState(null);
  const [news, setNews] = useState();

  const findAllAccountInfo = async () => {
    try {
      const accountInfo = await Api.Account.fetchBillingAccountInfo(account.id);
      setAccountInfo(accountInfo);
    } catch (e) {
      console.error('Error on function findAllAccountInfo()', e);
      const status = _.get(e, 'response.status', 0);
      let errorMsg = '';
      switch (status) {
        case 404:
          errorMsg = 'account.not.in.billing';
          break;
        case 412:
          errorMsg = 'accountInfo.inflator.error';
          break;
        default:
          errorMsg = 'accountInfo.fetch.error';
          break;
      }
      UiMsg.error(context.msg.t(errorMsg));
      setAccountInfo(null);
    }
  };

  useEffect(() => {
    (async () => {
      setLoading(true);
      try {
        await Promise.all([findAllAccountInfo(), findLatestPromotion()]);
      } finally {
        setLoading(false);
      }
    })();
  }, [account]);

  const openNews = () => {
    if (!!news.link) {
      window.open(news.link, '_blank');
    } else {
      window.open(`${Api.baseUrl()}/spr/bng/news`, '_blank');
    }
  };

  const findLatestPromotion = async () => {
    try {
      const fetchedNews = await Api.News.all(19);
      setNews(fetchedNews[0]);
    } catch (e) {
      console.error('Error on function findLatestPromotion()', e);
    }
  };

  return (
    <UiBlocker block={loading} className={`AccFinanceTab ${styles.AccFinanceTabBlocker}`}>
      {accountInfo != null && (
        <div className={`${styles.AccFinanceTab}`}>
          {!!accountInfo.planPricing && (
            <section id="AccFinanceTab-plan" className={styles.planSection}>
              <div className={styles.planCardWrapper}>
                <span>{context.msg.t('contract.plan')}</span>
                <PlanCard
                  planInfo={accountInfo.activePlan}
                  planPricing={accountInfo.planPricing}
                  agreedReviewRates={accountInfo.contract.agreedContractReviewRates}
                  onChange={findAllAccountInfo}
                />
              </div>
              <div className={styles.newsWrapper}>
                <span>{context.msg.t('news')}</span>
                {news ? (
                  <div className={`newsCard ${styles.newsCard} ${styles.ctaPlaceholder}`} onClick={openNews}>
                    <img src={news.image_local_path} />
                  </div>
                ) : (
                  <AddonInfoAd accountId={account.id} />
                )}
              </div>
            </section>
          )}

          <section id="AccFinanceTab-contract" className={styles.contractSection}>
            <span className={styles.sectionTitle}>{context.msg.t('contracts')}</span>
            <ContractsTable
              additionalPricings={accountInfo.additionalPricings}
              servicePricings={accountInfo.servicePricings}
              planPricing={accountInfo.planPricing}
              onSelectTab={onSelectTab}
            />
          </section>

          <section id="AccFinanceTab-infoCard" className={styles.infoCardSection}>
            <div className={styles.infoCard}>
              <span>{context.msg.t('billing_type')}</span>
              <span className={styles.infoValue}>{context.msg.t('bank.slip')}</span>
            </div>
            {!!accountInfo.planPricing && (
              <div className={styles.infoCard}>
                <span>{context.msg.t('periodicity')}</span>
                <span className={styles.infoValue}>
                  {context.msg.t(proposalUtils.billingFormat(accountInfo.planPricing))}
                </span>
              </div>
            )}
            <div className={styles.infoCard}>
              <span>{context.msg.t('billing.contact')}</span>
              <span className={styles.infoValue}>{accountInfo.contract.buyerInfo.emails}</span>
            </div>
          </section>

          <section id="AccFinanceTab-consumption" className={styles.consumptionSection}>
            <span className={styles.sectionTitle}>{context.msg.t('consumption')}</span>
            <div className={styles.consumptionItemWrapper}>
              <ConsumptionItems
                planInfo={accountInfo.activePlan}
                accountMetrics={accountInfo.metrics}
                additionals={accountInfo.additionalPricings}
                onSelectTab={onSelectTab}
              />
            </div>
          </section>

          <section id="AccFinanceTab-invoices" className={styles.invoicesSection}>
            <span className={styles.sectionTitle}>{context.msg.t('invoices')}</span>
            <InvoicesTable invoices={accountInfo.invoices} account={account} />
          </section>
        </div>
      )}
    </UiBlocker>
  );
}
