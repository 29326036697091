import App from "components/App";
import BngInputColor from "components/bng/form/BngInputColor";
import {NoteViewPopper} from "components/bng/note/NoteView";
import {ceData} from "components/CeData";
import CockpitHeader from "components/ui/cockpit/CockpitHeader";
import Icon from "components/ui/common/Icon";
import SelectIconDialog from "components/ui/common/SelectIconDialog";
import DashGridEditor from "components/ui/dashboard/components/DashGridEditor";
import RequiredFilters from "components/ui/dashboard/components/RequiredFilters";
import ProjectTypeChangeButton from "components/ui/project/ProjectTypeChangeButton";
import Activity from "components/ui/recent-activities/Activity";
import StoreFactory from "components/ui/redux/StoreFactory";
import * as React from "react";
import ReactDOM from "react-dom";
import ReactDOMServer from 'react-dom/server'
import {Provider} from "react-redux";
import Api from "./Api";
import ChartConf from "./ui/analysis/ChartConf";
import ExpertConf from "./ui/analysis/ExpertConf";
import Cockpit from "./ui/cockpit/Cockpit";
import IconText from "./ui/dashboard/components/IconText";
import ImageDash from "./ui/dashboard/components/ImageDash";
import Text from "./ui/dashboard/components/Text";
import {MODALS} from "components/ui/redux/Actions";
import Kpi from "components/ui/kpi/Kpi";
import Favorite from "./ui/favorite/Favorite";
import LoadingBox from "./ui/loading/LoadingBox";
import LoadingCenter from "./ui/loading/LoadingCenter";
import LoadingPulse from "./ui/loading/LoadingPulse";
import BiSource from "./ui/map/BiSource";
import LeafletMap from "./ui/map/LeafletMap";
import {NewMapSource} from "./ui/map/NewMapSource";
import EditObjectContainer from "./ui/edit-object/EditObjectContainer";
import BimStorePage from "./ui/bimstore/BimStorePage";
import NewsHighlight from "./ui/news/NewsHighlight";
import LinksBox from "./ui/orgmap/LinksBox";
import {BngAnalysisTable} from "./bng/pages/analysis-view/BngAnalysisTable";
import {ContainerCreator} from "components/ui/dashboard/components/ContainerCreator";
import FilterFactory from "components/filter/FilterFactory";
import DashBreadcrumbToolbar from "./ui/dashboard/components/DashBreadcrumbToolbar";
import AnalysisBreadcrumbToolbar from "./ui/analysis/AnalysisBreadcrumbToolbar";
import OrgmapBreadcrumbToolbar from "./ui/orgmap/OrgmapBreadcrumbToolbar";
import RecentActivities from "./ui/recent-activities/RecentActivities";
import KpiBreadcrumbToolbar from "./ui/kpi/KpiBreadcrumbToolbar";
import MapBreadcrumbToolbar from "./ui/map/MapBreadcrumbToolbar";
import BreadcrumbToolbar from "components/ui/breadcrumb-toolbar/BreadcrumbToolbar";
import {
    CockpitPermissionDialog,
    OriginPermissionDialog,
    PathPermissionDialog,
    PermissionDialog
} from "components/bng/permission/PermissionDialog";
import UiMsg, {UiMsgContainer} from "components/ui/UiMsg";
import Aggregation from "./ui/analysis/Aggregation";
import Utils from "components/Utils";
import SaveAsDialogContainer from "components/ui/common/SaveAsDialogContainer";
import DashRightMenu from "components/ui/dashboard/components/DashRightMenu";
import KpiRightMenu from "components/ui/kpi/KpiRightMenu";
import OrgmapRightMenu from "components/ui/orgmap/OrgmapRightMenu";
import AnalysisRightMenu from "components/ui/analysis/AnalysisRightMenu";
import MapRightMenu from "components/ui/map/MapRightMenu";
import Dialog from "components/ui/Dialog";
import LoadLimitButton from "./bng/load-limit/LoadLimitButton";
import BngNewAnalysis from "components/bng/pages/newAnalysis/BngNewAnalysis";
import BngAnalystMenu from "components/bng/pages/newAnalysis/BngAnalystMenu";
import {BngAdvancedModeAlert} from "components/bng/pages/newAnalysis/BngAdvancedModeAlert";
import PublisherForbiddenAccess from "components/ui/publisher/PublisherForbiddenAccess";
import RenameDialogContainer from "components/ui/common/RenameDialogContainer";
import HtmlComponent from "components/ui/dashboard/components/HtmlComponent";
import BigTable from "components/bng/pages/bigTable/BigTable"
import BigTableRenderer from "components/bng/pages/bigTable/BigTableRenderer";
import BimIntegrationOrigins from "components/ui/in-memory/bim-integration/BimIntegrationOrigins";
import DataOriginsDialog from "components/ui/in-memory/DataOriginsDialog";
import UserList from "components/bng/pages/admin/users/UserList";
import FeatureManagement from "components/bng/pages/admin/features/FeatureManagement";
import NonEmptyAlertPopup from "components/ui/analysis/NonEmptyAlertPopup";
import ConnectionsDialog from "components/ui/in-memory/ConnectionsDialog";
import FormConnectionDialog from "components/ui/in-memory/FormConnectionDialog";
import UserGroupsDialog from "components/bng/pages/admin/users/UserGroupsDialog";
import IndexPage from "components/ui/cockpit/IndexPage";
import StructuresPage from "components/bng/pages/admin/structures/StructuresPage";
import FoldersPage from "components/bng/pages/admin/folders/FoldersPage";
import ServerErrorPage from "components/bng/pages/errors/ServerErrorPage";
import ForbiddenErrorPage from "components/bng/pages/errors/ForbiddenErrorPage";
import ResourceNotFoundErrorPage from "components/bng/pages/errors/ResourceNotFoundErrorPage";
import NotFoundErrorPage from "components/bng/pages/errors/NotFoundErrorPage";
import ServerTooBusyErrorPage from "components/bng/pages/errors/ServerTooBusyErrorPage";
import TimeoutErrorPage from "components/bng/pages/errors/TimeoutErrorPage";
import UserWithoutProjectErrorPage from "components/bng/pages/errors/UserWithoutProjectErrorPage";
import BimEventBus from "BimEventBus";
import {MoveObject} from "components/ui/dashboard/components/MoveObject";
import StructuresPageUtils from "components/bng/pages/admin/structures/StructuresPageUtils";
import BngDropdownTagsWrapper from "components/bng/ui/BngDropdownTagsWrapper";
import WhatsAppInfoDialog from "components/bng/pages/whatsApp/WhatsAppInfoDialog";
import OrgMapSelectObjectTree from "components/ui/orgmap/OrgMapSelectObjectTree";
import MonitorValueSourceObjectTree from "components/bng/monitoring/MonitorValueSourceObjectTree";
import MonitorMessageObjectsTreeDialog from "components/bng/monitoring/MonitorMessageObjectsTreeDialog";
import EventList from "components/bng/pages/admin/events/EventList";
import GroupRender from "components/bng/pages/admin/structures/GroupRender";
import InvalidPeriodicityAlert from "components/ui/common/InvalidPeriodicityAlert";
import {showQueryError} from "components/ui/map/editor/NewMapUtils";
import MembersConfigPage from "components/bng/pages/admin/users/UsersPage";
import BngAnalysisDrillDownBar, {
    chartElementClicked,
    parseChartItemClickedData
} from "components/bng/analysis/BngAnalysisDrillDownBar";
import BngEmpty from "components/bng/ui/BngEmpty";
import StructureNotLoadedPage from "components/bng/pages/errors/StructureNotLoadedPage";
import DashboardItemInformation from "components/ui/dashboard/components/DashboardItemInformation";
import {Axios} from "commonUtils";
import KeyFieldSelector from "components/bng/pages/admin/structures/KeyFieldSelector";
import OpConfirmation from "components/ui/OpConfirmation";
import AddonAcceptPage from "components/bng/accounts/addons/AddonAcceptPage";
import BngApp from "bng/BngApp";
import BypassComponent from "components/BypassComponent";
import BngTreeDropdownContainer from "components/bng/form/BngTreeDropdownContainer";
import AddonDisabledDialog from 'components/bng/accounts/addons/AddonDisabledDialog';
import BimUniversityPage from "components/ui/university/BimUniversityPage";
import AddonsPage from "components/ui/navbar/addons/AddonsPage";
import AddonInfoPage from "components/ui/navbar/addons/AddonInfoPage";
import GenericFileSource from "components/bng/pages/admin/structures/GenericFileSource";
import AnalysisECharts from "components/bng/analysis/AnalysisECharts";
import SchedulingPage from "components/bng/pages/exportScheduling/SchedulingPage";
import OrphanDwTablesPage from 'components/bng/pages/admin/structures/dw/OrphanDwTablesPage';
import BngNewKpiPage from "components/bng/pages/kpi/BngNewKpiPage";
import AddAdditionalDialog, { ADDITIONALS } from "components/bng/accounts/additionals/AddAdditionalDialog";
import CreateProjectPage from "components/bng/pages/create-project/CreateProjectPage";
import BngQuotaViewer from "components/ui/project/BngQuotaViewer";

const runAfterInit = (fn) => {
    application.utils.waitFor(() => {
        return j('.JsfReactIntegrationMarker').length === 0
            || j('div.App').length > 0;
    }, fn, 20000, fn);
};

const runAfterCeDataInit = (fn) => {
    application.utils.waitFor(() => ceData.state.initialized, fn, 20000, fn);
};

class ComponentFactory {

    static initCeData = ceData.initCeData;

    static runAfterInit = runAfterInit;

    static initBpp = async (data, translations, devel = false) => {
        window.SERVER_STATE = data;
        window.ReduxStore = ComponentFactory.Store(data, devel);
        await ceData.initCeData(data.context, translations);
        const reactRoot = document.getElementById('bim-react');
        ComponentFactory.renderApp(reactRoot, window.ReduxStore, BngApp);
    };

    static Store = (serverState, devel) => StoreFactory(serverState, devel);

    static renderApp = (container, store, Component = App) => ReactDOM.render(<Component store={store}/>, container);

    static initializeReact = async (devel) => {
        try {
            const {data, request} = await Axios.get('/spr/ui/server-state');
            // request is following redirects and retrieving html response from login page when user is not logged in
            const userNotLoggedIn = request.responseURL.includes('/login');
            if (userNotLoggedIn) {
                window.location.replace(request.responseURL);
                return;
            }
            window.SERVER_STATE = data;
            window.ReduxStore = ComponentFactory.Store(window.SERVER_STATE, devel);
            await ceData.initCeData(window.SERVER_STATE.context);
            const reactRoot = document.getElementById('bim-react');
            ComponentFactory.renderApp(reactRoot, window.ReduxStore);
            if (typeof Ice !== 'undefined') {
                Ice.onSendReceive('document:body',
                    function () {
                    },
                    function () {
                        Api.Event.getAndProcessEvents();
                    })
            }
        } catch (e) {
            console.error('Error on ComponentFactory.initializeReact', {devel}, e)
        }
    };

    static initCdataOutOfJsf = async () => {
        if (j('#react-integration').length === 0) {
            await ceData.initCeData();
        } else {
            await new Promise(res => runAfterInit(res));
        }
    };

    static renderFavorite = (container, favorites) => {
        runAfterInit(() => {
            ReactDOM.render(<Favorite favorites={favorites}/>, container);
        });
    };

    static renderRecentActivities = (container, activities) => {
        runAfterInit(() => {
            ReactDOM.render(<RecentActivities activities={activities}/>, container);
        });
    };


    /**
     * activity = UiResource -> LastActivityResponse
     * @param container
     * @param activity
     * @param props
     */
    static renderActivity = (container, activity, props = {}) => {
        runAfterInit(() => {
            ReactDOM.render(<Activity activity={activity} {...props}/>, container);
        });
    };

    static renderLoadingCenter = (container, props = {}) => {
        ReactDOM.render(<LoadingCenter {...props}/>, container);
    };

    static renderLoadLimitButton = (container, accountId, props = {}) => {
        runAfterInit(
            () => ReactDOM.render(
                <Provider store={window.ReduxStore}>
                    <LoadLimitButton accountId={accountId} {...props}/>
                </Provider>,
                container
            )
        );
    };

    static renderLoadingBox = (container, className) => {
        ReactDOM.render(<LoadingBox className={className}/>, container);
    };

    static renderLoadingPulse = (props, container) => {
        ReactDOM.render(<LoadingPulse {...props} />, container);
    };

    static renderEditObject = (container, props = {state: false}) => {
        const {state, ...otherProps} = props;
        if (j('#EditObjectContainer').length > 0) {
            return;
        }
        runAfterInit(() => {
            ReactDOM.render(
                <EditObjectContainer checked={state} {...otherProps}/>,
                container
            );
        });
    };

    static renderCockpit = async (container, cockpit) => {
        await ceData.initCeData();
        ReactDOM.render(<Cockpit cockpit={cockpit}/>, container);
    };

    static renderAdminUserList = (container) => {
        runAfterCeDataInit(() => {
            ReactDOM.render(
                <Provider store={window.ReduxStore}>
                    <UserList/>
                </Provider>,
                container
            );
        })
    }

    static renderAdminEventList = (container) => {
        runAfterCeDataInit(() => {
            ReactDOM.render(
                <Provider store={window.ReduxStore}>
                    <EventList/>
                </Provider>,
                container
            );
        })
    }

    static renderFeatureManagement = (container) => {
        runAfterCeDataInit(() => {
            ReactDOM.render(
                <Provider store={window.ReduxStore}>
                    <FeatureManagement/>
                </Provider>,
                container
            );
        })
    }

    static renderOrphanDwTablesPage = (container) => {
        runAfterCeDataInit(() => {
            ReactDOM.render(
              <Provider store={window.ReduxStore}>
                  <OrphanDwTablesPage />
              </Provider>,
              container
            );
        })
    }

    static renderFilters = async (container, params, isPublisher = false, type = 'DASHBOARD') => {
        if (window.__CURRENT_FILTER_BAR__) {
            ReactDOM.unmountComponentAtNode(window.__CURRENT_FILTER_BAR__.container);
            delete window.__CURRENT_FILTER_BAR__;
        }

        const initFn = () => {
            window.__CURRENT_FILTER_BAR__ = FilterFactory.renderComponent(container, {
                type: type,
                isPublisher: isPublisher,
                ...params
            });
            window.__CURRENT_FILTER_BAR__.container = container;
            if (window.__CURRENT_FILTER_BAR_CLEAR_ITEM_CACHE__) {
                window.__CURRENT_FILTER_BAR__.wrappedComponent.clearFilterItemsCache();
            }
        };

        runAfterCeDataInit(initFn);
    };

    static renderRequiredFilters = async (container, requiredFiltersInfo) => {
        await ceData.initCeData();
        ReactDOM.render(<RequiredFilters requiredFiltersInfo={requiredFiltersInfo}/>, container);
    };

    static renderCockpitHeader = async (container, props) => {
        const $old = j('#cockpit-nav-header');
        if ($old.length > 0) {
            ReactDOM.unmountComponentAtNode($old[0]);
        }
        runAfterCeDataInit(() => {
            ReactDOM.render(
                <Provider store={window.ReduxStore}>
                    <CockpitHeader {...props}/>
                </Provider>, container
            );
        })
    };

    static renderBIMAcademy = async (container) => {
        runAfterInit(
            () => {
                const {context} = window.ReduxStore.getState();
                if (!context.showBimAcademy) return;
                ReactDOM.render(
                    <Provider store={window.ReduxStore}>
                        <NewsHighlight/>
                    </Provider>,
                    container
                );
            }
        );
    };

    static renderDataOrigins = (currentSelection = {sourceType: '', wsType: '', bimIntegrationName: ''}) => {
        window.ReduxStore.dispatch(
            MODALS.open(DataOriginsDialog, {
                onSelect: async (params) => {
                    await application.Api.executeExp(`#{inMemoryBean.sourceTypeChanged('${params.origin.sourceType}', '${params.origin.route || ''}')}`);
                    params.closeModal();
                },
                currentSelection
            })
        );
    };

    static renderConnections = (props = {}) => {
        window.ReduxStore.dispatch(
            MODALS.open(ConnectionsDialog, props)
        );
    };

    static renderFormConnection = (props = {}) => {
        window.ReduxStore.dispatch(
            MODALS.open(FormConnectionDialog, props)
        );
    };

    static renderUserGroupsDialog = (props = {}) => {
        try {
            window.ReduxStore.dispatch(
                MODALS.open(UserGroupsDialog, props)
            );
        } catch (e) {
            console.error('Error on renderUserGroupsDialog()', props, e);
        }
    };

    static renderAddAdditionalDialogForStructuresPage = (openKnowledgeBase = false) => {
        try {
            window.ReduxStore.dispatch(
              MODALS.open(AddAdditionalDialog, {additional: ADDITIONALS.STRUCTURES, openKnowledgeBase: openKnowledgeBase, useFeatureClassification: true})
            );
        } catch (e) {
            console.error('Error on renderUserGroupsDialog()', props, e);
        }
    }

    static BimStore = {
        render: (container) => {
            runAfterInit(
                () => ReactDOM.render(
                    <Provider store={window.ReduxStore}>
                        <BimStorePage/>
                    </Provider>,
                    container
                )
            );
        }
    };

    static Addon = {
        renderActivatePage(container, props) {
            runAfterCeDataInit(() => {
                ReactDOM.render(
                    <AddonAcceptPage currentAddonKey={props.addonKey}
                                     projectId={props.projectId}
                                     accountId={props.accountId}
                    />,
                    container
                );
            });
        },
        renderAddonDisabledDialog(props) {
            runAfterCeDataInit(() => {
                window.ReduxStore.dispatch(
                    MODALS.open(AddonDisabledDialog, {
                        addonKey: props.addonKey,
                    })
                );
            })
        }
    };

    static BimUniversity = {
        render: (container) => {
            runAfterInit(
                () => ReactDOM.render(
                    <BimUniversityPage/>,
                    container
                )
            );
        }
    }

    static Dash = {
        renderLabel(container, opts) {
            opts.value = opts.content;
            ReactDOM.render(<Text {...opts} />, container);
        },
        renderIcon(container, opts) {
            ReactDOM.render(<IconText {...opts} />, container);
        },
        renderImage(container, opts) {
            ReactDOM.render(<ImageDash {...opts} />, container);
        },
        renderHtml(container, opts) {
            ReactDOM.render(<HtmlComponent {...opts} />, container);
        },
        renderMap(container, filters, mdxFilter = '', fromCockpit) {
            const $this = jQuery(container);
            try {
                ComponentFactory.Map.load(container, {mapPath: $this.data('path'), filters, mdxFilter, fromCockpit});
            } catch (e) {
                console.error(e);
            }
        },
        updateCurrentDash(gridData) {
            window.__LAST_DASHBOARD_OPTS = gridData;
            ComponentFactory.Dash.renderablePreloadResize();
        },
        renderablePreloadResize() {
            j('.free-style-marker-class').each(function () {
                ReactDOM.unmountComponentAtNode(this);
                window.__RENDERABLE_PRELOAD_CLEAR_CACHE?.();
                ComponentFactory.Dash.renderDashGrid(this, window.___LAST_DASHBOARD_OPTS);
            });
        },
        async renderDashGrid(container, opts = {}) {
            window.___LAST_DASHBOARD_OPTS = opts;
            BimEventBus.emit('Change:___LAST_DASHBOARD_OPTS', opts);
            runAfterInit(() => {
                try {
                    window.__UNMOUNT_CURRENT_RENDERED_DASHGRID?.();

                    if (opts.editMode) {
                        opts = {
                            ...opts,
                            editMode: opts.editMode && !document.getElementById('cockpit-item-panel')
                        };
                    }

                    let dashGrid;
                    ReactDOM.render(
                        <Provider store={window.ReduxStore}>
                            <DashGridEditor fromCockpit={Utils.History.currentUrlSearchParams().get('cockpitId')}
                                            {...opts}
                                            ref={el => dashGrid = el}
                            />
                        </Provider>,
                        container
                    );

                    window.__UNMOUNT_CURRENT_RENDERED_DASHGRID = () => {
                        try {
                            delete window.__UNMOUNT_CURRENT_RENDERED_DASHGRID;
                            ReactDOM.unmountComponentAtNode(container);
                        } catch (e) {
                            console.error('Error while unmounting DashGrid on renderDashGrid', e);
                        }
                    };

                    // Need to restore state in cases where JSF destroy the element
                    if (window.__CURRENT_DASHGRID) {
                        dashGrid.dashGridRef.wrappedComponent.toggleCustomSelect(window.__CURRENT_DASHGRID.state.customSelect);
                        dashGrid.dashGridRef.wrappedComponent._layoutListeners = window.__CURRENT_DASHGRID._layoutListeners;
                    }
                    window.__CURRENT_DASHGRID = dashGrid.dashGridRef.wrappedComponent;
                } catch (e) {
                    console.error("ERROR WHILE RENDERING DashGrid:",
                        " -> CONTAINER:", container,
                        " -> OPTIONS:", opts,
                        " -> ERROR:", e
                    );
                }
            });
        },
        toggleContainerCreator(dashGrid, initialData) {
            const $old = j('.ContainerCreatorParent');
            if ($old.length > 0) {
                ReactDOM.unmountComponentAtNode($old[0]);
                $old.remove();
            }

            dashGrid().setState({selectedItem: null}, () => {
                const container = document.createElement('div');
                container.classList.add('ContainerCreatorParent');
                document.body.appendChild(container);

                ReactDOM.render(
                    <ContainerCreator dashGrid={dashGrid}
                                      container={container}
                                      initialData={initialData}/>,
                    container
                );
            });
        },
        async toggleMove(dashGrid, selectedId) {
            const $old = j('.MoveObjectParent');
            if ($old.length > 0) {
                ReactDOM.unmountComponentAtNode($old[0]);
                $old.remove();
            }

            dashGrid().setState({selectedItem: null}, () => {
                const container = document.createElement('div');
                container.classList.add('MoveObjectParent');
                document.body.appendChild(container);

                ReactDOM.render(
                    <MoveObject dashGrid={dashGrid}
                                container={container}
                                idFirstSwap={selectedId}
                    />,
                    container
                );
            });
        },
        renderBreadcrumbToolbar(container, props) {
            BreadcrumbToolbar.renderIfNotExists(() =>
                runAfterInit(() =>
                    ReactDOM.render(
                        <Provider store={window.ReduxStore}>
                            <DashBreadcrumbToolbar {...props} />
                        </Provider>,
                        container
                    )
                )
            );
        },
        renderRightMenu(container, props) {
            if (!container) {
                console.warn('Trying to render DashRightMenu on null container, ignoring...');
                return;
            }

            runAfterInit(() => {
                ReactDOM.render(
                    <Provider store={window.ReduxStore}>
                        <DashRightMenu renderId={Utils.randomId()}
                                       {...props}
                        />
                    </Provider>,
                    container);
            });
        },
        renderDashItemInfo(container, props) {
            const msg = ceData?.context?.msg?.t('dashboard.item.error.message') || 'Error';
            ReactDOM.render(
                <DashboardItemInformation message={msg}
                                          snackbarType="error"
                                          showErrorDialog={() => application.utils.showErrorPopup('', props.errorTrace)}
                                          {...props}
                />,
                container
            );
        },
        renderDrillDown(container, props) {
            const render = () => {
                    try {
                        ReactDOM.render(
                          <BngAnalysisDrillDownBar {...props} />,
                          container
                        );
                    } catch (e) {
                        console.error('Error on Dash.renderDrillDown', {container, props}, e);
                    }
            }
            if (props.exporting) {
                render();
            } else {
                runAfterCeDataInit(() => {
                    render();
                });
            }
        }
    };

    static Map = {
        load: (container, opts) => {
            ReactDOM.render(<BiSource {...opts}/>, container);
        },
        ready: (container, opts) => {
            let adaptedOpts = BiSource.adaptLegacyMap(opts);
            ReactDOM.render(<LeafletMap {...adaptedOpts}/>, container);
        },
        readyNewMap: async (container, {query, style, geoJSON, exportView}) => {
            const {context} = await ceData.initCeData();
            runAfterCeDataInit(() => {
                if (geoJSON.error) {
                    geoJSON = Api.NewMap.processGeoJSON(geoJSON);
                    showQueryError(geoJSON.error, context.msg, {backdrop: false});
                }
                ReactDOM.render(
                    <NewMapSource query={query}
                                  style={style}
                                  location="cockpit"
                                  geoJSON={geoJSON}
                                  exportView={exportView}
                    />,
                    container
                )
            });
        },
        adaptLegacyMap: BiSource.adaptLegacyMap,
        renderBreadcrumbToolbar(container, props) {
            BreadcrumbToolbar.renderIfNotExists(() =>
                runAfterInit(() =>
                    ReactDOM.render(
                        <Provider store={window.ReduxStore}>
                            <MapBreadcrumbToolbar {...props} />
                        </Provider>,
                        container
                    )
                )
            );
        },
        renderRightMenu(container, props) {
            runAfterInit(() => {
                ReactDOM.render(
                    <Provider store={window.ReduxStore}>
                        <MapRightMenu {...props}/>
                    </Provider>,
                    container);
            });
        },
    };

    static OrgMap = {
        linksBox: (props) => {
            return ReactDOMServer.renderToString(<LinksBox {...props}/>);
        },
        renderBreadcrumbToolbar(container, props) {
            BreadcrumbToolbar.renderIfNotExists(() =>
                runAfterInit(() =>
                    ReactDOM.render(
                        <Provider store={window.ReduxStore}>
                            <OrgmapBreadcrumbToolbar {...props} />
                        </Provider>,
                        container
                    )
                )
            );
        },
        renderRightMenu(container, props) {
            runAfterInit(() => {
                ReactDOM.render(
                    <Provider store={window.ReduxStore}>
                        <OrgmapRightMenu {...props}/>
                    </Provider>,
                    container);
            });
        },
        renderObjectTree(container, props = {}) {
            runAfterInit(() => {
                try {
                    ReactDOM.render(
                        <Provider store={window.ReduxStore}>
                            <OrgMapSelectObjectTree {...props} />
                        </Provider>,
                        container
                    );
                } catch (e) {
                    console.error('Error on OrgMap.renderObjectTree()', container, props, e);
                }
            });
        }
    };

    static dashboardMaps = () => {
        runAfterInit(async () => {
            const filters = eval(j('.map-filters-val').val());
            const queryParams = Utils.History.currentUrlSearchParams();
            jQuery('.map').each(function () {
                const mdxFilter = j(this).data('filter');
                const fromCockpit = queryParams.get('cockpitId')
                ComponentFactory.Dash.renderMap(this, filters, mdxFilter, fromCockpit);
            });
        });
    };

    static Analysis = {
        renderChartConf(container, opts) {
            ReactDOM.render(<ChartConf {...opts} />, container);
        },
        renderExpertConf(container, opts) {
            ReactDOM.render(<ExpertConf {...opts} />, container);
        },
        renderBppAnalysisTable: async function (container, opts) {
            await ComponentFactory.initCdataOutOfJsf();
            ReactDOM.render(<BngAnalysisTable {...opts} />, container);
        },
        renderBreadcrumbToolbar(container, props) {
            BreadcrumbToolbar.renderIfNotExists(() =>
                runAfterInit(() =>
                    ReactDOM.render(
                        <Provider store={window.ReduxStore}>
                            <AnalysisBreadcrumbToolbar {...props} />
                        </Provider>,
                        container
                    )
                )
            );
        },
        renderAggregationDropdown({container, anchorEl, ...props}) {
            runAfterCeDataInit(() => {
                const $old = j('.AggregationViewParent');
                if ($old.length > 0) {
                    ReactDOM.unmountComponentAtNode($old[0]);
                    $old.remove();
                }
                const container = document.createElement('div');
                container.classList.add('AggregationViewParent');
                document.body.appendChild(container);

                ReactDOM.render(
                    <Aggregation container={container}
                                 anchorEl={anchorEl}
                                 {...props}
                    />,
                    container
                );
            });
        },
        renderRightMenu(container, props) {
            runAfterInit(() => {
                ReactDOM.render(
                    <Provider store={window.ReduxStore}>
                        <AnalysisRightMenu {...props}/>
                    </Provider>,
                    container);
            });
        },
        renderNewAnalysis(container) {
            runAfterInit(() => {
                ReactDOM.render(
                  <Provider store={window.ReduxStore}>
                    <BngNewAnalysis/>
                  </Provider>,
                  container
                );
            });
        },
        renderAnalystMenu(container, params) {
            runAfterInit(() => {
                try {
                    ReactDOM.render(
                      <Provider store={window.ReduxStore}>
                        <BngAnalystMenu {...params} />
                      </Provider>,
                      container
                    );
                } catch (e) {
                    console.error('Error on renderAnalystMenu()', e);
                }
            });
        },
        renderAdvancedModeAlert(container, assistedType) {
            runAfterInit(() => {
                try {
                    ReactDOM.render(
                        <BngAdvancedModeAlert assistedType={assistedType}/>,
                        container
                    );
                } catch (e) {
                    console.error(e);
                }
            });
        },
        showNonEmptyAlert() {
            try {
                const container = document.querySelector('.HideCleanRowsTooltip');
                runAfterInit(() =>
                    ReactDOM.render(
                        <NonEmptyAlertPopup closePopup={() => ReactDOM.unmountComponentAtNode(container)}/>,
                        container
                    )
                );
            } catch (e) {
                console.error(e);
            }
        },
        renderStructureNotLoadedPage(container, renderForCockpit, isDashboard = false) {
            runAfterInit(() => {
                  try {
                      return ReactDOM.render(
                        <StructureNotLoadedPage renderForCockpit={renderForCockpit}
                                                onClick={isDashboard ? () => {
                                                    const currentUrl = new URL(window.location.href);
                                                    currentUrl.searchParams.append('dontCheckStructure', 'true');
                                                    window.location.href = currentUrl.toString();
                                                } : undefined}
                        />,
                        container
                      );
                  } catch (e) {
                      console.error('Error on renderStructureNotLoadedPage()', {
                          container,
                          renderForCockpit,
                          isDashboard
                      }, e);
                  }
              }
            );
        },
        parseChartItemClickedData: parseChartItemClickedData,
        chartElementClicked: chartElementClicked,
        renderDrillDown(container, props) {
            runAfterCeDataInit(() => {
                if (window.__PREV_RENDER_DRILLDOWN_CONTAINER__) {
                  ReactDOM.unmountComponentAtNode(window.__PREV_RENDER_DRILLDOWN_CONTAINER__);
                }
                window.__PREV_RENDER_DRILLDOWN_CONTAINER__ = container;
                try {
                    ReactDOM.render(
                        <BngAnalysisDrillDownBar reprocessImgMapTime={Date.now()}
                                                 {...props}
                        />,
                        container
                    );
                } catch (e) {
                    console.error('Error on renderDrillDown', container, data, e);
                }
            });
        },
        renderECharts: async function (container, additionalParams = {}, fromExport = false) {
            try {
                await ComponentFactory.initCdataOutOfJsf();

                const hideToolbar = fromExport || !!additionalParams?.dashItemId;

                // Dash export
                if (additionalParams.dashItemId) {
                    await new Promise(res => {
                        application.utils.waitFor(
                          () => {
                            return window.__INITIAL_GRID_RENDERED === true;
                          },
                          res,
                          2000,
                          res
                        );
                    });
                    container = document.querySelector(`.imgChart-${additionalParams.dashItemId}`);
                    const isOnContainer = !!container.closest('.ContainerChild')
                    if(isOnContainer) {
                        // Wait for container to render, see free-layout.ftl
                        const contentContainer = container.closest('.grid-stack');
                        if(contentContainer) {
                            await new Promise(res => {
                                application.utils.waitFor(
                                  () => contentContainer.classList.contains('done'),
                                  res,
                                  5000,
                                  res
                                );
                            });
                        }
                    }
                    const drillContainer = container.closest('.BngAnalysisDrillDownContainer');
                    const itemContentContainer = container.closest('.item-content-container');
                    const drillContainerMargin = isOnContainer ? 0 : 30;
                    drillContainer.style.height = `${itemContentContainer.clientHeight - drillContainerMargin}px`;
                    container.classList.add('fill-h')
                    delete additionalParams.dashItemId;
                } else if (fromExport) {
                    const headerHeight = document.querySelector('#header-print')?.clientHeight ?? 0;
                    const widgetHeight = document.querySelector('.widget-header')?.clientHeight ?? 0;
                    const containerHeight = window.innerHeight - headerHeight - widgetHeight;
                    container.style.height = `${containerHeight - 128}px`;
                }

                ReactDOM.render(
                    <AnalysisECharts renderDate={Date.now()}
                                     hideToolbar={hideToolbar}
                                     {...additionalParams}
                    />,
                    container
                );
            } catch (e) {
                console.error('Error on renderECharts', {container, additionalParams}, e);
            }
        },
    };

    static BigTable = {
        renderBigTable: function (container, opts) {
            runAfterInit(() => {
                ReactDOM.render(
                    <Provider store={window.ReduxStore}>
                        <BigTable {...opts}/>
                    </Provider>,
                    container);
            });
        },


        async renderToExport(container, {
            path,
            height,
            width,
            prefetchedData,
            onTotalHeightChange,
            fullHeight = false,
            onDashboard = false,
            bigtableFilterModel = {},
            bigtableSortModel = {},
        }) {
            await ComponentFactory.initCdataOutOfJsf();
            runAfterCeDataInit(() => {
                application.utils.waitFor(() => {
                    const x = j('.Container').length;
                    const y = j('.Container .done').length;
                    return x === y;
                }, () => {
                    try {
                        const store = window.ReduxStore || ComponentFactory.Store(undefined, false);
                        ReactDOM.render(
                            <Provider store={store}>
                                <BigTableRenderer path={path}
                                                  height={height || container.clientHeight}
                                                  width={width}
                                                  prefetchedData={prefetchedData}
                                                  exporting={true}
                                                  fullHeight={fullHeight}
                                                  onTotalHeightChange={onTotalHeightChange}
                                                  onDashboard={onDashboard}
                                                  bigtableFilterModel={bigtableFilterModel}
                                                  bigtableSortModel={bigtableSortModel}
                                />
                            </Provider>,
                            container
                        );
                    } catch (e) {
                        console.error('ERROR ' + e, e);
                    }
                }, 90000);
            });

        },
    };

    static Kpi = {
        renderBreadcrumbToolbar(container, props) {
            BreadcrumbToolbar.renderIfNotExists(() =>
                runAfterInit(() =>
                    ReactDOM.render(
                        <Provider store={window.ReduxStore}>
                            <KpiBreadcrumbToolbar {...props} />
                        </Provider>,
                        container
                    )
                )
            );
        },
        async renderToExport(container, {data, align = 'center', ...otherProps}) {
            await ComponentFactory.initCdataOutOfJsf();
            runAfterCeDataInit(() => {
                application.utils.waitFor(() => {
                    var x = j('.Container').length;
                    var y = j('.Container .done').length;
                    return x === y;
                }, () => {
                    try {
                        ReactDOM.render(
                            <Kpi data={data}
                                 width={container.clientWidth}
                                 height={container.clientHeight}
                                 align={align}
                                 {...otherProps}
                            />,
                            container
                        );
                    } catch (e) {
                        console.error('ERROR ' + e, e);
                    }
                }, 90000);
            });

        },
        async renderTo(container, path, filters = '') {
            await ComponentFactory.initCdataOutOfJsf();
            runAfterCeDataInit(() => {
                try {
                    ReactDOM.render(
                        <Kpi path={path}
                             width={container.clientWidth}
                             height={container.clientHeight}
                             filters={filters || jQuery.QueryString.filter || ''}
                        />,
                        container
                    );
                } catch (e) {
                    console.error('ERROR ' + e, e);
                }
            })
        },
        renderToPreview(container, path, filters = '') {
            try {
                ReactDOM.render(
                    <Kpi width={850}
                         height={350}
                         path={path}
                         filters={filters || jQuery.QueryString.filter || ''}
                    />,
                    container
                );
            } catch (e) {
                console.error('ERROR ' + e, e);
            }
        },
        renderRightMenu(container, props) {
            runAfterInit(() => {
                ReactDOM.render(
                    <Provider store={window.ReduxStore}>
                        <KpiRightMenu {...props}/>
                    </Provider>,
                    container);
            });
        },
        renderNewKpi(container) {
            runAfterInit(() => {
                ReactDOM.render(
                    <Provider store={window.ReduxStore}>
                        <BngNewKpiPage/>
                    </Provider>,
                    container
                )
            });
        }
    };

    static Notes = {
        openNotes({boundaryElement = document.body, anchorEl, ...props}) {
            runAfterCeDataInit(() => {
                const $old = j('.NoteViewParent');
                if ($old.length > 0) {
                    ReactDOM.unmountComponentAtNode($old[0]);
                    $old.remove();
                }
                const container = document.createElement('div');
                container.classList.add('NoteViewParent');
                boundaryElement.appendChild(container);
                ReactDOM.render(
                    <NoteViewPopper container={container}
                                    boundaryElement={boundaryElement}
                                    anchorEl={anchorEl}
                                    {...props}
                    />,
                    container
                );
            });
        }
    };

    static Others = {
        renderFolderField(container, {initialValue, onSelectFolder, disabled}) {
            runAfterInit(
                () => ReactDOM.render(
                    <Provider store={window.ReduxStore}>
                        <BngTreeDropdownContainer name="folder"
                                                  className="w-100"
                                                  field={{
                                                      name: "folder",
                                                      value: initialValue,
                                                  }}
                                                  form={{
                                                      setFieldValue: onSelectFolder
                                                  }}
                                                  disabled={disabled}
                                                  selectFolders
                                                  initialValue={initialValue}
                        />
                    </Provider>,
                    container
                )
            );
        },
        renderQuotaViewer(container, props) {
            runAfterInit(
                () => ReactDOM.render(<BngQuotaViewer {...props} />, container)
            );
        },
        renderProjectTypeChangeButton(container, props) {
            runAfterInit(
                () => ReactDOM.render(
                    <Provider store={window.ReduxStore}>
                        <ProjectTypeChangeButton {...props} />
                    </Provider>,
                    container
                )
            );
        },
        renderTagsDropdown(group, container) {

            const applyGroup = async (newGroup) => {
                try {
                    await Api.executeExp(`#{inMemoryBean.setInMemoryGroup('${newGroup?.value || ''}')}`)
                } catch (e) {
                    UiMsg.error("Error", e);
                }
            }

            runAfterInit(
                () => ReactDOM.render(
                    <BngDropdownTagsWrapper selectedGroupId={group?.id}
                                            applyGroup={applyGroup}
                                            customButton={({openDropdown}) => {
                                                return (
                                                    <GroupRender
                                                        name={!group ? ceData.context.msg.t('without.tag') : group.name}
                                                        color={!group ? '005DFF' : group.color}
                                                        onClick={openDropdown}
                                                        className="selectedGroupRender"
                                                    />
                                                );
                                            }}
                    />,
                    container
                )
            );
        },
        renderClEditorInputColor(button, initialValue, onChange, basicLayoutAddColor = false, paletteEnabled = true) {
            const promise = new Promise((resolve) => {
                let container = document.querySelector('.ClEditorInputColorContainer');
                if (!container) {
                    container = document.createElement('div');
                    container.classList.add('ClEditorInputColorContainer');
                    document.body.appendChild(container);
                }

                const props = {
                    htmlButton: button,
                    field: {
                        name: 'color',
                        value: initialValue
                    },
                    form: {
                        setFieldValue(name, value) {
                            props.field.value = value;
                            ReactDOM.render(<BngInputColor {...props} />, container);
                            onChange(value);
                        }
                    },
                    onClose: () => {
                        try {
                            ReactDOM.unmountComponentAtNode(container);
                            container.parentNode.removeChild(container);
                        } catch (e) {
                        }
                        if (!basicLayoutAddColor || !props.field.value) {
                            return;
                        }
                        Api.executeExp(`#{projectSelectionMB.projectCRUD.crud.projectTheme.addColor('${props.field.value}')}`)
                    },
                    paletteEnabled
                };

                runAfterInit(
                    () => {
                        ReactDOM.render(<BngInputColor {...props} />, container);
                        resolve();
                    }
                );
            });

            promise.then(() => {
                button.openColorPicker({currentTarget: button});
            });

            return promise;
        },
        renderJsfInputColor(container, props = {}) {
            if (!container) {
                return;
            }

            const input = document.getElementById(container.id.replace('-parent', ''));

            props = _.merge({size: 'sm'}, {
                field: {
                    name: 'color',
                    value: input.value
                },
                form: {
                    setFieldValue(name, value) {
                        input.value = value;
                        props.field.value = value;
                        ReactDOM.render(<BngInputColor {...props} />, container);
                    }
                }
            }, props);

            runAfterInit(
                () => ReactDOM.render(<BngInputColor {...props} />, container)
            );
        },
        renderIcon(container, props) {
            return ReactDOM.render(<Icon {...props} />, container)
        },
        openSaveAsDialog(path, folder, name, description, onSubmit) {
            const isNew = !folder || folder === 'null';
            try {
                window.ReduxStore.dispatch(
                    MODALS.open(SaveAsDialogContainer, {
                        path: isNew ? '' : path,
                        folder: isNew ? '' : folder,
                        name: isNew ? '' : name,
                        description: isNew ? '' : description,
                        onSubmit
                    })
                );
            } catch (e) {
                console.error(e);
            }
            return false;
        },
        openRenameDialog(path, folder, name, loadAfterSave = true, icon) {
            let isFolder = Utils.Object.isFolder(path);
            if (!folder) {
                const parts = path.split("/");
                parts.pop();
                folder = parts.join('/');
            }
            try {
                window.ReduxStore.dispatch(MODALS.open(RenameDialogContainer, {
                    path,
                    folder,
                    name,
                    isFolder,
                    loadAfterSave,
                    icon
                }));
            } catch (e) {
                console.error(e);
            }
            return false;
        },
        htmlDialog({html = '', ...props} = {}) {
            const Component = ({closeModal, ...props}) => (
                <Dialog onClose={closeModal} {...props}>
                    <div dangerouslySetInnerHTML={{__html: html}}></div>
                </Dialog>
            );
            window.ReduxStore.dispatch(MODALS.open(Component, props))
        },
        alertInvalidPeriodicity(periodicity) {
            runAfterInit(async () => {
                const {context: {msg}} = await ceData.initCeData();
                const message = msg.t('invalid.date.filter.alert', [msg.t(periodicity)]);
                UiMsg.warn(`${msg.t('attention')}!`, message);
            });
        },
        renderInvalidPeriodicity(container, {path, caption, periodicity, showAlert = true, useObjectMessage = false}) {
            runAfterInit(async () => {
                await ceData.initCeData();
                ReactDOM.render(
                    <InvalidPeriodicityAlert path={path ?? ''}
                                             caption={caption ?? ''}
                                             periodicity={periodicity}
                                             parentElement={container}
                                             showAlert={showAlert}
                                             useObjectMessage={useObjectMessage}
                    />,
                    container
                );
            });
        },
    };

    static Icons = {
        openCockpitChangeIcon({id, icon}) {
            try {
                ComponentFactory.Icons.openIconDialog({
                    icon: icon,
                    onSelect: async icon => await Api.Cockpit.saveIcon({cockpitId: id, icon: icon.name})
                });
            } catch (e) {
                console.error(e)
            }
        },

        openInMemoryChangeIcon({icon}) {
            try {
                ComponentFactory.Icons.openIconDialog({
                    icon: icon,
                    onSelect: async icon => Api.executeExp(`#{inMemoryBean.inMemory.setIcon('${icon.name}')}`)
                });
            } catch (e) {
                console.error('Error on openInMemoryChangeIcon()', e)
            }
        },

        openIconDialog({icon, onSelect}) {
            window.ReduxStore.dispatch(
                MODALS.open(SelectIconDialog, {
                    icon: {name: icon},
                    onSelect: onSelect
                })
            );
        },
    };

    static Permissions = {
        openOriginPermission: ({originId, projectId, cubeName, ...props}) => {
            ComponentFactory.Permissions.openPermissionDialog({
                dialog: OriginPermissionDialog,
                projectId,
                originId,
                cubeName,
                lockCurrentUser: false,
                ...props
            });

        },

        openPathPermission({projectId, path, ...props}) {
            ComponentFactory.Permissions.openPermissionDialog({
                dialog: PathPermissionDialog,
                projectId,
                path,
                ...props
            });
        },

        openCockpitPermission({projectId, ...props}) {
            projectId = projectId || ceData.context.findProjectIdFromContext();

            if (!projectId) {
              UiMsg.warn('No project detected');
              return;
            }

            ComponentFactory.Permissions.openPermissionDialog({
              dialog: CockpitPermissionDialog,
              projectId,
                ...props
            });
        },

        openPermissionDialog({projectId, dialog = PermissionDialog, ...props}) {
            projectId = projectId || ceData.context.findProjectIdFromContext();

            if (!projectId) {
                UiMsg.warn('No project detected');
                return;
            }

            window.ReduxStore.dispatch(
                MODALS.open(dialog, {
                    projectId,
                    ...props
                })
            );
        },

    };

    static Publisher = {
        forbiddenAccess: async (container, props = {}) => {
            await ceData.initCeData();
            ReactDOM.render(<PublisherForbiddenAccess {...props}/>, container);
        }
    }

    static findProjectIdFromContext() {
        const state = window.ReduxStore.getState();
        return _.get(
            state,
            'context.project.id',
            _.get(
                state,
                'context.store.currentProject.id'
            )
        );
    }

    static BimIntegration = {
        renderBimIntegrationTabsOrigin(container, props = {}) {
            runAfterInit(
                () => {
                    if(window.__BIM_INTEGRATION_CONTAINER) {
                        ReactDOM.unmountComponentAtNode(window.__BIM_INTEGRATION_CONTAINER);
                        delete window.__BIM_INTEGRATION_CONTAINER;
                    }

                    ReactDOM.render(
                      <Provider store={window.ReduxStore}>
                          <BimIntegrationOrigins {...props} />
                      </Provider>,
                      container
                    );

                    window.__BIM_INTEGRATION_CONTAINER = container;
                }
            );
        },
    }

    static Index = {
        renderIndexPage(container, indexPageProps = {}) {
            runAfterInit(() => ReactDOM.render(
                <IndexPage
                    columnCount={3}
                    onSelect={(p) => Api.executeExp(`#{cockpitViewMB.renderPanelId('${p.id}')}`)}
                    location={'cockpit'}
                    content={indexPageProps.currentItem.content}
                    title={indexPageProps.currentItem.name}
                    icon={indexPageProps.currentItem.icon}
                    panels={indexPageProps.panels}
                    enableIframe={indexPageProps.currentItem.enableIframe}
                />,
                container
            ));
        }
    }

    static Addons = {
        renderPage(container) {
            runAfterInit(() => {
                ReactDOM.render(
                    <Provider store={window.ReduxStore}>
                        <AddonsPage />
                    </Provider>,
                    container
                )
            })
        },
        renderInfoPage(container) {
            runAfterInit(() => {
                ReactDOM.render(
                    <Provider store={window.ReduxStore}>
                        <AddonInfoPage />
                    </Provider>,
                    container
                )
            })
        }
    }

    static Structures = {
        renderPage(container) {
            runAfterInit(() => {
                ReactDOM.render(
                    <Provider store={window.ReduxStore}>
                        <StructuresPage/>
                    </Provider>
                    ,
                    container
                )
            })
        },
        editStructuresConfirmation() {
            const {msg} = ceData.context;
            runAfterInit(
                () => OpConfirmation({
                    title: msg.t('attention'),
                    html: msg.t('in_memory_dirty_confirmation'),
                    onConfirm: async () => {
                        await Api.executeExp("#{inMemoryBean.acceptEdit()}")
                    },
                    msg: msg
                }))
        },
        googleSheetsAuthorization: StructuresPageUtils.authenticationRedirect,
        renderKeyFieldSelector(container, props = {}) {
            try {
                runAfterInit(() => {
                    ReactDOM.render(
                        <KeyFieldSelector renderDate={Date.now()} {...props} />,
                        container
                    );
                });
            } catch (e) {
                console.error("Error on ComponentFactory.Structures.renderKeyFieldSelector", {container, props}, e);
            }
        },
        renderGenericFileSource(container, props = {}) {
            try {
                runAfterInit(() => {
                    ReactDOM.render(
                        <GenericFileSource renderDate={Date.now()}
                                           {...props}
                        />,
                        container
                    );
                });
            } catch (e) {
                console.error("Error on ComponentFactory.Structures.renderGenericFileSource", {container, props}, e);
            }
        }
    }

    static Folders = {
        renderPage(container, embedded = false) {
            runAfterInit(() => {
                try {
                    ReactDOM.render(
                        <Provider store={window.ReduxStore}>
                            <FoldersPage embedded={embedded}/>
                        </Provider>
                        ,
                        container
                    )
                } catch (e) {
                    console.error('Error on Folders.renderPage()', e);
                }
            })
        }
    }

    static Users = {
        renderPage(container) {
            runAfterInit(() => {
                try {
                    ReactDOM.render(
                        <Provider store={window.ReduxStore}>
                            <MembersConfigPage/>
                        </Provider>
                        ,
                        container
                    );
                } catch (e) {
                    console.error('Error on ComponentFactory.Users.renderPage', e);
                }
            })
        }
    }

    static Schedulings = {
        renderPage(container) {
            runAfterInit(() => {
                try {
                    ReactDOM.render(
                        <Provider store={window.ReduxStore}>
                            <SchedulingPage/>
                        </Provider>,
                        container
                    );
                } catch (e) {
                    console.error('Error on ComponentFactory.Schedulings.renderPage', e);
                }
            })
        }
    }

    static ErrorPages = {
        renderServerError(container, opts = {}) {
            runAfterInit(() => {
                ReactDOM.render(
                    <Provider store={window.ReduxStore}>
                        <ServerErrorPage {...opts}/>
                    </Provider>,
                    container
                );
            });
        },
        renderForbiddenError(container, opts = {}) {
            runAfterInit(() => {
                ReactDOM.render(
                    <Provider store={window.ReduxStore}>
                        <ForbiddenErrorPage {...opts}/>
                    </Provider>,
                    container
                );
            });
        },
        renderResourceNotFoundError(container, opts = {}) {
            runAfterInit(() => {
                ReactDOM.render(
                    <Provider store={window.ReduxStore}>
                        <ResourceNotFoundErrorPage {...opts}/>
                    </Provider>,
                    container
                );
            });
        },
        renderNotFoundError(container, opts = {}) {
            runAfterInit(() => {
                ReactDOM.render(
                    <Provider store={window.ReduxStore}>
                        <NotFoundErrorPage {...opts}/>
                    </Provider>,
                    container
                );
            });
        },
        renderServerTooBusyError(container, opts = {}) {
            runAfterInit(() => {
                ReactDOM.render(
                    <Provider store={window.ReduxStore}>
                        <ServerTooBusyErrorPage {...opts}/>
                    </Provider>,
                    container
                );
            });
        },
        renderTimeoutError(container, opts = {}) {
            runAfterInit(() => {
                ReactDOM.render(
                    <Provider store={window.ReduxStore}>
                        <TimeoutErrorPage {...opts}/>
                    </Provider>,
                    container
                );
            });
        },
        renderUserWithoutProjectError(container, opts = {}) {
            runAfterInit(() => {
                ReactDOM.render(
                    <Provider store={window.ReduxStore}>
                        <UserWithoutProjectErrorPage {...opts}/>
                    </Provider>,
                    container
                );
            });
        },
        async renderServerOutOfApp(container, opts = {}) {
            await ceData.initCeData();
            return ReactDOM.render(
                <ServerErrorPage {...opts} outOfApp/>,
                container
            );
        },
    }

    static Messages = {
        async renderMessageContainer(container, props = {}) {
            try {
                await ComponentFactory.initCdataOutOfJsf();
                return ReactDOM.render(
                    <UiMsgContainer {...props}/>,
                    container
                );
            } catch (e) {
                console.error('Error on Messages.renderMessageContainer()', container, e);
            }
        },
        sendMessage({type, title, details, ...opts}) {
            try {
                let method = type;
                switch (type) {
                    case "info": {
                        method = 'ok';
                        break;
                    }
                    case "warning": {
                        method = 'warn';
                        break;
                    }
                    case "error": {
                        method = 'error';
                        break;
                    }
                }

                UiMsg[method](title, details, opts);
            } catch (e) {
                console.error('Error on Messages.sendMessage()', {type, title, details, opts}, e);
            }
        }
    };

    static Cockpit = {
        renderEmptyPanel() {
            runAfterInit(
                () => {
                    try {
                        const container = document.getElementById('cockpitEmptyPanelContainer');
                        ReactDOM.render(
                            <BngEmpty message={ceData.context.msg.t('cockpit.without.panel.empty.title')}
                                      isEmpty
                            />,
                            container
                        );
                    } catch (e) {
                        console.error('Error on Cockpit.renderEmptyPanel', e);
                    }
                }
            );
        }
    };

    static WhatsApp = {
        renderWhatsAppInfoDialog(container) {
            runAfterInit(async () => {
                try {
                    ReactDOM.render(
                        <WhatsAppInfoDialog/>,
                        container
                    );
                } catch (e) {
                    console.error('Error on WhatsAppQRCode.renderWhatsAppQRCode()', container, e);
                }
            });
        }
    }

    static Monitor = {
        renderValueSourceObjectTree(container) {
            runAfterInit(() => {
                try {
                    ReactDOM.render(
                        <Provider store={window.ReduxStore}>
                            <MonitorValueSourceObjectTree/>
                        </Provider>,
                        container
                    );
                } catch (e) {
                    console.error('Error on Monitor.renderValueSourceObjectTree()', container, e);
                }
            });
        },
        openMessageObjectsTree(props = {}) {
            try {
                window.ReduxStore.dispatch(
                    MODALS.open(MonitorMessageObjectsTreeDialog, props)
                );
            } catch (e) {
                console.error('Error on Monitor.openMessageObjectsTree()', props, e);
            }
        }
    };

    static Replication = {
        renderConnectionsFragment() {
            runAfterInit(() => {
                try {
                    const container = document.querySelector('.ReplicationConnectionsContainer');
                    ReactDOM.render(
                        <Provider store={window.ReduxStore}>
                            <ConnectionsDialog dialogComponent={BypassComponent}
                                               forReplication
                            />
                        </Provider>,
                        container
                    );
                } catch (e) {
                    console.error('Error on Replication.renderConnectionsFragment()', e);
                }
            })
        }
    }

    static renderFirstStep = (container) => {
        runAfterCeDataInit(() => {
            ReactDOM.render(
                <Provider store={window.ReduxStore}>
                    <CreateProjectPage/>
                </Provider>,
                container
            );
        })
    }

}

export default ComponentFactory;
