import React from 'react';

import useBimContext from 'components/hooks/useBimContext';
import Api from 'components/Api';
import UiMsg from 'components/ui/UiMsg';
import { localStore } from 'components/Storage';
import AdaCensus from 'components/bng/ui/Census/AdaCensus';

export const NPS_CENSUS_KEY = 'NpsCensusAnswerLater:NPS_CENSUS_STORAGE';

const CensusTypes = {
  NPS: 'NPS',
};

const NPSCensus = ({ closeModal }) => {
  const context = useBimContext();

  const answerLaterButton = async () => {
    localStore.put(NPS_CENSUS_KEY, true);
    closeModal();
  };

  const saveCensusAnswer = async (values) => {
    try {
      const censusResponse = {
        result: values,
        projectId: context.project.id,
        projectName: context.project.name,
        mobile: application.page.isMobile(),
        type: CensusTypes.NPS,
      };
      await Api.News.saveCensusResponse(censusResponse);
      localStore.put(NPS_CENSUS_KEY, true);

      // Fix: https://github.com/sol7/bi-machine/issues/8480
      context.nps.lastAvaliationDate = Date.now();
      
      UiMsg.ok(context.msg.t('census.thanks.for.feedback'));
    } catch (e) {
      console.error(e);
      UiMsg.error(context.msg.t('census.error.sending.feedback'), e);
    } finally {
      closeModal();
    }
  };

  return (
    <AdaCensus
      onSubmit={saveCensusAnswer}
      answerLaterButton={answerLaterButton}
      questionTitle={context.msg.t('census.nps.title')}
    />
  );
};

export default NPSCensus;
