import './BngSelectSearch.css';

import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';

import { ceData } from 'components/CeData';
import { findChildProp } from 'components/bng/form/BngSelect';
import BngSearch from 'components/bng/ui/BngSearch';
import Icon from 'components/ui/common/Icon';
import { BngIconButton } from 'components/bng/ui/BngIconButton';
import BngPopper from 'components/bng/ui/BngPopper';
import { BngCheckboxPure } from 'components/bng/form/BngCheckbox';
import BngClickOutsideOverlay from 'components/bng/ui/BngClickOutsideOverlay';

const ExpandCircle = ({ expanded = false, className = '', ...props }) => {
  return (
    <div className={`ExpandCircle ${expanded ? 'Expanded' : ''} ${className}`} {...props}>
      <div className="Line" />
      <div className="Bar"></div>
    </div>
  );
};

function optionLabel(option) {
  return option.previewLabel || option.label || option.value || '';
}

const OptionPreview = ({
  setSearchTerm = _.noop,
  toggleDropdown = _.noop,
  setCustomDropOpts = _.noop,
  defaultPreviewIcon = '',
  disabled = false,
  currentOpts = [],
  clearable = false,
  handleOptClick = _.noop,
  multiple = false,
  emptyOption = true,
  dropdownIcon = 'arrow_drop_down',
}) => {
  const defaultRender = ({ label, icon }) => {
    const iconValue = icon || defaultPreviewIcon;
    return (
      <>
        {iconValue && <Icon icon={iconValue} className="mr-1 OptionPreviewIcon" />}
        <label className="flex-grow-1">{label}</label>
      </>
    );
  };

  let renderResult;
  if (currentOpts.length === 0) {
    const emptyLabel =
      typeof emptyOption === 'string' ? emptyOption : emptyOption ? ceData.context.msg.t('select.one') : '';
    renderResult = defaultRender({
      label: emptyLabel,
    });
  } else if (multiple) {
    // TODO support option.render with multiple
    const label = currentOpts.map(({ option }) => optionLabel(option)).join(', ');
    renderResult = defaultRender({ label });
  } else {
    const option = currentOpts[0].option;
    if (option.render) {
      renderResult = option.render({ multiple, selected: true, preview: true });
    } else {
      renderResult = defaultRender({ label: optionLabel(option), icon: option.icon });
    }
  }

  return (
    <div
      className={`OptionPreview`}
      onClick={
        disabled
          ? undefined
          : () => {
              setSearchTerm('');
              toggleDropdown(true);
              setCustomDropOpts(null);
            }
      }
    >
      {renderResult}

      {!disabled && clearable && currentOpts.length > 0 && (
        <BngIconButton
          icon={'close'}
          size={'xs'}
          className={'ml-auto'}
          onClick={(event) => {
            event.stopPropagation();
            handleOptClick(event, null);
          }}
        />
      )}

      <Icon icon={dropdownIcon} />
    </div>
  );
};

const GroupedOptions = ({
  opt = {},
  options = [],
  expandedGroup = {},
  expandedGroups = [],
  setExpandedGroups = _.noop,
  onClickOpt = _.noop,
  childProp = undefined,
  multiple = false,
  currentOpts = [],
  defaultPreviewIcon,
}) => {
  const containChildProp = childProp ? opt.hasOwnProperty(childProp) : true;
  return (
    <div
      className={`GroupedOptions ${expandedGroup.expanded && containChildProp ? 'Expanded' : ''}  ${
        opt.disabled ? 'Disabled' : ''
      } ${containChildProp ? '' : 'DirectOption'}`}
    >
      <label
        className="OptionGroup OptionLabel"
        onClick={
          opt.disabled
            ? undefined
            : (event) => {
                if (containChildProp) {
                  expandedGroup.expanded = !expandedGroup.expanded;
                  setExpandedGroups({ ...expandedGroups });
                } else {
                  onClickOpt(event, opt);
                }
              }
        }
      >
        <ExpandCircle
          expanded={expandedGroup.expanded}
          className="mr-2"
          style={{ visibility: containChildProp ? undefined : 'hidden' }}
        />
        {opt.icon && <Icon icon={opt.icon} className="mr-1" />}
        {opt.label}
      </label>
      {containChildProp && expandedGroup.expanded && (
        <div>
          <ul className="OptionGroup flex-grow-1">
            {options.map((childOpt, idx2) => {
              const isLast = options.length - 1 === idx2;
              return (
                <Option
                  key={idx2}
                  {...childOpt}
                  onClick={(event) => onClickOpt(event, childOpt)}
                  isLast={isLast}
                  multiple={multiple}
                  selected={currentOpts.some(({ option }) => option.value === childOpt.value)}
                  defaultPreviewIcon={defaultPreviewIcon}
                />
              );
            })}
          </ul>
        </div>
      )}
    </div>
  );
};

export function DEFAULT_SEARCH_FUNCTION({ item, search }) {
  return item.label?.toLowerCase().includes(search?.toLowerCase());
}

export function BngSelectSearch({
  className = '',
  emptyOption = true,
  groupedOpts = false,
  clearable = true,
  options = [],
  field,
  form,
  style = {},
  placeholder = `${ceData.context.msg.t('search.here')}...`,
  defaultPreviewIcon = '',
  disabled = false,
  disableDropdownPortal = false,
  popperClassName = '',
  onChange = null,
  onCreate,
  onCreateMessage,
  itemSearchFn = DEFAULT_SEARCH_FUNCTION,
  multiple = false,
  closeAfterSelect = !multiple,
  open,
  onToggle,
  customPreview = false,
  previewComponent = OptionPreview,
  dropdownIcon,
  onSearch = _.noop,
}) {
  const [availableOpts, setAvailableOpts] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [dropdownState, setDropdownState] = useState(false);

  const isControlled = !_.isNil(open) && _.isFunction(onToggle);
  const dropdownIsOpen = isControlled ? open : dropdownState;

  const toggleDropdown = (newState) => {
    if (isControlled) {
      onToggle({ open: newState });
    } else {
      setDropdownState(newState);
    }
  };

  const childProp = groupedOpts && options.length > 0 ? findChildProp(options) : null;

  const findOpts = (value) => {
    const result = [];

    for (let option of availableOpts) {
      if (childProp && option[childProp]) {
        const childOpts = option[childProp] || [];
        const match = childOpts.find((optChild) => optChild.value === value);
        if (match) {
          result.push({ option: match, parent: option });
        }
      } else if ((_.isArray(value) && value.includes(option.value)) || value === option.value) {
        result.push({ option });
      }

      if (!multiple && result.length > 0) {
        break;
      }
    }

    return result;
  };

  const initialExpandedGroups = () => {
    const result = {};
    if (groupedOpts) {
      if (field.value) {
        const matches = findOpts(field.value);
        for (const match of matches) {
          if (match.parent) {
            result[match.parent] = { expanded: true };
          }
        }
      }
    }
    return result;
  };

  const [expandedGroups, setExpandedGroups] = useState(initialExpandedGroups());
  const [customDropOpts, setCustomDropOpts] = useState(null);
  const elementRef = useRef(null);
  const popperElementRef = useRef(null);

  useEffect(() => {
    handleSearch();
  }, [options, searchTerm]);

  useEffect(() => {
    onSearchChange('');
  }, [dropdownIsOpen]);

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [options, searchTerm]);

  const handleClickOutside = (event) => {
    const refs = [elementRef, popperElementRef];
    for (const ref of refs) {
      if (ref.current && ref.current.contains(event.target)) {
        return;
      }
    }
    toggleDropdown(false);
  };

  const handleOptClick = (event, option) => {
    const optVal = option?.value ?? '';

    let fieldValue = optVal;
    if (multiple) {
      if (optVal) {
        fieldValue = (_.isArray(field.value) ? field.value ?? [] : []).slice();
        const idx = fieldValue.indexOf(optVal);
        if (idx === -1) {
          fieldValue.push(optVal);
        } else {
          fieldValue.splice(idx, 1);
        }
      } else {
        fieldValue = [];
      }
    }

    if (onChange) {
      onChange(fieldValue);
    } else {
      form.setFieldValue(field.name, fieldValue);
    }

    if (closeAfterSelect) {
      toggleDropdown(false);
    }
  };

  const handleSearch = (search = searchTerm) => {
    if (!!search) {
      const cloneOpts = _.cloneDeep(options);
      setAvailableOpts(
        cloneOpts.filter((item) => {
          const itemMatch = itemSearchFn({ item, search });
          if (!itemMatch && childProp && item[childProp]) {
            const childrenMatch = item[childProp].filter((optChild) =>
              optChild.label.toLowerCase().includes(search.toLowerCase())
            );
            item[childProp] = childrenMatch;
            return childrenMatch.length > 0;
          }
          return itemMatch;
        })
      );
    } else {
      setAvailableOpts(options);
    }
  };

  if (field) {
    field.value = field.value === null ? (multiple ? [] : '') : field.value;
  }

  const onSearchChange = (search) => {
    setSearchTerm(search);
    if (search) {
      Object.values(expandedGroups).forEach((group) => (group.expanded = true));
      setExpandedGroups({ ...expandedGroups });
    }
    onSearch({ search });
  };

  const currentOpts = findOpts(field.value);
  const onCreateHandler = () =>
    onCreate({
      value: searchTerm,
      close: () => {
        toggleDropdown(false);
      },
    });

  const PreviewComponent = previewComponent;
  return (
    <div
      ref={elementRef}
      className={`BngSelectSearch fill-w ${className} ${dropdownIsOpen ? 'Open' : 'Close'} ${
        defaultPreviewIcon ? 'WithIcon' : ''
      } ${disabled ? 'Disabled' : ''}`}
      style={style}
    >
      {elementRef.current && (
        <React.Fragment>
          {!customPreview && (
            <PreviewComponent
              defaultPreviewIcon={defaultPreviewIcon}
              setCustomDropOpts={setCustomDropOpts}
              toggleDropdown={toggleDropdown}
              setSearchTerm={setSearchTerm}
              disabled={disabled}
              currentOpts={currentOpts}
              clearable={clearable}
              handleOptClick={handleOptClick}
              multiple={multiple}
              emptyOption={emptyOption}
              dropdownIcon={dropdownIcon}
            />
          )}

          {dropdownIsOpen && (
            <>
              <BngClickOutsideOverlay
                className={`BngSelectDropdownPopper-outside-overlay`}
                onClick={() => setDropdownState((prev) => !prev)}
              />
              <BngPopper
                className={`BngSelectDropdownPopper ${popperClassName} ${className ? `${className}Dropdown` : ''}`}
                open={true}
                anchorEl={elementRef.current}
                modifiers={{ flip: { enabled: false } }}
                disablePortal={disableDropdownPortal}
              >
                <div
                  ref={popperElementRef}
                  className={`BngSelectSearch ${customDropOpts ? 'Invert' : ''}`}
                  style={{
                    marginTop: customDropOpts
                      ? customDropOpts.marginTop
                      : !customPreview
                      ? -elementRef.current.offsetHeight
                      : '',
                  }}
                >
                  <div
                    className={`BngSelectDropdown ${groupedOpts ? 'Grouped' : ''}`}
                    style={{ width: elementRef.current.clientWidth }}
                  >
                    <div className="SearchContainer">
                      <BngSearch
                        name={`${field.name}-search`}
                        onChange={onSearchChange}
                        onKeyPress={(e) => {
                          if (e.key !== 'Enter') return;
                          onCreateHandler();
                        }}
                        placeholder={placeholder}
                        simple={true}
                        alwaysOpen={true}
                        inputAutoComplete={false}
                        value={searchTerm}
                      />
                    </div>
                    <ul
                      style={{ height: customDropOpts ? customDropOpts.height : undefined }}
                      ref={(ref) => {
                        if (!ref || customDropOpts) return;

                        window.requestAnimationFrame(() => {
                          const rect = ref.getBoundingClientRect();
                          const h = document.body.clientHeight;
                          const result = h - rect.y - rect.height;
                          if (result < 0) {
                            setCustomDropOpts({
                              marginTop: `${-popperElementRef.current.offsetHeight}px`,
                              height: `${rect.height}px`,
                            });
                          }
                        });
                      }}
                    >
                      {availableOpts.length === 0 && (
                        <li className="Option EmptyOpt">
                          {onCreate ? (
                            <div onClick={onCreateHandler}>
                              {`${onCreateMessage ? onCreateMessage : ceData.context.msg.t('create')} '${searchTerm}'`}
                            </div>
                          ) : (
                            <>{`${ceData.context.msg.t('no.result.found.for')} '${searchTerm}'`}</>
                          )}
                        </li>
                      )}
                      {availableOpts.map((opt, idx) => {
                        if (childProp) {
                          const options = opt[childProp];

                          const optKey = `${opt.value || idx}`;
                          if (!expandedGroups[optKey]) {
                            expandedGroups[optKey] = { expanded: false };
                          }
                          const expandedGroup = expandedGroups[optKey];
                          return (
                            <GroupedOptions
                              optKey={optKey}
                              expandedGroup={expandedGroup}
                              expandedGroups={expandedGroups}
                              onClickOpt={handleOptClick}
                              opt={opt}
                              options={options}
                              setExpandedGroups={setExpandedGroups}
                              key={optKey}
                              childProp={childProp}
                              multiple={multiple}
                              currentOpts={currentOpts}
                              defaultPreviewIcon={defaultPreviewIcon}
                            />
                          );
                        } else {
                          return (
                            <Option
                              key={idx}
                              {...opt}
                              onClick={(event) => handleOptClick(event, opt)}
                              multiple={multiple}
                              selected={currentOpts.some(({ option }) => option.value === opt.value)}
                              defaultPreviewIcon={defaultPreviewIcon}
                            />
                          );
                        }
                      })}
                    </ul>
                  </div>
                </div>
              </BngPopper>
            </>
          )}
        </React.Fragment>
      )}
    </div>
  );
}

const Option = ({
  value,
  label,
  icon,
  title = '',
  disabled = false,
  onClick,
  isLast = false,
  render = undefined,
  multiple = false,
  selected = false,
  defaultPreviewIcon,
}) => {
  const optIcon = icon || defaultPreviewIcon;
  return (
    <li
      className={`Option ${disabled ? 'Disabled' : ''}`}
      data-value={value}
      onClick={disabled ? undefined : onClick}
      title={title}
    >
      {isLast && (
        <div className="HideBorder">
          <div className="Circle"></div>
          <div className="WhiteBox"></div>
        </div>
      )}

      {render ? (
        render({ multiple, selected })
      ) : (
        <>
          {multiple && <BngCheckboxPure className={`selectSearchOptCheckbox`} checked={selected} onChange={_.noop} />}

          {optIcon && <Icon icon={optIcon} className="mr-1" />}
          {label || value}
        </>
      )}
    </li>
  );
};

BngSelectSearch.propTypes = {
  emptyOption: PropTypes.any,
  groupedOpts: PropTypes.bool,
  options: PropTypes.array,
};

export default BngSelectSearch;
