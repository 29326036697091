import "./Favorite.css";

import React, {Component} from "react";
import ContextEnhancer from "components/ContextEnhancer";
import Api from "components/Api";
import Utils from 'components/Utils'

class Favorite extends Component {

    static propTypes = {};

    static defaultProps = {
        favorites: []
    };

    state = {};

    constructor(props) {
        super(props);
    }

    componentDidMount() {
        jQuery(function () {
            j('.slim-scroll').each(function () {
                var $this = j(this);
                $this.slimScroll({
                    height: $this.data('height') || 100,
                    railVisible: true
                });
            });
        });
    }

    render() {

        var pagination = '';
        var bodyRender = '';

        const generateIcon = (icon) => {
            if (icon === Utils.Object.getObjectIcon('bigtable')) {
                return `<i class="${icon} favorite-icon"></i>`
            } else {
                return `<i class="Icon notranslate material-icons favorite-icon">${icon}</i>`
            }
        }

        if (this.props.favorites.length > 0) {
            jQuery.each(this.props.favorites, function (index, value) {

                if(value.description==null){
                    value.description='';
                }

                if (index % 6 == 0) {
                    var tab = index / 6;
                    var activeTab = tab == 0 ? 'active' : '';
                    bodyRender += `<div class="favorites-nav-tab favorites-nav-tab-${tab} ${activeTab}">`;
                    pagination += `<a href="#" onclick="j('.favorites-nav-tab').removeClass('active'); j('.favorites-nav-tab-${tab}').addClass('active'); j('.widget-footer-pg-button-favorite').removeClass('active'); j('.widget-footer-pg-button-favorite-tab-${tab}').addClass('active');"><div class="widget-footer-pg-button widget-footer-pg-button-favorite widget-footer-pg-button-favorite-tab-${tab} ${activeTab}"></div></a>`;
                }
                bodyRender += `<div class="favorite-item"><a href="${Api.baseUrl()}/load.iface?${j.param({content: value.href})}"><table class="favorite-item-table"><tr><td class="favorite-icon-td">${generateIcon(value.icon)}</td><td class="favorite-text-td"> <div class="favorite-caption">${value.text}</div> <div class="favorite-description">${value.description}</div></td></tr></table></a></div>`;                if (index % 6 == 5) {
                    bodyRender += '</div>';
                }
            });
        } else {
            bodyRender = `<div class="favorite-empty-message"><img class="favorite-empty-image" src="${Api.baseUrl()}/resources/images/empty.png" /><div class="favorite-empty-title">${this.props.context.msg.t('favorites.empty')}</div><div class="favorite-empty-description">${this.props.context.msg.t('favorites.empty.message.moment')}<br>${this.props.context.msg.t('favorites.empty.message.avaliable')}</div></div>`;
        }

        return (
            <div className="widget-box">
                <div className="widget-header">
                    {this.props.context.msg.t('favorites')}
                </div>
                <div className="widget-body">
                    <div className="widget-body-inner slim-scroll" data-height="270">
                        <div className="favorite-itens">
                            <div dangerouslySetInnerHTML={{__html: bodyRender}}></div>
                        </div>
                    </div>
                </div>

                <div className="widget-footer">
                    <div className="widget-footer-pg-buttons" dangerouslySetInnerHTML={{__html: pagination}}>
                    </div>
                </div>
            </div>
        );
    }


}

export default ContextEnhancer(Favorite);