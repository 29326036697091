import styles from './HtmlItemDialog.module.css';

import React from 'react';
import { Field, Formik } from 'formik';

import Dialog from 'components/ui/Dialog';
import BngInputRichText from 'components/bng/form/BngInputRichText';
import BngField from 'components/bng/form/BngField';
import BngForm from 'components/bng/form/BngForm';
import { DefaultDialogActions } from 'components/ui/FormUtils';
import { TYPES } from 'components/ui/cockpit/Cockpit';
import UiMsg from 'components/ui/UiMsg';
import BngButton, { Size } from 'components/bng/ui/BngButton';
import useTranslation from 'components/hooks/useTranslation';

export default function HtmlItemDialog({ className = '', itemData, closeModal = _.noop, onSave = _.noop }) {
  const { t } = useTranslation();

  const handleSave = async (values) => {
    if (!values.content) {
      UiMsg.error(t('object.save.error'), t('cockpit.content.empty.error'));
      return;
    }

    const requestData = {
      content: values.content,
      name: values.name,
      icon: values.icon,
      color: values.color,
      id: Date.now(),
      order: values.order,
      enableIframe: true,
    };

    try {
      await onSave(requestData);
      UiMsg.ok(t('object.save.success'));
      closeModal();
    } catch (e) {
      console.error(e);
      UiMsg.ajaxError(null, e);
    }
  };

  const title =
    itemData.type === TYPES.INDEX_PAGE_ITEM
      ? t('index.page.config')
      : itemData.type === TYPES.HTML_ITEM
      ? t('new.content.page')
      : t('edit.content.page', [itemData.name]);

  const handleBoolean = itemData.type === TYPES.INDEX_PAGE_ITEM;

  return (
    <Formik initialValues={itemData} onSubmit={handleSave}>
      {({ values, setFieldValue }) => (
        <Dialog
          title={title}
          onClose={closeModal}
          className={`HtmlItemDialog ${styles.HtmlItemDialog} ${className}`}
          newDialogLayout={true}
        >
          <BngForm>
            <Dialog.Body>
              <Field name="name" type="text" component={BngField} autoFocus={true} readOnly={handleBoolean} />
              <Field
                label={t('content')}
                className="form-control"
                name="content"
                component={BngField}
                inputComponent={BngInputRichText}
                showVars
              />
              <div className="htmlEditorTextVarsLabel">{t('html.vars')}</div>
              <div className="flex-center-items gap-1">
                {[
                  {
                    label: t('user_name'),
                    onClick: () => {
                      setFieldValue('content', (values.content ?? '') + ' ${user.displayName}');
                    },
                  },
                  {
                    label: t('user.email'),
                    onClick: () => {
                      setFieldValue('content', (values.content ?? '') + ' ${user.email}');
                    },
                  },
                  {
                    label: t('date'),
                    onClick: () => {
                      setFieldValue('content', (values.content ?? '') + ' ${date}');
                    },
                  },
                  {
                    label: t('time'),
                    onClick: () => {
                      setFieldValue('content', (values.content ?? '') + ' ${time}');
                    },
                  },
                ].map((item, idx) => {
                  return (
                    <BngButton key={idx} onClick={item.onClick} size={Size.sm} className={styles.htmlVarsButton}>
                      {item.label}
                    </BngButton>
                  );
                })}
              </div>
            </Dialog.Body>
            <Dialog.Footer>
              <DefaultDialogActions closeModal={closeModal} />
            </Dialog.Footer>
          </BngForm>
        </Dialog>
      )}
    </Formik>
  );
}
