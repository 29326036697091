import './UserHomeItem.css';
import './Menu.css';

import React from 'react';
import clsx from 'clsx';

import MenuItem from 'components/ui/menu/MenuItem';
import ContextEnhancer from 'components/ContextEnhancer';
import Toggleable from 'components/ui/Toggleable';
import Icon from 'components/ui/common/Icon';
import Utils from 'components/Utils';
import { VIEW } from 'bng/routes';
import UiBlocker from 'components/bng/ui/UiBlocker';
import BngErrorBoundary from 'components/bng/ui/BngErrorBoundary';

class Menu extends Toggleable {
  static defaultProps = {
    rootItems: [],
    expanded: false,
    favorites: [],
    breadcrumb: null,
  };

  state = {
    filteredItems: null,
    searchValue: '',
    lastOpenedFolders: [],
  };

  constructor(props) {
    super(props);
  }

  updateScroll() {
    try {
      let height = jQuery(window).height() - 180;

      jQuery('#sidebarContainerList').slimScroll({
        height: height,
        position: 'right',
      });
    } catch (e) {
      console.error('Error on updateScroll()', e);
    }
  }

  componentDidMount() {
    this.updateScroll();
    super.componentDidMount();
    this.updateMenu();
    this.__contentChangeEventUnregister = bimEventBus.on('ContentChangeEvent', this.updateMenu);
  }

  componentWillUnmount() {
    this.__contentChangeEventUnregister();
  }

  updateMenu = () => {
    try {
      if (!this.processMenu()) {
        return;
      }
      this.props.findMenuRoots();
    } catch (e) {
      console.error('Error on updateMenu()', e);
    }
  };

  clickedOutOfDropdown = () => {
    if (!this.isOver() && this.isOpen()) {
      this.toggleExpand();
    }
  };

  toggleExpand = () => {
    this.props.toggleExpandMenu();
    if (this.isOpen()) {
      this.close();
      this.setState({ lastOpenedFolders: [] });
    } else {
      this.open();
    }
  };

  findItem(filteredItems, searchValue) {
    const foundItems = [];
    searchValue = (searchValue ?? '').toLowerCase();
    filteredItems.forEach((item) => {
      try {
        if (item.children && item.children.length > 0) {
          return foundItems.push(this.findItem(item.children, searchValue));
        }
      } catch (e) {
        console.error('Error on findItem()', filteredItems, searchValue, e);
      }

      if (item.text && item.text.toLowerCase().includes(searchValue)) {
        foundItems.push(item);
      }
    });
    return foundItems;
  }

  toggleOpenItem = ({ path }) => {
    const { lastOpenedFolders } = this.state;
    const idx = lastOpenedFolders.indexOf(path);
    let copy;
    if (idx !== -1) {
      copy = lastOpenedFolders.slice(0, idx);
    } else {
      copy = lastOpenedFolders.slice();
      const depth = path.split('/').length;
      copy = lastOpenedFolders.filter((p) => p.split('/').length < depth);
      copy.push(path);
    }
    this.setState({ lastOpenedFolders: copy });
  };

  processMenu() {
    return (
      !Utils.ExternalPages.isExternalPage() &&
      (this.props.view === VIEW.Discover ||
        (this.props.context.isAccessingProject &&
          !this.props.context.cockpitEnabled &&
          !this.props.context.userIsOnlyCockpit))
    );
  }

  render() {
    if (!this.processMenu()) {
      return null;
    }

    const itemActiveName = this.props.breadcrumb?.pathFor?.[0] ?? '';
    const filteredItems = this.state.filteredItems ? this.state.filteredItems : this.props.rootItems;
    const isAnyItemOpened = this.state.lastOpenedFolders.length > 0;

    return (
      <div
        id="sidebar"
        className={clsx('Menu no-print sidebar', {
          'menu-min': !this.props.expanded,
          menuItemOpened: isAnyItemOpened,
        })}
        onMouseDown={this.over}
        onMouseUp={this.out}
      >
        <div className={clsx('menu-home', { active: itemActiveName })}>
          {this.props.expanded && (
            <a href={`${this.props.context.contextPath}/pages/personal-page/index.iface`}>
              <table>
                <tbody>
                  <tr>
                    <td className="menutd menutd-icon menutd-icon-home">
                      <Icon icon="home" />
                    </td>
                    <td className="menutd menutd-text menutd-text-home">
                      <div className={this.props.container ? 'menu-text' : 'menu_option'}>
                        <span className="menu-text-span">{this.props.context.msg.t('my.page')}</span>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </a>
          )}
          {!this.props.expanded && (
            <a href={`${this.props.context.contextPath}/pages/personal-page/index.iface`}>
              <Icon icon="home" />
            </a>
          )}
        </div>

        <BngErrorBoundary>
          <UiBlocker id="sidebarContainerList" block={_.isEmpty(filteredItems)}>
            <ul className="nav nav-list">
              {filteredItems.map((item, idx) => {
                const active = this.state.lastOpenedFolders.length === 0 && item.text === itemActiveName;
                return (
                  <BngErrorBoundary key={`${item.path ?? ''}-${idx}`}>
                    <MenuItem
                      expanded={this.props.expanded}
                      active={active}
                      toggleExpand={this.toggleExpand}
                      toggleOpenItem={this.toggleOpenItem}
                      lastOpenedFolders={this.state.lastOpenedFolders}
                      {...item}
                    />
                  </BngErrorBoundary>
                );
              })}
            </ul>
          </UiBlocker>
        </BngErrorBoundary>

        <div className="sidebar-collapse" id="sidebar-collapse" onClick={this.toggleExpand}>
          <i className={`icon ${this.props.expanded ? 'icon-bim-menucontracted' : 'icon-bim-menuexpand'}`}></i>
        </div>
      </div>
    );
  }
}

export default ContextEnhancer(Menu);
