import 'components/ui/cockpit/buttons/CockpitButtons.css';

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import ContextEnhancer from 'components/ContextEnhancer';
import { TYPES } from 'components/ui/cockpit/Cockpit';
import Api from 'components/Api';
import UiMsg from 'components/ui/UiMsg';
import FilterService from 'components/filter/FilterService';
import PanelItensButton from 'components/ui/cockpit/buttons/PanelItemsButton';
import BookButton from 'components/ui/cockpit/buttons/BookButton';
import PresentationButton from 'components/ui/cockpit/buttons/PresentationButton';
import GlobalFilterButton from 'components/ui/cockpit/buttons/GlobalFilterButton';
import NotesButton from 'components/ui/cockpit/buttons/NotesButton';
import ExportButton from 'components/ui/cockpit/buttons/ExportButton';
import HideHeaderToolBarItem, { HideHeaderPageOpts } from 'components/ui/breadcrumb-toolbar/HideHeaderToolbarItem';
import CockpitReactSwitch from "components/bng/pages/cockpit/CockpitReactSwitch";

class CockpitButtons extends Component {
  static propTypes = {
    canCreatePresentation: PropTypes.bool,
    context: PropTypes.any,
    panels: PropTypes.any,
    currentPanel: PropTypes.any,
    indexPanel: PropTypes.any,
    onSelect: PropTypes.func,
  };

  state = {
    activeFilters: [],
    inactiveFilters: [],
    hasContainer: false,
    isFiltered: false,
    headerExpanded: true,
  };

  loadCockpitFilters = async () => {
    const shouldLoad =
      _.isEmpty(this.state.inactiveFilters) && _.isEmpty(this.state.activeFilters) && _.isEmpty(this.state.isFiltered);
    if (!shouldLoad) {
      return;
    }
    try {
      let filters = await Api.Cockpit.findFilters(this.props.id, this.props.currentPanel);
      this.updateGlobalFilters(filters);
    } catch (e) {
      UiMsg.ajaxError('error', e);
    }
  };

  onChangeFilter = async (filter, selectedMembers) => {
    const filters = await Api.Cockpit.applyGlobalFilter({ id: filter.id, selectedMembers });
    this.updateGlobalFilters(filters);
    await Api.updateJsf();
  };

  updateGlobalFilters = (filters = {}) => {
    if (filters !== null) {
      this.populateSelectedMembers(filters.enabled, filters.filledFilters);
      this.populateSelectedMembers(filters.disabled, filters.filledFilters);

      filters.enabled = FilterService.transform(filters.enabled);
      this.setState({
        inactiveFilters: filters.disabled,
        activeFilters: filters.enabled,
        isFiltered: filters.filtered,
      });
    }
  };

  populateSelectedMembers = (filters, filledFilters) => {
    filters.map((filter) => {
      if (!filter.selectedMembers) {
        filter.selectedMembers = [];
      }
      for (let filled of filledFilters) {
        if (filled.id === filter.id) {
          filter.selectedMembers = filled.selectedMembers;
          break;
        }
      }
    });
  };

  componentDidMount() {
    application.cockpit.scrollBar(true);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    application.cockpit.scrollBar(true);
  }

  render() {
    const { currentPanel, panels, path } = this.props;
    const activePanel = panels.find((p) => p.id === currentPanel);

    const exportFilters = this.state.activeFilters.map((filter) => {
      return {
        id: filter.id,
        members: filter.selectedMembers.map((m) => {
          return m.value;
        }),
        restrictionType: filter.type,
      };
    });

    if (!activePanel) return null;

    return (
      <div id="cockpit-action-buttons" className="cockpit-action-buttons">
        <PanelItensButton items={panels} currentPanel={currentPanel} />

        <CockpitReactSwitch />

        <ExportButton {...this.props} activePanel={activePanel} filters={exportFilters} />

        <HideHeaderToolBarItem location={HideHeaderPageOpts.COCKPIT_TABS} />

        {activePanel.type === TYPES.PATH_AWARE_ITEM && (
          <React.Fragment>
            <GlobalFilterButton
              {...this.props}
              {...this.state}
              loadCockpitFilters={this.loadCockpitFilters}
              onChangeFilter={this.onChangeFilter}
            />
            <NotesButton path={path} />
          </React.Fragment>
        )}

        {this.props.canCreatePresentation && <PresentationButton {...this.props} />}

        <BookButton {...this.props} />
      </div>
    );
  }
}

export default ContextEnhancer(connect()(CockpitButtons));
