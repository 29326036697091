import "./BngSearch.css";
import React, {Component} from "react";
import PropTypes from "prop-types";
import Icon from "components/ui/common/Icon";

const UNCONTROLLED_VALUE = 'UNCONTROLLED';

export const filterByProps = ({array = [], searchTerm = '', searchProps = []}) => {
    const onSearchProps = (item) => {
        return searchProps.filter(prop => !!item[prop] ? item[prop].toLowerCase().includes(searchTerm.toLowerCase()) : false)
    }
    return _.filter(array, (item) => {
        return onSearchProps(item).length > 0
    })
}

class BngSearch extends Component {

    static propTypes = {
        className: PropTypes.string,
        placeholder: PropTypes.string,
        name: PropTypes.string,
        onChange: PropTypes.func,
        alwaysOpen: PropTypes.bool,
        simple: PropTypes.bool,
        inline: PropTypes.bool,
        inputAutoComplete: PropTypes.bool,
        value: PropTypes.string,
        stateListener: PropTypes.func,
        title: PropTypes.string,
        maxLength: PropTypes.string,
        label: PropTypes.any,
        side: PropTypes.any,
        disabled: PropTypes.bool,
        onKeyDown: PropTypes.func,
    };

    static defaultProps = {
        className: '',
        placeholder: '',
        name: '',
        alwaysOpen: false,
        simple: false,
        inline: false,
        onChange: _.noop,
        inputAutoComplete: true,
        value: UNCONTROLLED_VALUE,
        stateListener: _.noop,
        title: '',
        label: undefined,
        side: 'left',
        disabled: false,
        onKeyDown: undefined,
    };

    constructor(props) {
        super(props);
        this.state = {
            open: props.alwaysOpen || props.inline,
            searchTerm: '',
        };
        this.textInput = React.createRef();
    }

    componentDidMount() {
        if (this.state.open) {
            this.textInput.current.focus();
        }
    }

    toggleVisibility = (event) => {
        event.preventDefault();
        const isOpen = this.state.open;
        const newValue = !isOpen;
        this.setState({open: newValue});
        if (isOpen) {
            this.clearSearchTerm();
        } else {
            this.textInput.current.focus();
        }
        this.props.stateListener({open: newValue});
    };

    clearSearchTerm() {
        this.setState({searchTerm: ''});
        this.props.onChange('', true);
    }

    getSearchTerm = () => {
        return this.state.searchTerm;
    };

    handleChange = (event) => {
        event.preventDefault();
        this.setState({searchTerm: event.target.value});
        this.props.onChange(event.target.value);
    };

    hasLabel = () => {
        if (this.props?.label) {
            return `with-label ${this.state.open ? 'closed' : 'open'}`
        }
        return '';
    }

    render() {
        const {
            simple,
            name,
            className,
            placeholder,
            alwaysOpen,
            inputAutoComplete,
            onKeyPress,
            title,
            inline,
            maxLength,
            side,
            disabled,
            onKeyDown
        } = this.props;
        const {open, searchTerm} = this.state;
        const effectiveValue = this.props.value === UNCONTROLLED_VALUE ? searchTerm : this.props.value;
        const simpleButton = simple && searchTerm.length === 0;
        const iconOnLeft = side === 'left';
        const searchLabelFlexOrder = (!open && this.props?.label && !iconOnLeft) ? 1 : 0;
        return (
            <div
                className={`bng-search ${className} ${open ? 'open' : 'closed'} ${simple ? 'SimpleBngSearch' : ''} ${inline ? 'InlineBngSearch' : ''} ${disabled ? 'disabled' : ''}`}>
                <div className={`bng-search-input ${open ? 'open' : 'closed'}`}>
                    {!simple && <Icon className={'SearchIcon'} icon="search" style={{order: iconOnLeft ? 0 : 1}}/>}
                    <input ref={this.textInput}
                           onChange={this.handleChange}
                           name={name}
                           type="text"
                           value={effectiveValue}
                           placeholder={placeholder}
                           className="search-field"
                           autoComplete={inputAutoComplete ? 'on' : 'off'}
                           onKeyPress={onKeyPress}
                           maxLength={maxLength}
                           disabled={disabled}
                           onKeyDown={onKeyDown}
                    />
                </div>

                {!inline &&
                <div className={`search-button ${this.hasLabel()} ml-auto ${alwaysOpen ? 'alwaysOpen' : ''} ${simpleButton ? 'simple-button' : ''}`}
                     onClick={(e) => alwaysOpen ? this.clearSearchTerm() : this.toggleVisibility(e)}
                     title={title}
                >
                    {(!open && this.props?.label) &&
                    <span className={'search-label'}
                          style={{order: `${searchLabelFlexOrder}`}}>
                        {this.props.label}
                    </span>
                    }
                    {((alwaysOpen && this.state.searchTerm) || !alwaysOpen) &&
                        <Icon icon={simpleButton || !open ? 'search' : 'close'}/>
                    }
                </div>
                }
            </div>
        )
    }
}

export default BngSearch;