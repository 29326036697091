import React from "react";
import PropTypes from 'prop-types';
import Utils from 'components/Utils';

const OpTextConfirmation = ({
                                title = '',
                                message = '',
                                nameToDelete = '',
                                onConfirm = _.noop,
                                msg = {},
                                level = 'error',
                                html = null,
                                confirmText = msg.t('yes'),
                                cancelText = msg.t('no'),
                                validationMessage = '',
                                showCheckbox = false,
                                checkboxLabel = '',
                            }) => {
    let checkboxId = '';
    let checkboxComponent = '';
    if(showCheckbox) {
      checkboxId = Utils.randomId();
      checkboxComponent = `
       <div class="mt-2">
        <input type="checkbox" id="${checkboxId}" >
        <label for="${checkboxId}">${checkboxLabel}</label>
       </div>`
    }

    Swal.fire({
            title: title,
            text: message,
            input: 'text',
            inputAttributes: {
                autocapitalize: 'off'
            },
            icon: level,
            html: `
              ${html}
              ${checkboxComponent}
            `,
            showCancelButton: true,
            confirmButtonColor: level === 'error' ? "#DD6B55" : (level === 'warning' ? 'FFA500' : ''),
            confirmButtonText: confirmText,
            cancelButtonText: cancelText,
            preConfirm: (inputForDelete) => {
                if (inputForDelete !== nameToDelete) {
                    Swal.showValidationMessage(validationMessage);
                }
            },
        }
    ).then((result) => {
        if (result.value) {
            const checkboxValue = showCheckbox
              ? document.getElementById(checkboxId).checked
              : undefined;
            onConfirm({ checkboxValue });
        }
    })
};

OpTextConfirmation.propTypes = PropTypes.shape({
    msg: PropTypes.object,
    title: PropTypes.string,
    message: PropTypes.string,
    level: PropTypes.string,
    onConfirm: PropTypes.func,
    showCheckbox: PropTypes.bool,
    checkboxLabel: PropTypes.string,
});

export default OpTextConfirmation;