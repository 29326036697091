import { Axios, buildUrlWithAuth } from 'commonUtils';
import Utils from 'components/Utils';
import FilterService from 'components/filter/FilterService';
import Api from 'components/Api';

class ExportApi {

    exportObjectUrl({ fullUrl = false, ...props }) {
        Api.checkContent(props);
        props.uiOnError = true;
        return buildUrlWithAuth(`/spr/render/export/pdf?${j.param(props)}`);
    }

    getMobileUrlExcelExport = (path, params) => {
        try {
            const filename = Utils.Object.getObjectInPath(path);
            return buildUrlWithAuth(`/spr/analysis/export-to-excel?${j.param({path, filename, ...params})}`);
        } catch (e) {
            console.error(e);
        }
    }

    getUrlToExportNewMap = async (props = {}) => {
        if (!props.filter) {
            let filter = new URLSearchParams(window.location.search).get('filter');
            if (!!filter) {
                props.filter = filter;
            }
        }
        try {
            const {data} = await Axios.post(`/spr/export-current-view/currentObject`, props.object);
            return data;
        } catch (error) {
            await props.onErrorExport(error);
            return null;
        }
    };

    exportCurrentObjectUrl = async ({fullUrl = false, mdxRef = null, ...props}) => {
        Api.checkContent(props);

        if (!Utils.Object.isNewMap(props.content)) {
            const extension = !!props.analysisNotPersisted ? 'analysis' : Utils.Object.getObjectType(props.content);
            let dashStateRef;
            if(extension === "analysis") {
                const data = await j.getJSON('/spr/ui/analysis/chart-conf');
                await j.postJSON('/spr/ui/analysis/chart-conf', {
                    enabled: data.enabled,
                    confs: data.conf
                });
                await Api.updateJsf();
            } else if(extension === 'dashboard'
              && window.__CURRENT_DASHGRID
              && window.__CURRENT_DASHGRID.stateForExport) {
                const { filters, ...dashState } = window.__CURRENT_DASHGRID.stateForExport() ?? {};
                dashStateRef = await this.dashStateToDashRef(dashState);
            }

            let bigtableFilterModel = props.bigtableFilterModel;
            if (Array.isArray(bigtableFilterModel) && Utils.Object.isBigTable(props.content)) {
              if (bigtableFilterModel.length === 1) {
                bigtableFilterModel = bigtableFilterModel[0].filterMod;
              } else {
                const match = bigtableFilterModel.find((fm) => fm.path === props.content);
                if (match) {
                  bigtableFilterModel = match.filterMod;
                }
              }
            }

            const urlParams = Api.params({
                mdxRef,
                bigtableFilterModel: JSON.stringify(bigtableFilterModel),
                bigtableSortModel: JSON.stringify(props.bigtableSortModel),
                dashStateRef
            });
            window.open(`${Api.baseUrl()}/spr/export-current-view/pdf/${extension}?${urlParams}`, '_blank');
        } else {
            let exportParams = await Api.Export.getUrlToExportNewMap(props);
            if (!!exportParams) {
                window.open(`${Api.baseUrl()}/spr/export-current-view/exportCurrentObject?${Api.params({...exportParams})}`, '_blank');
            }
        }
    };

    exportCockpitObject = async ({ filters, dashState, ...params }) => {
        Api.checkContent(params);

        ['bigtableFilterModel', 'bigtableSortModel'].forEach(key => {
            if (params[key]) {
                params[key] = JSON.stringify(params[key]);
            }
        });

        const dashStateRef = await this.dashStateToDashRef(dashState);
        const filterRef = await FilterService.createFilterRef(filters);
        const exportUrl = Api.Export.exportObjectUrl({
            filterRef,
            dashStateRef,
            ...params
        });

        window.open(exportUrl, '_blank');
    };

    async dashStateToDashRef(dashState) {
        let dashStateRef = undefined;
        if (!_.isEmpty(dashState)) {
            dashState = {
                itemOverrides: Object.values(dashState.itemOverrides)
                  .map(io => {
                      return ({
                          id: io.id,
                          path: io.path,
                          viewType: io.viewType
                      });
                  }),
                mdxOverrides: dashState.mdxOverrides ?? []
            };
            const { key } = await Api.TemporaryStorage.put(dashState);
            dashStateRef = key;
        }
        return dashStateRef;
    }

    exportPdfFromDash = async (path, filters, item) => {
        const filterRef = await FilterService.createFilterRef(filters);

        let mdxRef;
        let viewType;
        if (item && Utils.Object.isAnalysis(path)) {
            if (application.ice.isLoaded()) {
                try {
                    mdxRef = await Api.Dash.storageMDX(item.id);
                } catch (e) {
                }
            } else {
                const mdx = window.RENDERABLE_PRELOAD_CACHE[item.id]?.mdx;
                if (mdx) {
                    const { key } = await Api.TemporaryStorage.put(mdx);
                    mdxRef = key;
                }
            }

            if (item.viewType) {
                viewType = item.viewType === 'html' ? 'TABLE' : 'CHART';
            }
        }

        const exportUrl = Api.Export.exportObjectUrl({
            filterRef,
            mdxRef,
            content: path,
            viewType
        });

        window.open(exportUrl, '_blank');
    };

}

export default ExportApi;