import styles from './ActivityDialog.module.css';

import React, { useEffect, useMemo, useState } from 'react';
import { Field, Formik } from 'formik';

import Dialog from 'components/ui/Dialog';
import { BngTable } from 'components/bng/ui/BngTable';
import Api from 'components/Api';
import UiMsg from 'components/ui/UiMsg';
import { BngAvatar } from 'components/bng/ui/BngAvatar';
import Icon from 'components/ui/common/Icon';
import { BngDropdown } from 'components/bng/ui/BngDropdown';
import Button from 'components/ui/Button';
import { BngField } from 'components/bng/form/BngField';
import { BngSelectSearch } from 'components/bng/form/BngSelectSearch';
import { BngForm } from 'components/bng/form/BngForm';
import { UiBlocker } from 'components/bng/ui/UiBlocker';
import { bngYup } from 'components/bng/form/yup/BngYup';
import useBimContext from 'components/hooks/useBimContext';
import Utils from 'components/Utils';
import { ActivityHandlers } from 'components/ui/recent-activities/Activity';

const ActivityFilterDropdownSchema = bngYup((yup) => {
  return yup.object().shape({
    eventType: yup.string().nullable().default(''),
    who: yup.string().nullable().default(''),
    startDate: yup.date().nullable().default(null),
    endDate: yup.date().nullable().default(null),
    resultLimit: yup.number().default(50),
  });
});

const ActivityFilterDropdown = ({ initialValues = {}, users = [], applyFilters = _.noop }) => {
  const { msg } = useBimContext();
  const [loading, setLoading] = useState(false);

  const [usersOpts] = useState(
    users
      .map((user) => {
        return {
          value: user.user.id,
          label: `${user.user.email} ${user.user.displayName ?? ''}`,
          render: () => (
            <span title={`${user.user.displayName ?? ''} (${user.user.email})`} className={styles.selectOption}>
              {user.user.displayName || user.user.email}
            </span>
          ),
        };
      })
      .sort((a, b) => Utils.Strings.compareIgnoreCase(a.label, b.label))
  );

  const [limitOpts] = useState(
    [50, 100, 150, 200, 300, 400, 500].map((opt) => {
      return {
        value: opt,
        label: `${opt}`,
      };
    })
  );

  const [eventTypesOpts, setEventTypesOpts] = useState([]);

  const fetchData = async () => {
    setLoading(true);
    try {
      let eventTypes = await Api.Activity.findEventTypes();
      eventTypes = eventTypes
        .map((event) => {
          return {
            value: event,
            label: msg.t(event),
            render: () => (
              <span title={msg.t(event)} className={styles.selectOption}>
                {msg.t(event)}
              </span>
            ),
          };
        })
        .sort((a, b) => Utils.Strings.compareIgnoreCase(a.label, b.label));

      setEventTypesOpts(eventTypes);
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  };

  const initialFormValues = useMemo(() => {
    return _.merge({}, ActivityFilterDropdownSchema.default(), initialValues);
  }, [initialValues]);

  return (
    <BngDropdown
      title={msg.t('filter')}
      icon="filter_alt"
      className="ActivityFilterDropdown"
      customButton={({ openDropdown }) => {
        return (
          <div className={styles.buttonOpenDropdown} onClick={openDropdown}>
            <Icon icon="filter_alt" />
            <label className={styles.buttonDropdownText}>{msg.t('filter')}</label>
          </div>
        );
      }}
      popperClassName={`${styles.ActivityFilterDropdownPopper} ActivityFilterDropdownPopper`}
      onOpen={() => {
        if (_.isEmpty(eventTypesOpts)) {
          fetchData();
        }
      }}
      customOptions={({ closeDropdown }) => {
        return (
          <div className={styles.activityDropdownBody}>
            <Formik
              initialValues={initialFormValues}
              validationSchema={ActivityFilterDropdownSchema}
              onSubmit={async (values, formikHelpers) => {
                await applyFilters(values);
                closeDropdown();
              }}
            >
              <UiBlocker block={loading}>
                <BngForm>
                  <div className="flex-center-items mb-4">
                    <Icon icon={'filter_alt'} className={'mr-1'} />
                    {msg.t('filter')}
                  </div>

                  <Field
                    name="eventType"
                    component={BngField}
                    label={msg.t('what')}
                    inputComponent={BngSelectSearch}
                    options={eventTypesOpts}
                    className={styles.filterDropdownField}
                    asProps={{
                      popperClassName: styles.filterDropdownPopper,
                    }}
                  />

                  <Field
                    name="who"
                    component={BngField}
                    inputComponent={BngSelectSearch}
                    options={usersOpts}
                    className={styles.filterDropdownField}
                    asProps={{
                      popperClassName: styles.filterDropdownPopper,
                    }}
                  />

                  <Field
                    name="startDate"
                    component={BngField}
                    label={msg.t('initial.date')}
                    type="date"
                    className={`${styles.filterDropdownField} ${styles.dateField}`}
                  />

                  <Field
                    name="endDate"
                    component={BngField}
                    label={msg.t('final.date')}
                    type="date"
                    className={`${styles.filterDropdownField} ${styles.dateField}`}
                  />

                  <Field
                    name="resultLimit"
                    component={BngField}
                    options={limitOpts}
                    label={msg.t('result.limit')}
                    inputComponent={BngSelectSearch}
                    className={styles.filterDropdownField}
                    clearable={false}
                    asProps={{
                      popperClassName: styles.filterDropdownPopper,
                    }}
                  />

                  <div className="d-flex mt-4" style={{ justifyContent: 'space-between' }}>
                    <Button
                      className={'bng-button cancel borderless btn-small'}
                      onClick={async (e) => {
                        e.stopPropagation();
                        await applyFilters(ActivityFilterDropdownSchema.default());
                        closeDropdown();
                      }}
                    >
                      {msg.t('clear')}
                    </Button>
                    <Button className={'bng-button confirm borderless btn-small'} type="submit">
                      {msg.t('apply')}
                    </Button>
                  </div>
                </BngForm>
              </UiBlocker>
            </Formik>
          </div>
        );
      }}
    />
  );
};

const GenericCaption = (activity, msg) => {
  if (activity?.what?.message) {
    return activity.what.message;
  }

  let caption = activity?.props?.params?.caption ?? '';
  if (caption && msg.containKey(caption)) {
    caption = msg.t(caption);
  }
  return msg.t(`${activity.type}.msg`, [`<strong>${caption}</strong>`]);
};

const AddonRequestCaption = (activity, msg) => {
  const { params } = activity.props;
  return msg.t('for.the.account', [msg.t(params.addon), params.accountName]);
};

const CaptionHandler = {
  AddonRequested: AddonRequestCaption,
  AddonRequestAccepted: AddonRequestCaption,
  AddonRequestRejected: AddonRequestCaption,
};

export default function ActivityDialog({ closeModal = _.noop, beforeClose = _.noop, users = [] }) {
  const context = useBimContext();
  const [loading, setLoading] = useState(false);
  const [activities, setActivities] = useState([]);
  const [filters, setFilters] = useState(ActivityFilterDropdownSchema.default());

  const fetchActivities = async () => {
    setLoading(true);
    try {
      const fetchedActivities = await Api.Activity.findActivitiesList({
        projectId: context.project.id,
        userId: filters.who,
        eventType: filters.eventType,
        startDate: filters.startDate,
        endDate: filters.endDate,
        resultLimit: filters.resultLimit,
      });
      setActivities(fetchedActivities);
    } catch (e) {
      UiMsg.ajaxError(null, e);
      console.error('Error on ActivityDialog.fetchActivities', filters, e);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchActivities();
  }, [filters]);

  const activitiesColumns = useMemo(() => {
    return [
      {
        label: context.msg.t('who'),
        size: 240,
        render: (row) => {
          return (
            <div className={`flex-center-items gap-2`}>
              <BngAvatar userId={row.user.id} />
              <span>{row.user.displayName}</span>
            </div>
          );
        },
      },
      {
        label: context.msg.t('when'),
        size: 150,
        render: (row) => row.date?.format('DD/MM/YYYY HH:mm') ?? '',
      },
      {
        label: context.msg.t('activity'),
        render: (row) => {
          const ActivityHandler = ActivityHandlers[row.type];
          if (ActivityHandler) {
            return <ActivityHandler activity={row} />;
          }

          const captionHandler = CaptionHandler[row.type] || GenericCaption;
          const caption = captionHandler(row, context.msg);
          const msgHtml = `<strong>${row.user.displayName}</strong> 
                        ${caption} 
                        <strong>${
                          row.where.message ||
                          (row.project ? `${context.msg.t('inproject')} ${row.project.displayName}` : '')
                        }</strong>`;
          return (
            <span
              className={styles.activityMessage}
              title={msgHtml}
              dangerouslySetInnerHTML={{
                __html: msgHtml,
              }}
            />
          );
        },
      },
    ];
  }, []);

  const applyFilters = async (values) => {
    const filtersNewValue = { ...filters, ...values };
    setFilters(filtersNewValue);
  };

  return (
    <Dialog
      className={styles.ActivityDialog}
      title={context.msg.t('activities.view')}
      loading={loading}
      onClose={async () => {
        await beforeClose();
        closeModal();
      }}
      contentFullWidth={true}
    >
      <div className={'conection-dialog-division top'} />

      <div>
        <div className={styles.fieldsWrapper}>
          <ActivityFilterDropdown applyFilters={applyFilters} initialValues={filters} users={users} />
          <span>
            {context.msg.t('total.rows.found')}: <strong>{activities.length}</strong>
          </span>
        </div>
      </div>

      <div className={'conection-dialog-division top'} style={{ marginBottom: 0 }} />

      <div className={styles.activitiesScroll}>
        <BngTable
          cols={activitiesColumns}
          rows={activities}
          stickyHeader={true}
          showEmptyAlert={true}
          emptyAlertProps={{ message: context.msg.t('activities.not.registered') }}
          className={styles.ActivityTable}
          rowHeight={66}
        />
      </div>
    </Dialog>
  );
}
