import React from 'react';
import PropTypes from 'prop-types';
import ContextEnhancer from 'components/ContextEnhancer';
import DashboardItemMenuTitle from './DashboardItemMenuTitle';
import Api from 'components/Api';
import UiMsg from 'components/ui/UiMsg';

class DashboardItemMenuShowMDX extends React.Component {
  static propTypes = {
    item: PropTypes.object,
  };

  static defaultProps = {
    item: {},
  };

  state = {};

  showMDX = async () => {
    try {
      let html = '';
      if (window.BiSourceMapData?.hasOwnProperty(this.props.item.id)) {
        const { dataMdx, geoMdx } = window.BiSourceMapData[this.props.item.id];
        html = `<div>
                    <div>
                        <h5 class="m-0">${this.props.context.msg.t('map.mdx.points')}</h5>
                        <pre>${geoMdx}</pre>
                    </div> 
                    <div>
                        <h5 class="m-0">${this.props.context.msg.t('map.mdx.data')}</h5>
                        <pre>${dataMdx}</pre>
                    </div>
                </div>`;
      } else {
        let mdx = '';
        if (window.RENDERABLE_PRELOAD_CACHE?.hasOwnProperty(this.props.item.id)) {
          mdx = window.RENDERABLE_PRELOAD_CACHE[this.props.item.id].mdx;
        } else {
          mdx = (await Api.Dash.findItemMdx(this.props.item.id)).mdx;
        }
        html = `<pre style="max-height: 350px; overflow-y: auto;">${mdx}</pre>`;
      }
      bootbox.alert(`<div style="padding: 0 5px 0 5px;">${html}</div>`);
    } catch (e) {
      console.error(e);
      UiMsg.ajaxError(null, e);
    }
  };

  render() {
    return (
      <li onClick={this.showMDX}>
        <DashboardItemMenuTitle title={this.props.context.msg.t('see.mdx')} icon="icon-align-justify" more={false} />
      </li>
    );
  }
}

export default ContextEnhancer(DashboardItemMenuShowMDX);
