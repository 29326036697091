import "./RecentActivities.css";

import React, {Component} from "react";
import ContextEnhancer from "components/ContextEnhancer";
import {ScrollContainer} from "components/ui/ScrollContainer";
import Activity from "components/ui/recent-activities/Activity";

class RecentActivities extends Component {

    static propTypes = {};

    static defaultProps = {
        activities: []
    };

    state = {
        page: 0
    };

    constructor(props) {
        super(props);
    }

    render() {
        const pagedActivities = _.chunk(this.props.activities, 5);
        const activities = pagedActivities[this.state.page];

        return (
            <div className="RecentActivities widget-box">
                <div className="widget-header">
                    {this.props.context.msg.t('recent_activities')}
                </div>
                <div className="widget-body">
                    <ScrollContainer className="widget-body-inner">
                        {activities.map(activity => (
                            <Activity activity={activity} key={activity.id}/>
                        ))}
                    </ScrollContainer>
                </div>

                <div className="widget-footer">
                    <div className="widget-footer-pg-buttons widget-footer-pg-buttons-activity">
                        {_.range(pagedActivities.length).map(page => (
                            <a href="#"
                               key={page}
                               onClick={(e) => {
                                   e.preventDefault();
                                   return this.setState({page});
                               }}>
                                <div
                                    className={`widget-footer-pg-button widget-footer-pg-button-activity ${this.state.page === page ? 'active' : ''}`}
                                />
                            </a>
                        ))}
                    </div>
                </div>
            </div>
        );
    }
}

export default ContextEnhancer(RecentActivities);