import React from 'react';
import {connect} from "react-redux";
import {MODALS} from "components/ui/redux/Actions";
import ImageDialog from "components/bng/pages/bigTable/components/ImageDialog";
import { findContextPath } from 'commonUtils';

export function rgbToYIQ(color) {
    let r = parseInt(color.substring(1,3), 16);
    let g = parseInt(color.substring(3,5), 16);
    let b = parseInt(color.substring(5,7), 16);

    return ((r * 299) + (g * 587) + (b * 114)) / 1000;
}

export const pSBC=(p,c0,c1,l)=>{
    let r,g,b,P,f,t,h,i=parseInt,m=Math.round,a=typeof(c1)=="string";
    if(typeof(p)!="number"||p<-1||p>1||typeof(c0)!="string"||(c0[0]!='r'&&c0[0]!='#')||(c1&&!a))return null;
    let pSBCr=(d)=>{
        let n=d.length,x={};
        if(n>9){
            [r,g,b,a]=d=d.split(","),n=d.length;
            if(n<3||n>4)return null;
            x.r=i(r[3]=="a"?r.slice(5):r.slice(4)),x.g=i(g),x.b=i(b),x.a=a?parseFloat(a):-1
        }else{
            if(n==8||n==6||n<4)return null;
            if(n<6)d="#"+d[1]+d[1]+d[2]+d[2]+d[3]+d[3]+(n>4?d[4]+d[4]:"");
            d=i(d.slice(1),16);
            if(n==9||n==5)x.r=d>>24&255,x.g=d>>16&255,x.b=d>>8&255,x.a=m((d&255)/0.255)/1000;
            else x.r=d>>16,x.g=d>>8&255,x.b=d&255,x.a=-1
        }return x};
    h=c0.length>9,h=a?c1.length>9?true:c1=="c"?!h:false:h,f=pSBCr(c0),P=p<0,t=c1&&c1!="c"?pSBCr(c1):P?{r:0,g:0,b:0,a:-1}:{r:255,g:255,b:255,a:-1},p=P?p*-1:p,P=1-p;
    if(!f||!t)return null;
    if(l)r=m(P*f.r+p*t.r),g=m(P*f.g+p*t.g),b=m(P*f.b+p*t.b);
    else r=m((P*f.r**2+p*t.r**2)**0.5),g=m((P*f.g**2+p*t.g**2)**0.5),b=m((P*f.b**2+p*t.b**2)**0.5);
    a=f.a,t=t.a,f=a>=0||t>=0,a=f?a<0?t:t<0?a:a*P+t*p:0;
    if(h)return"rgb"+(f?"a(":"(")+r+","+g+","+b+(f?","+m(a*1000)/1000:"")+")";
    else return"#"+(4294967296+r*16777216+g*65536+b*256+(f?m(a*255):0)).toString(16).slice(1,f?undefined:-2)

}


function colorRange(color1, color2, ratio) {
    let c1 = color1.slice(1)
    let c2 = color2.slice(1)

    var hex = function (x) {
        x = x.toString(16);
        return (x.length == 1) ? '0' + x : x;
    };

    var r = Math.ceil(parseInt(c1.substring(0, 2), 16) * ratio + parseInt(c2.substring(0, 2), 16) * (1 - ratio));
    var g = Math.ceil(parseInt(c1.substring(2, 4), 16) * ratio + parseInt(c2.substring(2, 4), 16) * (1 - ratio));
    var b = Math.ceil(parseInt(c1.substring(4, 6), 16) * ratio + parseInt(c2.substring(4, 6), 16) * (1 - ratio));

    if (r > 255) r = 255;
    if (g > 255) g = 255;
    if (b > 255) b = 255;

    return hex(r) + hex(g) + hex(b);
}

function getRangeValue(range, metaData) {
    if (!range) {
        return 0;
    }

    if (range.valueOption === 'max') {
        return metaData.max;
    } else if (range.valueOption === 'min') {
        return metaData.min;
    } else if (range.valueOption === 'number') {
        return range.value;
    } else if (range.valueOption === 'percentile') {
        let percentile = Math.floor(range.value);
        if (range.value <= 0) {
            percentile = 0;
        } else if (range.value >= 100) {
            percentile = 100;
        }

        if (metaData.percentiles[percentile]) {
            return metaData.percentiles[percentile]
        } else {
            return 0;
        }
    } else if (range.valueOption === 'percent') {
        return metaData.max * (range.value/100);
    }
}

export function getGradientColor(ranges, value, metaData) {
    let color1, color2, value1, value2;

    for (let i = 0; i < ranges.length; i++) {

        let v1 = getRangeValue(ranges[i], metaData);
        let v2 = getRangeValue(ranges[i+1], metaData);

        if (i === 0 && value <= v1) {
            color1 = ranges[i].color;
            break;
        }

        if (i+1 <= ranges.length-1 && (value > v1 && value <= v2)) {
            color1 = ranges[i].color;
            value1 = v1;
            color2 = ranges[i+1].color;
            value2 = v2;
            break;
        }
    }

    if (color1 === undefined && color2 === undefined) {
        return ranges[ranges.length-1].color
    }

    if (color2 === undefined) {
        return color1
    } else {
        return '#' + colorRange(color2, color1, (value-value1)/(value2-value1))
    }
}

function getColorFromRanges(ranges, value, metaData) {
    let color = '';

    for (let i = 0; i < ranges.length; i++) {
        let v1 = getRangeValue(ranges[i], metaData);
        let v2 = getRangeValue(ranges[i+1], metaData);
        if (i === 0) {
            v1 = -1 * Number.MAX_SAFE_INTEGER
        }

        if (i+1 <= ranges.length-1 && (value > v1 && value <= v2)) {
            color = ranges[i].color;
            break;
        }
    }

    if (color === '') {
        color = ranges[ranges.length-1].color;
    }

    return color;
}

function getTextColor(config, value, metaData, cellStyle) {
    let color = config.textColor;

    if (config.textColorType === 'automatic' && !isNaN(config.textAutomaticThreshold)) {
        let backgroundColor = cellStyle.backgroundColor;
        if (!backgroundColor) {
            backgroundColor = config.gridConfig.backgroundColor;
        }
        color = rgbToYIQ(backgroundColor) >= (parseFloat(config.textAutomaticThreshold) * 256)  ? '#000' : '#fff';
    }

    if (config.textColorType === 'ranges' && config.textColorRanges.length > 0) {
        color = getColorFromRanges(config.textColorRanges, value, metaData);
    }

    if (config.textColorType === 'gradient' && config.textColorGradients.length > 0) {
        color = getGradientColor(config.textColorGradients, value, metaData);
    }

    return {
        color
    };
}

function getCellColor(config, value, metaData) {
    let styles = {};

    if (value === null || value === 'Infinity') {
        return styles;
    }

    if (config.cellColorType === 'ranges' && config.cellColorRanges.length > 0) {
        let color = getColorFromRanges(config.cellColorRanges, value, metaData);
        styles.backgroundColor = color;
        styles.borderTop = `1px solid ${color}`;
        styles.borderBottom = `1px solid ${color}`;
    }

    if (config.cellColorType === 'gradient' && config.cellColorGradients.length > 0) {
        let color = getGradientColor(config.cellColorGradients, value, metaData);
        styles.backgroundColor = color;
        styles.borderTop = `1px solid ${color}`;
        styles.borderBottom = `1px solid ${color}`;
    }

    return styles;
}


function getBarColor(config, value, metaData) {
    if (config.cellBarColorType === 'ranges' && config.cellBarColorRanges.length > 0) {
        return getColorFromRanges(config.cellBarColorRanges, value, metaData);
    }

    if (config.cellBarColorType === 'gradient' && config.cellBarColorGradients.length > 0) {
        return getGradientColor(config.cellBarColorGradients, value, metaData);
    }

    return config.cellBarColor;
}

//valor bruto: props.value
//valor formatado: props.valueFormatted
//dados da linha: props.data
const DefaultRenderer = (props) => {

    let configs = props.colDef.custom;
    let metaData = props.metaData[configs.bigTableId][props.colDef.colId];
    let selectedRowId = props.selectedRowId[configs.bigTableId];
    let divRef = React.useRef(null);

    if (!metaData) {
        metaData = {
            sum: 0,
            max: 0,
            min: 0,
            count: 0,
            average: 0
        }
    }

    let cellStyle = getCellColor(configs, props.value, metaData);
    let styles = {
        ...getTextColor(configs, props.value, metaData, cellStyle),
        ...cellStyle,
        fontWeight: configs.textFormat.indexOf('bold') >= 0 ? 'bold' : 400,
        fontStyle: configs.textFormat.indexOf('italic') >= 0 ? 'italic' : 'unset',
        textDecoration: configs.textFormat.indexOf('underline') >= 0 ? 'underline' : 'unset',
        fontSize: parseInt(configs.gridConfig.fontSize) + 'px',
        textAlign: configs.align,
        justifyContent: configs.verticalAlign,
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        height: '100%',
        whiteSpace: 'pre-wrap'
    };

    if (props.node.selected) {
        styles.backgroundColor = configs.selectedBackgroundColor;
        styles.color = configs.selectedTextColor;
    }

    if (selectedRowId === String(props.data.key)) {
        styles.color = configs.gridConfig.selectedTextColor;
    }

    const valueFormatted = props.valueFormatted == null
        ? props.valueFormatted
        : configs.prefix + props.valueFormatted + configs.suffix;

    const textSliceEllipsis = configs.gridConfig.textSlice
        ? { overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', width: `calc(100% - (2 * ${configs.padding})px)` }
        : {};

    props.colDef.custom.getColumnWidth(valueFormatted, props.colDef.colId);
    if (configs.cellColorType === 'bar') {
        let goal = configs.cellBarFixedValue;

        switch (configs.cellBarType) {
            case "perc_measure":
                goal = 0;
                if (configs.cellBarMeasureColumn !== '') {
                    goal = props.data['[' + configs.cellBarMeasureColumn + ']'];
                }
                break;
            case "perc_max":
                goal = metaData.max;
                break;
            case "perc_sum":
                goal = metaData.sum;
                break;
            case "perc_avg":
                goal = metaData.average;
                break;
        }

        let width = props.column.actualWidth;
        let val = props.value;

        let rate = 1;
        if (goal === 0) {
            rate = 1;
        } else if (val <= 0) {
            rate = 0;
        } else {
            rate = val / goal;
        }

        if (configs.cellBarType === 'measure') {
            rate = props.data['[' + configs.cellBarMeasureColumn + ']']/100;
        }

        let barWidth = width * rate;
        let barColor = 'rgba(0,0,0,0)';

        if (configs.cellBarColorValueType === 'cell') {
            barColor = getBarColor(configs, props.value, metaData);
        } else if (configs.cellBarColorValueType === 'perc') {
            barColor = getBarColor(configs, rate*100, metaData);
        }

        const padding = 5;

        return (
            <div style={styles}>
                <div
                    style={{
                        paddingLeft: padding,
                        paddingRight: padding,
                        width: width - 2 * padding,
                        position: 'absolute'
                    }}>
                    {valueFormatted}
                </div>
                <div style={{
                    background: barColor,
                    height: '100%',
                    width: barWidth < width ? barWidth : width,
                }}/>
            </div>
        )
    }

    if (props.colDef.custom.stringFormat === 'image_url') {
        const imageConfig = props.colDef.imageConfig;
        const size = imageConfig.enabled ? imageConfig.height : ((parseInt(props.colDef.custom.gridConfig.rowHeight) || 25) + 10);

        const openImage = () => {
            window.ReduxStore.dispatch(
              MODALS.open(ImageDialog, {
                  image: props.value.caption,
                  title: props.context.msg.t('image')
              })
            );
        };

        let imgUrl = (props.value?.caption ?? '').trim();

        if(imgUrl.startsWith('/resources/images')) {
            imgUrl = `${findContextPath()}${imgUrl}`;
        } else if(!imgUrl.startsWith('http')) {
            imgUrl = `${findContextPath()}/resources/images/dummy.png`;
        }

        return (
          <div style={styles}>
              <div
                ref={divRef}
                style={{
                    paddingLeft: configs.padding,
                    paddingRight: configs.padding,
                    ...textSliceEllipsis
                }}>
                  <div style={{
                      height: size,
                      width: size,
                      borderRadius: imageConfig.enabled ? imageConfig.borderRadius : 0,
                      backgroundSize: 'cover',
                      backgroundPosition: 'center center',
                      backgroundImage: imgUrl ? `url(${imgUrl})` : undefined,
                      border: `${imageConfig.border ? imageConfig.borderWidth : 0}px solid ${imageConfig.border ? imageConfig.borderColor : 'transparent'}`
                  }}
                         className="pointer"
                         onClick={openImage}
                  />
              </div>
          </div>
        );
    }

    return (
        <div style={styles}>
            <div
                title={valueFormatted}
                ref={divRef}
                style={{
                    paddingLeft: configs.padding,
                    paddingRight: configs.padding,
                    ...textSliceEllipsis
                }}>
                {props.colDef.custom.stringFormat === 'url' ?
                    (<a href={props.value.caption} target='_blank'>{props.value.caption}</a>) :
                    valueFormatted}
            </div>
        </div>
    );

};


const mapStateToProps = state => {
    return {
        metaData: state.bigTable.data.rowMetaData,
        selectedRowId: state.bigTable.data.selectedRowId,
        hoverRowId: state.bigTable.data.hoverRowId,
    };
};

export default connect(mapStateToProps)(DefaultRenderer);
