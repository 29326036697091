import React, {Component} from "react";
import PropTypes from 'prop-types';

import Api from "components/Api";
import UiMsg from "components/ui/UiMsg";
import {MODALS} from "components/ui/redux/Actions";
import {BngPopperButton, BngPopperWrapper} from "components/bng/ui/BngPopperWrapper";
import {UiBlocker} from "components/bng/ui/UiBlocker";
import {BngTable} from "components/bng/ui/BngTable";
import {BngIconButton} from "components/bng/ui/BngIconButton";
import OpConfirmation from "components/ui/OpConfirmation";
import Icon from "components/ui/common/Icon";
import SchedulingLogDialog from "components/ui/cockpit/SchedulingLogDialog";
import BookCreationDialog from "components/ui/cockpit/BookCreationDialog";
import BngExportDialog from "components/bng/exportScheduling/BngExportDialog";
import Utils from "components/Utils";

class BookButtonDropdown extends Component {

    static propTypes = {
        findBooks: PropTypes.func.isRequired,
        bookData: PropTypes.object.isRequired,
    };

    state = {
        loading: true,
        container: null,
    };

    constructor(props) {
        super(props);
        this.bookDropdown = React.createRef();
    }

    componentDidMount() {
        const container = document.getElementById('cockpit-book-popper-items');
        this.setState({container, loading: false});
    }

    closeBookDropdown(event) {
        this.bookDropdown.current.closePopper(event);
    }

    openExecutionLog = async (event) => {
        try {
            const exportSchedId = this.props.bookData.exportScheduling.id;
            this.props.dispatch(MODALS.open(SchedulingLogDialog, {exportSchedId}));
            this.closeBookDropdown(event);
        } catch (e) {
            UiMsg.ajaxError(null, e);
        }
    };

    shareBook = () => {
        this.props.dispatch(MODALS.open(BngExportDialog, {
            schedulingType: Utils.Scheduling.SCHEDULING_TYPE.IMMEDIATE,
            cockpit: {
                id: this.props.id,
                name: this.props.name,
                exportType: Utils.Scheduling.EXPORT_TYPE.PDF,
            },
        }));
    };

    deleteBook = async (book) => {
        this.props.setLoading(true);
        try {
            await Api.Cockpit.deleteBook(book.id);
            UiMsg.ok(this.props.context.msg.t('remove_success', 'Book'));
            await this.props.findBooks();
        } catch (e) {
            console.error(e);
            UiMsg.ajaxError(null, e);
        } finally {
            this.props.setLoading(false);
        }
    };

    attemptDeleteBook = (e, book = this.props.bookData.book) => {
        this.closeBookDropdown(e);
        OpConfirmation({
            title: book.description,
            message: this.props.context.msg.t('confirm.delete.default'),
            onConfirm: () => this.deleteBook(book),
            msg: this.props.context.msg
        })
    };

    render() {
        const {bookData: {book}} = this.props;
        return (
            <BngPopperWrapper
                ref={this.bookDropdown}
                className="cockpit-book-dropdown-popper"
                container={this.state.container}
                disabled={this.state.loading}
                modifiers={{
                    preventOverflow: {
                        boundariesElement: this.state.container,
                    }
                }}>

                <BngPopperButton className="cockpit-more-button-dropdown">
                    <BngIconButton className="cockpit-nav-btn" icon="more_vert"/>
                </BngPopperButton>

                <div className="bng-dropdown-parent">
                    <ul className="bng-dropdown">
                        {book.alreadyExecuted &&
                        <React.Fragment>
                            {!book.containError &&
                            <React.Fragment>
                                <li className="li-dropdown" onClick={this.shareBook}>
                                    <Icon icon="share"/>
                                    <span>{this.props.context.msg.t('share')}</span>
                                </li>
                                <li className="li-dropdown link">
                                    <a href={Api.baseUrl() + book.downloadLink} download>
                                        <Icon icon="cloud_download"/>
                                        <span>{this.props.context.msg.t('download')}</span>
                                    </a>
                                </li>
                            </React.Fragment>
                            }
                            <li className="li-dropdown" onClick={this.openExecutionLog}>
                                <Icon icon="sort"/>
                                <span>{this.props.context.msg.t('view.log')}</span>
                            </li>
                        </React.Fragment>
                        }
                        <li className="li-dropdown" onClick={this.attemptDeleteBook}>
                            <Icon icon="delete_outline"/>
                            <span>{this.props.context.msg.t('remove')}</span>
                        </li>
                    </ul>
                </div>

            </BngPopperWrapper>
        )
    }
}


export default class BookButton extends Component {

    state = {
        books: [],
        loading: false,
    };

    constructor(props) {
        super(props);
    }

    setLoading = (loading) => {
        this.setState({loading});
    };

    findBooks = async (event) => {
        this.setState({loading: true});
        try {
            const books = await Api.Cockpit.findBooks(this.props.id);
            this.setState({books});
        } catch (e) {
            UiMsg.ajaxError('error', e);
        } finally {
            this.setState({loading: false});
        }
    };

    generateBook = (event) => {
        this.props.dispatch(MODALS.open(BookCreationDialog, {
            cockpitId: this.props.id,
            cockpitName: this.props.name,
            refreshBooks: this.findBooks
        }));
    };

    refreshBooks = async (event) => {
        await this.findBooks(event);
    };

    renderBookButtons = (bookData) => {
        return (
            <BookButtonDropdown bookData={bookData} setLoading={this.setLoading}
                                findBooks={this.findBooks} {...this.props}/>
        )
    };

    renderLoading = (book) => {
        return (
            <div style={{textAlign: 'right'}}>
                <Icon icon="icon-refresh icon-spin" style={{fontSize: '14px'}}
                      title={this.props.context.msg.t('generating.book.title')} type={Icon.FONT_AWESOME}/>
            </div>
        )
    };

    renderError = (book) => {
        return (
            <div style={{textAlign: 'right'}}>
                <Icon icon="warning" style={{fontSize: '18px', cursor: 'default', color: '#E24C38'}}
                      title={this.props.context.msg.t('book.generate.notification.error.title', [book.description, this.props.name])}/>
            </div>
        )
    };

    getFormattedDate = (date) => {
        return moment(date).format('DD/MM/YYYY HH:mm');
    };

    bookTableColumns = [
        {label: this.props.context.msg.t('description'), render: ({book}) => book.description},
        {label: this.props.context.msg.t('creation'), render: ({book}) => this.getFormattedDate(book.createdOn)},
        {
            label: '', render: ({book}) => !book.alreadyExecuted ? this.renderLoading(book)
                : book.containError ? this.renderError(book) : null
        },
        {label: '', render: (bookData) => this.renderBookButtons(bookData), size: '30px'},
    ];

    render() {
        return (
            <BngPopperWrapper
                onOpen={this.findBooks}
                className="cockpit-book-popper"
                container={document.body}
                modifiers={{
                    preventOverflow: {
                        boundariesElement: document.body,
                    }
                }}>

                <BngPopperButton className="cockpit-nav-btn">
                    <a id="bng-cockpit-book-button" href="#" title={this.props.context.msg.t('Book')}>
                        <Icon icon="auto_stories"/>
                    </a>
                </BngPopperButton>

                <UiBlocker block={this.state.loading}>
                    <div className="cockpit-dropdown-popper">
                        <div className="cockpit-dropdown-popper-header">
                            <span style={{width: '100%'}}>{this.props.name}</span>
                            <a href="#" onClick={this.refreshBooks}>
                                <Icon icon="refresh" style={{fontSize: '20px'}}/>
                            </a>
                        </div>
                        <div id="cockpit-book-popper-items" className="cockpit-book-popper-items">
                            {this.state.books.length > 0 ?
                                <BngTable cols={this.bookTableColumns} rows={this.state.books}
                                          className="InnerTable Narrow"/>
                                : <div
                                    className="empty-list-books">{this.props.context.msg.t('cockpit.without.books')}</div>
                            }
                        </div>
                        <div className="cockpit-dropdown-popper-action" onClick={this.generateBook}>
                            <span
                                className="cockpit-generate-book-button">{this.props.context.msg.t('generate.book')}</span>
                        </div>
                    </div>
                </UiBlocker>

            </BngPopperWrapper>
        )
    }
}
