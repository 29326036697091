import React, { useEffect, useState } from 'react';

import { BngAnalysisTable } from 'components/bng/pages/analysis-view/BngAnalysisTable';
import FilterService from 'components/filter/FilterService';
import { Axios } from 'commonUtils';
import LoadingPulse from 'components/ui/loading/LoadingPulse';
import BngAnalysisDrillDownBar from 'components/bng/analysis/BngAnalysisDrillDownBar';
import useEventBus from 'components/hooks/useEventBus';
import Api from 'components/Api';
import AnalysisECharts from 'components/bng/analysis/AnalysisECharts';
import UiMsg from 'components/ui/UiMsg';
import { ON_TOGGLE_EVENT } from 'components/ui/breadcrumb-toolbar/HideHeaderToolbarItem';

const AnalysisViewApi = {
  async load(path, filters = [], drillState = { drills: [] }) {
    const { data } = await Axios.post('/spr/ui/analysisView/load', {
      path,
      ...FilterService.createFilterParam(filters, true),
      drillState,
    });
    return data;
  },
  async renderChart(width, height) {
    const { data } = await Axios.get('/spr/ui/analysisView/renderChart', {
      params: {
        width: Math.floor(width),
        height: Math.floor(height),
      },
    });
    return data;
  },
  async chartInteraction(drillState, filters) {
    const { data } = await Axios.post('/spr/ui/analysisView/interactiveChart', {
      drillState,
      ...FilterService.createFilterParam(filters, true),
    });
    return data;
  },
  async tableInteraction(formData) {
    const reqBody = new FormData();
    formData.forEach(function (value, key) {
      reqBody.set(key, value);
    });
    const { data } = await Axios.post(`/spr/ui/analysisView/interaction`, reqBody);
    return data;
  },
  exportToXls() {
    const url = Axios.getUri({
      url: `${Api.baseUrl()}/spr/ui/analysisView/export/xls`,
      params: {
        _t: Date.now(),
      },
    });
    window.open(url, '_blank');
  },
  exportToPdf() {
    const url = Axios.getUri({
      url: `${Api.baseUrl()}/spr/ui/analysisView/export/pdf`,
      params: {
        _t: Date.now(),
      },
    });
    window.open(url, '_blank');
  },
};

export default function CockpitAnalysisRender({ path, filters, viewType, $cockpitPanel, cockpitId }) {
  const [loading, setLoading] = useState(true);
  const [result, setResult] = useState();
  const [analysis, setAnalysis] = useState();
  const [analysisViewTypes, setAnalysisViewTypes] = useState();

  const [chartSvg, setChartSvg] = useState('');
  const [drillState, setDrillState] = useState({ drills: [] });
  const [drillResponse, setDrillResponse] = useState();

  const loadChart = async (viewTypes) => {
    let chartSvg = '';
    if (viewType === 'CHART' && viewTypes?.chart) {
      const chartResponse = await AnalysisViewApi.renderChart($cockpitPanel.clientWidth, $cockpitPanel.clientHeight);
      chartSvg = chartResponse.svg;
    }
    setChartSvg(chartSvg);
  };

  const loadAnalysis = async () => {
    setLoading(true);
    try {
      const viewTypes = await Api.Analysis.getViewType(path);
      setAnalysisViewTypes(viewTypes);

      let analysisData = analysis;
      if (!analysisData) {
        analysisData = await Api.Analysis.analysisData(path, cockpitId);
        setAnalysis(analysisData);
      }

      const loadResponse = await AnalysisViewApi.load(path, filters, drillState);
      if (!analysisData.echartsModel) {
        setResult(loadResponse);
        await loadChart(viewTypes);
      }
    } catch (e) {
      console.error('Error on loadAnalysis()', e);
      UiMsg.ajaxError(null, e);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    loadAnalysis();
  }, [path, filters, viewType]);

  useEventBus('BngCockpitButtons:ExportToExcel', AnalysisViewApi.exportToXls);
  useEventBus('BngCockpitButtons:ExportToPdf', AnalysisViewApi.exportToPdf);
  useEventBus(ON_TOGGLE_EVENT, loadAnalysis);

  const interactionHandler = async (formData) => {
    const interactionResponse = await AnalysisViewApi.tableInteraction(formData);
    setResult({
      ...(result ?? {}),
      ...interactionResponse,
    });
  };

  const isChartView = viewType === 'CHART' && analysisViewTypes?.chart;
  return (
    <div className={`CockpitAnalysisRender BngAnalysisDrillDownContainer h-100`}>
      {loading || !analysisViewTypes ? (
        <div className="drillDownOperationsLoaderContainer" style={{ display: 'block' }}>
          <LoadingPulse loaderType="circle" />
        </div>
      ) : (
        <>
          {analysis?.echartsModel ? (
            <AnalysisECharts path={path} prefetchedData={{ analysis }} cockpitId={cockpitId} filters={filters} />
          ) : isChartView ? (
            <div dangerouslySetInnerHTML={{ __html: chartSvg }}></div>
          ) : (
            <BngAnalysisTable analysis={result?.info.analysis} html={result?.html} onInteraction={interactionHandler} />
          )}
          {result?.info?.analysis?.drillableChartModel && (
            <div id="drillDownOperationsContainer">
              <BngAnalysisDrillDownBar
                assisted={result.info.assistedData}
                drillState={drillState}
                drillResponse={drillResponse}
                drillHandler={async (drills) => {
                  setLoading(true);
                  try {
                    const state = { drills };
                    setDrillState(state);
                    const response = await AnalysisViewApi.chartInteraction(state, filters);
                    setDrillResponse(response);
                    await loadChart(analysisViewTypes);
                  } finally {
                    setLoading(false);
                  }
                }}
              />
            </div>
          )}
        </>
      )}
    </div>
  );
}
