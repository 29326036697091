import styles from './ProjectGeneralTab.module.css';

import React from 'react';
import { Field, useFormikContext } from 'formik';

import useReduxDispatch from 'components/hooks/useReduxDispatch';
import useBimContext from 'components/hooks/useBimContext';
import BngDropdown from 'components/bng/ui/BngDropdown';
import BngField from 'components/bng/form/BngField';
import BngInput from 'components/bng/form/BngInput';
import BngTextArea from 'components/bng/form/BngTextArea';
import BngSelect from 'components/bng/form/BngSelect';
import BngSwitch from 'components/bng/form/BngSwitch';
import UiBlocker from 'components/bng/ui/UiBlocker';
import Avatar from 'components/ui/Avatar';
import Icon from 'components/ui/common/Icon';
import BngPasswordConfirmation from 'components/bng/ui/BngPasswordConfirmation';
import { MODALS } from 'components/ui/redux/Actions';
import Api from 'components/Api';
import useFetchData from 'components/hooks/useFetchData';
import Utils from 'components/Utils';
import useTranslation from 'components/hooks/useTranslation';
import UiMsg from 'components/ui/UiMsg';

const ProjectTypes = [
  {
    type: 'Template',
    badge: 'badge-warning',
    icon: 'icon-sitemap',
    color: (opacity) => `rgba(248, 148, 6, ${opacity})`,
  },
  {
    type: 'Demo',
    badge: 'badge-important',
    icon: 'icon-bullseye',
    color: (opacity) => `rgba(209, 91, 71, ${opacity})`,
  },
  {
    type: 'Production',
    badge: 'badge-primary',
    icon: 'icon-check',
    color: (opacity) => `rgba(34, 131, 197, ${opacity})`,
  },
  { type: 'Homologation', badge: 'badge-grey', icon: 'icon-bug', color: (opacity) => `rgba(99, 99, 99, ${opacity})` },
];

export default function ProjectGeneralTab({ project, account, master, options, theme }) {
  const context = useBimContext();
  const { t } = useTranslation();
  const dispatch = useReduxDispatch();
  const { values, setFieldValue } = useFormikContext();

  const isMaster = context.user.email === master?.email;
  const canImport = context.permissions.canImportProjects();

  const $languages = useFetchData(async () => {
    const data = await Api.Bng.getProfileData();
    return data.languages;
  });

  const projectType = ProjectTypes.find((pt) => pt.type === values?.projectType) ?? ProjectTypes[0];
  const isNotInProduction = projectType.type !== 'Production';
  const canChangeType = (isMaster && isNotInProduction) || canImport;

  let projectLogoImage = 'resources/images/logo-bim-black-gray.png';
  if (context.addons.includes('VISUAL_IDENTITY') && theme?.enableHeaderLogo) {
    projectLogoImage = theme?.headerLogo?.relativeLink || projectLogoImage;
  }

  const projectTypeOpts = ProjectTypes.slice();
  if (!account || !account.partner) {
    projectTypeOpts.shift();
  }

  return (
    <div className={`ProjectGeneralTab ${styles.GeneralWrapper}`}>
      <div className={`${styles.TitleWrapper}`}>
        <div>
          <div className={styles.logoWrapper}>
            <img src={Api.buildUrl(`/${projectLogoImage}`)} alt="Project Logo" />
          </div>
          <div className={`${styles.Title}`}>{values?.displayName}</div>
          <div>
            <BngDropdown
              popperClassName={`${styles.ProjectTypePopper}`}
              customButton={({ openDropdown }) => {
                return (
                  <div
                    onClick={openDropdown}
                    className={`${styles.ProjectTypeButton}`}
                    style={{ backgroundColor: projectType.color(0.1) }}
                  >
                    <div style={{ backgroundColor: projectType.color(1) }}>{projectType.type[0]}</div>
                    <span style={{ color: projectType.color(1) }}>{t(projectType.type)}</span>
                    {canChangeType && <Icon icon="expand_more" style={{ color: projectType.color(1) }} />}
                  </div>
                );
              }}
              customOptions={({ closeDropdown }) => {
                if (canChangeType) {
                  return (
                    <div>
                      <UiBlocker block={$languages.isLoading} className={styles.ProjectTypeUiBlocker}>
                        <div className={`${styles.ProjectType}`}>
                          <span>{t('type')}</span>
                          <div />
                        </div>
                        {projectTypeOpts.map((pt) => (
                          <div
                            key={pt.type}
                            onClick={() => {
                              dispatch(
                                MODALS.open(BngPasswordConfirmation, {
                                  onConfirm: async () => {
                                    try {
                                      await Api.Project.changeProjectType(pt.type);
                                      setFieldValue('projectType', pt.type);
                                    } catch (e) {
                                      console.error('Error on changeProjectType()', e);
                                      UiMsg.ajaxError(null, e);
                                    }
                                  },
                                })
                              );
                            }}
                            className={`${styles.ProjectTypeOptionWrapper}`}
                          >
                            <div style={{ backgroundColor: pt.color(1) }}>{pt.type[0]}</div>
                            <span style={{ color: pt.color(1) }}>{t(pt.type)}</span>
                          </div>
                        ))}
                      </UiBlocker>
                    </div>
                  );
                }
              }}
            />
          </div>
        </div>
        <div className={`${styles.ProjectIdWrapper}`}>
          <div>{t('project.id')}:</div>
          <div>{values?.id}</div>
        </div>
      </div>
      <div className={`${styles.ProjectInfos}`}>
        <div className={`${styles.Form1}`}>
          <Field
            name="displayName"
            component={BngField}
            inputComponent={BngInput}
            required={true}
            label={t('newInMemoryVisualizationField')}
          />
          <Field name="name" component={BngField} inputComponent={BngInput} disabled={true} label={t('name')} />
        </div>
        <div className={`${styles.Form2}`}>
          <Field
            name="description"
            rootClassName={`${styles.ProjectDescription}`}
            component={BngField}
            inputComponent={BngTextArea}
            label={t('description')}
          />
          <div>
            <Field
              name="projectUrl"
              value={`https:${values.projectUrl?.replace('.br', '')}`}
              component={BngField}
              inputComponent={BngInput}
              disabled={true}
              label={t('url')}
            />
            <Field
              name="language"
              component={BngField}
              inputComponent={BngSelect}
              emptyOption={false}
              options={$languages.data ?? []}
              label={t('language')}
            />
          </div>
        </div>
      </div>
      <div className={`${styles.MasterAndAccount}`}>
        <div className={`${styles.CardWrapper} ${styles.MasterWrapper}`}>
          <span>{t('project.master')}</span>
          <div className={`${styles.Card} ${styles.Master}`}>
            <Avatar userId={master?.id} className={`${styles.Avatar}`} />
            <div>
              <div className="master-name">{Utils.Users.displayName(master)}</div>
              <div className="master-email">{master?.email}</div>
            </div>
          </div>
        </div>
        <div className={`${styles.CardWrapper} disabled`}>
          <span>{t('account')}</span>
          <div className={`${styles.Card} ${styles.Account} disabled`}>
            <div className="account-name">{account?.name}</div>
            <div className="account-id">ID: {account?.id}</div>
          </div>
        </div>
      </div>
      <div className={`${styles.ProjectOptions}`}>
        <div>
          <Field
            name="supportEnabled"
            value={options?.supportEnabled}
            component={BngSwitch}
            title={t('project.option.supportEnabled')}
          />
          <Field
            name="mobileEnabled"
            value={options?.mobileEnabled}
            component={BngSwitch}
            title={t('project.option.mobileEnabled')}
          />
        </div>
        <div>
          <Field
            name="supportForAdmins"
            value={options?.supportForAdmins}
            component={BngSwitch}
            title={t('project.option.supportForAdmins')}
          />
          <Field name="allowExcel" value={options?.allowExcel} component={BngSwitch} title={t('enable.excel.export')} />
        </div>
        <div>
          <Field
            name="mobileEnabledForObjects"
            value={options?.mobileEnabledForObjects}
            component={BngSwitch}
            title={t('mobile.default.state')}
          />
        </div>
      </div>
    </div>
  );
}
