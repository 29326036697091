// css imported on CommonCssImports.js

import React, {useEffect, useRef, useState} from 'react';
import PropTypes from 'prop-types';
import RGL, {WidthProvider} from "react-grid-layout";
import {Axios} from "commonUtils";
import RenderablePreload from "components/ui/dashboard/components/RenderablePreload";
import RenderQueue from "components/ui/dashboard/components/RenderQueue";
import Api from "components/Api";
import DeepEqualComponent from "components/DeepEqualComponent";
import ContextEnhancer from "components/ContextEnhancer";
import {transformItemToPersist, transformLayoutToPersist} from "components/ui/dashboard/components/common";
import DashGridContext from "components/ui/dashboard/components/DashGridContext";
import RequiredFilters from "components/ui/dashboard/components/RequiredFilters";
import Icon from "components/ui/common/Icon";
import Utils from "components/Utils";
import BngEmpty from "components/bng/ui/BngEmpty";
import EmptyComponent from "components/ui/EmptyComponent";
import DashboardItemMenuExplorerMobile
    from "components/ui/dashboard/components/itemMenus/DashboardItemMenuExplorerMobile";
import {ResizeSensor} from "css-element-queries";
import FilterUtils from "components/filter/FilterUtils";
import UiMsg from "components/ui/UiMsg";
import BimEventBus from "BimEventBus";
import DashGridEvents from 'components/ui/dashboard/components/DashGridEvents';

const ReactGridLayout = WidthProvider(RGL, {measureBeforeMount: true});

let CACHED_RENDER_QUEUE = null;
let LAST_SCROLL_POSITION = 0;

let INVALID_PERIODICITY_ALERTED = '';

class DashGrid extends DeepEqualComponent {

    static propTypes = {
        className: PropTypes.string,
        mobile: PropTypes.bool,
        useDefaultMenus: PropTypes.bool,
        fromCockpit: PropTypes.number,
    };

    static defaultProps = {
        className: '',
        layouts: {},
        divisions: 96,
        nextItemDelay: 1,
        editMode: false,
        breakpointView: 'DESKTOP',
        itemsData: {},
        mobile: false,
        isFromPublisher: false,
        privateVisibility: true,
        isPresentation: false,
        exportView: false,
        dashboardPath: '',
        style: {
            allowMargin: false,
            allowContainerMargin: false,
            allowBoxShadow: false,
            borderStyleClass: '',
            bodyStyle: '',
            borderType: '',
            highlightBoxColor: '',
            backgroundTheme: 'NONE',
            itemTransparency: 0,
        },
        requiredFilters: [],
        initialFilters: [],
        filters: [],
        mobileMenuComponent: EmptyComponent,
        dashboardItemMenuComponent: EmptyComponent,
        containerMenuComponent: EmptyComponent,
        iconTextEventOnOpenObjectAction: false,
        useDefaultMenus: undefined,
        fromCockpit: undefined,
    };

    state = {
        rowHeight: null,
        filters: [],
        initialRenderComplete: false,
        customSelect: null,
        selectedItem: null,
        horizontalLikeVertical: false,
        onCockpit: false,
        itemOverride: {},
    };

    gridItems = {};

    constructor(props) {
        super(props);
        if (!CACHED_RENDER_QUEUE) {
            CACHED_RENDER_QUEUE = new RenderQueue({delay: props.nextItemDelay * 100});
        }
        this.renderQueue = CACHED_RENDER_QUEUE;
        if (props.mobile) {
            window.__FILTER_CHANGE_LISTENER = this.filterChangeHandler;
        }
        this._renderedItems = 0;

        let FILTER_CHANGE_TEMP_CACHE = null;
        try {
            if (props.mobile && Utils.parentWindowIsAccessible()) {
                FILTER_CHANGE_TEMP_CACHE = parent.__FILTER_CHANGE_TEMP_CACHE;
            }
        } catch (e) {
            console.error('Error loading FILTER_TEMP_CACHE: ', e);
        }
        if (!!FILTER_CHANGE_TEMP_CACHE) {
            this.state.filters = FILTER_CHANGE_TEMP_CACHE;
        } else if (props.initialFilters) {
            this.state.filters = [...props.initialFilters];
        }
        this._layoutListeners = {};
        this.state.onCockpit = !!document.getElementById('cockpit-item-panel');
    }

    filterChangeHandler = (filters = [],
                           forceClear = false,
                           appendAndReplace = false,
                           additionalProps = {clearFilters: false}) => {
        filters = filters.map((filter) => {
            const originalFilter = this.props.filters[filter.id];
            let newFilter;
            if (filter.hasOwnProperty('members')) {
              newFilter = _.cloneDeep(filter);
            } else {
              let restrictionType = originalFilter?.restrictionType ?? 'SHOW_SELECTED';
              if (
                filter.restrictMembers &&
                restrictionType === 'HIDE_SELECTED' &&
                !_.isEmpty(filter.selectedMembers) &&
                !_.isEqual(
                  _.sortBy(filter.requiredFilterMembers ?? []),
                  _.sortBy(filter.selectedMembers?.map((m) => m.value) ?? [])
                )
              ) {
                restrictionType = 'SHOW_SELECTED';
              }

              newFilter = {
                id: filter.id,
                members: filter.selectedMembers.map((m) => m.value),
                restrictionType,
              };
            }

            newFilter.members = newFilter.members.filter(m => !_.isEmpty(m));
            if (originalFilter && _.isEmpty(newFilter.members)) {
                newFilter.members = _.cloneDeep(originalFilter[forceClear ? 'restrictedMembers' : 'defaultMembers'] || []);
            }
            return newFilter;
        });

        if (appendAndReplace || additionalProps.clearFilters) {
            const currentFilters = _.cloneDeep(this.state.filters);
            for (const filter of filters) {
                const existentFilter = currentFilters.find(f => f.id === filter.id);
                if (existentFilter && !additionalProps.clearFilters) {
                    existentFilter.members = filter.members;
                    if (filter.restrictionType) {
                        existentFilter.restrictionType = filter.restrictionType;
                    }
                } else if (additionalProps.clearFilters) {
                    filter.members = [];
                    currentFilters.push(filter);
                } else {
                    currentFilters.push(filter);
                }
            }
            filters = currentFilters;
        }

        this.setState({filters});
        this.alertIfContainInvalidFilters(filters);
        return filters;
    };

    addLayoutChangeListener(name, fn) {
        this._layoutListeners[name] = fn;
    }

    removeLayoutChangeListener(name) {
        delete this._layoutListeners[name];
    }

    findItemSize(uuid) {
        const $parent = j(`.DashGridItem-${uuid}`);
        const $itemSize = j(`.item-size-${uuid}`);
        const headerSize = $parent.find('.widget-header').filter(':visible').height() || 0;
        const $placeHolder = j('.react-grid-placeholder');
        const $size = $placeHolder.length > 0 ? $placeHolder : $parent;
        const {borderWidth, borderHeight} = application.dashboards.parseBorders({$parent: $size});

        const size = {
            width: Math.ceil(parseInt($size.css('width'), 10)) - borderWidth,
            height: Math.ceil(parseInt($size.css('height'), 10) - headerSize) - borderHeight
        };

        const $dashBox = $parent.find(`#dashbox-${uuid}`);
        const gridItemRef = this.gridItems[uuid];
        return {$parent, $itemSize, $dashBox, size, gridItemRef};
    }

    lastLayout = null;

    updateOnResize = () => {
        setTimeout(async () => {
            const layout = [window.innerWidth, window.innerHeight];
            if (this.lastLayout === null || layout[0] !== this.lastLayout[0] && layout[1] !== this.lastLayout[1]) {
                this.lastLayout = layout;
                window.__FILTER_CACHE_ON_RESIZE = window.__FILTER_CHANGE_TEMP_CACHE;
                let {rowHeight, horizontalLikeVertical} = this.calculateRowHeight();
                await this.setState({rowHeight, horizontalLikeVertical});
                for (let k in this.gridItems) {
                    const ref = this.gridItems[k].ref;
                    if (!!ref && !!ref.updateSize) {
                        ref.updateSize(null, true);
                    }
                }
            }
        }, 500);
    };

    pageRememberScrollHandler(event) {
        if (this.scrollHeight > this.clientHeight) {
            LAST_SCROLL_POSITION = this.scrollTop ?? 0;
        }
    }

    componentDidMount() {
        this.lastLayout = [window.innerWidth, window.innerHeight];
        let {rowHeight, horizontalLikeVertical} = this.calculateRowHeight();
        const cancelToken = Axios.generateCancelToken();
        window.__CANCEL_RENDER_DASHBOARDITEM_REQUEST = cancelToken;
        this.setState({rowHeight, cancelToken, horizontalLikeVertical});
        application.dashboards.itemCloner.initializeCloner(this.props.context);
        j('body').on('click', this.bodyClickHandler);

        if (application.page.isMobile()) {
            window.addEventListener('resize', this.updateOnResize, true);
        }

        this.alertIfContainInvalidFilters(this.state.filters);

        BimEventBus.on(DashGridEvents.RENDER_IN_PLACE, this.updateItemVisualization);
        this.__removeUpdateOnResize = BimEventBus.on('CockpitButtons:HEADER_EXPANDED', () => {
            const {rowHeight, horizontalLikeVertical} = this.calculateRowHeight();
            this.setState({rowHeight, horizontalLikeVertical});
        } );
    }

    alertIfContainInvalidFilters(filters) {
        const currentDate = new Date();
        for (const filter of filters) {
            const member = filter.members.find(member => !FilterUtils.isPeriodicityValidOnDate(member, currentDate));
            // Prevent duplicated alert on recreations
            if (member && member !== INVALID_PERIODICITY_ALERTED) {
                INVALID_PERIODICITY_ALERTED = member;
                UiMsg.warn(
                    `${this.props.context.msg.t('attention')}!`,
                    this.props.context.msg.t(
                        'invalid.date.filter.alert',
                        [this.props.context.msg.t(member)]
                    )
                );
                setTimeout(() => {
                    if (INVALID_PERIODICITY_ALERTED === member) {
                        INVALID_PERIODICITY_ALERTED = '';
                    }
                }, 1000);
                break;
            }
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const {effectiveBreakpoint} = this.calculateBreakpoint();
        const newLayout = JSON.stringify(
            _.sortBy(
                _.get(this.props, `layouts.${effectiveBreakpoint}`, []),
                ['i']
            )
        );
        const current = JSON.stringify(_.sortBy(_.get(this, 'currentLayout', []), ['i']));

        if (newLayout !== current) {
            this.currentLayout = JSON.parse(newLayout);
            setTimeout(() => {
                Object.values(this._layoutListeners).forEach(fn => fn());
            }, 100);
        }

        if (!this.$pageContent
            && this.state.initialRenderComplete
            && (this.state.initialRenderComplete !== prevState.initialRenderComplete)) {
            j('.breakpoint-MOBILE, .breakpoint-MOBILE_HORIZONTAL').length > 0
                ? this.$pageContent = j('.breakpoint-MOBILE, .breakpoint-MOBILE_HORIZONTAL')
                : this.$pageContent = j('#page-content, #cockpit-item-panel');
            this.$pageContent.scrollTop(LAST_SCROLL_POSITION);
            this.$pageContent.on('scroll', this.pageRememberScrollHandler);
        }
    }

    cloneFunction = (layout, itemData) => {
        let itemUuid = layout.i;
        application.dashboards.itemCloner.selectItem(
            itemData.path,
            itemData.additionalProps,
            itemData.viewType,
            itemUuid
        );
    };

    requiredFiltersInfo = () => {
        let allRequiredFilterFilled = true;
        let requiredFiltersInfo = [];
        for (const filterId of this.props.requiredFilters) {
            const filter = this.state.filters.find(({id}) => id === filterId);
            let ok = true;
            if (!filter || filter.members.length === 0) {
                allRequiredFilterFilled = false;
                ok = false;
            }

            const filterInfo = this.props.filters[filterId];
            if (filterInfo) {
                requiredFiltersInfo.push({...filterInfo, ok});
            }
        }
        return {
            filled: allRequiredFilterFilled,
            info: requiredFiltersInfo
        };
    };

    componentWillUnmount() {
        j('body').off('click', this.bodyClickHandler);
        if (application.page.isMobile()) {
            window.removeEventListener('resize', this.updateOnResize);
        }
        if (this.$pageContent) {
            this.$pageContent.off('scroll', this.pageRememberScrollHandler);
        }
        delete window.__CANCEL_RENDER_DASHBOARDITEM_REQUEST;
        delete window.__FILTER_CHANGE_LISTENER;
        BimEventBus.off(DashGridEvents.RENDER_IN_PLACE, this.updateItemVisualization);
        this.__removeUpdateOnResize?.();
    }

    bodyClickHandler = (event) => {
        if (!this.state.selectedItem) {
            return;
        }

        const $target = j(event.target);
        const $closestItem = $target.closest('.DashGridItem');
        const match = [$target, $closestItem].find($el => $el.hasClass(`DashGridItem-${this.state.selectedItem}`));

        if (match) {
            return;
        }

        this.setState({selectedItem: null});
        application.dashboards.itemCloner.removeCloneItemSelection();

    };

    clickedOnItem = (event, layout, data) => {
        if (!this.props.editMode) {
            return;
        }

        if (this._layoutChanged) {
            this._layoutChanged = false;
            return;
        }

        const isContainer = this.isContainer(layout.i);
        const bp = this.calculateBreakpoint();

        if (this.state.customSelect) {
            // Ignore if action come from drag
            if (event === 'dragstart') {
                return;
            }
            this.state.customSelect.onSelect({
                layout,
                data,
                ...bp,
                isContainer
            }, event);
        } else {
            if (layout.i === this.state.selectedItem) {
                return;
            }

            this.setState({selectedItem: layout.i});

            if (bp.effectiveBreakpoint !== 'DESKTOP') {
                const $mobileItem = j('#MobileItem-' + layout.i);
                if ($mobileItem.length === 0) return;
                j('.ActionList.MobileMenuList .ActionListItems').stop().animate({
                    scrollTop: $mobileItem.position().top
                }, 1000);
            }

            if (isContainer) {
                return;
            }

            this.cloneFunction(layout, data);
        }
    };

    getCurrentLayout = () => {
        return this.currentLayout;
    };

    buildStyleClass = () => {
        return application.dashboards.buildStyleClass(this.props.style);
    };

    filterLayouts = (layout, viewBreakpoint = 'DESKTOP') => {
        if (this.props.isFromPublisher
            || this.props.isPresentation
            || viewBreakpoint === 'DESKTOP') {
            return layout;
        }
        return layout.filter(item => this.props.itemsData[item.i]?.availableOnMobile ?? false);
    };

    getMockupBackground(isMobileBreakpoint, viewBreakpoint) {
        if (this.props.mobile || !isMobileBreakpoint) {
            return '';
        }
        const mockups = {
            'MOBILE': `url('${Api.baseUrl()}/resources/images/dashboard/mockup_mobile.svg')`,
            'MOBILE_HORIZONTAL': `url('${Api.baseUrl()}/resources/images/dashboard/mockup_mobile_horizontal.svg')`,
        };
        return mockups[viewBreakpoint.toUpperCase()];
    }

    updateItemVisualization = ({ action, item }) => {
        const itemOverride = {...this.state.itemOverride};

        if (action === 'OVERRIDE') {
            itemOverride[item.id] = item;
        } else if (action === 'CLEAR') {
            delete itemOverride[item.id];
        }

        this.setState({
            itemOverride
        });
    }

    // Create an array with selected and empty filters
    selectedFilters = () => {
        const filters = this.state.filters.slice();
        for (const filter of this.props.availableFilters) {
            const match = filters.find(f => f.id === filter.id);
            if(!match) {
                filters.push({
                    id: filter.id,
                    members: [],
                    restrictionType: '"SHOW_SELECTED"'
                });
            }
        }
        return filters;
    }

    findItemData(item) {
        return this.state.itemOverride[item.i] ?? this.props.itemsData[item.i]
    }

    render() {
        const requiredFilterInfo = this.requiredFiltersInfo();

        if (!requiredFilterInfo.filled) {
            let requiredFiltersInfo = _.sortBy(requiredFilterInfo.info, ['caption']);
            return (
                <div ref={ref => this._requiredFilterEl = ref}>
                    <RequiredFilters requiredFiltersInfo={requiredFiltersInfo}/>
                </div>
            );
        }

        const {effectiveBreakpoint, viewBreakpoint} = this.calculateBreakpoint();
        const isMobileBreakpoint = effectiveBreakpoint.startsWith('MOBILE');
        const {fullLayout} = this.processEmptyLayouts(this.props.layouts, effectiveBreakpoint);
        const mobileFrameClass = !this.props.mobile ? 'mobile-frame' : '';

        const gridProps = {
            cols: this.props.divisions,
            rowHeight: this.state.rowHeight,
            margin: [0, 0],
            containerPadding: [0, 0],
            compactType: null,
            isDraggable: this.props.editMode,
            isResizable: this.props.editMode,
            preventCollision: true
        };

        if (!this.props.mobile && !this.props.isFromPublisher && !this.props.isPresentation) {
            gridProps.onLayoutChange = this.onLayoutChange;
            gridProps.onResize = this.onItemResize;
            gridProps.onResizeStop = this.onItemResizeStop;
            gridProps.onDragStart = (layout, oldItem, newItem, placeholder, event, element) => {
                const isItemMenuOpen = !!document.querySelector('.dashboard-item-popper');
                if (isItemMenuOpen) return false;

                const isContainerMenuOpen = !!document.querySelector('.ContainerMenuPopper');
                if (isContainerMenuOpen) return false;

                // Prevent drag while resizing a bigtable column (ag-header-cell-resize)
                // Prevent drag while moving map (leaflet)
                // Prevent drag while clicking on button (DashboardItemOptsButton)
                for (const c of event.target.classList) {
                    if (c.includes('ag-header-cell-resize') ||
                        c.includes('leaflet') ||
                        c.includes('DashboardItemOptsButton')) return false;
                }

                if (this._dragTimeout) {
                    clearTimeout(this._dragTimeout);
                }
                j('body').addClass('DashGridDraggingItem');
                this.clickedOnItem('dragstart', oldItem, this.props.itemsData[oldItem.i]);
            };
            gridProps.onDragStop = () => {
                this._dragTimeout = setTimeout(() => j('body').removeClass('DashGridDraggingItem'), 1000);
            };
        }

        let GridComponent = ReactGridLayout;
        if (!this.props.mobile && isMobileBreakpoint) {
            GridComponent = RGL;
            gridProps.width = effectiveBreakpoint === 'MOBILE_HORIZONTAL' ? 623 : 320;
        }

        const layout = this.filterLayouts(fullLayout, viewBreakpoint);
        const alertCustomMobile = _.isEqual(this.props.layouts['MOBILE'], this.props.layouts['DESKTOP']);
        this.currentLayout = layout;
        const highlightColor = !!this.props.style.highlightBoxColor ? this.props.style.highlightBoxColor : "#unset";
        const dashboardBgThemeName = `DashTheme-${this.props.style.backgroundTheme.toLowerCase()}`;

        // Components
        const LazyMobileMenu = this.props.mobileMenuComponent;
        const LazyDashboardItemMenu = this.props.dashboardItemMenuComponent;
        const LazyContainerMenu = this.props.containerMenuComponent;

        const gridElementWidth = gridProps.width + 17 || (this.props.editMode ? 'calc(100% - 6px)' : '100%');
        const lastYDivision = layout?.reduce((acc, l) => Math.max(acc, (l.y ?? 0) + (l.h ?? 0)), 0) ?? 0;

        return (
            <DashGridContext.Provider value={{
                dashboardPath: this.props.dashboardPath,
                filters: this.state.filters,
                editMode: this.props.editMode,
                isFromPublisher: this.props.isFromPublisher,
                privateVisibility: this.props.privateVisibility,
                isPresentation: this.props.isPresentation,
                iconTextEventOnOpenObjectAction: this.props.iconTextEventOnOpenObjectAction,
                fromCockpit: this.props.fromCockpit
            }}>
                    {(this.props.editMode && !isMobileBreakpoint) && (
                      <>
                          {/* TODO display background grid, useful for debug
                              <style
                              dangerouslySetInnerHTML={{
                                __html: `
                                .free-style-marker-class {
                                    background-image: repeating-linear-gradient(#ccc 0 1px, transparent 1px 100%), repeating-linear-gradient(90deg, #ccc 0 1px, transparent 1px 100%);
                                    background-size: calc(100% / ${gridProps.cols}) ${gridProps.rowHeight}px;
                                }`,
                              }}
                            />
                          */}

                          {_.range(0, Math.ceil(lastYDivision / gridProps.cols) + 1).map((i) => {
                              const pageHeight = gridProps.cols * gridProps.rowHeight;
                              const pageBreakIdx = i + 1;
                              return (
                                <div
                                  key={`pageBreak${i}`}
                                  className="dashboard-page-break-indicator w-100"
                                  style={{ top: `${pageBreakIdx * pageHeight}px` }}
                                >
                                    <p>
                                        <span>{this.props.context.msg.t('dashboard.page.break', pageBreakIdx)}</span>
                                    </p>
                                </div>
                              );
                          })}
                      </>
                    )}

                    <div id="DashGridComponent" className={`background-${viewBreakpoint} ${this.buildStyleClass()}`}
                         data-opacity={1 - (this.props.style.itemTransparency / 100)}
                    >

                        {(isMobileBreakpoint && this.props.editMode) &&
                            <LazyMobileMenu {...this.props}
                                            selectedItem={this.state.selectedItem}
                                            onLayoutChange={this.onLayoutChange}
                                            horizontalLikeVertical={this.state.horizontalLikeVertical}
                                            layout={fullLayout}/>
                        }

                        <div className={`div-${viewBreakpoint} ${mobileFrameClass}`} style={{
                            width: gridElementWidth,
                            backgroundImage: this.getMockupBackground(isMobileBreakpoint, viewBreakpoint)
                        }}>
                            <div className={`breakpoint-${viewBreakpoint} ${mobileFrameClass}`}
                                 style={{width: gridElementWidth}}
                                 ref={ref => this._breakpointEl = ref}>
                                {this.state.rowHeight &&
                                    <BngEmptyDashboard layout={layout}
                                                       width={gridProps.width - 10 || '98%'}
                                                       mobile={this.props.mobile}
                                                       context={this.props.context}
                                                       path={this.props.dashboardPath}
                                                       viewBreakpoint={viewBreakpoint}>
                                        <GridComponent  {...gridProps}
                                                        layout={layout}
                                                        style={{width: gridProps.width}}
                                                        customSelect={this.state.customSelect}
                                                        className={`DashGrid grid-stack item-content-container ${this.state.initialRenderComplete ? 'loaded' : ''} ${this.props.editMode ? 'inEditMode' : ''}`}>
                                            {layout.map((layoutItem) => {
                                                const itemData = this.findItemData(layoutItem);
                                                const isContainer = this.isContainer(layoutItem.i);
                                                const addingContainer = j('.ContainerCreatorParent').length > 0;
                                                const gridElClassName = `DashGridItem grid-stack-item DashGridItem-${layoutItem.i}
                                            ${isContainer ? 'Container' : 'Item'}
                                            ${this.state.customSelect ? this.state.customSelect.itemClass(layoutItem) : ''}
                                            ${layoutItem.i === this.state.selectedItem ? 'selectedToClone' : ''}
                                            `;

                                                return (

                                                    <GridEl onClick={(event) => this.clickedOnItem(event, layoutItem, itemData)}
                                                            key={layoutItem.i}
                                                            className={gridElClassName}>
                                                        {({position}) => {
                                                            const buildItemMenuProps = ({item, inContainer = false, position}) => {
                                                                return {
                                                                    item,
                                                                    isMobile: this.props.mobile,
                                                                    dashboardPath: this.props.dashboardPath,
                                                                    editMode: this.props.editMode,
                                                                    inContainer,
                                                                    position,
                                                                    style: this.props.style,
                                                                    dashGridItemRef: () => this.gridItems[item.id],
                                                                    selectedFilters: this.selectedFilters(),
                                                                    currentBreakpoint: {
                                                                        effectiveBreakpoint,
                                                                        viewBreakpoint
                                                                    }
                                                                };
                                                            }

                                                            return (
                                                                <React.Fragment>

                                                                    <div className={
                                                                        `${itemData.transparentbackground ? 'ImgTransparentBg' : ''} grid-stack-item-content grid-stack-item-content-${itemData.viewType} ${dashboardBgThemeName}
                                                                 ${itemData.highlight ? `item-highlight color-highlight-${highlightColor.substring(1).toLowerCase()}` : ''}`
                                                                    }
                                                                         style={!itemData.highlight ? {backgroundColor: application.dashboards.buildBackgroundItemColor(this.props.style, itemData.additionalProps)} : {}}
                                                                         onClick={(event) => this.clickedOnItem(event, layoutItem, itemData)}
                                                                         data-edit-mode={this.props.editMode}>

                                                                        <style type={'text/css'} dangerouslySetInnerHTML={{
                                                                            __html: `.DashGrid .DashGridItem div.widget-body-${layoutItem.i} { ${this.props.style.bodyStyle} }`
                                                                        }}/>

                                                                        <div
                                                                            className={`itemsize item-size-${layoutItem.i} ${isContainer ? 'onLeft' : ''}`}
                                                                            data-highlight-color={highlightColor}>
                                                                            {position.width} x {position.height}
                                                                        </div>

                                                                        {this.props.mobile && this.props.privateVisibility && isMobileBreakpoint && !isContainer &&
                                                                            <DashboardItemMenuExplorerMobile
                                                                                item={itemData}
                                                                                dashboardPath={this.props.dashboardPath}
                                                                                selectedFilters={this.state.filters}
                                                                            />

                                                                        }

                                                                        {!isContainer && (this.props.useDefaultMenus || !this.props.mobile) && !addingContainer &&
                                                                            <LazyDashboardItemMenu
                                                                              {...buildItemMenuProps({
                                                                                  item: itemData,
                                                                                  position
                                                                              })}
                                                                            />
                                                                        }

                                                                        <div
                                                                        >
                                                                            {!isContainer &&
                                                                                <React.Fragment>

                                                                                    <DashGridItemContent
                                                                                        style={this.props.style}
                                                                                        renderQueue={this.renderQueue}
                                                                                        mobile={this.props.mobile}
                                                                                        isPresentation={this.props.isPresentation}
                                                                                        exportView={this.props.exportView}
                                                                                        dashItemId={layoutItem.i}
                                                                                        dashboardPath={this.props.dashboardPath}
                                                                                        inContainer={false}
                                                                                        selectedFilters={this.state.filters}
                                                                                        {...itemData}
                                                                                        ref={ref => this.gridItems[layoutItem.i] = {ref}}
                                                                                        renderCallback={this.itemRenderCallback}
                                                                                        cancelToken={this.state.cancelToken}
                                                                                        onSelectItem={isContainer ? undefined : (event) => this.clickedOnItem(event, layoutItem, itemData)}
                                                                                        position={position}
                                                                                    />

                                                                                </React.Fragment>
                                                                            }

                                                                            {isContainer &&
                                                                                <div
                                                                                    ref={ref => this.gridItems[layoutItem.i] = {ref}}>

                                                                                    <LazyContainerMenu item={itemData}
                                                                                                       itemsData={this.props.itemsData}
                                                                                                       editMode={this.props.editMode}
                                                                                                       style={this.props.style}
                                                                                                       currentBreakpoint={{
                                                                                                           effectiveBreakpoint,
                                                                                                           viewBreakpoint
                                                                                                       }}
                                                                                    />

                                                                                    {(() => {
                                                                                        let containerWidth = position.width;
                                                                                        let containerHeight = position.height;

                                                                                        let containerMargin;

                                                                                        if (this.props.style.allowContainerMargin) {
                                                                                            containerMargin = this.props.style.containerMargin;
                                                                                            containerWidth -= containerMargin * 2;
                                                                                            containerHeight -= containerMargin * 3;
                                                                                        } else {
                                                                                            containerMargin = 10;
                                                                                            containerHeight -= containerMargin * 2;
                                                                                        }

                                                                                        if (itemData.additionalProps.showTitle) {
                                                                                            containerHeight -= 40;
                                                                                        }

                                                                                        const childs = itemData.additionalProps.items;
                                                                                        return (
                                                                                            <RGL
                                                                                                className="ContainerRGL"
                                                                                                width={containerWidth}
                                                                                                rowHeight={containerHeight / gridProps.cols}
                                                                                                cols={gridProps.cols}
                                                                                                layout={childs}
                                                                                                margin={[0, 0]}
                                                                                                containerPadding={[containerMargin, containerMargin]}
                                                                                                compactType={null}
                                                                                                isDraggable={false}
                                                                                                isResizable={false}
                                                                                                preventCollision={true}
                                                                                            >
                                                                                                {childs.map(childLayout => {
                                                                                                        const childItem = this.findItemData(childLayout) || {viewType: ''};
                                                                                                        childItem.highlight = itemData.highlight;
                                                                                                        return (
                                                                                                            <GridEl key={childLayout.i}
                                                                                                                    className={`grid-stack-item-content grid-stack-item-content-${childItem.viewType} ${dashboardBgThemeName} ContainerChild Item`}>
                                                                                                                {({position: containerPosition}) => {
                                                                                                                    return (
                                                                                                                        <>
                                                                                                                            <div
                                                                                                                                className={`itemsize item-size-${childLayout.i} ${itemData.additionalProps.showTitle ? '' : 'onLeft'} hidden`}></div>


                                                                                                                            {this.props.mobile && isMobileBreakpoint && !addingContainer &&
                                                                                                                                <DashboardItemMenuExplorerMobile
                                                                                                                                    item={childItem}
                                                                                                                                    dashboardPath={this.props.dashboardPath}
                                                                                                                                    selectedFilters={this.state.filters}
                                                                                                                                />

                                                                                                                            }

                                                                                                                            {(this.props.useDefaultMenus || !this.props.mobile) && !addingContainer &&
                                                                                                                                <LazyDashboardItemMenu
                                                                                                                                  {...buildItemMenuProps({
                                                                                                                                        item: childItem,
                                                                                                                                        position: containerPosition,
                                                                                                                                        inContainer: true
                                                                                                                                  })}
                                                                                                                                />
                                                                                                                            }

                                                                                                                            <DashGridItemContent
                                                                                                                                style={this.props.style}
                                                                                                                                renderQueue={this.renderQueue}
                                                                                                                                mobile={this.props.mobile}
                                                                                                                                isPresentation={this.props.isPresentation}
                                                                                                                                exportView={this.props.exportView}
                                                                                                                                dashItemId={childItem.i}
                                                                                                                                dashboardPath={this.props.dashboardPath}
                                                                                                                                inContainer={true}
                                                                                                                                selectedFilters={this.state.filters}
                                                                                                                                {...childItem}
                                                                                                                                ref={ref => this.gridItems[childItem.i] = {ref}}
                                                                                                                                renderCallback={this.itemRenderCallback}
                                                                                                                                cancelToken={this.state.cancelToken}
                                                                                                                                position={containerPosition}
                                                                                                                                onSelectItem={(event) => this.clickedOnItem(event, layoutItem, childItem)}
                                                                                                                            />
                                                                                                                        </>
                                                                                                                    );
                                                                                                                }}
                                                                                                            </GridEl>
                                                                                                        )
                                                                                                    }
                                                                                                )}

                                                                                            </RGL>
                                                                                        );
                                                                                    })()}
                                                                                </div>
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                </React.Fragment>
                                                            )
                                                        }}
                                                    </GridEl>
                                                );
                                            })}
                                        </GridComponent>
                                    </BngEmptyDashboard>
                                }
                            </div>
                        </div>
                        {
                            (alertCustomMobile && this.props.mobile && application.page.isMobile()) &&
                            <div className='alertConfigDashMobile'>
                                <div className='paddingConfigDashMobile'>
                            <span
                                className="spamAlertConfigDashMobile">{this.props.context.msg.t('dashboard.not.ready.for.mobile')}.</span>
                                </div>
                                <div className="timesAlertConfigDashMobile"
                                     onClick={event => j('.alertConfigDashMobile').hide()}>
                                    <i className='fa fa-times'></i>
                                </div>
                            </div>

                        }
                    </div>
            </DashGridContext.Provider>
        );
    }

    isContainer(itemUuid) {
        const itemData = this.props.itemsData[itemUuid];
        return itemData && itemData.viewType === 'container';
    }

    calculateBreakpoint = () => {
        let effectiveBreakpoint = this.props.breakpointView;
        let viewBreakpoint = effectiveBreakpoint;

        if (this.props.mobile) {
            if (application.page.isMobile()) {
                effectiveBreakpoint = 'MOBILE';
                if (application.page.isHorizontalPosition()) {
                    effectiveBreakpoint = 'MOBILE_HORIZONTAL';
                }
            } else {
                effectiveBreakpoint = 'DESKTOP';
            }
            viewBreakpoint = effectiveBreakpoint;
        }

        return {
            effectiveBreakpoint,
            viewBreakpoint,
        };
    };

    itemRenderCallback = () => {
        if (this.state.initialRenderComplete) {
            return;
        }
        const {effectiveBreakpoint} = this.calculateBreakpoint();

        const layout = this.props.layouts[effectiveBreakpoint] || [];
        this._renderedItems++;
        if (this._renderedItems === layout.length) {
            this.setState({initialRenderComplete: true});
            this._renderedItems = 0;
        }
    };

    calculateRowHeight() {
        let rowHeight = 50;

        if (this.props.staticSize) {
            let y = 0;
            this.props.layouts["DESKTOP"].forEach(e => y = Math.max(y, e.y + e.h));
            const rowHeight = y > 0 ? this.props.height / y : 1;
            return {rowHeight, horizontalLikeVertical: false};
        }

        const _el = this._breakpointEl || this._requiredFilterEl;
        const {effectiveBreakpoint} = this.calculateBreakpoint();
        if (this.props.breakpointView !== 'DESKTOP') {
            const height = jQuery(_el).height();
            rowHeight = height / this.props.divisions;
        } else {
            const viewportHeight = application.page.viewport()[1];
            const topOffset = jQuery(_el).offset().top;
            if (effectiveBreakpoint === 'MOBILE' || effectiveBreakpoint === 'MOBILE_HORIZONTAL') {
                rowHeight = (viewportHeight - 5) / this.props.divisions;
            } else {
                rowHeight = (viewportHeight - topOffset - 5) / this.props.divisions;
            }
        }

        const layouts = !!window.___LAST_DASHBOARD_OPTS ? window.___LAST_DASHBOARD_OPTS.layouts : this.props.layouts;
        const horizontalLikeVertical = !layouts['MOBILE_HORIZONTAL'];

        if (effectiveBreakpoint === 'MOBILE_HORIZONTAL') {
            rowHeight *= 2.8;
        }
        return {rowHeight, horizontalLikeVertical};
    }

    onItemResize = (layout, oldItem, newItem) => {
        const {$itemSize, $dashBox, size} = this.findItemSize(newItem.i);
        $itemSize.text(`${size.width} x ${size.height}`);
        $dashBox.css(size);
        $dashBox.find('.item-content-container').height(size.height);
    };

    onItemResizeStop = (layout, oldItem, newItem) => {
        const {$itemSize, size, gridItemRef} = this.findItemSize(newItem.i);
        $itemSize.text(`${size.width} x ${size.height}`);
        if (gridItemRef.ref.updateSize) {
            gridItemRef.ref.updateSize(size);
        } else {
            _.get(this.props.itemsData[newItem.i], 'additionalProps.items', []).forEach(i => {
                const result = this.findItemSize(i.i);
                if (result.gridItemRef) {
                    // pass null to fetch size automatically
                    result.gridItemRef.ref.updateSize(null);
                }
            });
        }
    };

    processEmptyLayouts(layouts, effectiveBreakpoint) {
        let layout = layouts[effectiveBreakpoint] || [];
        if (effectiveBreakpoint === 'MOBILE_HORIZONTAL' && layout.length === 0) {
            layout = layouts['MOBILE'];
        }
        const maxPositionFn = ({y, h}) => y + h;
        let nextY = _.maxBy(layout, maxPositionFn) || {y: 0, h: 0};
        nextY = maxPositionFn(nextY);
        const {itemDefaultSizeX, itemDefaultSizeY} = {
            itemDefaultSizeX: effectiveBreakpoint === 'DESKTOP' ? this.props.divisions / 3 : this.props.divisions,
            itemDefaultSizeY: this.props.divisions / 3,
        };
        return {
            fullLayout: layout.map(i => {
                let item = {...i};
                if (!('x' in item)) {
                    nextY++;
                    item.w = itemDefaultSizeX;
                    item.h = itemDefaultSizeY;
                    item.x = 0;
                    item.y = nextY;
                    nextY += itemDefaultSizeY;
                }
                return item;
            })
        };
    }

    makeSaveButtonGlow() {
        j('.SaveDashboardChangesButton').addClass('right-menu-icon-glow');
    }

    onLayoutChange = async (newLayout, fromDragAndDrop = false) => {
        if (!!window.__BLOCK_DASHGRID_UPDATE) return;

        this.currentLayout = newLayout;
        let newLayoutToPersist = transformLayoutToPersist(newLayout);

        const breakpoint = this.props.breakpointView;
        if (window.___LAST_DASHBOARD_OPTS) {
            if (breakpoint in window.___LAST_DASHBOARD_OPTS.layouts) {
                let oldLayoutToPersist = transformLayoutToPersist(window.___LAST_DASHBOARD_OPTS.layouts[breakpoint]);
                if (_.isEqual(newLayoutToPersist, oldLayoutToPersist)) {
                    return;
                }
                this._layoutChanged = true;
            } else if (breakpoint === 'MOBILE_HORIZONTAL') {
                // See: https://www.youtube.com/watch?v=pQLwYKImbik
                let newContainersLayout = [];
                const layoutMobileVertical = fromDragAndDrop ? newLayout : window.___LAST_DASHBOARD_OPTS.layouts['MOBILE'];
                for (let layoutItem of layoutMobileVertical) {
                    if (this.isContainer(layoutItem.i)) {
                        window.__BLOCK_DASHGRID_UPDATE = true;
                        let newContainer = this.props.itemsData[layoutItem.i].additionalProps;
                        newContainer.breakpoint = 'MOBILE_HORIZONTAL';
                        let layoutJson = JSON.parse(JSON.stringify(layoutItem));
                        const data = await Api.Dash.updateContainerItem({layoutJson: transformItemToPersist(layoutJson), ...newContainer});
                        layoutJson.i = data.id;
                        layoutJson.oldId = layoutItem.i;
                        newContainersLayout.push(layoutJson);
                    }
                }
                _.remove(newLayout, (ll) => _.find(newContainersLayout, (lc) => ll.i === lc.oldId));
                newLayout = newLayout.concat(newContainersLayout);
                newLayoutToPersist = transformLayoutToPersist(newLayout);
                if (this.state.horizontalLikeVertical) {
                    await this.setState({horizontalLikeVertical: false});
                }
                this._layoutChanged = true;
            }
            window.___LAST_DASHBOARD_OPTS.layouts[breakpoint] = newLayout;
        }

        this.renderQueue.add({
            render: async () => {
                await Api.Dash.updateLayout(JSON.stringify(newLayoutToPersist), breakpoint);
                typeof window.__DISPATCH_DIRTY_ALERT === "function" && window.__DISPATCH_DIRTY_ALERT(true);
                if (!!window.__BLOCK_DASHGRID_UPDATE) {
                    await Api.updateJsf();
                    delete window.__BLOCK_DASHGRID_UPDATE;
                }
            },
        });

        if (this._layoutChanged) {
            setTimeout(() => {
                Object.values(this._layoutListeners).forEach(fn => fn());
                this.makeSaveButtonGlow();
            }, 100);
        }
    };

    toggleCustomSelect(fn = null) {
        this.setState({
            customSelect: fn,
        })
    }

    stateForExport = () => {
        return {
            filters: _.cloneDeep(this.selectedFilters() || []),
            itemOverrides: _.cloneDeep(this.state.itemOverride ?? {}),
            mdxOverrides: Object.entries(window.RENDERABLE_PRELOAD_CACHE ?? {})
              .map(([id, result]) => {
                  return {
                      id,
                      mdx: result.mdx,
                      drillData: {
                          drillState: result.additionalProps?.drillState,
                          drillResponse: result.additionalProps?.drillResponse,
                      }
                  }
              })
              .filter(mo => !!mo.mdx)
        }
    }

}

class DashGridItemContent extends DeepEqualComponent {

    static defaultProps = {
        resizable: true,
    };

    updateSize = (size, forceUpdate = false) => {
        if (this.props.resizable || forceUpdate) {
            this.renderComponent.wrappedComponent.updateSize(size);
        }
    };

    //MIAUUUUU filtros com dependência do bean (restrição de filtro por item)
    getFilters = () => {
        const { restrictFilters, selectedFilters, filters, mobile, path } = this.props;

        const isXmlaObject = Utils.Object.isKpi(path)
          || Utils.Object.isNewMap(path)
          || Utils.Object.isBigTable(path)
          || Utils.Object.isAnalysis(path);

        const applyRestrictFilter = restrictFilters && isXmlaObject;
        const totalSelectedFilters = selectedFilters?.length ?? 0;
        const totalFilters = filters?.length ?? 0;
        const moreFiltersThanSelected = totalFilters > totalSelectedFilters;

        if (totalSelectedFilters > 0 && !applyRestrictFilter && !moreFiltersThanSelected) {
            return selectedFilters;
        }

        if ((mobile || moreFiltersThanSelected) && isXmlaObject) {
            const mergedFilters = _.isArray(filters) ? _.cloneDeep(filters) : JSON.parse(filters || '[]');
            for (const mf of mergedFilters) {
                const match = selectedFilters.find(f => f.id === mf.id);
                if (match) {
                    mf.members = _.cloneDeep(match.members);
                    mf.restrictionType = match.restrictionType ?? mf.restrictionType;
                }
            }
            return mergedFilters;
        }

        return filters;
    };

    //END MIAUUUU
    render() {
        const {style, onSelectItem, exportView, isPresentation, mobile, dashItemId, ...props} = this.props;

        return (
            <div className={`widget-box mini-header dashboarditem dashboard-item ${style.borderStyleClass}`}
                 onClick={onSelectItem}>
                <div className="widget-header">
                    <h4 className="lighter smaller">
                        {!props.isText &&
                            <div style={{fontSize: '14px'}}>
                                <Icon icon={props.icon}/>
                                {props.caption}
                            </div>
                        }
                    </h4>
                    <span className="widget-toolbar"/>
                </div>
                <div className={`widget-body widget-body-${props.id}`}>
                    <RenderablePreload key={`${props.id}-${props.path ?? ''}`}
                                       {...props}
                                       style={style}
                                       filters={this.getFilters()}
                                       ref={ref => this.renderComponent = ref}
                                       dashItemId={dashItemId}
                                       mobile={mobile}
                                       isPresentation={isPresentation}
                                       exportView={exportView}
                    />
                </div>
            </div>
        );
    }
}

const GridEl = ({children, className = '', ...props}) => {
    const [position, setPosition] = useState({width: 0, height: 0});
    const gridElRef = useRef();

    useEffect(() => {
        if (!gridElRef.current) return;

        const recalcPosition = () => {
            const newPosition = {
                width: gridElRef.current?.clientWidth || 0,
                height: gridElRef.current?.clientHeight || 0
            };
            setPosition(newPosition);
        }

        const sensor = new ResizeSensor(
            gridElRef.current,
            recalcPosition
        );

        recalcPosition();

        return () => sensor.detach();
    }, [gridElRef.current])

    const clonedChildren = React.Children.map(children, child =>
        React.cloneElement(child, {position}),
    );
    const childrenFunction = _.isFunction(children) ? children : children[0];

    return (
      <div className={`GridEl ${className}`} ref={gridElRef} {...props}>
        {gridElRef.current && (
          <GridElChildrenWrapper>
            {childrenFunction({ position })}
            <div className="CustomResizeHandle">
              <svg height="15" width="15">
                <circle cx="7" cy="7" r="6" stroke="#0b64fe" strokeWidth="1" fill="white" />
              </svg>
            </div>
            {clonedChildren}
          </GridElChildrenWrapper>
        )}
      </div>
    );
};

function GridElChildrenWrapper({ children }) {
    if (application.page.isIphone()) {
        return (
          <div className='appleDevicesFix'>
              {children}
          </div>
        );
    } else {
        return children;
    }
}

const BngEmptyDashboard = ({mobile, layout, viewBreakpoint, path, width, context, children, onCockpit = false}) => {
    const canManipulateObject = !!context.permissions && context.permissions.canManipulateObject(path);

    const emptyMsg = !mobile && !onCockpit && canManipulateObject ? (
        <span>{context.msg.t('dashboard.no.items')}</span>
    ) : <span>{context.msg.t('dashboard.no.items.viewer')}</span>;

    return (
        <BngEmpty isEmpty={layout.length === 0}
                  style={{width, margin: 'auto'}}
                  className={`EmptyDashboard-${viewBreakpoint || 'MOBILE-DEVICE'}`}
                  message={emptyMsg}
                  children={children}
        />
    );
};

export default ContextEnhancer(DashGrid);
