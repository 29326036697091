import styles from './AddAdditionalDialog.module.css';

import React from 'react';

import Dialog from 'components/ui/Dialog';
import AddonStars from 'components/ui/navbar/addons/AddonStars';
import Icon from 'components/ui/common/Icon';
import BngButton, { Variant } from 'components/bng/ui/BngButton';
import useBimContext from 'components/hooks/useBimContext';
import useFetchData from 'components/hooks/useFetchData';
import Api from 'components/Api';
import UiMsg from 'components/ui/UiMsg';
import { proposalUtils } from 'components/service/bng/AccountApi';
import Utils from 'components/Utils';

export const ADDITIONALS = {
  USERS: { name: 'user', key: 'USER' },
  STRUCTURES: { name: 'structure', key: 'STRUCTURE' },
  QUOTA: { name: 'quota', key: 'QUOTA' },
};

export function separateDecimals(num) {
  if (Number.isInteger(num)) {
    return [num, 0];
  }

  return num.toString().split('.');
}

export default function AddAdditionalDialog({
  additional,
  closeModal = _.noop,
  openKnowledgeBase = false,
  useFeatureClassification = false,
  onActivate = _.noop,
}) {
  const context = useBimContext();
  const $additionalInfo = useFetchData(async () => {
    try {
      const accountId = context.accountId;
      const fetchedAdditional = await Api.Account.fetchAdditionalInfo(
        accountId,
        additional.key,
        useFeatureClassification
      );
      const marketplaceInfo = (await Api.Marketplace.fetchMarketplaceInfo(accountId, fetchedAdditional.key)).additional
        .marketplaceInfo;

      fetchedAdditional.marketplaceInfo = marketplaceInfo;
      return fetchedAdditional;
    } catch (e) {
      console.error('Error on function fetchInfo()', e);
      UiMsg.ajaxError(null, e);
    }
  });

  const activateWithoutRequest = context.canActivateAddonWithoutRequest;

  const activateAdditional = async () => {
    try {
      await Api.Account.requestAdditional(
        context.accountId,
        additionalInfo,
        context.project.id,
        activateWithoutRequest
      );
      UiMsg.ok(
        context.msg.t(activateWithoutRequest ? 'additional.added.successfully' : 'additional.requested.successfully')
      );
      if (activateWithoutRequest) {
        await Utils.sleep(750);
        onActivate();
      }
      closeModal();
    } catch (e) {
      console.error('Error on function activateAdditional()', e);
      UiMsg.error(context.msg.t('additional.request.error'));
      closeModal();
    }
  };

  const redirectToMarketplace = () => {
    const redirectUrl = Api.buildUrl('/spr/bng/marketplace', {
      currentTab: 'item',
      addon: additionalInfo.key,
    });
    window.location.replace(redirectUrl);
  };

  const redirectToKnowledgeBase = () => {
    const knowledgeBaseLink =
      additionalInfo &&
      proposalUtils.findTranslation(additionalInfo?.marketplaceInfo.translatedProps ?? marketplaceInfo.translatedProps)
        .link;
    window.open(knowledgeBaseLink, '_blank').focus();
  };

  const additionalInfo = $additionalInfo.data;
  const classification = additionalInfo?.feature.classification;
  const quantity =
    classification === 'ADDON' || parseInt(additionalInfo?.featureValue.value) < 1
      ? 1
      : additionalInfo?.featureValue.value;
  const pricing = proposalUtils.getDefaultPricing(additionalInfo?.pricings);
  const pricingValue = separateDecimals((pricing?.pricing / quantity).toFixed(2));
  const addonTranslations = additionalInfo ? proposalUtils.translationsForSales(additionalInfo) : {};
  const pricingDesc = additionalInfo
    ? proposalUtils.translationsForSales(
        additionalInfo.marketplaceInfo.pageContent.find((page) => page.type === 'SINGLE_PRICE')
      )
    : {};

  return (
    <Dialog
      className={`${styles.AddAdditionalDialog} AddAdditionalDialog`}
      loading={$additionalInfo.isLoading}
      onClose={closeModal}
      titleComponent={() => {
        return (
          <div className={styles.AddAdditionalDialogHeader}>
            <AddonStars fill={additionalInfo?.marketplaceInfo.color} />
            <Icon
              icon={additionalInfo?.marketplaceInfo.icon}
              className={styles.headerIcon}
              style={{ color: additionalInfo?.marketplaceInfo.color }}
            />
          </div>
        );
      }}
      contentFullWidth
    >
      {additionalInfo && (
        <>
          <div className={styles.contentWrapper}>
            <span className={styles.additionalTitle}>{addonTranslations.name}</span>
            <span className={styles.additionalDesc}>{addonTranslations.description}</span>
            <div className={styles.additionalPricing}>
              <div className={styles.currencySymbol}>{pricing.currency.symbol}</div>
              <div className={styles.fullPricing}>{pricingValue[0]}</div>
              <div className={styles.centsWrapper}>
                <div className={styles.pricingCents}>{pricingValue[1]}</div>
                <div className={styles.byMonth}>{context.msg.t('byMonth')}</div>
              </div>
            </div>
            <span className={styles.additionalPricingInfo}>
              {additionalInfo.key === 'STRUCTURE_PACKAGE'
                ? context.msg.t('STRUCTURE_PACKAGE.single_price.info')
                : additionalInfo.key === 'USER_PACKAGE'
                ? context.msg.t('USER_PACKAGE.single_price.info')
                : pricingDesc.title}
            </span>
          </div>
          <div className={styles.buttonWrappers}>
            <BngButton
              className={`${styles.additionalButtons} AdditionalKnowMoreButton`}
              variant={Variant.textButton}
              onClick={openKnowledgeBase ? redirectToKnowledgeBase : redirectToMarketplace}
            >
              {context.msg.t('know.more')}
            </BngButton>
            <BngButton className={`${styles.additionalButtons} AdditionalActivateButton`} onClick={activateAdditional}>
              {context.msg.t(activateWithoutRequest ? 'additional.hire' : 'request.activation')}
            </BngButton>
          </div>
        </>
      )}
    </Dialog>
  );
}
