import { Outlet, useSearchParams } from 'react-router-dom';
import { useState } from 'react';

import BngSelectionOverlay from 'components/bng/ui/BngSelectionOverlay';
import useTranslation from 'components/hooks/useTranslation';
import { localStore, SESSION_DATA } from 'components/Storage';
import { proposalUtils } from 'components/service/bng/AccountApi';
import useBimNavigate from 'components/hooks/useBimNavigate';
import { TAB_QUERY_PARAM } from 'components/ui/accounts/AccountsPage';

const STORAGE_KEY = 'MarketplaceLayout:MARKETPLACE_SELECTED_ITEMS';

export const GROUP_KEYS = {
  ADDITIONALS: 'additionals',
  ADDONS: 'addons',
  SERVICES: 'services',
  TEMPLATES: 'templates',
  EDUCATIONAL: 'educational',
  PLANS: 'plans',
};

export const mapCardItemToSelectedItem = (cardItem, groupKey, quantity = 1, pricingId = null, canSelectQuantity = false) => {
  const defaultPricing = pricingId
    ? cardItem.pricings.find((p) => p.id === pricingId)
    : proposalUtils.getDefaultPricing(cardItem.pricings);
  return {
    id: cardItem.id,
    groupKey,
    key: cardItem.key,
    quantity,
    name: proposalUtils.translationsForSales(cardItem).name,
    pricing: defaultPricing,
    featureValue: cardItem.featureValue,
    pricings: cardItem.pricings,
    canSelectQuantity: canSelectQuantity,
  };
};

export const guessGroupKey = (cardItem) => {
  if (cardItem.feature) {
    if (cardItem.feature.classification === 'ADDON') {
      return cardItem.key === 'BIM_UNIVERSITY' ? GROUP_KEYS.EDUCATIONAL : GROUP_KEYS.ADDONS;
    } else {
      return GROUP_KEYS.ADDITIONALS;
    }
  } else if (cardItem.recurringType) {
    return GROUP_KEYS.SERVICES;
  } else if (cardItem.planFeatures) {
    return GROUP_KEYS.PLANS;
  }
};

const useSessionStorageState = ({ initialValue, key }) => {
  const [data, setData] = useState((localStore.get(SESSION_DATA) ?? {})[key] ?? initialValue);

  return [
    data,
    (val) => {
      const sessionData = localStore.get(SESSION_DATA) || {};
      sessionData[key] = val;
      localStore.put(SESSION_DATA, sessionData);
      setData(val);
    },
  ];
};

export default function MarketplaceLayout() {
  const { t } = useTranslation();
  const navigate = useBimNavigate();
  const [searchParams] = useSearchParams();

  const [selectedItems, setSelectedItems] = useSessionStorageState({
    initialValue: [],
    key: STORAGE_KEY,
  });

  const redirectToCart = () => {
    navigate('/marketplace?currentTab=checkout');
  };

  const selectCard = ({ cardItem, groupKey, quantity = 1, clearAll, pricingId = null, canSelectQuantity = false }) => {
    if (!groupKey) {
      groupKey = guessGroupKey(cardItem);
    }

    const isPlanItem = groupKey === GROUP_KEYS.PLANS;
    const itemToRemove = selectedItems.find(
      (value) =>
        (value.id === cardItem.id && value.groupKey === groupKey) || (isPlanItem && value.groupKey === GROUP_KEYS.PLANS)
    );
    if (itemToRemove) {
      const alteredItems = selectedItems.filter((value) => value.id !== itemToRemove.id);
      if ((pricingId && itemToRemove.pricing.id !== pricingId) || (isPlanItem && cardItem.id !== itemToRemove.id)) {
        alteredItems.push(mapCardItemToSelectedItem(cardItem, groupKey, quantity, pricingId));
      }
      setSelectedItems([...alteredItems]);
    } else {
      if (clearAll) {
        setSelectedItems([mapCardItemToSelectedItem(cardItem, groupKey, quantity, pricingId, canSelectQuantity)]);
      } else {
        selectedItems.push(mapCardItemToSelectedItem(cardItem, groupKey, quantity, pricingId, canSelectQuantity));
        setSelectedItems([...selectedItems]);
      }
    }
  };

  const currentTab = searchParams.get(TAB_QUERY_PARAM) ?? '';
  return (
    <BngSelectionOverlay
      icon="add_shopping_cart"
      btnAction={redirectToCart}
      btnLabel={t('click.for.checkout')}
      selectedItems={selectedItems}
      onChange={setSelectedItems}
      showPopup={currentTab !== 'checkout'}
    >
      <Outlet
        context={{
          selectedItems,
          selectCard,
          updateSelectedItems: setSelectedItems,
          onClearSelection: () => setSelectedItems([]),
        }}
      />
    </BngSelectionOverlay>
  );
}
