import React from "react";

import Icon from "components/ui/common/Icon";

const HelpIcon = ({
                      className = "",
                      title = '',
                      style = {fontSize: 16, marginLeft: 6, marginBottom: 1},
                      ...props
                  }) => {
    return (
        <Icon className={`qtip-hint ${className}`}
              icon="info_outline"
              style={style}
              title={title}
              {...props}
        />
    )
}

export default HelpIcon;