import ActivateBimDialog from 'components/ui/navbar/ActivateBimDialog';
import ProjectExpiredDialog from 'components/ui/project/ProjectExpiredDialog';
import InactiveProjectDialog from 'components/bng/project/InactiveProjectDialog';
import * as Actions from 'components/ui/redux/Actions';
import Api from 'components/Api';
import { localStore, SESSION_DATA } from 'components/Storage';
import AddonActivateDialog from 'components/bng/accounts/addons/AddonActivateDialog';
import OpConfirmation from 'components/ui/OpConfirmation';
import UiMsg from 'components/ui/UiMsg';
import SecurityCheckupDialog, { SecurityCheckups } from 'components/bng/securityCheckup/SecurityCheckupDialog';
import NPSCensus, { NPS_CENSUS_KEY } from 'components/bng/ui/Census/NPSCensus';
import FeatureCensus, { FEATURE_CENSUS_STORAGE_KEY } from 'components/bng/ui/Census/FeatureCensus';
import AdditionalActivateDialog from 'components/bng/accounts/additionals/AdditionalActivateDialog';

function runProjectChecks(props) {
  const projectChecks = [
    {
      check: ({ context }) => context.projectExpired && !context.projectActivated && context.planSelectionEnabled,
      selector: '.ActivateBimDialog',
      component: ActivateBimDialog,
    },
    {
      check: ({ context }) => context.projectExpired && (context.projectActivated || !context.planSelectionEnabled),
      selector: '.project-expired-form',
      component: ProjectExpiredDialog,
    },
    {
      check: ({ context }) => context.project && !context.project.enabled,
      selector: '.inactive-project-form',
      component: InactiveProjectDialog,
    },
  ];

  for (const projectCheck of projectChecks) {
    if (projectCheck.check(props)) {
      const openDialogFn = () => {
        if (j(projectCheck.selector).length !== 0) {
          return;
        }
        props.dispatch(Actions.MODALS.closeAll());
        props.dispatch(Actions.MODALS.open(projectCheck.component));
      };
      openDialogFn();
      setInterval(openDialogFn, 1000);
      return true;
    }
  }
  return false;
}

const runPostLoginChecks = async (params) => {
  const postLoginCheckKey = 'appStartupChecks:POST_LOGIN_CHECK';
  const sessionData = localStore.get(SESSION_DATA) || {};
  const alreadyChecked = sessionData[postLoginCheckKey] ?? false;
  if (alreadyChecked) {
    return false;
  }

  const postLoginChecks = [
    showAddonsRequestsDialog,
    startSecurityCheckup,
    showNpsCensus,
    showFeatureCensus,
  ];

  for (const postLoginCheck of postLoginChecks) {
    const checked = await postLoginCheck(params);

    if (checked) {
      sessionData[postLoginCheckKey] = true;
      localStore.put(SESSION_DATA, sessionData);
      return true;
    }
  }
  return false;
};

const showNpsCensus = async (params) => {
  const { context, dispatch } = params;
  const answerLater = localStore.get(NPS_CENSUS_KEY);

  try {
    if (params.context.nps?.enabled && !answerLater) {
      const currentDate = new Date().getTime();
      const nextAvaliationDate = new Date(context.nps.lastAvaliationDate);
      nextAvaliationDate.setMonth(nextAvaliationDate.getMonth() + 3);
      const hasBeenThreeMonths = currentDate >= nextAvaliationDate.getTime() && context.nps.lastAvaliationDate !== 0;
      if (hasBeenThreeMonths || context.nps.lastAvaliationDate === 0) {
        await new Promise((resolve) => {
          dispatch(
            Actions.MODALS.open(NPSCensus, {
              afterClose: resolve,
            })
          );
        });
        return true;
      }
    }
  } catch (e) {
    UiMsg.ajaxError(null, e);
    console.error('Error while trying to open NpsCensus');
  }
  return false;
};

const showFeatureCensus = async (params) => {
  if (params.context.user) {
    try {
      const answerLater = localStore.get(FEATURE_CENSUS_STORAGE_KEY);
      if (!answerLater) {
        const result = await Api.Feature.fetchOldestFeedbackFeature();
      if (result && result.length > 0) {
          await new Promise((resolve) => {
            params.dispatch(
              Actions.MODALS.open(FeatureCensus, {
              features: result,
                afterClose: resolve,
              })
            );
          });
          return true;
        }
      }
    } catch (e) {
      UiMsg.ajaxError(null, e);
      console.error('Error while trying to fetch oldest feature');
    }
  }
  return false;
};

const startSecurityCheckup = async (params) => {
  const { context, dispatch } = params;

  if (context.user) {
    const userLastSeen = await Api.UserParam.findKey(Api.UserParam.Keys.SecurityCheckup.SeenSecurityCheckup);
    const checkupsToShow = [];

    try {
      const sevenDaysAgo = moment().subtract(7, 'days');
      if (
        (!userLastSeen && moment(context.user.createdAt).isBefore(sevenDaysAgo)) ||
        moment(userLastSeen.date).isBefore(sevenDaysAgo)
      ) {

        const hasUpdatedPhone = await Api.UserParam.findKey(Api.UserParam.Keys.SecurityCheckup.UpdatedPhone);
        if (!hasUpdatedPhone) {
          checkupsToShow.push(SecurityCheckups.UpdatePhone);
        }

        if (_.isEmpty(checkupsToShow)) {
          await Api.UserParam.saveUserKey({
            key: Api.UserParam.Keys.SecurityCheckup.SeenSecurityCheckup,
            value: true,
            projectId: context.project.id,
          });
        }
      }

      if (!_.isEmpty(checkupsToShow)) {
        await new Promise((resolve) => {
          dispatch(
            Actions.MODALS.open(SecurityCheckupDialog, {
              checkupsToShow,
              afterClose: resolve,
            })
          );
        });
        return true;
      }
    } catch (e) {
      console.error('Error when starting security checkup', e);
    }
  }
  return false;
};

const showAddonsRequestsDialog = async (params) => {
  const { context, dispatch } = params;
  if (context.user) {
    const ADDON_ACCEPT_URL = '/addon-activate.iface';
    const sessionData = localStore.get(SESSION_DATA) || {};

    if (context.project && context.permissions.isAdmin()) {
      try {
        const request = await Api.Account.fetchOldestWaitingProposal(context.accountId);

        if (context.canActivateAddonWithoutRequest && request) {
          await new Promise((resolve) => {
            dispatch(
              Actions.MODALS.open(AdditionalActivateDialog, {
                afterClose: resolve,
                isRequest: true,
                proposalRequest: request,
              })
            );
          });
          return true;
        } else if (
          context.addonActivationRequests > 0 &&
          !_.get(sessionData, 'addonRequests.seen', false) &&
          !window.location.pathname.endsWith(ADDON_ACCEPT_URL)
        ) {
          _.set(sessionData, 'addonRequests.seen', true);
          localStore.put(SESSION_DATA, sessionData);
          await new Promise((resolve) => {
            dispatch(
              Actions.MODALS.open(AddonActivateDialog, {
                afterClose: resolve,
              })
            );
          });
          return true;
        }
      } catch (e) {
        console.error('Error on showAddonsRequestsDialog()', e);
      }
    }
  }
  return false;
};

const timeZoneCheck = async ({ context: { user, msg } }) => {
  const storageKey = 'appStartupChecks:timeZoneCheck';
  const storedValue = localStore.get(storageKey);

  if (!user || _.isEmpty(window.__BROWSER_TZ) || user.timeZone === window.__BROWSER_TZ || storedValue) {
    return false;
  }

  localStore.put(storageKey, { userId: user.id, createdAt: Date.now() });

  await new Promise((resolve) => {
    OpConfirmation({
      msg,
      title: `${msg.t('attention')}!`,
      html: msg.t('timeZone.check.message', [user.timeZone, window.__BROWSER_TZ]),
      level: 'warning',
      onResult: async (confirm) => {
        if (confirm) {
          try {
            await Api.User.updateTimeZone(window.__BROWSER_TZ);
            window.location.reload();
            UiMsg.ok(msg.t('timeZone.update.success'));
            resolve();
          } catch (e) {
            console.error('Error while updating timeZone', e);
            UiMsg.ajaxError(null, e);
          }
        }
        resolve();
      },
    });
  });
  return true;
};

const skipAds = () => {
  try {
    const queryParams = new URLSearchParams(window?.location?.search ?? '');
    const skipAdsQueryParam = queryParams.get('__skipAds') ?? '';

    const sessionData = localStore.get(SESSION_DATA) ?? {};

    if (skipAdsQueryParam === 'false') {
      sessionData.skiptAds = false;
    } else if (skipAdsQueryParam === 'true' || sessionData.skiptAds) {
      sessionData.skiptAds = true;
    }

    localStore.put(SESSION_DATA, sessionData);

    return sessionData.skiptAds;
  } catch (e) {
    console.warn('Error on skipAds()', e);
  }
};

export default async function appStartupChecks(params) {
  if (skipAds()) {
    return;
  }

  try {
    const checks = [runProjectChecks, timeZoneCheck, runPostLoginChecks];

    for (const check of checks) {
      const shown = await check(params);
      if (shown) {
        break;
      }
    }
  } catch (e) {
    console.error('Error during startup checks', e);
  }
}
