import styles from './AccProjectsTab.module.css';
import React, { useEffect, useMemo, useState } from 'react';
import useBimContext from 'components/hooks/useBimContext';
import useReduxDispatch from 'components/hooks/useReduxDispatch';
import SimpleAccTableTab from 'components/ui/accounts/tabs/SimpleAccTableTab';
import UiMsg from 'components/ui/UiMsg';
import Api from 'components/Api';
import FilterOpts from 'components/bng/pages/common/filter/FilterOpts';
import FilterDropdown from 'components/bng/pages/common/filter/FilterDropdown';
import bngYup from 'components/bng/form/yup/BngYup';
import { MODALS } from 'components/ui/redux/Actions';
import { NewProjectDialog } from 'components/bng/project/NewProjectDialog';
import Utils from 'components/Utils';
import BngDropdown from 'components/bng/ui/BngDropdown';

const buildProjectsColumn = ({ context }) => {
  return [
    {
      key: 'id',
      label: context.msg.t('id'),
      sortable: true,
      size: 20,
      render: (row) => {
        return (
          <div>
            <span>{row.id}</span>
          </div>
        );
      },
    },
    {
      key: 'name',
      label: context.msg.t('name'),
      sortable: true,
      size: 100,
      render: (row) => {
        return (
          <div>
            <span>{row.name}</span>
          </div>
        );
      },
    },
    {
      key: 'caption',
      label: context.msg.t('newInMemoryVisualizationField'),
      sortable: true,
      size: 100,
      render: (row) => {
        return (
          <div>
            <span>{Utils.Project.displayName(row)}</span>
          </div>
        );
      },
    },
    {
      key: 'type',
      label: context.msg.t('type'),
      sortable: true,
      size: 100,
      render: (row) => {
        const projectType = Utils.Project.types[row.projectType.type || row.projectType];
        return (
          <div className={styles.projectTypeWrapper} style={{ color: projectType.color }}>
            <div className={`${styles.projectTypeBadge} ${projectType.badge}`}>{projectType.name.charAt(0)}</div>
            <span>{projectType.name}</span>
          </div>
        );
      },
    },
    {
      key: 'action',
      label: context.msg.t('action'),
      size: 10,
      render: (row) => {
        return (
          <div>
            <BngDropdown
              popperOpts={{ placement: 'bottom-end' }}
              popperClassName={styles.ProjectsActionPopper}
              options={[
                {
                  label: context.msg.t('access.project'),
                  icon: 'login',
                  onClick: () => {
                    window.location.href = Api.Bng.accessProjectUrl(row.name);
                  },
                },
              ]}
            />
          </div>
        );
      },
    },
  ];
};

const ProjectsFilterDropdownSchema = bngYup((yup) => {
  return yup.object().shape({
    type: yup.string().nullable(),
  });
});

const ProjectsFilterDropdown = ({ rows = [], onChange = _.noop, initialValues = [], filters = {} }) => {
  const context = useBimContext();

  const projectOpts = FilterOpts({
    useIdAsValue: true,
    customIcon: (type) => {
      const projectType = Utils.Project.types[type.label];
      return (
        <div className={styles.projectTypeWrapper} style={{ color: projectType.color }}>
          <div className={`${styles.projectTypeBadge} ${projectType.badge}`}>{projectType.name.charAt(0)}</div>
        </div>
      );
    },
    options: _.uniqBy(
      rows.map((project) => {
        const type = project.projectType.type;
        return { id: type, label: type };
      }),
      'id'
    ),
  });

  const filterFields = [
    {
      name: 'type',
      label: context.msg.t('type'),
      options: projectOpts,
      multiple: true,
    },
  ];

  return (
    <FilterDropdown
      fields={filterFields}
      dropdownSchema={ProjectsFilterDropdownSchema}
      initialFormValues={initialValues}
      onChange={onChange}
      selectedFilters={filters.filterButton}
    />
  );
};

const filterProjects = (filters, projects) => {
  const {
    search,
    filterButton: { type },
  } = filters;
  if (search) {
    projects = projects.filter((p) => {
      return (
        p.name?.toLowerCase().includes(search.toLowerCase()) ||
        p.id.toString().includes(search) ||
        Utils.Project.displayName(p).includes(search)
      );
    });
  }

  if (type && type.length > 0) {
    projects = projects.filter((p) => type.includes(p.projectType.type));
  }

  return projects;
};

const projectOrderFunc = (row, prop) => {
  const value = row[prop] || row.projectType[prop];
  return _.isString(value) ? value.toLowerCase() : value;
};

export default function AccProjectsTab({ account }) {
  const context = useBimContext();
  const dispatch = useReduxDispatch();

  const [loading, setLoading] = useState(false);
  const [projects, setProjects] = useState([]);

  const fetchProjects = async () => {
    setLoading(true);
    try {
      const fetchedProjects = await Api.Account.findAccountProjects({
        accountId: account.id,
      });
      setProjects(fetchedProjects);
    } catch (e) {
      console.error('Error on function fetchProjects()', e);
      UiMsg.error(context.msg.t('project.fetch.error'));
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchProjects();
  }, [account]);

  const openCreateProjectDialog = () => {
    dispatch(MODALS.open(NewProjectDialog, { goToProject: false, afterSubmit: fetchProjects }));
  };

  const cols = useMemo(() => buildProjectsColumn({ context }), []);

  return (
    <SimpleAccTableTab
      className={`AccProjectsTab ${styles.AccProjectsTab}`}
      cols={cols}
      rows={projects}
      filterFunc={filterProjects}
      initialFilters={{
        search: '',
        filterButton: { type: null },
      }}
      filterComponent={ProjectsFilterDropdown}
      onReload={fetchProjects}
      emptyAlertMsg={context.msg.t('no.project')}
      loading={loading}
      setLoading={setLoading}
      headerButtonIcon={'add_circle'}
      headerButtonAction={openCreateProjectDialog}
      headerButtonLabel={context.msg.t('create_project')}
      rowOrderFunc={projectOrderFunc}
    />
  );
}
