import React from 'react';
import PropTypes from 'prop-types';

class BngInputRichText extends React.PureComponent {
  static propTypes = {
    className: PropTypes.string,
    value: PropTypes.string,
    onChange: PropTypes.func,
    form: PropTypes.object,
    field: PropTypes.object,
  };

  static defaultProps = {
    className: '',
    editorOptions: {},
    value: '',
    onChange: undefined,
    form: {},
    field: {},
  };

  state = {};

  componentDidMount() {
    const { editorOptions, onChange, form, field } = this.props;
    this.$editor = j(this._textArea).cleditor(editorOptions);

    this.$editor.change(function () {
      const newValue = this.$area.context.value;
      const oldValue = this.lastValue || '';

      if (newValue !== oldValue) {
        onChange?.(newValue);
        form?.setFieldValue(field?.name, newValue);

        this.lastValue = newValue;
      }
    });

    //TODO::Implement touch, for some reason it does not work as it says in the docs

    // cledit.touched(function () {
    //   console.log('touched?')
    //   if (!_.isEmpty(form) && !_.isEmpty(field)) {
    //     form.setFieldTouched(field.name, true);
    //   }
    // })
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.$editor) {
      const val = this.props.field?.value || this.props.value || '';
      const prevVal = prevProps.field?.value || prevProps.value || '';
      if (val !== prevVal) {
        this.$editor[0].$area.val(val);
        this.$editor[0].updateFrame();
      }
    }
  }

  render() {
    const { field, value, className, editorOptions, ...props } = this.props;
    return (
      <textarea
        ref={(ref) => (this._textArea = ref)}
        className={`BngInputRichText ${className}`}
        defaultValue={field?.value || value}
        {...props}
      />
    );
  }
}

export default BngInputRichText;
