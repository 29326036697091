import styles from './ConnectionsTab.module.css';

import React, { useEffect, useMemo, useState } from 'react';

import AccordionList from 'components/ui/AccordionList';
import Accordion from 'components/ui/Accordion';
import useBimContext from 'components/hooks/useBimContext';
import UiMsg from 'components/ui/UiMsg';
import Icon from 'components/ui/common/Icon';
import Api from 'components/Api';
import BngDropdown from 'components/bng/ui/BngDropdown';
import { MODALS } from 'components/ui/redux/Actions';
import ConnectionsDialog from 'components/ui/in-memory/ConnectionsDialog';
import Tooltip from 'components/ui/Tooltip';
import useReduxDispatch from 'components/hooks/useReduxDispatch';
import useTranslation from 'components/hooks/useTranslation';

const columnIconSelector = (columnType) => {
  switch (columnType) {
    case 'NUMERIC':
      return '123';
    case 'DATE':
      return 'schedule';
    case 'BOOL':
      return 'memory';
    case 'JSON':
      return '{JSO}';
    default:
      return 'abc';
  }
};

function ConnectionSelector({
  connections = [],
  selectedConnection = 0,
  isConnectionSelected = false,
  fetchConnections = _.noop,
  setFieldValue = _.noop,
}) {
  const { msg, project } = useBimContext();
  const dispatch = useReduxDispatch();

  const [opened, setOpened] = useState(false);

  const connection = connections.find((con) => con.id === selectedConnection);

  return (
    <BngDropdown
      customButton={({ openDropdown }) => (
        <div className={`${styles.ConnectionSelectorWrapper}`}>
          <div
            className={`${styles.ConnectionSelector} opened-${opened}`}
            onClick={(event) => {
              setOpened((prev) => !prev);
              openDropdown(event);
            }}
          >
            {connection ? connection.name : msg.t('select.one.connection')}
            <Icon icon="arrow_drop_down" />
          </div>
          {isConnectionSelected && (
            <Icon
              icon="close"
              onClick={() => {
                setFieldValue('connection', 0);
              }}
            />
          )}
        </div>
      )}
      popperOpts={{
        placement: 'bottom-start',
      }}
      className={`ConnectionSelector ${styles.ConnectionSelectorButton}`}
      onClose={() => setOpened((prev) => !prev)}
      popperClassName={`${styles.ConnectionListOptionPopper} `}
      customOptions={({ closeDropdown }) => (
        <div className={`${styles.ConnectionsOptionsDropdown}`}>
          {connections.map((connection) => {
            return (
              <div
                className={`${styles.ConnectionListOption}`}
                onClick={() => {
                  setFieldValue('connection', connection.id);
                  closeDropdown();
                }}
              >
                <img src={Api.buildUrl('/images/data/db.png')} alt="Icon" />
                <div>
                  <div>{`${msg.t('name')}: ${connection.name}`}</div>
                  <div>{connection.type.name}</div>
                </div>
              </div>
            );
          })}
          <div
            className={`${styles.ConnectionListOption} new-database-connection`}
            onClick={() => {
              dispatch(
                MODALS.open(ConnectionsDialog, {
                  projectId: project.id,
                  onClose: () => fetchConnections(),
                })
              );
              closeDropdown();
            }}
          >
            <Icon icon="add_circle" />
            <div>{msg.t('bim.query.connection.menu.new.database.connection')}</div>
          </div>
        </div>
      )}
    />
  );
}

function RenderList({ schemas = [], childProp = 'tables', parent = {}, handleSelection = _.noop, schema = '' }) {
  const { t } = useTranslation();

  const [expanded, setExpanded] = useState([]);

  const handleExpand = (idx) => {
    const expandedClone = expanded.slice();
    const indexOf = expandedClone.indexOf(idx);
    if (indexOf < 0) {
      expandedClone.push(idx);
    } else {
      expandedClone.splice(indexOf, 1);
    }
    setExpanded(expandedClone);
  };

  return schemas.map((item, idx) => {
    const isExpanded = expanded.indexOf(idx) >= 0;
    const children = item[childProp];
    const hasChild = children?.length > 0;
    const isSchema = item.hasOwnProperty('tables');
    const isTable = item.hasOwnProperty('columns');
    const schemaName = isSchema ? item.name : schema;

    let itemProps = {};

    if (!children) {
      itemProps.title = (
        <>
          <b>{item.name}</b>
          <div>
            {t('bim.query.menu.item.tooltip.from.table')} <b>{item.key.table}</b>
          </div>
          <div>
            {t('bim.query.menu.item.tooltip.column.name.from.table')} <b>{item.key.name}</b>
          </div>
          <div>
            {t('bim.query.menu.item.tooltip.column.type')} <b>{item.type}</b>
          </div>
        </>
      );
    }

    const Tip = !children ? Tooltip : 'div';

    return (
      <Tip {...itemProps}>
        <div
          className={`${styles.listWrapper}`}
          draggable={!isSchema}
          onDragStart={(event) => {
            event.persist();
            handleSelection(item, idx, childProp, parent, event.dataTransfer, schemaName);
            event.stopPropagation();
          }}
        >
          <div key={`${parent.id || 0}${idx}`} className={`${styles.schemaName}`} onClick={() => handleExpand(idx)}>
            <Icon
              className={`${styles.schemaNameArrowIcon} ${isExpanded ? 'open' : 'cerrado'} ${!hasChild ? 'hide' : ''}`}
              icon="arrow_right"
            />
            <Icon
              className={`${styles.listItemIcon} `}
              icon={(isSchema && 'schema') || (isTable && 'table_view') || columnIconSelector(item.type)}
            />
            <div className={`${styles.itemName} ${!children ? 'column' : ''}`}>{item.name}</div>
            {['FOREIGN', 'PRIMARY'].includes(item?.key?.type) && (
              <Icon className={`${styles.keyIcon} ${item.key.type}`} icon="key" />
            )}
          </div>
          <div className={`${styles.subListWrapper} ${isExpanded ? 'open' : 'closed'}`}>
            <RenderList
              schemas={children}
              childProp="columns"
              parent={item}
              handleSelection={handleSelection}
              schema={schemaName}
            />
          </div>
          {!isSchema && (
            <Icon
              className={`${styles.itemAddIcon}`}
              icon={children ? 'add' : ''}
              onClick={() => {
                handleSelection(item, idx, childProp, parent, {}, schemaName);
              }}
            />
          )}
        </div>
      </Tip>
    );
  });
}

export default function ConnectionsTab({ values, setFieldValue }) {
  const { msg, project } = useBimContext();

  const [connections, setConnections] = useState([]);
  const [loading, setLoading] = useState(false);
  const [dbSchemas, setDbSchemas] = useState(null);

  const selectedConnection = useMemo(() => values.connection, [values.connection]);
  const isConnectionSelected = Object.keys(selectedConnection).length > 0;

  useEffect(() => {
    fetchConnections();
  }, []);

  useEffect(() => {
    if (selectedConnection !== 0) {
      fetchDataBase();
    } else {
      setDbSchemas(null);
      setFieldValue('connection', 0);
    }
  }, [selectedConnection]);

  const fetchConnections = async () => {
    try {
      let connectionList = connections;
      if (connections.length === 0) {
        const projectId = project?.id;
        const data = await Api.Connection.findAll(projectId, _.isNil(projectId));
        connectionList = data.map((connection) => ({
          ...connection,
          database: connections.type?.name,
        }));

        setConnections(connectionList);
      }
      const connection = connectionList.find((connection) => connection.id === values.connection);
      if (connection) {
        setFieldValue('connection', connection.id);
      }
    } catch (e) {
      console.error('Error on function fetchConnections', e);
      UiMsg.error(msg.t('bim.query.fetch.connections.error'));
    }
  };

  const fetchDataBase = async () => {
    try {
      setLoading(true);
      const data = await Api.Connection.fetchSchema(selectedConnection);
      setDbSchemas(data.schemas);
    } catch (e) {
      setLoading(false);
      console.error('Error on function fetchDataBase', e);
      UiMsg.error(msg.t('bim.query.fetch.database.error'));
    } finally {
      setLoading(false);
    }
  };

  const handleSelection = (item, currentIdx, child, parent, dataTransfer = {}, schemaName) => {
    const hasChildProp = Object.hasOwn(item, child);
    const table = hasChildProp ? item : parent;
    let sqlSelect = '\nSELECT';
    let sqlFrom = '\nFROM';

    (hasChildProp ? table[child] : [item]).forEach((v, idx) => {
      sqlSelect += `\n${idx > 0 ? ',' : ' '} ${table.name}.${v.name}`;
    });
    sqlFrom += `\n"${schemaName}".${table.name}`;

    if (_.isEmpty(dataTransfer)) {
      setFieldValue('sql', values.sql + sqlSelect + sqlFrom)
    } else {
      dataTransfer.setData('text/plain', sqlSelect + sqlFrom);
    }
  };

  return (
    <>
      <AccordionList className={`${styles.ConnectionsTabAccordionWrapper} ObjectRightMenuAccordion`} loading={loading}>
        <Accordion title={msg.t('connection')} startOpen={true}>
          <ConnectionSelector
            connections={connections}
            selectedConnection={selectedConnection}
            isConnectionSelected={isConnectionSelected}
            fetchConnections={fetchConnections}
            setFieldValue={setFieldValue}
          />
        </Accordion>
        <Accordion title={msg.t('tables')} disabled={dbSchemas === null} startOpen={dbSchemas != null}>
          <div className={`${styles.schemasWrapper}`}>
            {dbSchemas !== null && dbSchemas.length > 0 && (
              <RenderList schemas={dbSchemas} handleSelection={handleSelection} />
            )}
          </div>
        </Accordion>
        {/*<BngButton className={`${styles.NextStepButton}`}>*/}
        {/*  {msg.t('bim.query.connection.configuration.next.step')}*/}
        {/*</BngButton>*/}
      </AccordionList>
    </>
  );
}
