import React, { useEffect, useMemo, useRef } from 'react';
import { connect } from 'react-redux';

import Api from 'components/Api';
import LoadingCenter from 'components/ui/loading/LoadingCenter';
import FilterService from 'components/filter/FilterService';
import FilterBar from 'components/filter/FilterBar';
import DashGridEditor from 'components/ui/dashboard/components/DashGridEditor';
import useFetchData from 'components/hooks/useFetchData';
import { UiBlocker } from 'components/bng/ui/UiBlocker';
import useEventBus from 'components/hooks/useEventBus';
import Utils from 'components/Utils';

const loadDashData = async (path, dashFilters) => {
  const { filter } = FilterService.createFilterParam(dashFilters, true);
  return await Api.Dash.loadDash(path, filter);
};

const DASHBOARD_FILTER_STATE_PARAM = '_dsf';

function CockpitDashboardRender({ path, filters = [], bigTableState = {}, fromCockpit }) {
  const $dashGrid = useRef();
  const $dashData = useFetchData(async () => {
    const filterStateKey = Utils.History.currentUrlSearchParams().get(DASHBOARD_FILTER_STATE_PARAM);
    if (filterStateKey) {
      Utils.History.updateQuery({ [DASHBOARD_FILTER_STATE_PARAM]: null });
      const data = await Api.TemporaryStorage.find(filterStateKey);
      if (data?.value) {
        filters = JSON.parse(data.value) ?? filters;
      }
    }
    return await loadDashData(path, filters);
  }, [path]);

  useEffect(() => {
    if (!$dashData.data) {
      return;
    }
    afterInitJs();
  }, [$dashData.data]);

  useEventBus(
    'BngCockpitButtons:ExportToPdf',
    () => {
      const { filters, ...dashState } = $dashGrid.current?.dashGridRef?.wrappedComponent?.stateForExport() ?? {};
      Api.Export.exportCockpitObject({
        content: path,
        filters,
        ...bigTableState,
        dashState,
      });
    },
    [path, filters]
  );

  const filterBarProps = useMemo(() => {
    if (!$dashData.data) {
      return {};
    }

    return {
      type: 'REACT_COCKPIT_DASHBOARD',
      isPublisher: false,
      filters: FilterService.transform($dashData.data?.gridData.availableFilters),
      dashboardPath: $dashData.data.dashboardPath,
      runtimeDashboard: false,
      canSave: false,
      onEditorMode: false,
      filterPosition: $dashData.data.filterPosition,
      onChangeListener: (filters, force, appendAndReplace = false, targetFilters = []) => {
        return window.__FILTER_CHANGE_LISTENER(targetFilters, force, true);
      },
    };
  }, [$dashData.data]);

  if (!$dashData.data) {
    return <LoadingCenter />;
  }

  const hasFilterClass =
    $dashData.data.hasFilters || filterBarProps.filters.length > 0 ? 'dashboard-wrapper-with-filters' : '';
  const editModeClass = $dashData.data.editMode ? 'edit-mode' : '';

  return (
    <UiBlocker
      id="dashboard-wrapper"
      className={`CockpitDashboardRender ${hasFilterClass} ${$dashData.data.filterPositionClass} ${editModeClass}`}
      block={$dashData.isLoading}
    >
      <div id="body-wrapper">
        {!$dashData.data.isDesktop && (
          <style
            type="text/css"
            dangerouslySetInnerHTML={{
              __html: 'div#page-content { background: none; }',
            }}
          />
        )}

        <div id="filters-dashboard-container">
          <FilterBar ref={(ref) => (window.__CURRENT_FILTER_BAR__ = ref)} {...filterBarProps} />
        </div>

        <div className="hide" dangerouslySetInnerHTML={{ __html: $dashData.data.dynamicCss }} />

        <div id="body-dashboard-home" className="free-style-marker-class">
          <DashGridEditor
            ref={$dashGrid}
            mobile
            useDefaultMenus={true}
            iconTextEventOnOpenObjectAction={true}
            fromCockpit={fromCockpit}
            {...$dashData.data.gridData}
          />
        </div>
      </div>
    </UiBlocker>
  );
}

let initialized = false;

const afterInitJs = () => {
  j('#cmf').mouseover();
  j('.fixedtableHeader').each(function (e) {
    j(this).find('.fixed-columns').width(j(this).find('.mdx-table th:eq(0)').outerWidth(true));
  });

  if (initialized) return;

  initialized = true;

  (function () {
    let dashTableId;

    j(document).on('click', '.mdx-table', function () {
      dashTableId = j(this).attr('id');
    });

    j(document).keydown(function (e) {
      var $table = j('#' + dashTableId + ' tbody');
      var $active = j('tr.highlight-row', $table);
      var position = parseInt($active.index());
      j('tr', $table).removeClass('highlight-row');
      switch (e.keyCode) {
        case 38:
          j(j('tr', $table)[position - 1]).addClass('highlight-row');
          break;
        case 40:
          j(j('tr', $table)[position + 1]).addClass('highlight-row');
          break;
      }
    });
  })();
};

export default connect((state) => ({
  bigTableState: {
    bigtableFilterModel: state.bigTable.data.bigtableFilterModel,
    bigtableSortModel: state.bigTable.data.bigtableSortModel,
  },
}))(CockpitDashboardRender);
