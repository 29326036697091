"use strict";

import {Axios} from "commonUtils";
import Utils from "components/Utils";
import Api from "components/Api";
import FilterService from "components/filter/FilterService";

const $ = jQuery;


class AnalysisApi {
    toggleMobile = () => {
        return Promise.resolve(
            $.post(`/spr/analysis/toggle-mobile`)
        );
    };

    setDynamicPeriodicities = (period) => {
        return Promise.resolve(
            $.postJSON(`/spr/analysis/dynamic-periodicities`, period)
        );
    };

    getDynamicPeriodicities = () => {
        return Promise.resolve(
            $.getJSON(`/spr/analysis/dynamic-periodicities`)
        );
    };

    toggleAggregate = ({axis, enabled = false, disabledAxis = "", enabledMeasures = [], rowOriented = false}) => {
        return Promise.resolve(
            $.post(`/spr/ui/analysis/aggregate?${j.param({axis, enabled, disabledAxis, enabledMeasures, rowOriented})}`)
        );
    };

    getAggregation = () => {
        return Promise.resolve(
            $.getJSON(`/spr/ui/analysis/aggregate`)
        );
    };

    getViewType = (path) => {
        return Promise.resolve($.getJSON(`/spr/ui/analysis/view-types`, {path}));
    };

    /**
     *
     * @param folder
     * @param name
     * @param description
     * @returns {Promise<*>}
     */
    saveAs = async ({folder, name, description}) => {
        const {data} = await Axios.post(`/spr/ui/analysis/save-as`, {folder, name, description});
        return data;
    };

    analysisData = async (path = '', cockpitId = undefined, publisherContent) => {
        const {data} = await Axios.get(`/spr/ui/analysis`, {
            params: {
                path,
                cockpitId,
                publisherContent
            }
        });
        return data;
    }

    hasFixedMembers = async () => {
        const {data} = await Axios.get(`/spr/ui/analysis/fixed-members`);
        return data;
    }

    exportExcelFromDash = async (path, filters, item) => {
        const filterRef = await FilterService.createFilterRef(filters);

        let mdxRef;
        if (item && Utils.Object.isAnalysis(path)) {
            if (application.ice.isLoaded()) {
                try {
                  mdxRef = await Api.Dash.storageMDX(item.id);
                } catch (e) {
                    console.error(e);
                }
            } else {
                const mdx = window.RENDERABLE_PRELOAD_CACHE[item.id]?.mdx;
                if (mdx) {
                  const { key } = await Api.TemporaryStorage.put(mdx);
                  mdxRef = key;
                }
            }
        }

        const exportLink = await Api.Bng.getExportExcelLink(path, {
        filterRef,
        mdxRef,
        iconExport: true,
        });

        window.open(exportLink, '_blank');
    };

    queryResult = async ({
                             path = 'RUNTIME',
                             filters = [],
                             publisherContent,
                             drillState = {drills: []}
    }) => {
        const reqBody = {
            path,
            ...FilterService.createFilterParam(filters, true),
            drillState
        };
        const {data} = await Axios.post(`/spr/analysis/queryResult`, reqBody, {
            params: {
                path,
                publisherContent
            }
        });
        return data;
    }
}

export default AnalysisApi;
