"use strict";

const $ = jQuery;

import { Axios } from 'commonUtils';

export default class SupportApi {

    static checkStatus() {
        return $.getJSON('/spr/support/status');
    }

    static findCategories(projectId) {
        return $.getJSON('/spr/support/categories', {projectId});
    }

    static newTicket(ticket, files) {
        let fd = new FormData();
        for (let key in ticket) {
            fd.append(key, ticket[key]);
        }
        files.forEach(file => fd.append('files', file));
        return $.postFd('/spr/support/tickets', fd);
    }

    static findStatusPageUrl() {
        return $.getJSON('/spr/support/statusPage')
    }

    static async findPhone() {
        const {data} = await Axios.get(`/spr/support/phoneNumber`)
        return data;
    }

}
