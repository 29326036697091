import { Axios } from 'commonUtils';

class ConnectionApi {
  findAll = async (projectId, globalOnly = false) => {
    return await Axios.getData(`/spr/connections`, {
      params: {
        projectId,
        globalOnly,
      },
    });
  };

  findById = async (id) => {
    const { data } = await Axios.get(`/spr/connections/${id}`);
    return data;
  };

  getDatabaseList = async () => {
    const { data } = await Axios.get('/spr/connections/databases');
    return data;
  };

  saveNewConnection = async (newConnection) => {
    const { data } = await Axios.post('/spr/connections', newConnection);
    return data;
  };

  deleteConnection = async (connectionId, connectionName = undefined) => {
    const { data } = await Axios.delete(`/spr/connections/${connectionId}`, {
      params: {
        connectionName,
      },
    });
    return data;
  };

  updateConnection = async (connection, connectionId) => {
    const { data } = await Axios.put(`/spr/connections/${connectionId}`, connection);
    return data;
  };

  testDb = async (connection) => {
    const { data } = await Axios.post('/spr/connections/test', connection);
    return data;
  };

  testDbOnBimCron = async (connection) => {
    return await Axios.post('/spr/connections/test/bim-cron', connection);
  };

  testHost = async (host, port) => {
    return await Axios.post('/spr/connections/test/host', {
      host,
      port,
    });
  };

  testHostOnBimCron = async (connection) => {
    return await Axios.post('/spr/connections/test/bim-cron/host', connection);
  };

  testInternetAccess = async () => {
    return await Axios.post('/spr/connections/test/internet-access');
  };

  fetchSchema = async (connectionId) => {
    return await Axios.getData(`/spr/connections/${connectionId}/schema`);
  };
}

export default ConnectionApi;
