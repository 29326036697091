import React, {useEffect, useState} from "react";
import {Field, useFormikContext} from "formik";

import ContextEnhancer from "components/ContextEnhancer";
import AccordionList from "components/ui/AccordionList";
import Accordion from "components/ui/Accordion";
import BngCheckbox from "components/bng/form/BngCheckbox";
import {BngField} from "components/bng/form/BngField";
import {BngSelect} from "components/bng/form/BngSelect";
import {bngYup} from "components/bng/form/yup/BngYup";
import ActionList from "components/ui/dashboard/components/ActionList";
import UiMsg from "components/ui/UiMsg";
import Api from "components/Api";
import Icon from "components/ui/common/Icon";
import BngSwitch from "components/bng/form/BngSwitch";
import {LabelHelper} from "components/ui/dashboard/DashLayoutAccordion";
import HelpIcon from "components/ui/common/HelpIcon";
import BngDropdownCheckbox from "components/bng/ui/BngDropdownCheckbox";
import {BngIconButton} from "components/bng/ui/BngIconButton";
import Button from "components/ui/Button";
import BngNavHeader from "components/bng/ui/BngNavHeader";
import FilterAccordion, {filterConfigHasChanged} from "components/ui/dashboard/FilterAccordion";


const filterMembersValues = ['REMOVE', 'DISABLE', 'NONE'];
const restrictionTypeValues = ['SHOW_SELECTED', 'HIDE_SELECTED'];
const periodRestrictionValues = ['NONE', 'DAY_LEVEL'];

const filterMembers = (context, filter) => {
    let opts = [];
    for (const value of filterMembersValues) {
        opts.push({
            value: value,
            label: context.msg.t(`data.link.${value}`)
        })
    }
    if (filter.timeFilter) {
        opts = opts.filter(opt => opt.value === 'NONE');
    }
    return opts;
};

const restrictionTypes = (context) => {
    let opts = [];
    for (const value of restrictionTypeValues) {
        opts.push({
            value: value,
            label: context.msg.t(`RequiredRestrictionType.${value}`)
        })
    }
    return opts;
};

const periodRestriction = (context) => {
    let opts = [];
    for (const value of periodRestrictionValues) {
        opts.push({
            value: value,
            label: context.msg.t(`TimeFilterRestriction.${value}`)
        })
    }
    return opts;
};

export const ConfigureFilterSchema = bngYup(yup => {
    return yup.object().shape({
        id: yup.number().default(0),
        type: yup.string().default(''), //only for temp data storage
        datasources: yup.array().default([]), //only for temp data storage
        filterLink: yup.string().required().oneOf(filterMembersValues).default('REMOVE'),
        hide: yup.boolean().required().default(false),
        fixed: yup.boolean().required().default(false),
        required: yup.boolean().required().default(false),
        periodRestriction: yup.string().default('NONE'),
        restrict: yup.object().shape({
            enabled: yup.boolean().required().default(false),
            restrictionType: yup.string().required().oneOf(restrictionTypeValues).default('SHOW_SELECTED'),
            members: yup.array().of(yup.string()).default([]),
        }),
    });
});


const DataAccordion = ({context, projectFilter, filterIndex}) => {
    return (
        <Accordion id="DataAccordion"
                   title={context.msg.t('link.data')}>
            <Field name={`filterConfig.filters.${filterIndex}.filterLink`}
                   label={context.msg.t('filter.members')}
                   component={BngField}
                   inputComponent={BngSelect}
                   options={filterMembers(context, projectFilter)}
                   emptyOption={false}
                   disabled={projectFilter.timeFilter}
            />
            {projectFilter.timeFilter &&
            <Field name={`filterConfig.filters.${filterIndex}.periodRestriction`}
                   label={context.msg.t('periods.visualization')}
                   component={BngField}
                   inputComponent={BngSelect}
                   options={periodRestriction(context)}
                   emptyOption={false}
            />
            }
            <Field name={`filterConfig.filters.${filterIndex}.hide`}
                   withLabel={false}
                   asProps={{label: <LabelHelper helpLabel={context.msg.t('hide.filter.hint')}
                                                 label={context.msg.t('hide.filter')}/>}}
                   component={BngField}
                   inputComponent={BngCheckbox}
            />
            <Field name={`filterConfig.filters.${filterIndex}.fixed`}
                   withLabel={false}
                   asProps={{label: <LabelHelper helpLabel={context.msg.t('fixed.filters.title')}
                                                 label={context.msg.t('fixed.filter')}/>}}
                   component={BngField}
                   inputComponent={BngCheckbox}
            />
            <Field name={`filterConfig.filters.${filterIndex}.required`}
                   withLabel={false}
                   asProps={{label: <LabelHelper helpLabel={context.msg.t('required.filter.hint')}
                                                 label={context.msg.t('required.filter')}/>}}
                   component={BngField}
                   inputComponent={BngCheckbox}
            />
        </Accordion>
    )
};

const RestrictMembersAccordion = ({
                                      context = {},
                                      restrict = {},
                                      filterId = 0,
                                      filterIndex = 0,
                                      setFieldValue = _.noop
                                  }) => {
    const [members, setMembers] = useState(null);
    const [boundariesElement] = useState(document.getElementById('page-content'));

    const removeMember = (member = '') => {
        const newRestrictMembers = restrict.members.filter(m => m !== member);
        setFieldValue(`filterConfig.filters[${filterIndex}].restrict.members`, newRestrictMembers)

        setMembers(buildSelectedMembers(newRestrictMembers));
    };

    const buildItems = () => {
        let items = [];
        for (let member of (restrict.members || [])) {
            items.push({
                "data-test": `FilterItem-${member}`,
                description: member,
                actions: [
                    {
                        icon: "close",
                        onClick: () => removeMember(member),
                        className: "RemoveFilterMember",
                        title: context.msg.t('remove')
                    },
                ]
            });
        }
        return items;
    };

    const addFilterMember = (selectedMembers = []) => {
        const selectedKeys = selectedMembers.map(m => m.key);
        setFieldValue(`filterConfig.filters[${filterIndex}].restrict.members`, selectedKeys)

        setMembers(buildSelectedMembers(selectedKeys));
    };

    const fetchMembers = async () => {
        if (members !== null) return;
        try {
            const filterMembersData = await Api.Filter.findMembers(filterId);
            setMembers(
                filterMembersData.members
                    .map(m => {return {key: m.value, label: m.label, value: restrict.members.includes(m.value)}})
            );
        } catch (error) {
            console.error(error);
            UiMsg.error(context.msg.t('ERROR'), error.responseJSON?.message || error);
        }
    };

    const buildSelectedMembers = (selectedKeys, currentMembers = members) => {
        return currentMembers?.map((member) => {
            return {...member, value: selectedKeys.includes(member.key)}
        })
    }

    return (
        <Accordion  id="RestrictMembersAccordion"
                    customTitle={(toggleAccordion) => {
                       return (
                           <div className="AccordionTitle AccordionCustomTitle">
                               <Icon className="AccordionIcon" icon="arrow_drop_up" onClick={toggleAccordion}/>
                               <span className="AccordionDescription" onClick={toggleAccordion}>
                                   {context.msg.t('restrict.members')}
                               </span>
                               <HelpIcon title={context.msg.t('restrict.members.hint')}/>
                               <Field name={`filterConfig.filters.${filterIndex}.restrict.enabled`}
                                      id={`filterConfig.filters.${filterIndex}.restrict.enabled`}
                                      value={restrict.enabled}
                                      component={BngSwitch}/>
                           </div>
                       )
                   }}>
            <Field name={`filterConfig.filters.${filterIndex}.restrict.restrictionType`}
                   label={context.msg.t('filter.members')}
                   component={BngField}
                   inputComponent={BngSelect}
                   options={restrictionTypes(context)}
                   emptyOption={false}
            />
            <ActionList items={buildItems()}
                        customAction={() => {
                            return (
                                <BngDropdownCheckbox
                                    boundariesElement={boundariesElement}
                                    customButton={({openDropdown}) => (
                                        <BngIconButton icon="add"
                                                       onClick={openDropdown}/>
                                    )}
                                    beforeOpen={fetchMembers}
                                    onApply={addFilterMember}
                                    items={members || []}
                                />
                            )
                        }}
            />
        </Accordion>
    )
};

const ConfigureFilterAccordion = (props) => {
    const [filterConfig, setFilterConfig] = useState(null);
    const {initialValues, values, isSubmitting, setFieldValue} = useFormikContext();

    const filterIndex = values.filterConfig.filters.findIndex(f => f.id === props.filterId);
    const filterData = values.filterConfig.filters[filterIndex];
    const projectFilter = props.projectFilters.find(pf => pf.id === props.filterId);

    useEffect(() => {
        if (!filterData) {
            fetchFilters();
        }
    }, []);

    useEffect(() => {
        fetchFilter();
    }, [props.filterId]);

    const fetchFilters = async () => {
        await props.fetchFilters();
        await fetchFilter();
    };

    const fetchFilter = async () => {
        if (filterData) return;
        try {
            const config = await Api.Dash.getFilterConfig({filterId: props.filterId});
            setFilterConfig(config);
            setFieldValue(`filterConfig.filters.${filterIndex}`, {
                ...(filterData || {}),
                loaded: true,
                type: config.type,
                filterLink: config.filterLink,
                hide: config.hide,
                fixed: config.fixedFilter,
                required: config.enabled,
                periodRestriction: config.timeFilterRestriction,
                restrict: {
                    enabled: config.restrictMembers,
                    restrictionType: config.restrictionType,
                    members: config.members,
                }
            })
        } catch (e) {
            console.error(e);
            UiMsg.error(e);
        }
    };

    const toggleFilterAccordion = () => {
        props.toggleAccordion(FilterAccordion, "FiltersMenuItem");
    };

    const applyFilters = (fromTimeFilter = false) => {
        props.handleFilterSubmit(values);
        if (fromTimeFilter) return;
        props.clearAccordion();
    };

    if (!filterData) {
        console.error('[MISSING_DATA] Missing filterData to render filter component', props.projectFilters, props.filterId);
        return null;
    }

    useEffect(() => {
        const newFilterConfig = {...values.filterConfig};
        newFilterConfig.filters.forEach(f => {
            if (f.type === 'TIME' && f.filterLink !== 'NONE') {
                f.filterLink = 'NONE';
                setFieldValue('filterConfig', newFilterConfig);
                applyFilters(true);
            }
        });
    }, [])

    return (
        <AccordionList className="ObjectRightMenuAccordion ConfigureFilterAccordion HasFixedButton">
            <BngNavHeader icon="icon-bim-filter"
                          className="ConfigureFilterHeader"
                          stack={[
                              {label: props.context.msg.t('filters')},
                              {label: filterData.caption}
                          ]}
                          onGoBack={toggleFilterAccordion}
            />
            <DataAccordion {...props}
                           projectFilter={projectFilter}
                           filterConfig={filterConfig}
                           filterIndex={filterIndex}
            />
            {!projectFilter.timeFilter &&
            <RestrictMembersAccordion {...props}
                                      restrict={filterData.restrict}
                                      filterConfig={filterConfig}
                                      setFieldValue={setFieldValue}
                                      filterIndex={filterIndex}
            />
            }

            <Accordion className="ApplyFilterConfig AccordionFixedButton" customTitle={() => null}>
                <Button className="bng-button save"
                        style={{margin: 0}}
                        type="button"
                        onClick={applyFilters}
                        disabled={!filterConfigHasChanged(values.filterConfig, props.appliedValues || initialValues.filterConfig)}
                        loading={isSubmitting}>
                    {props.context.msg.t('apply')}
                </Button>
            </Accordion>
        </AccordionList>
    )
};

export default ContextEnhancer(ConfigureFilterAccordion);