import styles from './MarketplaceCard.module.css';

import React from 'react';

import Icon from 'components/ui/common/Icon';
import useBimContext from 'components/hooks/useBimContext';
import { separateDecimals } from 'components/bng/accounts/additionals/AddAdditionalDialog';
import Utils from 'components/Utils';
import Api from 'components/Api';
import useBimNavigate from 'components/hooks/useBimNavigate';

export const RECURRING_TYPES = {
  ONCE: {
    key: 'ONCE',
    label: '',
  },
  YEARLY: {
    key: 'MONTHLY',
    label: 'byYear',
  },
  MONTHLY: {
    key: 'MONTHLY',
    label: 'byMonth',
  },
};

export default function MarketplaceCard({
  title,
  description,
  thumbnail = null,
  icon = 'extension',
  color,
  pricing,
  itemKey,
  recurringType = RECURRING_TYPES.MONTHLY,
  isChecked = false,
  selectCard,
  isContracted = false,
}) {
  const context = useBimContext();
  const navigate = useBimNavigate();
  const isConsultant = Utils.Users.isConsultant(context.user);
  const pricingValue = separateDecimals((pricing?.pricing).toFixed(2));

  const redirectToItemPage = async () => {
    const url = Api.buildUrl('/spr/bng/marketplace', {
      currentTab: 'item',
      addon: itemKey,
    });
    await navigate(url);
  };

  return (
    <div
      className={`MarketplaceCard-${itemKey} ${styles.MarketplaceCard} ${isChecked ? styles.selectedCard : ''}`}
      onClick={redirectToItemPage}
    >
      <div className={styles.imageWrapper}>
        {thumbnail ? (
          <img src={thumbnail} />
        ) : (
          <div className={styles.iconBackground} style={{ background: color }}>
            <Icon icon={icon} className={styles.cardIcon} />
          </div>
        )}
        <div className={styles.imageGradient}></div>
      </div>
      <div className={styles.detailsWrapper}>
        <div className={styles.textWrapper}>
          <div className={styles.cardTitleRow}>
            <span className={styles.cardTitle}>{title}</span>
            {isConsultant && !isContracted && (
              <Icon
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  selectCard();
                }}
                icon={isChecked ? 'check_box' : 'check_box_outline_blank'}
                outlined={!isChecked}
                className={`${styles.cardCheckbox}`}
              />
            )}
          </div>
          <span className={styles.cardDescription}>{description}</span>
        </div>
        <div className={styles.cardFooter}>
          {isContracted ? (
            <div className={styles.activeLabel}>{context.msg.t('enabled')}</div>
          ) : (
            <div className={styles.pricingFooter}>
              <span className={styles.footerDescription}>{context.msg.t('marketplace.starting.from')}</span>
              <div className={styles.pricingWrapper}>
                <span className={styles.currency}>{pricing.currency.symbol}</span>
                <strong className={styles.fullPricing}>{pricingValue[0]}</strong>
                <div className={styles.decimalsWrapper}>
                  <strong>{pricingValue[1]}</strong>
                  <div>{context.msg.t(recurringType.label)}</div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
