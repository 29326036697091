import styles from './LoginTfaUnlockForm.module.css';

import React, { useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate, useOutletContext } from 'react-router-dom';
import { Field, Formik } from 'formik';

import useTranslation from 'components/hooks/useTranslation';
import { BngCancelButton, BngPrimaryButton } from 'components/ui/Button';
import Api from 'components/Api';
import UiMsg from 'components/ui/UiMsg';
import BngForm from 'components/bng/form/BngForm';
import BngField from 'components/bng/form/BngField';
import BngTfaCodeInput from 'bng/pages/login/BngTfaCodeInput';
import bngYup from 'components/bng/form/yup/BngYup';
import ReactDOMServer from 'react-dom/server';
import Icon from 'components/ui/common/Icon';
import HelpIcon from 'components/ui/common/HelpIcon';

const FormSchema = bngYup((yup) =>
  yup.object({
    code: yup.string().required().ensure().trim().default(''),
  })
);

function TfaReason({ userAccess, lastValidAccess }) {
  const { t } = useTranslation();

  const checkEquality = (property) => {
    const value = userAccess?.[property];
    const lastValidValue = lastValidAccess?.[property];
    if (property === 'location') {
      return !_.isEqual(value, lastValidValue) && value !== 'UNKNOWN' && lastValidValue !== 'UNKNOWN';
    } else {
      return !_.isEqual(value, lastValidValue);
    }
  };

  let message = t('tfa.cookie');
  let description = '';

  if (checkEquality('ip')) {
    message = t('tfa.ip');
    description = t('tfa.ip.desc', lastValidAccess?.ip ?? '-', userAccess?.ip ?? '-');
  } else if (checkEquality('device')) {
    message = t('tfa.device');
    description = t('tfa.device.desc', lastValidAccess?.device ?? '-', userAccess?.device ?? '-');
  } else if (checkEquality('location')) {
    message = t('tfa.location');
    description = t('tfa.location.desc', lastValidAccess?.location ?? '-', userAccess?.location ?? '-');
  }

  return (
    <div className="TfaReason flex-center-items jc-center">
      <span>{message}</span>
      <HelpIcon title={description} />
    </div>
  );
}

export default function LoginTfaUnlockForm() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { state: locationState } = useLocation();
  const state = useMemo(() => _.cloneDeep(locationState), []);
  const { loadingCallback } = useOutletContext();

  const [resendCodeCountDown, setResendCodeCountDown] = useState(60);
  const [disableResendCode, setDisableResendCode] = useState(false);

  const tfaTypeLabel = _.capitalize(state?.credentials?.type?.toLowerCase() ?? 'email');

  const goToLoginPage = () => {
    navigate('/login', {
      replace: true,
    });
  };

  const sendCode = async (onSuccess = _.noop) => {
    loadingCallback(true);
    try {
      await Api.Auth.checkTfa(state.credentials);
      await onSuccess();
      startResendCodeCountdown();
    } catch (e) {
      if (e.response?.status === 401) {
        goToLoginPage();
      } else if (e.response?.status === 503) {
        UiMsg.warn(t('tfa.type.not.working', tfaTypeLabel));
      } else {
        console.error('Error on sendCode', state, e);
        UiMsg.ajaxError(null, e);
      }
    } finally {
      loadingCallback(false);
    }
  };

  const startResendCodeCountdown = () => {
    setDisableResendCode(true);
    let interval = setInterval(() => {
      setResendCodeCountDown((timeLeft) => {
        if (timeLeft <= 1) {
          clearInterval(interval);
          setDisableResendCode(false);
          return 60;
        }
        return timeLeft - 1;
      });
    }, 1000);
  };

  useEffect(() => {
    if (!state?.credentials) {
      navigate('/login', {
        replace: true,
      });
    } else {
      sendCode(() => {
        UiMsg.ok(t('tfa.code.sent.msg', tfaTypeLabel));
      });
    }
  }, []);

  return (
    <Formik
      initialValues={{ code: '' }}
      validationSchema={FormSchema}
      onSubmit={async (values, formikHelpers) => {
        try {
          values.code = values.code.toUpperCase();
          const { unlocked } = await Api.Auth.validateTfa({ email: state?.credentials.email ?? '', ...values });
          if (unlocked) {
            await Api.Auth.defaultLogin(state.credentials);
            UiMsg.ok(t('two_factor_auth_attempt_success'));
          } else {
            formikHelpers.setFieldError(
              'code',
              ReactDOMServer.renderToString(
                <div className="flex-center-items gap-1 mt-1">
                  <Icon icon="info" className="text-lg" /> {t('login_two_factor_auth_code_status_incorrect')}
                </div>
              )
            );
          }
        } catch (e) {
          if (e.response?.status === 401) {
            goToLoginPage();
            UiMsg.warn(t('badCredentials'));
          } else {
            console.error('Error on tfa code validation', e);
            UiMsg.ajaxError(null, e);
          }
        }
      }}
    >
      {({ values, isSubmitting }) => {
        return (
          <BngForm>
            {state.lastValidAccess && <TfaReason {...state} />}
            <hr />
            <h1>{t('login_two_factor_auth')}</h1>
            <p
              className="mt-2"
              dangerouslySetInnerHTML={{
                __html: t('login_two_factor_auth_device', tfaTypeLabel),
              }}
            />

            <div className="flex-center-items jc-center">
              <Field
                component={BngField}
                inputComponent={BngTfaCodeInput}
                name="code"
                withLabel={false}
                rootClassName="mt-5 mb-0 inline-flex"
              />
            </div>

            <div className="mt-4 flex-center-items jc-center">
              {disableResendCode ? (
                <span>{t('resend_code_in_seconds', resendCodeCountDown)}</span>
              ) : (
                <BngCancelButton
                  className={`${styles.resendBtn}`}
                  onClick={() =>
                    sendCode(() => {
                      UiMsg.ok(t('tfa.resend.code.msg', tfaTypeLabel));
                    })
                  }
                >
                  {t('resend.tfa.code')}
                </BngCancelButton>
              )}
            </div>

            <BngPrimaryButton className="mt-2" type="submit" disabled={values.code.trim().length < 6}>
              {t('verify')}
            </BngPrimaryButton>

            <div className="mt-2 flex-center-items jc-center">
              <BngCancelButton
                className={`${styles.backBtn}`}
                onClick={() => {
                  delete state.credentials.type;
                  navigate('/login/tfa', {
                    replace: true,
                    state,
                  });
                }}
              >
                {t('try.another.way')}
              </BngCancelButton>
            </div>
          </BngForm>
        );
      }}
    </Formik>
  );
}
