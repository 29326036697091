import styles from './ColumnsConfiguration.module.css';

import React, { useMemo } from 'react';
import { Field, useFormikContext } from 'formik';

import BngTable from 'components/bng/ui/BngTable';
import BngCheckbox from 'components/bng/form/BngCheckbox';
import useBimContext from 'components/hooks/useBimContext';
import BngInput from 'components/bng/form/BngInput';
import BngDropdown from 'components/bng/ui/BngDropdown';
import Icon from 'components/ui/common/Icon';
import useTranslation from 'components/hooks/useTranslation';

function DimensionTypeSelector({ row = {}, onChange = _.noop }) {
  const { t } = useTranslation();

  const dimensionOptions = useMemo(() => {
    return [
      {
        label: t('newInMemoryTypeDimensionRegular'),
        value: 'Regular',
        icon: 'abc',
      },
      {
        label: t('url'),
        value: 'Url',
        icon: 'link',
      },
      {
        label: t('newInMemoryTypeDimensionTime'),
        value: 'TimeDimension',
        icon: 'event',
      },
      {
        label: t('newInMemoryTypeDimensionMetric'),
        value: 'Measure',
        icon: '123',
      },
      {
        label: t('newInMemoryTypeDimensionGeoreference'),
        value: 'Georeference',
        icon: 'location_on',
      },
      {
        label: t('newInMemoryTypeDimensionNull'),
        value: 'Null',
        icon: 'filter_list',
      },
    ];
  }, []);

  const selectedOption = dimensionOptions.find((option) => option.value === row.dimensionType);

  return (
    <BngDropdown
      className={`DimensionTypeSelector ${styles.BngDropdown}`}
      customButton={({ openDropdown }) => (
        <div onClick={openDropdown} className={`${styles.DimensionTypeSelector}`}>
          <div>
            <Icon icon={selectedOption.icon} />
            {selectedOption.label}
          </div>
          <Icon icon="arrow_drop_down" />
        </div>
      )}
      popperClassName={`${styles.PopperOptions} `}
      customOptions={({ closeDropdown }) => {
        return dimensionOptions.map((option, idx) => {
          return (
            <div
              className={`${styles.DimensionOption}`}
              onClick={async () => {
                await onChange({
                  dimensionType: option.value,
                  measure: option.value === 'Measure',
                });
                closeDropdown();
              }}
            >
              <Icon icon={option.icon} />
              {option.label}
            </div>
          );
        });
      }}
    />
  );
}

function MeasureMasksSelector({ row, onChange = _.noop }) {
  const { t } = useTranslation();

  const maskOptions = useMemo(() => {
    return [
      {
        label: t('newInMemoryStandard'),
        value: 'Standard',
      },
      {
        label: t('newInMemoryPercent'),
        value: 'Percent',
      },
      {
        label: t('newInMemoryFixed'),
        value: 'Fixed',
      },
      {
        label: t('formatOptions.currency'),
        options: [
          {
            label: t('newInMemoryCurrencyDollar'),
            value: 'CurrencyDollar',
          },
          {
            label: t('newInMemoryCurrency'),
            value: 'Currency',
          },
          {
            label: t('newInMemoryCurrencyEuro'),
            value: 'CurrencyEuro',
          },
        ],
      },
      {
        label: t('newInMemoryHour'),
        value: 'Hour',
      },
      {
        label: t('formatOptions.accounting'),
        options: [
          {
            label: t('FormatString.Accounting'),
            value: 'Accounting',
          },
          {
            label: t('FormatString.AccountingFixed'),
            value: 'AccountingFixed',
          },
          {
            label: t('formatOptions.accountingUsd'),
            value: 'AccountingDollar',
          },
          {
            label: t('formatOptions.accountingBrl'),
            value: 'AccountingReal',
          },
          {
            label: t('formatOptions.accountingEur'),
            value: 'AccountingEuro',
          },
        ],
      },
    ];
  }, []);

  let label = '';
  maskOptions.forEach((option) => {
    if (option.value === row.formatString) {
      label = option.label;
    } else if (option.hasOwnProperty('options')) {
      option.options.forEach((o) => {
        if (o.value === row.formatString) {
          label = o.label;
        }
      });
    }
  });

  return (
    <BngDropdown
      className={`MeasureMasksSelector ${styles.BngDropdown} ${!row.measure && 'disabled'}`}
      customButton={({ openDropdown }) => (
        <div
          onClick={(event) => {
            if (row.measure) {
              openDropdown(event);
            }
          }}
          className={`${styles.DimensionTypeSelector}`}
        >
          <div>{label}</div>
          <Icon icon="arrow_drop_down" />
        </div>
      )}
      popperClassName={`${styles.PopperOptions} `}
      customOptions={({ closeDropdown }) => {
        return maskOptions.map((option, idx) => {
          if (option.hasOwnProperty('options')) {
            return (
              <BngDropdown
                className={`${styles.MaskOptionDropdown} `}
                customButton={({ openDropdown }) => (
                  <div
                    onClick={openDropdown}
                    className={`${styles.DimensionTypeSelector} ${styles.MaskOptionDropdownOption}`}
                  >
                    <div>{option.label}</div>
                    <Icon icon="arrow_drop_down" />
                  </div>
                )}
                popperOpts={{ placement: 'right' }}
                popperClassName={`${styles.PopperOptions} `}
                customOptions={({ closeDropdown }) => {
                  return option.options.map((o) => {
                    return (
                      <div
                        className={`${styles.DimensionOption}`}
                        onClick={async () => {
                          await onChange({ formatString: o.value });
                          closeDropdown();
                        }}
                      >
                        {o.label}
                      </div>
                    );
                  });
                }}
              />
            );
          }
          return (
            <div
              className={`${styles.DimensionOption}`}
              onClick={async () => {
                await onChange({ formatString: option.value });
                closeDropdown();
              }}
            >
              {option.label}
            </div>
          );
        });
      }}
    />
  );
}

function OperatorSelector({ row, onChange = _.noop }) {
  const { t } = useTranslation();

  const operationOptions = useMemo(() => {
    return [
      {
        label: t('sum'),
        value: 'SUM',
        icon: 'functions',
      },
      {
        label: t('count'),
        value: 'COUNT',
        icon: 'money',
      },
      {
        label: t('min'),
        value: 'MIN',
        icon: 'arrow_downward',
      },
      {
        label: t('max'),
        value: 'MAX',
        icon: 'arrow_upward',
      },
      {
        label: t('avg'),
        value: 'AVG',
        icon: 'sync_alt',
      },
      {
        label: t('distinct'),
        value: 'DISTINCT_COUNT',
        icon: 'pin',
      },
    ];
  }, []);
  const operator = operationOptions.find((operation) => operation.value === row.measureAggregator);

  return (
    <BngDropdown
      className={`OperatorSelector ${styles.BngDropdown} ${!row.measure && 'disabled'}`}
      customButton={({ openDropdown }) => (
        <div
          onClick={(event) => {
            if (row.measure) {
              openDropdown(event);
            }
          }}
          className={`${styles.DimensionTypeSelector}`}
        >
          <div>
            <Icon icon={operator.icon} />
            {operator.label}
          </div>
          <Icon icon="arrow_drop_down" />
        </div>
      )}
      popperClassName={`${styles.PopperOptions} `}
      customOptions={({ closeDropdown }) => {
        return operationOptions.map((option, idx) => {
          return (
            <div
              className={`${styles.DimensionOption}`}
              onClick={async () => {
                await onChange({
                  measureAggregator: option.value,
                });
                closeDropdown();
              }}
            >
              <Icon icon={option.icon} />
              {option.label}
            </div>
          );
        });
      }}
    />
  );
}

function AdvancedOptions({ row, onChange = _.noop }) {
  const { t } = useTranslation();

  const advancedOption = useMemo(() => {
    return [
      {
        label: t('all'),
        value: 'hasAll',
      },
      {
        label: t('visible'),
        value: 'visible',
      },
      {
        label: t('index'),
        value: 'indexed',
      },
    ];
  }, []);

  return (
    <BngDropdown
      popperClassName={`AdvancedOptions ${styles.PopperOptions} `}
      customOptions={({ closeDropdown }) => {
        return advancedOption.map((option, idx) => {
          return (
            <div
              className={`${styles.DimensionOption}`}
              onClick={async () => {
                await onChange({
                  [option.value]: !row[option.value],
                });
              }}
            >
              {option.label}
              <BngCheckbox field={{ value: row[option.value] }} />
            </div>
          );
        });
      }}
    />
  );
}

export default function ColumnsConfiguration() {
  const { msg } = useBimContext();
  const {
    values: { fieldConfigs = [] },
    setFieldValue,
  } = useFormikContext();

  const columns = useMemo(() => {
    const updateFieldValues = (row, values = {}) => {
      const copy = fieldConfigs.slice();
      const idx = copy.findIndex((field) => field.fieldName === row.fieldName);
      copy[idx] = {
        ...copy[idx],
        ...values,
      };
      setFieldValue('fieldConfigs', copy);
    };

    return [
      {
        render: (row) => (
          <BngCheckbox
            field={{
              // onChange: selectAllRows,
              value: true,
            }}
            size="lg"
          />
        ),
      },
      {
        label: msg.t('origin'),
        render: (row) => {
          return row.fieldName;
        },
      },
      {
        label: msg.t('newInMemoryVisualizationField'),
        render: (row) => {
          return (
            <Field
              className={`${styles.VisualizationName}`}
              component={BngInput}
              value={row.caption}
              onChange={(event) => {
                updateFieldValues(row, { caption: event.target.value });
              }}
            />
          );
        },
      },
      {
        label: msg.t('newInMemoryDimensionType'),
        render: (row) => (
          <DimensionTypeSelector
            row={row}
            fieldConfigs={fieldConfigs}
            onChange={(value) => {
              updateFieldValues(row, value);
            }}
          />
        ),
      },
      {
        label: msg.t('newInMemoryMeasureMasks'),
        render: (row) => (
          <MeasureMasksSelector
            row={row}
            onChange={(value) => {
              updateFieldValues(row, value);
            }}
          />
        ),
      },
      {
        label: msg.t('operation'),
        render: (row) => (
          <OperatorSelector
            row={row}
            onChange={(value) => {
              updateFieldValues(row, value);
            }}
          />
        ),
      },
      {
        render: (row) => (
          <AdvancedOptions
            row={row}
            onChange={(value) => {
              updateFieldValues(row, value);
            }}
          />
        ),
      },
    ];
  }, [fieldConfigs]);

  return <BngTable rows={fieldConfigs} cols={columns} />;
}
