import ABC_ANALYSIS from './assets/ABC_ANALYSIS.svg';
import MONTHLY_COMPARATIVE_CHART from './assets/MONTHLY_COMPARATIVE_CHART.svg';
import BEST_RANKING from './assets/BEST_RANKING.svg';
import WORST_RANKING from './assets/WORST_RANKING.svg';
import HISTORIC_EVOLUTION from './assets/HISTORIC_EVOLUTION.svg';
import GOAL_ACHIEVEMENT from './assets/GOAL_ACHIEVEMENT.svg';
import ACC_PERIOD_CHART from './assets/ACC_PERIOD_CHART.svg';
import ACC_COMPARATIVE_PERIOD_CHART from './assets/ACC_COMPARATIVE_PERIOD_CHART.svg';
import COMPARATIVE_CATEGORIES_CHART from './assets/COMPARATIVE_CATEGORIES_CHART.svg';
import TABLE_TOTAL_TWO_LEVELS from './assets/TABLE_TOTAL_TWO_LEVELS.svg';
import RADAR_GRAPHIC from './assets/RADAR_GRAPHIC.svg';
import HEAT_ANALYSIS from './assets/HEAT_ANALYSIS.svg';
import PARTICIPATION_GRAPHIC from './assets/PARTICIPATION_GRAPHIC.svg';
import PERIOD_VARIATION from './assets/PERIOD_VARIATION.svg';
import MOVING_AVERAGE from './assets/MOVING_AVERAGE.svg';
import COLOR_COMPARISON_GRAPHIC from './assets/COLOR_COMPARISON_GRAPHIC.svg';
import BUBBLE_CHART from './assets/BUBBLE_CHART.svg';
import BIG_TABLE from './assets/BIG_TABLE.svg'
import INTERACTIVE_CHART from './assets/INTERACTIVE_CHART.gif'

import Api from 'components/Api';
import { assistedObjectService } from 'components/bng/pages/common/AssistedObjectPage';
import { bngYup } from 'components/bng/form/yup/BngYup';
import { assistedAnalysisBaseSchema } from 'components/bng/pages/newAnalysis/BngAssistedAnalysisForm';
import ParamType from 'components/bng/pages/newAnalysis/BngAssistedAnalysisFormParamType';

const Images = {
  ABC_ANALYSIS,
  MONTHLY_COMPARATIVE_CHART,
  BEST_RANKING,
  WORST_RANKING,
  HISTORIC_EVOLUTION,
  GOAL_ACHIEVEMENT,
  ACC_PERIOD_CHART,
  ACC_COMPARATIVE_PERIOD_CHART,
  COMPARATIVE_CATEGORIES_CHART,
  TABLE_TOTAL_TWO_LEVELS,
  RADAR_GRAPHIC,
  HEAT_ANALYSIS,
  PARTICIPATION_GRAPHIC,
  PERIOD_VARIATION,
  MOVING_AVERAGE,
  COLOR_COMPARISON_GRAPHIC,
  BUBBLE_CHART,
  BIG_TABLE,
  INTERACTIVE_CHART,
};

const ValidationSchemas = {
  BLANK_ANALYSIS: bngYup((yup) =>
    assistedAnalysisBaseSchema.shape({
      params: yup.object({
        measure: yup.string().required(),
      }),
    })
  ),
  BIG_TABLE: bngYup((yup) => assistedAnalysisBaseSchema.clone()),
  ABC_ANALYSIS: bngYup((yup) =>
    assistedAnalysisBaseSchema.shape({
      params: yup.object({
        measure: yup.string().required(),
        rank: ParamType.Rank.validationSchema,
        timeDimension: yup.string(),
        periodicity: yup.string(),
        level: yup.string(),
      }),
    })
  ),
  MONTHLY_COMPARATIVE_CHART: bngYup((yup) =>
    assistedAnalysisBaseSchema.shape({
      params: yup.object({
        measure: yup.string().required(),
        timeDimension: yup.string().required(),
        periodicity: yup.string().required(),
      }),
    })
  ),
  BEST_RANKING: bngYup((yup) =>
    assistedAnalysisBaseSchema.shape({
      params: yup.object({
        measure: yup.string().required(),
        rank: ParamType.Rank.validationSchema,
        timeDimension: yup.string(),
        periodicity: yup.string(),
        filters: yup.array(
          yup.object({
            dimension: yup.string().required(),
            members: yup.array(yup.string().required()),
          })
        ),
      }),
    })
  ),
  HISTORIC_EVOLUTION: bngYup((yup) =>
    assistedAnalysisBaseSchema.shape({
      params: yup.object({
        measures: yup.array(yup.string().required()),
        timeDimensionData: ParamType.TimeDimensionWithHierarchy.validationSchema,
        periodicity: yup.string().required(),
      }),
    })
  ),
  GOAL_ACHIEVEMENT: bngYup((yup) =>
    assistedAnalysisBaseSchema.shape({
      params: yup.object({
        rank: ParamType.Rank.validationSchema,
        goalMeasure: yup.string().required(),
        accomplishedMeasure: yup.string().required(),
        greenAbove: yup.number().positive().required(),
        yellowAbove: yup.number().positive().required(),
        periodicity: yup.string(),
        timeDimension: yup.string(),
      }),
    })
  ),
  ACC_PERIOD_CHART: bngYup((yup) =>
    assistedAnalysisBaseSchema.shape({
      params: yup.object({
        measure: yup.string().required(),
        timeDimension: yup.string().required(),
        timeDimensionLevel: yup.string().required(),
        timeDimensionExpandLevel: yup.string().required(),
        periodicity: yup.string().required(),
        periodicityTimeDimension: yup.string().required(),
      }),
    })
  ),
  ACC_COMPARATIVE_PERIOD_CHART: bngYup((yup) =>
    assistedAnalysisBaseSchema.shape({
      params: yup.object({
        measure: yup.string().required(),
        timeDimension: yup.string().required(),
        timeDimensionLevel: yup.string().required(),
        timeDimensionCompareLevel: yup.string().required(),
        timeDimensionExpandLevel: yup.string().required(),
        periodicity: yup.string().required(),
        periodicityTimeDimension: yup.string().required(),
      }),
    })
  ),
  COMPARATIVE_CATEGORIES_CHART: bngYup((yup) =>
    assistedAnalysisBaseSchema.shape({
      params: yup.object({
        measure: yup.string().required(),
        dimension: yup.string().required(),
        periodicity: yup.string(),
        periodicityTimeDimension: yup.string(),
      }),
    })
  ),
  TABLE_TOTAL_TWO_LEVELS: bngYup((yup) =>
    assistedAnalysisBaseSchema.shape({
      params: yup.object({
        measure: yup.string().required(),
        groupingDimension: yup.string().required(),
        periodicity: yup.string(),
        periodicityTimeDimension: yup.string(),
      }),
    })
  ),
  RADAR_GRAPHIC: bngYup((yup) =>
    assistedAnalysisBaseSchema.shape({
      params: yup.object({
        measures: yup.array(yup.string().required()),
        dimension: yup.string().required(),
        periodicity: yup.string(),
        periodicityTimeDimension: yup.string(),
      }),
    })
  ),
  HEAT_ANALYSIS: bngYup((yup) =>
    assistedAnalysisBaseSchema.shape({
      params: yup.object({
        measure: yup.string().required(),
        columnDimension: yup.string().required(),
        rowDimension: yup.string().required(),
        periodicity: yup.string(),
        periodicityTimeDimension: yup.string(),
        colorPalette: yup.string(),
      }),
    })
  ),
  PARTICIPATION_GRAPHIC: bngYup((yup) =>
    assistedAnalysisBaseSchema.shape({
      params: yup.object({
        measure: yup.string().required(),
        dimension: yup.string().required(),
        number: yup.number().integer().required(),
        periodicity: yup.string(),
        periodicityTimeDimension: yup.string(),
      }),
    })
  ),
  PERIOD_VARIATION: bngYup((yup) =>
    assistedAnalysisBaseSchema.shape({
      params: yup.object({
        measure: yup.string().required(),
        periodicity: yup.string().required(),
        timeDimension: yup.string().required(),
        timeDimensionDataHierarchy: yup.string().required(),
        dimension: yup.string().required(),
      }),
    })
  ),
  MOVING_AVERAGE: bngYup((yup) =>
    assistedAnalysisBaseSchema.shape({
      params: yup.object({
        measure: yup.string().required(),
        timeDimension: yup.string().required(),
        timeDimensionDataHierarchy: yup.string().required(),
        number: yup.number().integer().required(),
        periodicity: yup.string().required(),
      }),
    })
  ),
  INTERACTIVE_CHART: bngYup((yup) =>
    assistedAnalysisBaseSchema.shape({
      params: yup.object({
        measure: yup.string().required(),
        dimensions: yup.array(
          yup.object({
            dimension: yup.string().required(),
            filterId: yup.number().positive().integer().nullable(),
          })
        ),
        filters: yup.array(
          yup.object({
            dimension: yup.string().required(),
            members: yup.array(yup.string().required()),
          })
        ),
      }),
    })
  ),
  COLOR_COMPARISON_GRAPHIC: bngYup((yup) =>
    assistedAnalysisBaseSchema.shape({
      params: yup.object({
        measure: yup.string().required(),
        comparisonMeasure: yup.string().required(),
        dimension: yup.string().required(),
      }),
    })
  ),
  BUBBLE_CHART: bngYup((yup) =>
    assistedAnalysisBaseSchema.shape({
      params: yup.object({
        measureX: yup.string().required(),
        measureY: yup.string().required(),
        measureDepth: yup.string().nullable().optional(),
        dimension: yup.string().required(),
      }),
    })
  ),
  TREE_MAP: bngYup((yup) =>
    assistedAnalysisBaseSchema.shape({
      params: yup.object({
        measure: yup.string().required(),
        dimensions: yup.array(
          yup.object({
            dimension: yup.string().required(),
          })
        ),
      }),
    })
  ),
};

ValidationSchemas.WORST_RANKING = ValidationSchemas.BEST_RANKING;

export default {
  ...assistedObjectService,
  findAssistedTypes: async ({ embeddedOpts }) => {
    const types = await Api.AssistedAnalysis.findTypes();
    return embeddedOpts?.filterTypes?.(types) ?? types;
  },
  createObject: async (values, { embeddedOpts }) => {
    const redirectAfterCreate = _.isNil(embeddedOpts);
    const newObject = await Api.AssistedAnalysis.createNewAnalysis(values, redirectAfterCreate);

    // Embedded on Dashboard
    if (!redirectAfterCreate) {
      await Api.Dash.addItems({ paths: [newObject.path], viewType: embeddedOpts.viewType });
      await Api.updateJsf();
      embeddedOpts.afterCreate?.({ newObject });
    }

    return newObject;
  },
  updateObject: (values) => {
    // if contains path request must have come from AssistedAnalysisEditMenu.js
    if (values.path) {
      return Api.AssistedAnalysis.update(values);
    } else {
      return Api.AssistedAnalysis.applyChanges(values);
    }
  },
  findPreviewImages: (selectedType) => Images[selectedType.type],
  findValidationSchema: (selectedType, { embeddedOpts }) => {
    const validationSchema = ValidationSchemas[selectedType.type];
    if (_.isNil(embeddedOpts)) {
      return validationSchema;
    }

    return bngYup((yup) => {
      return validationSchema.concat(
        yup.object({
          params: yup.object({
            folder: yup.string().required().ensure().default(''),
            name: yup.string().required().min(1).max(100).trim().ensure().validObjectName().default(''),
          }),
        })
      );
    });
  },
  findParams: (selectedType, additionalOpts = {}) => {
    if (additionalOpts?.embeddedOpts?.showFolderOpts) {
      return [
        { type: 'Folder', name: 'folder', label: 'folder' },
        {
          type: 'String',
          name: 'name',
          label: 'name',
        },
        ...selectedType.params,
      ];
    }

    return selectedType.params;
  },
};
