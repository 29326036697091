import React, {useCallback, useState} from 'react';
import Api from "components/Api";
import UiMsg from "components/ui/UiMsg";
import useEventBus from "../useEventBus";
import { INVALIDATE_CREDENTIALS_INTEGRATION } from "../../ui/in-memory/bim-integration/BimIntegrationOrigins";

const useValidateCredentials = ({name = null, authType = {}, context, initialCredentialsValid = false, fromScheduler = false}) => {
    const [isCredentialsValid, setValid] = useState(initialCredentialsValid);

    const invalidateCredentials = useCallback(() => {
        setValid(false)
        Api.BimIntegration.invalidateCredentials()
    }, []);

    const validateCredentials = useCallback(async (values) => {
        if (!name) {
            return
        }
        const config = {
            params: {
                ...values,
                authName: authType.name,
                fields: authType.fields,
                integration: name,
                fromScheduler,
            },
            fromScheduler
        }

        try {
            const isValid = await Api.BimIntegration.validateCredentials(config);
            if (isValid) {
                UiMsg.ok(context.msg.t('api4com.origin.token.isvalid'))
            } else {
                UiMsg.error(context.msg.t('api4com.origin.token.invalid'))
            }
            setValid(isValid);
        } catch (e) {
            UiMsg.error(context.msg.t('api4com.origin.token.invalid'))
            setValid(false);
        }
    }, [name, authType, context])

    useEventBus(INVALIDATE_CREDENTIALS_INTEGRATION, invalidateCredentials);

    return [validateCredentials, isCredentialsValid, invalidateCredentials]
};

export default useValidateCredentials;
