import styles from './BngExportOptionsTab.module.css';

import React, { useEffect } from 'react';
import { Field, useFormikContext } from 'formik';

import bngYup from 'components/bng/form/yup/BngYup';
import BngField from 'components/bng/form/BngField';
import BngButtonGroup from 'components/bng/ui/Buttons/BngButtonGroup';
import Utils from 'components/Utils';
import useBimContext from 'components/hooks/useBimContext';
import BngEmpty from 'components/bng/ui/BngEmpty';
import Icon from 'components/ui/common/Icon';
import BngSelect from 'components/bng/form/BngSelect';
import HelpIcon from 'components/ui/common/HelpIcon';

export const BngExportOptionsTabSchema = bngYup((yup) => {
  return yup.object().shape({
    defaultExportType: yup.string(),
    joinObjects: yup.boolean().default(false),
    sendReferences: yup.boolean().default(true),
    sendAttachments: yup.boolean().default(true),
    errorValidation: yup.object().shape({
      alertErrors: yup.boolean().default(false),
      alertStructureErrors: yup.boolean().default(false),
    }),
  });
});

const exportTypeDropdownProps = ({
  enableExcelExport = true,
  objects = [],
  context,
  formik,
  isAnyUserWhatsapp = true,
}) => {
  return {
    title: context.msg.t('Share.objects.changeOrder.label'),
    overDialog: true,
    customOptions: ({ closeDropdown }) => {
      return (
        <div className={`${styles.exportDropdownWrapper}`}>
          <div className={`${styles.exportDropdownHeader}`}>
            <span>{context.msg.t('analytical.objects')}</span>
          </div>
          <div className={`ObjectsExportDropdown ${styles.exportDropdownBody}`}>
            <BngEmpty isEmpty={_.isEmpty(objects)} className={`${styles.exportBngEmpty}`} showMessage={false}>
              {objects.map((item, idx) => {
                let itemExportTypeOpts = Object.values(Utils.Scheduling.EXPORT_TYPE);

                if (!enableExcelExport || !Utils.Object.isAnalysis(item.path)) {
                  itemExportTypeOpts = itemExportTypeOpts.filter((type) => type !== Utils.Scheduling.EXPORT_TYPE.EXCEL);
                }

                if (!Utils.Object.isBigTable(item.path)) {
                  itemExportTypeOpts = itemExportTypeOpts.filter((type) => type !== Utils.Scheduling.EXPORT_TYPE.CSV);
                }

                if (!isAnyUserWhatsapp) {
                  itemExportTypeOpts = itemExportTypeOpts.filter((type) => type !== Utils.Scheduling.EXPORT_TYPE.PNG);
                }

                return (
                  <div className={`${styles.exportDropdownObjects}`} key={`obj_${idx}_${item.name}`}>
                    <Icon
                      icon={Utils.Object.getObjectIcon(
                        item.path,
                        Utils.Object.isAnalysis(item.path) ? 'showChart' : ''
                      )}
                    />
                    <span className={`${styles.exportDropdownObjectsLabel}`}>{item.name}</span>
                    <div className={`${styles.exportToDropdownObjectsSelect}`}>
                      <BngSelect
                        value={item.exportType}
                        options={itemExportTypeOpts}
                        emptyOption={false}
                        onChange={(e) => {
                          formik.setFieldValue('resources', {
                            ...formik.values.resources,
                            contents: formik.values.resources.contents.map((obj) => {
                              return obj.path === item.path
                                ? {
                                    ...obj,
                                    exportType: e.target.value,
                                  }
                                : obj;
                            }),
                          });
                        }}
                      />
                    </div>
                  </div>
                );
              })}
            </BngEmpty>
          </div>
          <div className={`${styles.exportDropdownFooter}`} />
        </div>
      );
    },
  };
};

export default function BngExportOptionsTab({}) {
  const context = useBimContext();
  const formik = useFormikContext();

  const isNotPdf = formik.values.defaultExportType !== Utils.Scheduling.EXPORT_TYPE.PDF;
  const isCockpit = formik.values.resources.type === Utils.Scheduling.TYPE.COCKPIT;
  const joinObjectsDisabled = isNotPdf || isCockpit;

  useEffect(() => {
    if (joinObjectsDisabled && formik.values.joinObjects) {
      formik.setFieldValue('joinObjects', false);
    }
    if (
      formik.values.resources.type === Utils.Scheduling.TYPE.COCKPIT &&
      formik.values.defaultExportType !== Utils.Scheduling.EXPORT_TYPE.PDF
    ) {
      formik.setFieldValue('defaultExportType', Utils.Scheduling.EXPORT_TYPE.PDF);
    }
  }, [formik.values.defaultExportType, formik.values.resources.type]);

  const changeObjectsExportType = (type) => {
    if (!_.isEmpty(formik.values.resources.contents)) {
      const objArr = {
        ...formik.values.resources,
        contents: formik.values.resources.contents.map((obj) => {
          return { ...obj, exportType: type };
        }),
      };
      formik.setFieldValue('resources', objArr);
    }
  };

  const isAnyUserWhatsapp =
    formik.values.sendTo && formik.values.sendTo.length >= 1
      ? formik.values.sendTo.some((user) => user.deliveryType === Utils.Scheduling.DELIVERY_TYPE.WHATSAPP)
      : formik.values.messageType === Utils.Scheduling.DELIVERY_TYPE.WHATSAPP;

  const isEveryUserMobile =
    formik.values.sendTo && formik.values.sendTo.length >= 1
      ? formik.values.sendTo.every((user) => user.deliveryType !== Utils.Scheduling.DELIVERY_TYPE.EMAIL)
      : formik.values.messageType !== Utils.Scheduling.DELIVERY_TYPE.EMAIL;

  const isCockpitExport = formik.values.resources.type !== Utils.Scheduling.TYPE.OBJECT;
  const exportTypeGroupOpt = [
    {
      value: Utils.Scheduling.EXPORT_TYPE.PDF,
      label: context.msg.t('reportsProperties_popup_tab_print'),
      icon: 'picture_as_pdf',
      onClick: () => changeObjectsExportType(Utils.Scheduling.EXPORT_TYPE.PDF),
    },
    {
      value: Utils.Scheduling.EXPORT_TYPE.PNG,
      label: context.msg.t('image'),
      disabled: isCockpitExport || !isAnyUserWhatsapp,
      title: isCockpitExport
        ? context.msg.t('Share.options.notAvailableForCockpit')
        : isAnyUserWhatsapp
        ? context.msg.t('PNG_EXPORT_HELP')
        : undefined,
      icon: 'collections',
      onClick: () => changeObjectsExportType(Utils.Scheduling.EXPORT_TYPE.PNG),
    },
    {
      value: 'custom',
      label: context.msg.t('personalizado'),
      disabled: isCockpitExport,
      title: isCockpitExport
        ? context.msg.t('Share.options.notAvailableForCockpit')
        : isAnyUserWhatsapp
        ? context.msg.t('PNG_EXPORT_HELP')
        : undefined,
      icon: 'edit',
      dropdownProps: exportTypeDropdownProps({
        enableExcelExport: context.project.features.enableExcelExport,
        formik,
        context,
        objects: formik?.values?.resources?.contents,
        isAnyUserWhatsapp,
      }),
    },
  ];

  const updateLinksAttachments = (sendReferences, sendAttachments) => {
    formik.setFieldValue('sendReferences', sendReferences);
    formik.setFieldValue('sendAttachments', sendAttachments);
  };

  const linksAttachmentsGroupOpt = [
    {
      label: context.msg.t('Share.options.sendAttachmentsAndLinks'),
      title: isEveryUserMobile
        ? context.msg.t('Share.options.sendAttachmentsAndLinks.whats.warning')
        : context.msg.t('Share.options.sendAttachmentsAndLinks.title'),
      icon: 'add_link',
      onClick: () => updateLinksAttachments(true, true),
      selected: formik.values.sendReferences && formik.values.sendAttachments,
      disabled: isEveryUserMobile,
    },
    {
      label: context.msg.t('add.links'),
      title: isEveryUserMobile
        ? context.msg.t('Share.options.sendAttachmentsAndLinks.whats.warning')
        : context.msg.t('Share.options.sendLinks.title'),
      icon: 'insert_link',
      onClick: () => updateLinksAttachments(true, false),
      selected: formik.values.sendReferences && !formik.values.sendAttachments,
      disabled: isEveryUserMobile,
    },
    {
      label: context.msg.t('Share.options.sendAttachments'),
      title: context.msg.t('Share.options.sendAttachments.title'),
      icon: 'attach_file',
      onClick: () => updateLinksAttachments(false, true),
      selected: !formik.values.sendReferences && formik.values.sendAttachments,
    },
  ];

  const joinObjectOpt = [
    {
      value: false,
      label: context.msg.t('Share.options.separateFiles'),
      title: context.msg.t('Share.options.separateFiles.title'),
      icon: 'file_copy',
    },
    {
      value: true,
      label: context.msg.t('Share.options.joinObjects'),
      title: joinObjectsDisabled
        ? isCockpit
          ? context.msg.t('Share.options.notAvailableForCockpit')
          : isNotPdf
          ? context.msg.t('Share.options.notAvailableForNotPdf')
          : ''
        : context.msg.t('Share.options.joinObjects.title'),
      icon: 'insert_drive_file',
      disabled: joinObjectsDisabled,
    },
  ];

  const errorValidationGroupOpt = [
    {
      name: 'alertErrors',
      label: context.msg.t('Share.options.alertErrors'),
      title: context.msg.t('Share.options.alertErrors.title'),
      icon: 'equalizer',
    },
    {
      name: 'alertStructureErrors',
      label: context.msg.t('Share.options.alertStructureErrors'),
      title: context.msg.t('Share.options.alertStructureErrors.title'),
      icon: 'cloud',
    },
  ];

  return (
    <div className={`OptionsTab ${styles.optionsTabWrapper}`}>
      <Field
        name={`defaultExportType`}
        className={`${styles.optionsTypeButtons}`}
        label={context.msg.t('Share.options.exportType')}
        labelClassName={`${styles.optionsFieldLabel}`}
        component={BngField}
        showErrors={false}
        inputComponent={BngButtonGroup}
        selectedIndicator={true}
        options={exportTypeGroupOpt}
      />
      <div className={`${styles.sendAttachmentsAndLinksContainer}`}>
        <label className={`${styles.optionsFieldLabel} ${styles.sendAttachmentsAndLinksTip}`}>
          {context.msg.t('Share.options.sendAttachmentsAndLinks')}
        </label>
        <HelpIcon title={context.msg.t('Share.options.sendAttachmentsAndLinks.tip')} />
      </div>
      <BngButtonGroup
        className={`${styles.optionsTypeButtons}`}
        selectedIndicator={true}
        options={linksAttachmentsGroupOpt}
      />
      <Field
        name={`joinObjects`}
        className={`${styles.optionsTypeButtons}`}
        label={context.msg.t('Share.options.filesOptions')}
        labelClassName={`${styles.optionsFieldLabel}`}
        component={BngField}
        showErrors={false}
        inputComponent={BngButtonGroup}
        selectedIndicator={true}
        options={joinObjectOpt}
      />
      <Field
        name={`errorValidation`}
        className={`${styles.optionsTypeButtons}`}
        label={context.msg.t('Share.options.errorValidation')}
        labelClassName={`${styles.optionsFieldLabel}`}
        component={BngField}
        showErrors={false}
        inputComponent={BngButtonGroup}
        selectedIndicator={true}
        checkbox={true}
        options={errorValidationGroupOpt}
      />
    </div>
  );
}
