import './ConnectionsDialog.css';
import React, { useEffect, useMemo, useState } from 'react';

import Dialog from 'components/ui/Dialog';
import { BngTable } from 'components/bng/ui/BngTable';
import { BngDropdown } from 'components/bng/ui/BngDropdown';
import { MODALS } from 'components/ui/redux/Actions';
import FormConnectionDialog from 'components/ui/in-memory/FormConnectionDialog';
import Api from 'components/Api';
import UiMsg from 'components/ui/UiMsg';
import BngSearch from 'components/bng/ui/BngSearch';
import OpConfirmation from 'components/ui/OpConfirmation';
import Icon from 'components/ui/common/Icon';
import useBimContext from 'components/hooks/useBimContext';
import useReduxDispatch from 'components/hooks/useReduxDispatch';

export const fetchDatabases = async (setLoading = _.noop) => {
  let databases = [];
  try {
    setLoading(true);
    databases = await Api.Connection.getDatabaseList();
  } catch (e) {
    console.error(e);
    UiMsg.error('', e);
  } finally {
    setLoading(false);
  }
  return databases;
};

export default function ConnectionsDialog({
  dialogComponent = Dialog,
  closeModal = _.noop,
  forReplication = false,
  onClose = _.noop,
}) {
  const context = useBimContext();
  const dispatch = useReduxDispatch();

  const [connections, setConnections] = useState([]);
  const [databases, setDatabases] = useState([]);
  const [loading, setLoading] = useState(false);
  const [search, setSearch] = useState('');

  const globalConnections = _.isEmpty(context.project);

  useEffect(() => {
    setLoading(true);
    if (globalConnections && !context.user.superUser) {
      console.error(`You don't have permission to change global connections`);
      closeModal();
    }
    fetchConnections();
    fetchDatabaseOpts();
    setLoading(false);
  }, []);

  const fetchDatabaseOpts = async () => {
    const databases = await fetchDatabases();
    setDatabases(databases);
  };

  const fetchConnections = async () => {
    try {
      const projectId = context?.project?.id;
      const data = await Api.Connection.findAll(projectId, _.isNil(projectId));
      const connectionList = data.map((connection) => ({
        ...connection,
        database: connections.type?.name,
      }));
      setConnections(connectionList);
    } catch (e) {
      console.error(e);
      UiMsg.error('', e);
    }
  };

  const removeItem = (row) => {
    OpConfirmation({
      title: context.msg.t('attention'),
      message: context.msg.t('delete.confirmation.message'),
      onConfirm: () => deleteConnection(row),
      msg: context.msg,
    });
  };

  const deleteConnection = async (row) => {
    try {
      await Api.Connection.deleteConnection(row.id || 0, row.name);
      await fetchConnections();
      UiMsg.ok('', context.msg.t('connection.remove.success'));
    } catch (e) {
      console.error(`Error on deleteConnection(id=[${row.id}])`, e);
      UiMsg.error('', context.msg.t('connection.remove.error'));
    }
  };

  const buildDropdownOpts = (row) => {
    const result = [{ icon: 'edit', label: context.msg.t('edit'), onClick: () => openFormConnectionDialog(row) }];
    result.push({ icon: 'remove', label: context.msg.t('remove'), onClick: () => removeItem(row) });
    return result;
  };

  const cols = [
    { label: context.msg.t('id'), render: (row) => row.id, size: '5%' },
    { label: context.msg.t('name'), render: (row) => row.name, size: '20%' },
    { label: context.msg.t('url'), render: (row) => row.url, size: '40%' },
    { label: context.msg.t('database.type'), render: (row) => row.database, size: '25%' },
    {
      label: context.msg.t('actions'),
      render: (row) => <BngDropdown options={buildDropdownOpts(row)} overDialog />,
      size: '10%',
    },
  ];

  const openFormConnectionDialog = (row) => {
    dispatch(
      MODALS.open(FormConnectionDialog, {
        connectionId: row?.id,
        fetchConnections,
        databaseList: databases,
        globalConnection: globalConnections,
        forReplication,
        connectionData: row,
      })
    );
  };

  const filteredConnections = useMemo(
    () =>
      !search
        ? connections
        : connections.filter((item) => {
            const name = item.name.toUpperCase();
            const upperSearch = search.toUpperCase();
            const database = (item.database ?? item.type?.name ?? '').toUpperCase();
            return name.includes(upperSearch) || database.includes(upperSearch);
          }),
    [search, connections]
  );

  const DialogComponent = dialogComponent;
  return (
    <DialogComponent
      title={context.msg.t('connection.dialog.list')}
      className="ConnectionsDialog xlarge"
      onClose={() => {
        onClose?.();
        closeModal();
      }}
      contentFullWidth={true}
      loading={loading}
    >
      <div
        className={'groups-header'}
        onKeyDown={(event) => {
          if (event.key === 'Enter') {
            event.preventDefault();
            event.stopPropagation();
          }
        }}
      >
        <div className="new-connection-btn" onClick={() => openFormConnectionDialog()}>
          <Icon icon="add_circle" />
          <div style={{ padding: '0 5px', width: '135px' }}>{context.msg.t('new.connection')}</div>
        </div>
        <BngSearch alwaysOpen={false} className="ConnectionSearch" onChange={(search) => setSearch(search ?? '')} />
      </div>
      <div className={'conection-dialog-division top'} />

      <Dialog.Body>
        <BngTable className="ConnectionsList" rows={filteredConnections} cols={cols} />
      </Dialog.Body>
    </DialogComponent>
  );
}
