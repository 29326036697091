import styles from './MarketplacePage.module.css';

import React, { useMemo } from 'react';
import { useOutletContext, useSearchParams } from 'react-router-dom';

import UiBlocker from 'components/bng/ui/UiBlocker';
import Api from 'components/Api';
import useBimContext from 'components/hooks/useBimContext';
import { ACC_QUERY_PARAM, NavPanel } from 'components/ui/accounts/AccountsPage';
import MktHomeTab from 'components/bng/pages/marketplace/tabs/MktHomeTab';
import MktPlanTab from 'components/bng/pages/marketplace/tabs/MktPlanTab';
import MktAdditionalsTab from 'components/bng/pages/marketplace/tabs/MktAdditionalsTab';
import MktAddonsTab from 'components/bng/pages/marketplace/tabs/MktAddonsTab';
import MktServicesTab from 'components/bng/pages/marketplace/tabs/MktServicesTab';
import MktTemplatesTab from 'components/bng/pages/marketplace/tabs/MktTemplatesTab';
import MktEducationalTab from 'components/bng/pages/marketplace/tabs/MktEducationalTab';
import useFetchData from 'components/hooks/useFetchData';
import Utils from 'components/Utils';
import useBimNavigate from 'components/hooks/useBimNavigate';
import { CheckoutPage } from 'components/bng/pages/marketplace/Checkout';
import { MarketplaceItemTab } from 'components/bng/pages/marketplace/MarketplaceItemPage';
import UiMsg from 'components/ui/UiMsg';

export const MARKETPLACE_GROUPS = {
  BRAND: 'BRAND',
  SECURITY: 'SECURITY',
  MANAGEMENT: 'MANAGEMENT',
  TEMPLATE: 'TEMPLATE',
};

const MARKETPLACE_TABS = [
  {
    key: 'marketplace',
    component: MktHomeTab,
    icon: 'storefront',
    betaTag: true,
  },
  {
    key: 'plans',
    component: MktPlanTab,
    icon: 'checklist',
  },
  {
    key: 'additionals',
    component: MktAdditionalsTab,
    icon: 'add_business',
  },
  {
    key: 'addons',
    component: MktAddonsTab,
    icon: 'extension',
    children: [
      { title: 'tabNav.brand', anchorId: `MarketplacePage-BRAND` },
      { title: 'tabNav.security', anchorId: `MarketplacePage-SECURITY` },
      { title: 'tabNav.manage', anchorId: `MarketplacePage-MANAGEMENT` },
    ],
  },
  {
    key: 'services',
    component: MktServicesTab,
    icon: 'business_center',
  },
  {
    key: 'templates',
    component: MktTemplatesTab,
    icon: 'widgets',
  },
  {
    key: 'educational',
    component: MktEducationalTab,
    icon: 'school',
  },
  {
    key: 'checkout',
    component: CheckoutPage,
    hide: true,
  },
  {
    key: 'item',
    component: MarketplaceItemTab,
    hide: true,
  },
];

export default function MarketplacePage() {
  const context = useBimContext();
  const { selectedItems, selectCard, onClearSelection } = useOutletContext();

  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useBimNavigate();

  const currentAccountId =
    (searchParams.has(ACC_QUERY_PARAM) ? _.parseInt(searchParams.get(ACC_QUERY_PARAM)) : null) ?? context.accountId;

  const { data: availableAccounts = [], isLoading: availableAccountsLoading } = useFetchData(async () => {
    try {
      const accounts = await Api.Account.findAvailable({ includeRelated: true, activeForBilling: true });

      if (accounts.length === 0) {
        navigate('/errors/403');
        return [];
      }

      const accountOpts = accounts.map((account) => {
        return {
          value: account.id,
          label: account.name,
          isMaster: account.accountMasterId === context.user.id,
          account,
        };
      });

      accountOpts.sort((a, b) => {
        return Utils.Strings.compareIgnoreCase(a.label, b.label);
      });

      return accountOpts;
    } catch (e) {
      console.error('Error on fetch available accounts', e);
      UiMsg.error(context.msg.t('no.account.info'));
    }
  });

  const { data: account, isLoading: accountLoading, reload } = useFetchData(async ({ accountId = null }) => {
    try {
      return await Api.Account.findAccount(accountId ?? currentAccountId, { publicDataOnly: true });
    } catch (e) {
      console.error('Error on fetch account', e);
      UiMsg.error(context.msg.t('no.account.info'));
    }
  });

  const {
    data: marketplaceItems = {},
    isLoading: marketplaceItemsLoading,
    reload: reloadMarketplaceItems,
  } = useFetchData(async () => {
    if (!currentAccountId) {
      return [];
    }
    return await Api.Marketplace.fetchAll(currentAccountId);
  }, [currentAccountId]);

  const selectAccount = async (accountId) => {
    searchParams.set(ACC_QUERY_PARAM, accountId);
    setSearchParams(searchParams);
    onClearSelection();
    await reload({ accountId });
  };

  const isMasterOfCurrentAccount = useMemo(() => {
    return availableAccounts.find((acc) => acc.value === currentAccountId)?.isMaster;
  }, [account]);

  return (
    <UiBlocker
      block={marketplaceItemsLoading || !account || accountLoading}
      className={`MarketplacePage ${styles.MarketplacePageBlocker}`}
    >
      {account && !marketplaceItemsLoading && (
        <NavPanel
          account={account}
          accountOpts={availableAccounts}
          onSelectAccount={selectAccount}
          tabs={MARKETPLACE_TABS}
          defaultTab={MARKETPLACE_TABS[0]}
          accountsLoading={availableAccountsLoading}
          tabProps={{
            marketplaceItems: marketplaceItems,
            selectCard: selectCard,
            selectedItems: selectedItems,
            accountId: currentAccountId,
            isMasterOfCurrentAccount: isMasterOfCurrentAccount,
            reloadMarketplaceItems,
          }}
        />
      )}
    </UiBlocker>
  );
}
