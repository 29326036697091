import './ObjectSearch.css';

import React from 'react';
import PropTypes from 'prop-types';
import validator from 'validator';

import ContextEnhancer from 'components/ContextEnhancer';
import Toggleable from 'components/ui/Toggleable';
import Icon from 'components/ui/common/Icon';
import Utils from 'components/Utils';
import Api from 'components/Api';

class ObjectSearch extends Toggleable {

    static propTypes = {
        items: PropTypes.arrayOf(
            PropTypes.shape({
                path: PropTypes.string.isRequired,
                url: PropTypes.string.isRequired,
                icon: PropTypes.string.isRequired,
                displayName: PropTypes.string.isRequired
            })
        )
    };

    static defaultProps = {
        items: [],
        onToggle: _.noop,
        searching: false,
    };

    state = {
        searchTerm: '',
        open: false,
        over: false
    };

    constructor(props) {
        super(props);
    }

    componentDidMount() {
        j(window).on('resize',
            () => this.forceUpdate()
        );
        super.componentDidMount();
    }

    componentWillUnmount() {
        super.componentWillUnmount();
    }

    clickedOutOfDropdown = () => {
        if (!this.isOver() && this.isOpen() && !_.isEmpty(this.state.searchTerm)) {
            this.onToggle();
        }
    };

    logoSize(){
        const logoSize = j(document.getElementById("logo-project")).outerWidth();
        //logo do menu superior ainda não montados
        return  logoSize === null ? 180 : logoSize ;
    }

    searchSize() {
        let size = this.logoSize() + 82; //logo e margins
        //componentes do menu superior ainda não montados
        const navItens = j('.navbar-inner').find('li.nav-bar-button');
        if(navItens.length > 0) {
            navItens.each(function () {
                size += j(this).outerWidth();
            });
        }
        else {
            size += 480;
        }
        const searchSize = j(window).width() - size;
        return searchSize;
    }

    typeaheadSize() {
        return this.searchSize() + 28;
    }

    splitFolder(path) {
        let split = path.split('/');
        split = split.slice(2, split.length - 1);
        if (validator.isInt(split[0])) {
            split[0] = "Pasta pessoal";
        }
        return split;
    };

    displayResultsNotFound(){
        if(j('.typeahead.dropdown-menu').css('display') === undefined || j('.typeahead.dropdown-menu').css('display') === "none"){
            j('#search-not-found').css({'display': 'block'})
        }
        else {
            j('#search-not-found').css({'display': 'none'})
        }
    }

    initializeTypeahead(el) {
        if (!el) return;

        const extractIdx = str => str.split('|-')[0];
        let lastFolder = "";
        jQuery(el).typeahead({
            source: (query, process) => {
                process(this.props.items.map((item, idx) => {
                    return idx + "|-" + item.path + item.displayName
                }));
                this.displayResultsNotFound();
                lastFolder = "";
            },
            updater: (str) => {
                const item = this.props.items[extractIdx(str)];
                const url = Api.buildUrl('/load.iface', {
                    content: item.path
                });
                window.location.replace(url);
                return item.displayName;
            },
            highlighter: (str) => {
                const item = this.props.items[extractIdx(str)];

                let folder = '/' + this.splitFolder(item.path).join('/');

                if (folder === lastFolder) {
                    folder = null
                }
                let result = createResultItem(item, folder);
                if (folder) {
                    lastFolder = folder;
                }

                return result;
            },
            items: 30,
            highlight: true,
        });
    }

    initializeItems = (e) => {
        if (this.props.items.length === 0 && !this.__initializeItems) {
            this.__initializeItems = true;
            this.props.fetchMenuItems();
        }
        if (e) {
            this.setState({searchTerm: e.target.value});
        }
    };

    onToggle = () => {
        this.props.onToggle();
        this.isOpen() ? this.close() : this.open();
        this.setState({searchTerm: ''});
    };

    render() {
        const searchSize = this.searchSize();
        const searchResultSize = searchSize - 28;

        const stylesheet = (
            <style type="text/css" dangerouslySetInnerHTML={{
                __html: `
        ul.typeahead.dropdown-menu { width: ${searchSize}px; left: ${this.logoSize()}px !important; }
        .nav-search-input-box { width: ${searchSize}px;}
        .nav-search-new input[type="text"]{ width: ${searchSize - 115}px;}
        .SearchResultDiv { width: ${searchResultSize}px;}
        `
            }}/>
        );

        return (

            <li className="nav-search-new-item">
                {stylesheet}
                <div>
                    <div className={`nav-search-new ${this.props.searching ? 'expanded' : ''}`} onMouseDown={this.over} onMouseUp={this.out}>
                        <div className={`nav-search-input-box ${this.props.searching ? '' : 'hidden-bottom'}`}>
                            <Icon icon="search"
                                  className="nav-search-button-on-input"
                                  onClick={this.onToggle}
                            />
                            <input type="text" placeholder={this.props.context.msg.t('header.search')}
                                   onChange={this.initializeItems}
                                   className="nav-search-input-new"
                                   ref={el => {
                                       if (!el) return;
                                       this.initializeTypeahead(el);
                                       el.focus();
                                   }}/>
                            <div className="nav-search-button-on-input-right-box">
                                <span className="nav-search-advanced-link"
                                       onClick={async () => {
                                           await Api.Bng.accessObjectSearch();
                                           const redirectUrl = Api.buildUrl('/objects-map.iface');
                                           window.location.replace(redirectUrl);
                                       }}>
                                    {this.props.context.msg.t('advanced.search')}
                                </span>
                            </div>
                        </div>
                        <Icon icon={this.props.searching ? 'close' : 'search'}
                              className="nav-search-button"
                              onClick={this.onToggle}
                        />
                    </div>
                    <div className={`nav-search-input-new-line ${this.props.searching ? '' : 'hidden'}`}></div>
                    {(this.props.searching && !_.isEmpty(this.state.searchTerm)) &&
                        <div className={`SearchResultDiv`}>{this.props.context.msg.t('results.search')}
                            {
                                <div id="search-not-found" style={{marginTop: 15, display: 'none'}}>{this.props.context.msg.t('results.search.not.found')}</div>
                            }
                        </div>
                    }
                </div>
            </li>

        );
    }
}

const createResultItem = (item, folder) => {
    folder = folder ? `<div class="search-item-folder"><i class="Icon notranslate material-icons favorite-icon">folder</i>${folder} </div>` : "";
    const generateIcon = (icon) => {
        if (icon === Utils.Object.getObjectIcon('bigtable')) {
            return `<i class="${icon} favorite-icon"></i>`;
        } else {
            return `<i class="Icon notranslate material-icons">${icon}</i>`;
        }
    }

    return (`
        <div class="search-item-result truncate-text">
            ${folder}
            <div class="search-item-name">
                <div class="search-item-icon">
                    ${generateIcon(item.icon)}
                </div>
                <span class="typeaheadtext d-l-m">
                    ${item.displayName}
                </span>
            </div>
        </div>
    `);
};

export default ContextEnhancer(ObjectSearch);