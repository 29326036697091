import './BimIntegrationOrigin.css';

import React, { useCallback, useEffect, useState } from 'react';
import isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';
import { Field, FieldArray, Formik, useFormikContext } from 'formik';

import { BngSelectSearch } from 'components/bng/form/BngSelectSearch';
import { BngField } from 'components/bng/form/BngField';
import ContextEnhancer from 'components/ContextEnhancer';
import { BngForm } from 'components/bng/form/BngForm';
import { bngYupLang } from 'components/bng/form/yup/BngYup';
import useBimIntegrationEndpoint from 'components/hooks/bim-integration/useBimIntegrationEndpoint';
import Button from 'components/ui/Button';
import useValidateCredentials from 'components/hooks/bim-integration/useValidateCredentials';
import useRoutePathTranslator from 'components/hooks/bim-integration/useRoutePathTranslator';
import useBuildRouteAuthFields from 'components/hooks/bim-integration/useBuildRouteAuthFields';
import Api from 'components/Api';
import { FormikListener } from 'components/bng/form/formik/FormikListener';
import BngCheckbox from 'components/bng/form/BngCheckbox';
import { BngMaskedField } from 'components/bng/form/BngMaskedField';
import HelpIcon from 'components/ui/common/HelpIcon';
import StructuresPageUtils, {
  EVENT as OAUTH_CALLBACK,
} from 'components/bng/pages/admin/structures/StructuresPageUtils';
import useEventBus from 'components/hooks/useEventBus';
import { BngAddButton } from 'components/bng/ui/BngAddButton';
import { BngTable } from 'components/bng/ui/BngTable';
import BngInput from 'components/bng/form/BngInput';
import { BngIconButton } from 'components/bng/ui/BngIconButton';
import useBimContext from 'components/hooks/useBimContext';
import UiMsg from 'components/ui/UiMsg';
import BimEventBus from 'BimEventBus';

export const LabelHelper = ({ label, helpLabel }) => {
  return (
    <>
      <span>{label}</span>{' '}
      <HelpIcon style={{ fontSize: '14px', marginLeft: '2px', verticalAlign: 'text-bottom' }} title={helpLabel} />
    </>
  );
};

const YupLang = bngYupLang((yup) => yup);

const maskType = (type) => {
  if (type === 'date_en') {
    return { format: '####-##-##', placeholder: '____-__-__', mask: '_' };
  } else if (type === 'date_br') {
    return { format: '##/##/####', placeholder: '__/__/____', mask: '_' };
  } else if (type === 'number') {
    return { format: '##########', decimalScale: 0 };
  }
  return undefined;
}

const validateCredentialsDates = async (routeQueryParams, params) => {
  const typeDateInForm = [];
  for (const itemDate of routeQueryParams) {
    if (itemDate.mask === 'date_en' || itemDate.mask === 'date_br') {
      typeDateInForm.push({ name: itemDate.name, mask: itemDate.mask });
    }
  }

  let match = true;
  for (const item of typeDateInForm) {
    if (!!params[item.name]) {
      if (item.mask === 'date_en') {
        const regexDateEn = /^([0-9]{4}[-/]?((0[13-9]|1[012])[-/]?(0[1-9]|[12][0-9]|30)|(0[13578]|1[02])[-/]?31|02[-/]?(0[1-9]|1[0-9]|2[0-8]))|([0-9]{2}(([2468][048]|[02468][48])|[13579][26])|([13579][26]|[02468][048]|0[0-9]|1[0-6])00)[-/]?02[-/]?29)$/;
        return regexDateEn.test(params[item.name]);
      } else if (item.mask === 'date_br') {
        const regexDateBr = /^(((0[1-9]|[12][0-9]|30)[-/]?(0[13-9]|1[012])|31[-/]?(0[13578]|1[02])|(0[1-9]|1[0-9]|2[0-8])[-/]?02)[-/]?[0-9]{4}|29[-/]?02[-/]?([0-9]{2}(([2468][048]|[02468][48])|[13579][26])|([13579][26]|[02468][048]|0[0-9]|1[0-6])00))$/;
        return regexDateBr.test(params[item.name]);
      }
    }
  }
  return match;
};

export const INVALIDATE_CREDENTIALS_INTEGRATION = 'invalidate_credentials_integration';

function MultipleItemsInput({ form, keyLabel, valueLabel, disabled }) {
  const context = useBimContext();

  useEffect(() => {
    if (checkForDuplicates(form.values.properties)) {
      UiMsg.warn(`${context.msg.t('inMemory_duplicatedFields_Message')}`);
    }
  }, [form.values.properties]);

  keyLabel = keyLabel ?? keyLabel;
  valueLabel = valueLabel ?? context.msg.t('bim.integration.base.url');

  const propertiesColumns = [
    {
      label: keyLabel,
      render: (row, index) => {
        return <Field name={`properties.${index}.key`} component={BngInput} disabled={disabled} required />;
      },
    },
    {
      label: valueLabel,
      render: (row, index) => {
        return <Field name={`properties.${index}.value`} component={BngInput} disabled={disabled} required />;
      },
    },
    {
      label: '',
      render: (row, index, rowProps) => {
        const disabled = rowProps.form.values?.properties?.length === 1;
        return (
          <BngIconButton
            className="remove-property-button"
            icon={'delete'}
            title={context.msg.t('delete')}
            disabled={disabled}
            onClick={disabled ? undefined : () => rowProps.remove(index)}
          />
        );
      },
    },
  ];

  const checkForDuplicates = (array) => {
    if (array === undefined || array === []) return false;
    const seen = {};
    for (const item of array) {
      if (seen[item.key] && seen[item.key] === item.value) {
        return true;
      }
      seen[item.key] = item.value;
    }
    return false;
  };

  const addProperty = (arrayHelpers) => {
    arrayHelpers.push({ key: '', value: '' });
    BimEventBus.emit(INVALIDATE_CREDENTIALS_INTEGRATION);
  };

  return (
    <FieldArray
      name="properties"
      render={(arrayHelpers) => (
        <div>
          <BngAddButton className="AddProperties" disabled={disabled} onClick={() => addProperty(arrayHelpers)}>
            <span>{context.msg.t('add')}</span>
          </BngAddButton>
          <div style={{ overflowY: 'auto', width: '100%' }}>
            <BngTable cols={propertiesColumns} rows={form.values.properties} rowProps={arrayHelpers} />
          </div>
        </div>
      )}
    />
  );
}

const BimIntegrationOrigins = ({
  name,
  context,
  creating = false,
  isEditing = false,
  validCredentials = false,
  formikProps = null,
  fromScheduler = false,
  onFormParamsInfo = _.noop,
  googleConf = { clientId: '', redirectUri: '' },
  facebookConf = { clientId: '', redirectUri: '' },
  microsoftConf = { clientId: '', redirectUri: '' },
  eagleEyeConf = { clientId: '', redirectUri: '' },
}) => {
  const { values, isValid, isSubmitting, setSubmitting, setValues } = useFormikContext();

  const [optionsEndpoints, setOptionsEndpoint] = useState([]);
  const [excelWorksheetOpts, setExcelWorksheetOpts] = useState([]);
  const [routeQueryParams, setRouteQueryParams] = useState([]);
  const [prevRouteQueryParams, setPrevRouteQueryParams] = useState(null);
  const [metaAccountOpts, setMetaAccountOpts] = useState([]);
  const [formValues, setFormValues] = useState({});
  const [noneEmptyFields, setNoneEmptyFields] = useState();

  const [integrationInfo] = useBimIntegrationEndpoint(name);
  const [validateCredentialsFn, isCredentialsValid] = useValidateCredentials({
    name,
    authType: integrationInfo.authType,
    context,
    initialCredentialsValid: validCredentials,
    fromScheduler,
  });

  const [routePathParams] = useRoutePathTranslator(values.endpoint);
  const [prevRoutePathParams, setPrevRoutePathParams] = useState(null);
  const routeAuthFields = useBuildRouteAuthFields(name, integrationInfo.authType, context);

  const [loadingPreview, setLoadingPreview] = useState(false);
  const [groupedOptions, setGroupedOptions] = useState(false);

  useEffect(() => {
    validateFields();
  }, []);

  useEffect(() => {
    validateFields();
  }, [values, routeQueryParams, routePathParams]);

  useEffect(() => {
    if (prevRoutePathParams === null && routePathParams.length !== 0) setPrevRoutePathParams(routePathParams);
    if (routePathParams !== prevRoutePathParams && prevRoutePathParams !== null) {
      let tempValues = _.clone(values);
      prevRoutePathParams.forEach((item) => {
        delete tempValues[item.name];
      });
      setValues(tempValues);
      setPrevRoutePathParams(routePathParams);
    }
  }, [routePathParams]);

  useEffect(() => {
    if (prevRouteQueryParams === null && routeQueryParams.length !== 0) setPrevRouteQueryParams(routeQueryParams);
    if (routeQueryParams !== prevRouteQueryParams && prevRouteQueryParams !== null) {
      let tempValues = _.clone(values);
      prevRouteQueryParams.forEach((item) => {
        if (_.findIndex(routeQueryParams, item) === -1) delete tempValues[item.name];
      });
      setValues(tempValues);
      setPrevRouteQueryParams(routeQueryParams);
    }
  }, [routeQueryParams]);

  useEffect(() => {
    const $wizardNextButton =
      document.querySelector('.JsfWizardNextButton') || document.querySelector('.JsfWizardNextBtn');

    const blockButtonNext = () => {
      $wizardNextButton.disabled = 'disabled';
      $wizardNextButton.classList.remove('btn-next');
      $wizardNextButton.classList.add('btn-next-dis');
      $wizardNextButton.classList.remove('iceCmdBtn');
      $wizardNextButton.classList.add('iceCmdBtn-dis');
    };

    blockButtonNext();
    if (!isCredentialsValid) return;

    if (!$wizardNextButton) return;

    let emptyOption = false;

    const paramsEndpoint = integrationInfo?.endpoints?.find((item) => item.endpoint === values.endpoint);
    for (const key in values) {
      const value = values[key].toString();
      const paramInfo = paramsEndpoint?.params.find((param) => param.name === key);

      if (_.isEmpty(value) && (!paramInfo || paramInfo?.required === true)) {
        emptyOption = true;
        break;
      }
    }

    if (emptyOption) {
      blockButtonNext();
    } else {
      $wizardNextButton.removeAttribute('disabled');
      $wizardNextButton.classList.remove('btn-next-dis');
      $wizardNextButton.classList.add('btn-next');
      $wizardNextButton.classList.remove('iceCmdBtn-dis');
      $wizardNextButton.classList.add('iceCmdBtn');
    }
  }, [values, isCredentialsValid, integrationInfo]);

  useEffect(() => {
    if (!isEmpty(integrationInfo)) {
      //     |\__/,|   (`\
      //   _.|o o  |_   ) )
      // -(((---(((--------
      // Update JSF rendered labels ^^
      document.querySelectorAll('.ReplaceSourceLabel').forEach((el) => (el.innerHTML = integrationInfo.name));

      const opts = integrationInfo.endpoints
        .filter((i) => !i.logicEndpoint)
        .map((integration) => {
          return {
            icon: 'cloud',
            value: integration.endpoint,
            label: integration.name,
          };
        });

      const logicOpts = integrationInfo.endpoints
        .filter((i) => i.logicEndpoint)
        .map((integration) => {
          return {
            icon: 'cloud',
            value: integration.endpoint,
            label: integration.name,
          };
        });

      if (logicOpts.length > 0) {
        setGroupedOptions(true);
        const labelLogicOpts = integrationInfo.name.startsWith('Ponto')
          ? context.msg.t('reports_integrations')
          : context.msg.t('logic_integrations');

        setOptionsEndpoint([
          {
            icon: 'folder',
            label: context.msg.t('integrations'),
            children: opts,
          },
          {
            icon: 'device_hub',
            label: labelLogicOpts,
            children: logicOpts,
          },
        ]);
      } else {
        setGroupedOptions(false);
        setOptionsEndpoint(opts);
        if (opts.length === 1) {
          formikProps.setFieldValue('endpoint', opts[0].value);
        }
      }

      if (integrationInfo?.authType?.name === 'bim_open_data') {
        formikProps.setFieldValue('project_id', `${context.applicationEnvironment}_${context.project.name}`);
      }
    }
  }, [integrationInfo]);

  useEffect(filterQueryParamsFromEndpoint, [values.endpoint, integrationInfo]);

  const validateFields = () => {
    let temp = true;

    if (_.isArray(values?.properties)) {
      const val = values?.properties[0];
      if (val && _.isEmpty(val.key) && _.isEmpty(val.value)) {
        temp = false;
      }
    } else {
      routeAuthFields.forEach((item) => {
        if (!values[item.name] && temp) {
          temp = false;
        }
      });
    }

    routeQueryParams.forEach((item) => {
      if (!values[item.name] && temp && item.required) {
        temp = false;
      }
    });

    routePathParams.forEach((item) => {
      if (!values[item.name] && temp) {
        temp = false;
      }
    });

    if ((!values?.endpoint || values?.endpoint === '') && temp) {
      temp = false;
    }

    setNoneEmptyFields(temp);
  };

  function filterQueryParamsFromEndpoint() {
    if (!isEmpty(values.endpoint) && !isEmpty(integrationInfo)) {
      const { endpoints } = integrationInfo;
      const selectedIntegration = endpoints.find((integration) => integration.endpoint === values.endpoint);
      const qp = selectedIntegration.params || [];
      setRouteQueryParams(qp);
      onFormParamsInfo({ routeQueryParams: qp });
    }
  }

  async function updateWsParams() {
    if (isCredentialsValid && !isEmpty(formValues.authName)) {
      try {
        await Api.BimIntegration.addWsParams(formValues, fromScheduler);
        await Api.BimIntegration.updateForm();
      } catch (e) {
        console.error(e);
      }
    }
  }

  async function updateAccountsMeta(token) {
    const refreshToken = token || formikProps.values.refresh_token;
    let options = [];

    if (refreshToken && isEmpty(metaAccountOpts) && name.startsWith('meta')) {
      try {
        const metaAccounts = await Api.BimIntegration.findMetaAccounts(refreshToken);

        if (_.isEmpty(metaAccounts.data)) {
          UiMsg.warn(context.msg.t('role.title.hint'), context.msg.t('forbidden.meta'));
          return false;
        }

        options = metaAccounts.data.map((acc) => ({
          icon: 'cloud',
          label: `${acc.name} (${acc.id})`,
          value: [acc],
        }));

        options.push({
          icon: 'cloud',
          label: context.msg.t('all.accounts'),
          value: metaAccounts.data,
        });

        setMetaAccountOpts(options);
        return true;
      } catch (e) {
        console.error('Error on updateAccountsMeta()', e);
        if (e.response && e.response.status === 403) {
          UiMsg.warn(context.msg.t('role.title.hint'), context.msg.t('forbidden.meta'));
          return false;
        } else {
          UiMsg.ajaxError(null, e);
          return true;
        }
      }
    }
  }

  async function updateExcelWorksheets(token) {
    const refreshToken = token || formikProps.values.refresh_token;
    let options = [];
    if (refreshToken && isEmpty(excelWorksheetOpts) && name.startsWith('microsoft')) {
      try {
        const worksheetExcel = await Api.BimIntegration.findExcelWorksheets(refreshToken);

        options = worksheetExcel.map((worksheet) => ({
          icon: 'description',
          label: worksheet.name.replace('.xlsx', ''),
          value: worksheet.webUrl,
        }));

        setExcelWorksheetOpts(options);
        return true;
      } catch (e) {
        console.error('Error on updateWorksheetExcel()', { refreshToken }, e);
        UiMsg.ajaxError(null, e);
        return false;
      }
    }
  }

  useEffect(() => {
    updateAccountsMeta();
    updateExcelWorksheets();
  }, []);

  useEffect(() => {
    updateWsParams();
  }, [isCredentialsValid, formValues]);

  const validateCredentialsAndFetchFields = async (values) => {
    let skip = true;
    try {
      setSubmitting(true);
      const { authType } = integrationInfo;

      const params = Object.assign({}, values, { authName: authType.name, fields: authType.fields });

      if (!isEmpty(routeQueryParams)) {
        const valid = await validateCredentialsDates(routeQueryParams, params);
        if (valid === false) {
          skip = false;
          await validateCredentialsFn(valid);
        }
      }

      if (skip) {
        await validateCredentialsFn(values);
      }
      setFormValues(params);
    } finally {
      setSubmitting(false);
    }
  };

  useEventBus(
    OAUTH_CALLBACK,
    async ({ event, name }) => {
      const token = event.data;
      if (values.name === name) {
        let validate = true;
        if (integrationInfo.name.startsWith('Meta')) {
          validate = await updateAccountsMeta(token);
        }

        if (integrationInfo.name.startsWith('Microsoft')) {
          validate = await updateExcelWorksheets(token);
        }

        if (validate) {
          await validateCredentialsAndFetchFields({
            ...values,
            refresh_token: token,
          });
        }
      }
    },
    [validateCredentialsAndFetchFields]
  );

  const handleAuthentication = async (integrationName) => {
    const baseUrl = Api.buildBaseUrl(true);
    let authUrl, authParams;

    if (integrationName.startsWith('Google')) {
      authUrl = 'https://accounts.google.com/o/oauth2/v2/auth';
      authParams = {
        access_type: 'offline',
        prompt: 'consent',
        client_id: googleConf.clientId,
        response_type: 'code',
        scope: integrationInfo.scope,
        redirect_uri: `${googleConf.redirectUri}/api/origins/google/google-auth`,
        state: baseUrl,
      };
    } else if (integrationName.startsWith('Meta')) {
      authUrl = 'https://www.facebook.com/v19.0/dialog/oauth';
      authParams = {
        client_id: facebookConf.clientId,
        redirect_uri: facebookConf.redirectUri,
        response_type: 'code',
        display: 'popup',
        force_confirmation: 'true',
        scope: integrationInfo.scope,
        state: baseUrl,
      };
    } else if (integrationName.startsWith('Microsoft')) {
      authUrl = 'https://login.microsoftonline.com/common/oauth2/v2.0/authorize';
      authParams = {
        client_id: microsoftConf.clientId,
        response_type: 'code',
        redirect_uri: microsoftConf.redirectUri,
        response_mode: 'query',
        scope: integrationInfo.scope,
        state: baseUrl,
      };
    } else if (integrationName.startsWith('EagleEye')) {
      authUrl = 'https://auth.eagleeyenetworks.com/oauth2/authorize';
      authParams = {
        scope: 'vms.all',
        client_id: eagleEyeConf.clientId,
        response_type: 'code',
        redirect_uri: eagleEyeConf.redirectUri,
        state: baseUrl,
      };
    } else {
      throw new Error(`Unsupported integration: ${integrationName}`);
    }

    StructuresPageUtils.authenticationRedirect({ uri: authUrl, params: authParams, name: name });
  };

  const handleInputQueryParam = (routeQueryParam) => {
    const commonParams = {
      key: routeQueryParam.name,
      name: routeQueryParam.name,
      component: BngField,
      disabled: disableFields,
      required: routeQueryParam.required,
      maxLength: 2000,
      multiple: routeQueryParam?.multiple,
      type: 'text',
      label: routeQueryParam.info ? (
        <LabelHelper helpLabel={routeQueryParam.info} label={routeQueryParam.label} />
      ) : (
        routeQueryParam.label
      ),
    };

    if (routeQueryParam?.options) {
      commonParams.inputComponent = BngSelectSearch;
      commonParams.options = routeQueryParam.options;
      return commonParams;
    }

    commonParams.inputComponent = !routeQueryParam.mask || routeQueryParam.mask === 'text' ? undefined : BngMaskedField;
    commonParams.mask = maskType(routeQueryParam.mask);

    switch (routeQueryParam.mask) {
      case 'number': {
        commonParams.asNumber = true;
        break;
      }
      case 'date_en':
      case 'date_br': {
        commonParams.asDate = routeQueryParam.mask.split('_')[1];
        break;
      }
    }
    return commonParams;
  };

  const handleInputAuthFields = (routeAuthField) => {
    const commonParams = {
      key: routeAuthField.name,
      name: routeAuthField.name,
      component: BngField,
      label: routeAuthField.label,
      required: true,
      disabled: disableFields,
      type: routeAuthField.type,
      maxLength: 2000,
    };

    if (routeAuthField?.multipleItems) {
      commonParams.inputComponent = MultipleItemsInput;
      Object.assign(commonParams, routeAuthField.multipleItems);
    }

    return commonParams;
  };

  const disableFields = creating && !isEditing;

  const renderAuthorizationButton = (integrationName) => {
    const socialAuthProps = {};
    if (integrationName.startsWith('Google')) {
      socialAuthProps.img = 'google.svg';
      socialAuthProps.imgAlt = 'Google Logo';
      socialAuthProps.label = context.msg.t('google.login.with.google');
      socialAuthProps.className = 'google';
    } else if (integrationName.startsWith('Meta')) {
      socialAuthProps.img = 'facebook-letter.png';
      socialAuthProps.imgAlt = 'Facebook Logo';
      socialAuthProps.label = context.msg.t('login.with.facebook');
      socialAuthProps.className = 'google';
    } else if (integrationName.startsWith('Microsoft')) {
      socialAuthProps.img = 'microsoft.svg';
      socialAuthProps.imgAlt = 'Microsoft Logo';
      socialAuthProps.label = context.msg.t('login.with.microsoft');
      socialAuthProps.className = 'microsoftButton';
    } else if (integrationName.startsWith('EagleEye')) {
      socialAuthProps.img = 'eagle-eye.png';
      socialAuthProps.imgAlt = 'Eagle Eye';
      socialAuthProps.label = context.msg.t('login.with.eagle.eye');
      socialAuthProps.className = 'google';
    }

    if (!_.isEmpty(socialAuthProps)) {
      return (
        <OauthButton
          onClick={() => handleAuthentication(integrationName)}
          loading={isSubmitting}
          disabled={!isValid || disableFields || !noneEmptyFields}
          {...socialAuthProps}
        />
      );
    }

    return (
      <Button
        icon="icon-ok"
        type="button"
        onClick={() => validateCredentialsAndFetchFields(values)}
        loading={isSubmitting}
        disabled={!isValid || disableFields || !noneEmptyFields}
        className="BtnValidate"
      >
        {context.msg.t('bim.integration.validate.credentials')}
      </Button>
    );
  };

  return (
    <div className="BimIntegrationDataOrigin">
      <div className="flex-center-items">
        <h6>{context.msg.t('bim.integration.origins.title')}</h6>
        <div className="ml-auto flex-center-items">
          <Field
            name="ignoreLoadErrors"
            component={BngCheckbox}
            className="IgnoreLoadErrorsCheckbox"
            label={context.msg.t('newInMemoryIgnoreEmptyColumns')}
            title={context.msg.t('newInMemoryIgnoreParseErrorsHint')}
          />
        </div>
      </div>
      <hr />
      {optionsEndpoints.length > 1 && (
        <Field
          name="endpoint"
          component={BngField}
          inputComponent={BngSelectSearch}
          groupedOpts={groupedOptions}
          label={context.msg.t('bim.integration.select.integration')}
          emptyOption={true}
          required={true}
          disabled={disableFields}
          options={optionsEndpoints}
        />
      )}
      {integrationInfo.authType && (
        <>
          {integrationInfo.authType.name === 'bim_open_data' && (
            <div style={{ display: 'none' }}>
              <Field
                name="project_id"
                component={BngField}
                label="project_id"
                required={true}
                disabled={disableFields}
                type="text"
              />
            </div>
          )}

          {integrationInfo.authType.name === 'oauth2' && (
            <div>
              <div style={{ display: 'none' }}>
                <Field
                  name="refresh_token"
                  component={BngField}
                  label="refresh_token"
                  required={true}
                  disabled={disableFields}
                  type="text"
                  className="google-sheets-refresh-token"
                />
              </div>
              {integrationInfo.name.startsWith('Meta') && (
                <div>
                  <Field
                    name="selected_account"
                    component={BngField}
                    inputComponent={BngSelectSearch}
                    groupedOpts={false}
                    label={context.msg.t('select_an_account')}
                    emptyOption={true}
                    required={false}
                    disabled={!isCredentialsValid && optionsEndpoints.length > 1}
                    options={metaAccountOpts}
                  />
                </div>
              )}
              {integrationInfo.name.startsWith('Microsoft') && (
                <div>
                  <Field
                    name="selected_worksheet"
                    component={BngField}
                    inputComponent={BngSelectSearch}
                    groupedOpts={false}
                    label={context.msg.t('select.worksheet')}
                    emptyOption={true}
                    required={true}
                    disabled={!isCredentialsValid}
                    options={excelWorksheetOpts}
                  />
                </div>
              )}
            </div>
          )}

          {!isEmpty(routePathParams) &&
            routePathParams.map((routeParam) => {
              return (
                <Field
                  key={routeParam.name}
                  name={routeParam.name}
                  type={'text'}
                  required={true}
                  component={BngField}
                  disabled={disableFields}
                  label={routeParam.label}
                />
              );
            })}
          {!isEmpty(routeAuthFields) &&
            routeAuthFields.map((routeAuthField) => <Field {...handleInputAuthFields(routeAuthField)} />)}
          {!isEmpty(routeQueryParams) &&
            routeQueryParams.map((routeQueryParam) => <Field {...handleInputQueryParam(routeQueryParam)} />)}

          <div className="AbsoluteButtons">
            {renderAuthorizationButton(integrationInfo.name)}

            <Button
              className="btn btn-success BtnValidCredentials"
              disabled={false}
              style={{ display: isCredentialsValid ? 'initial' : 'none' }}
            >
              <span className="fa fa-check" />
            </Button>
            <Button
              icon="icon-eye-open"
              onClick={async () => {
                setLoadingPreview(true);
                try {
                  await updateWsParams();
                  const source = fromScheduler ? 'inMemorySchedulingFormMB.scheduling.source' : 'inMemoryBean.source';
                  await Api.executeExp(`#{sourcePreviewMB.openPreview(inMemoryBean.inMemory, ${source})}`);
                } finally {
                  setLoadingPreview(false);
                }
              }}
              loading={loadingPreview}
              disabled={!isCredentialsValid}
              className="BtnPreview btn-inverse"
            >
              {context.msg.t('data.preview')}
            </Button>
          </div>
        </>
      )}
    </div>
  );
};

const OauthButton = ({ img, altImg, className = '', label, ...props }) => {
  return (
    <Button className={`OauthButton ${className}`} {...props}>
      <img src={`${Api.baseUrl()}/resources/images/socials/${img}`} alt={altImg} className="mr-1" />
      {label}
    </Button>
  );
};

const FormikWrapper = ({ name, state, context, ignoreLoadErrors = 'true', ...props }) => {
  const [initialValues] = useState({ ...(JSON.parse(state) || {}), ignoreLoadErrors: ignoreLoadErrors === 'true' });
  const [formParamsInfo, setFormParamsInfo] = useState({ routeQueryParams: [] });

  const validate = useCallback(
    (values) => {
      const errors = {};
      const requiredFieldMessage = YupLang.mixed.required;

      const keys = Object.keys(values);

      for (const field of keys) {
        const value = values[field];
        if (!value && !_.isBoolean(value)) {
          const paramInfo = formParamsInfo.routeQueryParams.find((qp) => qp.name === field);
          if (!paramInfo || paramInfo.required === true) {
            errors[field] = requiredFieldMessage;
          }
        }
      }

      if (_.isEmpty(values.endpoint)) {
        errors.endpoint = requiredFieldMessage;
      }

      return errors;
    },
    [formParamsInfo]
  );

  const formChangeListener = useCallback(
    _.debounce((next) => {
      Api.BimIntegration.addWsParams(next.values, props.fromScheduler ?? false);
    }, 300),
    []
  );

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={_.noop}
      validate={validate}
      validateOnChange={true}
      isInitialValid={true}
      enableReinitialize={true}
    >
      {(formikProps) => (
        <BngForm>
          <FormikListener onChange={formChangeListener} />
          <BimIntegrationOrigins
            name={name}
            context={context}
            formikProps={formikProps}
            onFormParamsInfo={(p) => {
              setFormParamsInfo({
                ...formParamsInfo,
                ...p,
              });
            }}
            {...props}
          />
        </BngForm>
      )}
    </Formik>
  );
};

BimIntegrationOrigins.propTypes = {
  name: PropTypes.string.isRequired,
};

export default ContextEnhancer(FormikWrapper);
