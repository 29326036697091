import { Axios } from 'commonUtils';
import Utils from 'components/Utils';

class ActivityApi {
  findActivitiesList = async ({ projectId, ...params }) => {
    const { data } = await Axios.get(`/spr/activities/${projectId}`, { params: Utils.ObjectUtils.removeEmptyKeys(params) });
    data.forEach(row => {
      row.date = moment(row.date);
    });
    return data;
  };

  findEventTypes = async () => {
    const { data } = await Axios.get(`/spr/activities/event-types`);
    return data;
  };
}

export default ActivityApi;
