import styles from './MarketplaceBanner.module.css';

import React, { useState } from 'react';

import BngIconButton from 'components/bng/ui/BngIconButton';
import useFetchData from 'components/hooks/useFetchData';
import UiMsg from 'components/ui/UiMsg';
import AddonInfoAd from 'components/ui/navbar/addons/AddonInfoAd';
import Api from 'components/Api';

export default function MarketplaceBanner({ accountId }) {
  const [activeImg, setActiveImg] = useState(0);
  const $bannerImage = useFetchData(async () => {
    try {
      // TODO fetch the banner images from store?
      let images = [];

      const fetchedAddons = await Api.Account.findAccountAddons(accountId);
      let filteredAddons = fetchedAddons.allAddons.filter(
        (addon) => addon.billingAdditional && addon.billingAdditional.marketplaceInfo
      );

      const allAddonsActive = filteredAddons.every((addon) =>
        fetchedAddons.accountAddons.some((accAddon) => addon.id === accAddon.id)
      );
      if (!allAddonsActive) {
        filteredAddons = filteredAddons.filter(
          (addon) => !fetchedAddons.accountAddons.some((accAddon) => accAddon.id === addon.id)
        );
      }

      images = images.concat(filteredAddons.splice(0, 3));
      return images;
    } catch (e) {
      console.error('Error on function fetchBannerImage()', e);
      UiMsg.ajaxError(null, e);
    }
  }, [accountId]);

  const nextImage = () => {
    setActiveImg((prevIndex) => (prevIndex === $bannerImage.data.length - 1 ? 0 : prevIndex + 1));
  };

  const prevImage = () => {
    setActiveImg((prevIndex) => (prevIndex === 0 ? $bannerImage.data.length - 1 : prevIndex - 1));
  };

  const carouselTranslation = -100 * activeImg;
  return (
    <div
      className={`MarketplaceBanner ${styles.bannerWrapper} ${
        $bannerImage.data?.length === 0 ? styles.emptyBanner : ''
      }`}
    >
      <div className={styles.carouselWrapper}>
        {$bannerImage.data?.map((image, idx) => {
          if (typeof image === 'object') {
            return (
              <div
                key={`banner-addon-${idx}`}
                className={styles.bannerImage}
                style={{ transform: `translateX(${carouselTranslation}%)` }}
              >
                <AddonInfoAd
                  accountId={accountId}
                  imageClassName={styles.addonImage}
                  addon={image}
                  forMarketplace={true}
                />
              </div>
            );
          } else {
            return (
              <img
                key={`banner-img-${idx}`}
                className={styles.bannerImage}
                src={image}
                style={{ transform: `translateX(${carouselTranslation}%)` }}
              />
            );
          }
        })}
      </div>
      {$bannerImage.data?.length > 1 && (
        <>
          <BngIconButton
            className={`btnPrevBannerImg ${styles.navigationButton} ${styles.btnPrevBannerImg}`}
            icon={'navigate_before'}
            onClick={prevImage}
          />
          <BngIconButton
            className={`btnNextBannerImg ${styles.navigationButton}  ${styles.btnNextBannerImg}`}
            icon={'navigate_next'}
            onClick={nextImage}
          />
        </>
      )}
    </div>
  );
}
