import './BngHorizontalCard.css';

import React, {useEffect, useRef, useState} from 'react';
import PropTypes from 'prop-types';
import Icon from "components/ui/common/Icon";
import ContextEnhancer from "components/ContextEnhancer";
import {ResizeSensor} from "css-element-queries";

export const BngIsNewLabel = ContextEnhancer(({context, className = '', text}) => {
    return (
        <div className={`BngIsNewLabel ${className}`}>
            {text ? text : context.msg.t('is.new')}
        </div>
    )
});

export const BngIsComingLabel = ContextEnhancer(({context, className = '', text}) => {
    return (
        <div className={`BngIsComingLabel ${className}`}>
            {text ? text : context.msg.t('is.coming')}
        </div>
    )
});

export const BngIsBetaLabel = ContextEnhancer(({context, className = '', text}) => {
    return (
        <div className={`BngIsBetaLabel ${className}`}>
            {text ? text : context.msg.t('is.beta')}
        </div>
    )
});

export const BngIsPremiumLabel = ContextEnhancer(({context,className = '', text}) => {
    return (
        <div className={`BngIsPremiumLabel ${className}`}>
            <Icon icon='rocket_launch'/>
            {text ? text : context.msg.t('is.upgrade')}
        </div>
    )
})

export const BngHorizontalCard = ({
                                      className = '',
                                      icon = '',
                                      title = 'Change this title :D',
                                      text = 'Change this text :P',
                                      isNew = false,
                                      isComing = false,
                                      isBeta = false,
                                      isPremium = false,
                                      onClick = _.noop,
                                      tooltip = undefined,
                                      labelText = undefined,
                                      disabled = false,
                                      image = undefined,
                                      preventScroll = true,
                                      ...props
                                  }) => {
    const contentRef = useRef();
    const textRef = useRef();
    const [processedText, setProcessedText] = useState(text);

    useEffect(() => {
        if (!preventScroll || !contentRef.current || !textRef.current || !text) return;

        const processTextSize = () => {
            const result = handleTextSize(contentRef.current, textRef.current, text);
            setProcessedText(result);
        };

        const sensor = new ResizeSensor(contentRef.current, () => {
            window.requestAnimationFrame(() => {
                processTextSize();
            });
        });
        processTextSize();
        return () => {
            sensor.detach();
        }
    }, [contentRef.current, textRef.current, text]);

    const textContent = preventScroll ? processedText : text;
    return (
        <div className={`BngHorizontalCardBorder ${isPremium ? 'premiumBorder' : ''}`}>
            <div className={`BngHorizontalCard ${className} ${isComing ? 'isComing' : ''} ${disabled ? 'Disabled' : ''} ${isPremium ? 'isPremium' : ''}`}
                 onClick={disabled ? undefined : onClick}
                 title={tooltip}
                 {...props}>
                <div className={`IconContainer ${image ? 'withImage' : ''}`}>
                    {image ?
                        <img src={image} alt={"Icon"}/> :
                        <Icon icon={icon}/>
                    }
                </div>
                <div className="Content" ref={contentRef}>
                    <div>
                        <div className="Title">
                            {title}
                            {isNew &&
                                <BngIsNewLabel text={labelText}/>
                            }
                            {isBeta &&
                                <BngIsBetaLabel/>
                            }
                        </div>
                        <div className="Text" ref={textRef}>
                            {textContent}
                        </div>
                    </div>
                    <div className="LabelWrapper">
                        {isPremium &&
                            <BngIsPremiumLabel />
                        }
                        {isComing &&
                            <BngIsComingLabel text={labelText}/>
                        }
                    </div>
                </div>
            </div>
        </div>
    );
};

BngHorizontalCard.propTypes = {
    className: PropTypes.string,
    icon: PropTypes.string,
    title: PropTypes.string,
    text: PropTypes.string,
    isNew: PropTypes.bool,
    isComing: PropTypes.bool,
    labelText: PropTypes.string,
    onClick: PropTypes.func,
};

const handleTextSize = (ref, $text, originalText) => {
    let content = originalText;
    $text.innerText = content;
    $text.removeAttribute('title');

    if (ref.clientHeight >= ref.scrollHeight) return content;

    $text.setAttribute('title', originalText);

    for (let i = 0; i < 32 && (ref.scrollHeight > ref.clientHeight); i++) {
        content = content.slice(0, content.lastIndexOf(' ')) + '...';
        $text.innerText = content;
    }

    return content;
}