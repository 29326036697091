import styles from './BimQueryPage.module.css';

import React, { useEffect, useState } from 'react';
import { Form, Formik } from 'formik';

import useBimContext from 'components/hooks/useBimContext';
import BimQueryRightMenu from 'components/bng/pages/admin/structures/bimQuery/BimQueryRightMenu';
import { bngYup } from 'components/bng/form/yup/BngYup';
import BimQuerySqlEditor from 'components/bng/pages/admin/structures/bimQuery/BimQuerySqlEditor';
import UiBlocker from 'components/bng/ui/UiBlocker';
import { Tab, TabSet } from 'components/ui/TabSet';
import AssistedMode from 'components/bng/pages/admin/structures/bimQuery/AssistedMode';
import BngDropdown from 'components/bng/ui/BngDropdown';
import BngIconButton from 'components/bng/ui/BngIconButton';
import Icon from 'components/ui/common/Icon';
import Api from 'components/Api';
import UiMsg from 'components/ui/UiMsg';
import QueryResultPreview from 'components/bng/pages/admin/structures/bimQuery/menuTabs/QueryResultPreview';
import ColumnsConfiguration from 'components/bng/pages/admin/structures/bimQuery/menuTabs/ColumnsConfiguration';
import bimEventBus from 'BimEventBus';

const Schema = bngYup((yup) => {
  return yup.object({
    sql: yup.string().required().default(''),
    fieldConfigs: yup.array(yup.object()).default([]),
    connection: yup.number().required().default(0),
    structureName: yup.string().required().default(''),
    structureIcon: yup.string().required().default('cloud'),
    structureGroup: yup.number().default(0),
    structureDescription: yup.string().required().default(''),
    structureType: yup.string().required().default(''),
    fullLoads: yup.boolean().default(false),
  });
});

export default function BimQueryPage() {
  const { msg, project, labs } = useBimContext();

  const [accordionOpened, setAccordionOpened] = useState(false);
  const [queryResult, setQueryResult] = useState([]);
  const [resultLimit, setResultLimit] = useState(20);
  const [loading, setLoading] = useState(false);

  const initialValues = Schema.default();

  useEffect(() => {
    if (labs.features.some((feature) => feature === 'BIM_QUERY')) {
      return;
    }
    window.location.replace(Api.buildUrl('/pages/errors/403.iface'));
  }, []);

  return (
    <div className={`BimQueryPage ${styles.BimQueryPage}`}>
      <Formik
        enableReinitialize={false}
        validationSchema={Schema}
        initialValues={initialValues}
        onSubmit={(values) => {
          alert(JSON.stringify(values, null, 2));
        }}
      >
        {({ values, setFieldValue }) => {
          const validateQuery = async () => {
            if (_.isEmpty(values.sql)) {
              return;
            }

            try {
              setLoading(true);
              await Api.BimQuery.validateQuery({
                connectionId: values.connection,
                sql: values.sql,
                fromScheduler: false,
                projectId: project.id,
              });
              UiMsg.ok(msg.t('inmemory.sql.syntax.valid'));
            } catch (e) {
              console.error('Error on validateQuery()', e);
              UiMsg.ajaxError(msg.t('inMemory_errorGettingSqlMetadata'), e);
            } finally {
              setLoading(false);
            }
          };

          const executeQuery = async () => {
            try {
              setLoading(true);
              const response = await Api.BimQuery.executeQuery({
                connectionId: values.connection,
                sql: values.sql,
                fromScheduler: false,
                projectId: project.id,
                pageSize: resultLimit,
              });

              setQueryResult(response.result);
              setFieldValue('fieldConfigs', response.fields);
            } catch (e) {
              console.error('Error on executeQuery()', e);
              UiMsg.ajaxError(msg.t('inMemory_errorGettingSqlMetadata'), e);
            } finally {
              setLoading(false);
            }
          };

          return (
            <UiBlocker block={loading} className={`${accordionOpened ? 'menu-opened' : 'menu-closed'}`}>
              <Form>
                <TabSet internal={true} tabToOpenIndex={1} className={`${styles.TopTabsWrapper}`}>
                  <Tab icon="outbound" label={msg.t('bim.query.tab.title.assisted.mode')}>
                    <AssistedMode />
                  </Tab>
                  <Tab icon="code" label={msg.t('bim.query.tab.title.sql.mode')}>
                    <BimQuerySqlEditor />
                  </Tab>
                </TabSet>
                <div className={`${styles.CenterButtonsWrapper}`}>
                  <div className={`${styles.ActionsButtons} ${accordionOpened ? 'menu-opened' : 'menu-closed'}`}>
                    <BngIconButton
                      icon="done"
                      className="validate-query"
                      text={msg.t('sql.validate')}
                      onClick={validateQuery}
                    />
                    <BngDropdown
                      popperClassName={`${styles.QueryResultLinesAmountPopper} `}
                      customButton={({ openDropdown }) => (
                        <div className={`${styles.ExecuteQueryButton}`}>
                          <Icon icon="play_circle" onClick={executeQuery} />
                          <Icon icon="arrow_drop_down" onClick={openDropdown} />
                        </div>
                      )}
                      options={[20, 50, 100, 1000].map((limit) => ({
                        label: `${limit} ${msg.t('bim.query.dropdown.line.selector.option')}`,
                        icon: `${resultLimit === limit ? 'done' : ''}`,
                        onClick: () => setResultLimit(limit),
                      }))}
                    />
                  </div>
                  <div
                    className={`${styles.ExportViewButton}`}
                    onClick={() => {
                      bimEventBus.emit(QueryResultPreview.EXPORT_CSV_EVENT);
                    }}
                  >
                    {msg.t('bim.query.export.preview.button.label')} <Icon icon="csv" />
                  </div>
                </div>
                <TabSet internal={true} tabToOpenIndex={0}>
                  <Tab icon="settings" label={msg.t('configuration')} className={`${styles.ColumnsConfigurationTab}`}>
                    {!_.isEmpty(values.fieldConfigs) && <ColumnsConfiguration />}
                  </Tab>
                  <Tab icon="visibility" label={msg.t('preview')} className={`${styles.QueryResultPreviewTab}`}>
                    {!_.isEmpty(queryResult) && <QueryResultPreview queryResult={queryResult} />}
                  </Tab>
                </TabSet>
                <div className={`${styles.BimQueryRightMenuWrapper}`}>
                  <BimQueryRightMenu
                    values={values}
                    onAccordionChange={({ accordionKey }) => {
                      setAccordionOpened(!_.isNil(accordionKey));
                    }}
                    setFieldValue={setFieldValue}
                  />
                </div>
              </Form>
            </UiBlocker>
          );
        }}
      </Formik>
    </div>
  );
}
