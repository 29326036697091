import './USER_DATA_RESTRICT.css';
import './DOMAIN_NAME.css';

import { MODALS } from 'components/ui/redux/Actions';
import WhatsSupportDialog from 'components/bng/support/WhatsSupportDialog';

const AddonPageContent = {
  WHATS_APP: {
    HEADER: {
      props: {
        image: 'WHATS_APP_header.svg',
        isPlan: true,
      },
    },
    MULTI_INFO: {
      props: {
        title: 'addon.WHATS_APP.contentTitle',
        description: '',
        infoCards: [
          {
            title: 'addon.WHATS_APP.raiseSales',
            description: 'addon.WHATS_APP.raiseSales.desc',
            icon: 'trending_up',
            media: 'WHATS_APP_raise_sales.png',
          },
          {
            title: 'addon.WHATS_APP.sendReports',
            description: 'addon.WHATS_APP.sendReports.desc',
            icon: 'send',
            media: 'WHATS_APP_send_reports.png',
          },
          {
            title: 'addon.WHATS_APP.agility',
            description: 'addon.WHATS_APP.agility.desc',
            icon: 'task_alt',
            media: 'WHATS_APP_agility.png',
          },
          {
            title: 'WHATS_APP_kpi_monitoring',
            description: 'WHATS_APP_kpi_monitoring.desc',
            icon: 'speed',
            media: 'WHATS_APP_kpi_monitoring.png',
          },
        ],
      },
    },
    PLAN_PRICE: {
      props: {
        title: 'addon.WHATS_APP.price.title',
        description: '',
        video: 'addon.WHATS_APP.price.video',
        videoTitle: '',
        price: [
          {
            planKey: 'BASIC',
            value: 800,
            name: 'Basic',
            pricing: 389,
            description: 'addon.WHATS_APP.price.desc1',
            extraInfo: '800 envios/mês',
          },
          {
            planKey: 'STANDARD',
            value: 1500,
            name: 'Standard',
            pricing: 589,
            description: 'addon.WHATS_APP.price.desc2',
            extraInfo: '1500 envios/mês',
          },
          {
            planKey: 'PREMIUM',
            value: 3000,
            name: 'Premium',
            pricing: 989,
            description: 'addon.WHATS_APP.price.desc3',
            extraInfo: '3000 envios/mês',
          },
          {
            planKey: 'ADVANCED',
            value: 5000,
            name: 'Advanced',
            pricing: 1289,
            description: 'addon.WHATS_APP.price.desc4',
            extraInfo: '5000 envios/mês',
          },
        ],
      },
    },
    LINK_INFO: {
      props: {
        image: 'WHATS_APP_link_info_img.png',
      },
    },
    FAQ: {
      props: {
        questions: [
          {
            question: 'addon.WHATS_APP.faq.q1',
            answer: 'addon.WHATS_APP.faq.a1',
          },
          {
            question: 'addon.WHATS_APP.faq.q2',
            answer: 'addon.WHATS_APP.faq.a2',
          },
          {
            question: 'addon.WHATS_APP.faq.q3',
            answer: 'addon.WHATS_APP.faq.a3',
          },
          {
            question: 'addon.WHATS_APP.faq.q4',
            answer: 'addon.WHATS_APP.faq.a4',
          },
          {
            question: 'addon.WHATS_APP.faq.q5',
            answer: 'addon.WHATS_APP.faq.a5',
          },
          {
            question: 'addon.WHATS_APP.faq.q6',
            answer: 'addon.WHATS_APP.faq.a6',
          },
        ],
      },
    },
  },
  WHATS_SUPPORT: {
    HEADER: {
      props: {
        image: 'WHATS_SUPPORT_header_img.png',
        enabledButtonAction: ({ dispatch }) => {
          dispatch(MODALS.open(WhatsSupportDialog));
        },
        enabledButtonLabel: 'whatsapp.talk.to.us',
      },
    },
    LINK_INFO: {
      props: {
        image: 'Ada_WHATS_SUPPORT.png',
        style: { paddingBottom: 0 },
      },
    },
    LINED_MEDIA: {
      props: {
        title: 'addon.WHATS_SUPPORT.linedMedia.title',
        description: 'addon.WHATS_SUPPORT.linedMedia.description',
        mediaArray: [
          { url: 'gif_WHATS_SUPPORT_1.gif', title: 'addon.WHATS_SUPPORT.gif.title1' },
          { url: 'gif_WHATS_SUPPORT_4.gif', title: 'addon.WHATS_SUPPORT.gif.title2' },
          { url: 'gif_WHATS_SUPPORT_3.gif', title: 'addon.WHATS_SUPPORT.gif.title3' },
        ],
      },
    },
    SINGLE_PRICE: {
      props: {
        pricing: 189,
      },
    },
  },
  VISUAL_IDENTITY: {
    HEADER: {
      props: {
        image: 'VISUAL_IDENTITY_header.png',
      },
    },
    LINK_INFO: {
      props: {
        image: 'VISUAL_IDENTITY_body.png',
      },
    },
    SINGLE_PRICE: {
      props: {
        pricing: 389,
      },
    },
  },
  PRESENTATION: {
    HEADER: {
      props: {
        image: 'PRESENTATION_header.png',
      },
    },
    LINK_INFO: {
      props: {
        image: 'PRESENTATION_body.png',
      },
    },
    SINGLE_PRICE: {
      props: {
        pricing: 389,
      },
    },
  },
  USER_DATA_RESTRICT: {
    HEADER: {
      props: {
        image: 'USER_DATA_RESTRICT_header.png',
      },
    },
    LINK_INFO: {
      props: {
        image: 'USER_DATA_RESTRICT_body.png',
      },
    },
    SINGLE_PRICE: {
      props: {
        pricing: 389,
      },
    },
  },
  PUBLISHER: {
    HEADER: {
      props: {
        image: 'PUBLISHER_header.png',
      },
    },
    LINK_INFO: {
      props: {
        image: 'PUBLISHER_body.png',
      },
    },
    SINGLE_PRICE: {
      props: {
        pricing: 289,
      },
    },
    FAQ: {
      props: {
        questions: [
          {
            question: 'addon.PUBLISHER.faq.q1',
            answer: 'addon.PUBLISHER.faq.a1',
          },
          {
            question: 'addon.PUBLISHER.faq.q2',
            answer: 'addon.PUBLISHER.faq.a2',
          },
          {
            question: 'addon.PUBLISHER.faq.q3',
            answer: 'addon.PUBLISHER.faq.a3',
          },
          {
            question: 'addon.PUBLISHER.faq.q4',
            answer: 'addon.PUBLISHER.faq.a4',
          },
          {
            question: 'addon.PUBLISHER.faq.q5',
            answer: 'addon.PUBLISHER.faq.a5',
          },
          {
            question: 'addon.PUBLISHER.faq.q6',
            answer: 'addon.PUBLISHER.faq.a6',
          },
        ],
      },
    },
  },
  KPI_MONITORING: {
    HEADER: {
      props: {
        image: 'KPI_MONITORING_header.png',
      },
    },
    LINK_INFO: {
      props: {
        image: 'KPI_MONITORING_body.png',
      },
    },
    SINGLE_PRICE: {
      props: {
        pricing: 389,
      },
    },
  },
  DOMAIN_NAME: {
    HEADER: {
      props: {
        image: 'DOMAIN_NAME_header.png',
      },
    },
    LINK_INFO: {
      props: {
        image: 'DOMAIN_NAME_body.png',
      },
    },
    SINGLE_PRICE: {
      props: {
        pricing: 289,
      },
    },
  },
  TRACEABILITY: {
    HEADER: {
      props: {
        image: 'TRACEABILITY_header.png',
      },
    },
    LINK_INFO: {
      props: {
        image: 'TRACEABILITY_body.png',
      },
    },
    SINGLE_PRICE: {
      props: {
        pricing: 389,
      },
    },
  },
  PUBLISHER_FULL: {
    HEADER: {
      props: {
        image: 'PUBLISHER_FULL_header.svg',
        isPlan: true,
      },
    },
    MULTI_INFO: {
      props: {
        title: 'addon.PUBLISHER_FULL.contentTitle',
        description: '',
        infoCards: [
          {
            title: 'addon.PUBLISHER_FULL.raiseSales',
            description: 'addon.PUBLISHER_FULL.raiseSales.desc',
            icon: 'trending_up',
            media: 'PUBLISHER_FULL_raise_sales.gif',
          },
          {
            title: 'addon.PUBLISHER_FULL.sendReports',
            description: 'addon.PUBLISHER_FULL.sendReports.desc',
            icon: 'send',
            media: 'PUBLISHER_FULL_send_reports.gif',
          },
          {
            title: 'addon.PUBLISHER_FULL.agility',
            description: 'addon.PUBLISHER_FULL.agility.desc',
            icon: 'task_alt',
            media: 'PUBLISHER_FULL_agility.gif',
          },
          {
            title: 'PUBLISHER_FULL_kpi_monitoring',
            description: 'PUBLISHER_FULL_kpi_monitoring.desc',
            icon: 'speed',
            media: 'PUBLISHER_FULL_kpi_monitoring.png',
          },
        ],
      },
    },
    SINGLE_PRICE: {
      props: {
        pricing: 899,
      },
    },
    FAQ: {
      props: {
        questions: [
          {
            question: 'addon.PUBLISHER_FULL.faq.q1',
            answer: 'addon.PUBLISHER_FULL.faq.a1',
          },
          {
            question: 'addon.PUBLISHER_FULL.faq.q2',
            answer: 'addon.PUBLISHER_FULL.faq.a2',
          },
          {
            question: 'addon.PUBLISHER_FULL.faq.q3',
            answer: 'addon.PUBLISHER_FULL.faq.a3',
          },
          {
            question: 'addon.PUBLISHER_FULL.faq.q4',
            answer: 'addon.PUBLISHER_FULL.faq.a4',
          },
          {
            question: 'addon.PUBLISHER_FULL.faq.q5',
            answer: 'addon.PUBLISHER_FULL.faq.a5',
          },
          {
            question: 'addon.PUBLISHER_FULL.faq.q6',
            answer: 'addon.PUBLISHER_FULL.faq.a6',
          },
        ],
      },
    },
  },
};

export default AddonPageContent;
