import styles from './Checkout.module.css';

import React, { useState } from 'react';
import { useOutletContext } from 'react-router-dom';

import BngRadio from 'components/bng/form/BngRadio';
import useTranslation from 'components/hooks/useTranslation';
import Dialog from 'components/ui/Dialog';
import BngIconButton from 'components/bng/ui/BngIconButton';
import BngNumberCounter from 'components/bng/form/BngNumberCounter';
import BngField from 'components/bng/form/BngField';
import { proposalUtils } from 'components/service/bng/AccountApi';
import { BngCheckboxPure } from 'components/bng/form/BngCheckbox';
import BngButton from 'components/bng/ui/BngButton';
import Icon from 'components/ui/common/Icon';
import { Axios } from 'commonUtils';
import useBimContext from 'components/hooks/useBimContext';
import BngEmpty from 'components/bng/ui/BngEmpty';
import { GROUP_KEYS } from 'components/bng/pages/marketplace/MarketplaceLayout';
import useBimNavigate from 'components/hooks/useBimNavigate';
import UiMsg from 'components/ui/UiMsg';
import Api from 'components/Api';
import UiBlocker from 'components/bng/ui/UiBlocker';

export function CheckoutDialog({
  closeModal = _.noop,
  items = [],
  account,
  afterSubmit = _.noop,
  currentPlan,
  isMasterOfCurrentAccount = false,
}) {
  const { t } = useTranslation();
  const [checkoutItems, setCheckoutItems] = useState(items);

  const onSubmit = async () => {
    await afterSubmit();
    closeModal();
  };

  return (
    <Dialog
      className={`CheckoutDialog ${styles.CheckoutDialog}`}
      onClose={closeModal}
      title={t('checkout')}
      newDialogLayout={true}
    >
      <Dialog.Body className={`checkoutBody ${styles.checkoutBody}`}>
        <Checkout
          items={checkoutItems}
          account={account}
          afterSubmit={onSubmit}
          currentPlan={currentPlan}
          isMasterOfCurrentAccount={isMasterOfCurrentAccount}
          updateSelectedItems={setCheckoutItems}
        />
      </Dialog.Body>
    </Dialog>
  );
}

export function CheckoutPage({ account, marketplaceItems, isMasterOfCurrentAccount = false, reloadMarketplaceItems }) {
  const { t } = useTranslation();
  const navigate = useBimNavigate();
  const { selectedItems, updateSelectedItems, onClearSelection } = useOutletContext();

  const backAction = () => {
    navigate(-2);
  };

  return (
    <div className={`CheckoutPage ${styles.CheckoutPage}`}>
      <BngIconButton
        icon={'arrow_back'}
        text={t('back_button')}
        onClick={backAction}
        className={styles.checkoutBackButton}
      />
      <h3>{t('order.summary')}</h3>
      <div className={styles.checkoutWrapper}>
        <Checkout
          items={selectedItems}
          updateSelectedItems={updateSelectedItems}
          account={account}
          currentPlan={marketplaceItems.activePlanForAccount}
          afterSubmit={async () => {
            backAction();
            onClearSelection();
            await reloadMarketplaceItems();
          }}
          isMasterOfCurrentAccount={isMasterOfCurrentAccount}
        />
      </div>
    </div>
  );
}

const BillingFormat = {
  MONTHLY: 'MONTHLY',
  ANNUAL: 'ANNUAL',
};

export default function Checkout({
  items,
  isMasterOfCurrentAccount,
  updateSelectedItems = _.noop,
  account,
  afterSubmit = _.noop,
  currentPlan,
}) {
  const context = useBimContext();
  const { t } = useTranslation();
  const [billingFormat, setBillingFormat] = useState(BillingFormat.MONTHLY);
  const [agreed, setAgreed] = useState(false);
  const [loading, setLoading] = useState(false);

  const submitProposal = async (values) => {
    setLoading(true);
    try {
      const plan = values.items.find((item) => item.groupKey === GROUP_KEYS.PLANS);
      if (plan) {
        plan.billingPricingId = plan.pricing.id;
        plan.agreedPricing = plan.pricing.pricing;
      }
      await Api.Marketplace.activateMarketplaceItem({
        billingFormat,
        items: values.items.filter((item) => item !== plan),
        plan,
        accountId: account.id,
        projectId: context.project.id,
      });

      UiMsg.ok(
        t(
          isMasterOfCurrentAccount || isAdmin
            ? 'marketplace.item.enabled.successfully'
            : 'marketplace.item.requested.successfully'
        )
      );
      afterSubmit();
    } catch (e) {
      console.error('Error on function submitProposal()', e);
      UiMsg.error(t('marketplace.request.error'));
    } finally {
      setLoading(false);
    }
  };

  const commercialPhone = context.commercialSupportPhone;
  const calculateTotal = () => {
    let totalValue = items.reduce((currentValue, item) => currentValue + item.pricing.pricing * item.quantity, 0);
    if (billingFormat === BillingFormat.ANNUAL) {
      totalValue = totalValue * 12;
    }
    return proposalUtils.formatPricing(totalValue, items[0].pricing.currency.symbol);
  };

  const removeCheckoutItem = (item) => {
    const updatedArray = items.filter((i) => i.id !== item.id);
    updateSelectedItems(updatedArray);
  };
  const emptyCheckout = items.length === 0;
  const isAdmin = account?.role === 'Administrator';

  return (
    <UiBlocker block={loading}>
      <div className={`Checkout ${styles.Checkout}`}>
        <BngEmpty
          isEmpty={emptyCheckout}
          className={styles.emptyCheckout}
          title={t('marketplace.checkout.no.items')}
          message={null}
        >
          <div className={styles.checkoutField}>
            <label>{t('checkout.billingFormat')}:</label>
            <div className={styles.radioButtonWrapper}>
              {Object.values(BillingFormat).map((format) => (
                <BngRadio
                  key={format}
                  name="billingFormat"
                  label={t(`recurrence.${format}`)}
                  field={{ value: billingFormat === format }}
                  checked={billingFormat === format}
                  form={{ setFieldValue: () => setBillingFormat(format) }}
                  className={format === 'ANNUAL' ? styles.checkoutFieldDisabled : ''}
                  disabled={format === 'ANNUAL'}
                />
              ))}
            </div>
          </div>
          <div className={styles.checkoutField}>
            <label className={styles.paymentFormat}>{t('billing_type_checkout')}</label>
          </div>
          <hr className={styles.divider} />
          {items.map((item, idx) => {
            const isPlan = item.groupKey === GROUP_KEYS.PLANS;
            return (
              <React.Fragment key={`${idx}-${item.id}-${item.name}`}>
                {isPlan && (
                  <div className={`${styles.checkoutField} ${styles.itemField}`}>
                    <div className={`${styles.itemNameWrapper} ${styles.itemDetail}`}>
                      <div>
                        {proposalUtils.translationsForSales(currentPlan).name} <strong>({t('current')})</strong>
                      </div>
                    </div>
                    <strong className={`${styles.itemPricing} ${styles.itemDetail}`}>
                      {proposalUtils.getPricingLabel(proposalUtils.getDefaultPricing(currentPlan.pricings))}
                    </strong>
                  </div>
                )}
                <div className={`${styles.checkoutField} ${styles.itemField}`}>
                  <div className={styles.itemNameWrapper}>
                    <div>{`${item.name} ${
                      context.msg.translateIfHasKey(`${item.pricing.planKey}.card.title`) ?? ''
                    }`}</div>
                    {item.featureValue?.value > 1 && (
                      <div className={styles.smallPrint}>{`${item.pricing.currency.symbol} ${
                        item.pricing.pricing / item.featureValue.value
                      } x ${item.featureValue.value}`}</div>
                    )}
                  </div>
                  {!isPlan && (
                    <BngField
                      label={null}
                      value={item.quantity}
                      inputComponent={BngNumberCounter}
                      blockRightBtn={!item.canSelectQuantity}
                      onChange={(e) => {
                        items[idx].quantity = e.target.value;
                        updateSelectedItems([...items]);
                      }}
                      onDelete={() => removeCheckoutItem(item)}
                    />
                  )}
                  <strong className={styles.itemPricing}>{proposalUtils.getPricingLabel(item.pricing)}</strong>
                </div>
              </React.Fragment>
            );
          })}
          <hr className={styles.divider} />
          <div className={styles.checkoutField}>
            <strong>{t('total')}</strong>
            <strong>{!emptyCheckout && calculateTotal()}</strong>
          </div>
          <BngCheckboxPure label={t('checkout.agree')} checked={agreed} onChange={() => setAgreed(!agreed)} />
          <BngButton
            onClick={() => submitProposal({ billingFormat, items })}
            disabled={!agreed}
            className={styles.finishButton}
          >
            {isMasterOfCurrentAccount || isAdmin
              ? t('checkout.finish')
              : context.permissions.isConsultant()
              ? t('checkout.send.proposal')
              : t('checkout.send.request')}
          </BngButton>
          {commercialPhone && (
            <>
              <hr className={styles.divider} />
              <div className={styles.supportFooter}>
                <Icon icon={'support_agent'} />
                <span>{t('questions')}</span>
                <a
                  href={Axios.getUri({
                    url: 'https://api.whatsapp.com/send',
                    params: { phone: commercialPhone, type: 'phone_number', app_absent: '0' },
                  })}
                  target={'_blank'}
                >
                  {t('checkout.contactCommercial')}
                </a>
              </div>
            </>
          )}
        </BngEmpty>
      </div>
    </UiBlocker>
  );
}
