import styles from './BngItemSelect.module.css';

import React, { useState } from 'react';
import BngButton from 'components/bng/ui/BngButton';
import BngIconButton from 'components/bng/ui/BngIconButton';
import useBimContext from 'components/hooks/useBimContext';

export default function BngItemSelect({
  className = '',
  value = [],
  buttonLabel = '',
  openComponent,
  onOpen = _.noop,
  tagComponent,
  headerButton,
  showTags = false,
}) {
  const { msg } = useBimContext();

  const [expanded, setExpanded] = useState(true);

  return (
    <div className={`BngItemSelect ${styles.itemSelectWrapper} ${className}`}>
      <div className={`${styles.selectButtonContainer}`}>
        {openComponent && <div className={`${styles.customComponentContainer}`}>{openComponent()}</div>}
        <BngButton className={`${styles.selectButton}`} icon={'add_circle'} hover={false} onClick={onOpen}>
          {buttonLabel}
        </BngButton>
        {showTags && headerButton && <div className={`${styles.customHeaderButtonContainer}`}>{headerButton()}</div>}
        {showTags && (
          <div className={`${styles.customHeaderButtonContainer}`}>
            <BngIconButton
              title={msg.t('manageFiles_folder_title')}
              icon={expanded ? 'expand_more' : 'expand_less'}
              onClick={() => setExpanded(!expanded)}
            />
          </div>
        )}
      </div>
      {showTags && (
        <div className={`${styles.tagsContainer} ${!expanded && styles.notExpanded}`}>
          {expanded && <div className={`${styles.fadingArea}`} />}
          {tagComponent ? (
            tagComponent()
          ) : (
            <React.Fragment>
              {value.map((item, idx) => {
                return (
                  <div className={`${styles.tag}`} key={`key_${idx}_${item?.id ?? item?.name ?? ''}`}>
                    {item?.displayName}
                  </div>
                );
              })}
            </React.Fragment>
          )}
        </div>
      )}
    </div>
  );
}
