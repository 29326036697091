import './MemberFilterList.css';

import React, { useEffect, useMemo, useState } from 'react';
import { FieldArray } from 'formik';
import { FixedSizeList } from 'react-window';

import { BngField } from 'components/bng/form/BngField';
import { BngDropdown } from 'components/bng/ui/BngDropdown';
import Api from 'components/Api';
import UiMsg from 'components/ui/UiMsg';
import { UiBlocker } from 'components/bng/ui/UiBlocker';
import { BngSelectSearch } from 'components/bng/form/BngSelectSearch';
import BngSearch from 'components/bng/ui/BngSearch';
import BngCheckbox from 'components/bng/form/BngCheckbox';
import Button from 'components/ui/Button';
import { BngIconButton } from 'components/bng/ui/BngIconButton';
import { buildDimensionOpts } from 'components/bng/pages/newAnalysis/paramTypes/DimensionListWithFilters';
import useBimContext from 'components/hooks/useBimContext';

let SHARED_MEMBERS_CACHE = {};

export function MemberFilterListComponent({ className = '', name, label, sourceFields, formikProps }) {
  const context = useBimContext();
  const { msg } = context;
  const [membersCache, setMembersCache] = useState(SHARED_MEMBERS_CACHE);

  const dimensionOpts = useMemo(() => {
    const result = buildDimensionOpts({
      msg,
      sourceFields,
    });

    for (const option of result) {
      option.value = `BIMF${option.value}`;
      if (option.children) {
        for (const childOpt of option.children) {
          childOpt.value = `BIMF${childOpt.value}`;
        }
      }
    }

    return result;
  }, [sourceFields]);

  const mdxFilters = _.get(formikProps.values, name) || [];
  const { datasource, cube } = formikProps.values;

  const fetchMembersForDimension = async (dimension) => {
    if (!dimension) return [];

    if (membersCache[dimension]) {
      return membersCache[dimension];
    }

    membersCache[dimension] = [];

    const { hierarchy, members } = await Api.Project.findDimensionMembers({
      projectId: context.project.id,
      sourceName: datasource,
      cube,
      dimension,
    });
    if (hierarchy.hasAll) {
      members.splice(0, 1);
    }

    SHARED_MEMBERS_CACHE[dimension] = members;
    setMembersCache({ ...SHARED_MEMBERS_CACHE });

    return members;
  };

  useEffect(() => {
    for (const mdxFilter of mdxFilters) {
      if (!mdxFilter.dimension || mdxFilter.members.length === 0) continue;
      fetchMembersForDimension(mdxFilter.dimension);
    }
  }, []);

  useEffect(() => {
    SHARED_MEMBERS_CACHE = {};
    setMembersCache(SHARED_MEMBERS_CACHE);
  }, [datasource, cube]);

  return (
    <FieldArray name={name}>
      {(arrayHelpers) => {
        const disableAddButton = mdxFilters.length >= dimensionOpts.length;
        return (
          <div className={`MemberFilterList ${className}`}>
            <label className="control-label">{label}</label>
            {mdxFilters.map((mdxFilter, idx) => {
              const fieldPrefix = `${name}[${idx}]`;

              const dimensionCaption = mdxFilter.dimension
                ? dimensionOpts.find((d) => d.value === mdxFilter.dimension || mdxFilter.dimension.startsWith(d.value))
                    ?.label ?? ''
                : '';
              const memberCaptions = mdxFilter.members.map((uniqueName) => {
                return (
                  membersCache[mdxFilter.dimension]?.find((md) => md.uniqueName === uniqueName)?.caption ?? uniqueName
                );
              });
              const fieldText =
                dimensionCaption && mdxFilter.members.length > 0
                  ? `${dimensionCaption} > ${memberCaptions.join(', ')}`
                  : '';

              return (
                <div key={fieldPrefix} className="MemberFilterListItem d-flex">
                  <BngField
                    rootClassName="MemberInfo flex-grow-1 mr-2"
                    withLabel={false}
                    value={fieldText}
                    title={fieldText.length >= 32 ? fieldText : undefined}
                    placeholder={msg.t('select.a.filter')}
                    readOnly
                  />

                  <div className="no-wrap">
                    <FilterDropdownButton
                      className="EditButton"
                      mdxFilter={mdxFilter}
                      mdxFilters={mdxFilters}
                      dimensionOpts={dimensionOpts}
                      fetchMembersForDimension={fetchMembersForDimension}
                      onChange={(values) => {
                        for (const [key, value] of Object.entries(values)) {
                          formikProps.setFieldValue(`${fieldPrefix}.${key}`, value);
                        }
                      }}
                      edit
                    />

                    <BngIconButton className="RemoveButton" icon="delete" onClick={() => arrayHelpers.remove(idx)} />
                  </div>
                </div>
              );
            })}
            <div>
              <FilterDropdownButton
                className="block"
                mdxFilters={mdxFilters}
                dimensionOpts={dimensionOpts}
                fetchMembersForDimension={fetchMembersForDimension}
                onChange={(values) => {
                  arrayHelpers.push(values);
                }}
                customButton={({ openDropdown }) => {
                  return (
                    <Button
                      className={'bng-button fix save flex-grow-1 Action AddButton'}
                      disabled={disableAddButton}
                      onClick={openDropdown}
                    >
                      {msg.t('add.filter')}
                    </Button>
                  );
                }}
              />
            </div>
          </div>
        );
      }}
    </FieldArray>
  );
}

export default {
  defaultVal() {
    return [];
  },
  render(props = {}) {
    return <MemberFilterListComponent {...props} />;
  },
};

export const FilterDropdownButton = ({
  className = '',
  mdxFilter = {},
  mdxFilters = [],
  dimensionOpts = [],
  fetchMembersForDimension,
  onChange = _.noop,
  customButton = undefined,
  edit = false,
  popperClassName = '',
}) => {
  const { msg } = useBimContext();
  return (
    <BngDropdown
      icon="edit"
      title={edit ? msg.t('edit') : ''}
      className={className}
      popperClassName={`${popperClassName} MemberFilterListItemPopper BngDropdownCheckboxPopper`}
      customButton={customButton}
      customOptions={({ closeDropdown, isOpen }) => {
        const [dimension, setDimension] = useState('');
        const [selectedMembers, setSelectedMembers] = useState([]);
        const [memberOpts, setMemberOpts] = useState([]);
        const [loading, setLoading] = useState(false);
        const [search, setSearch] = useState('');

        useEffect(() => {
          if (!isOpen) return;

          setDimension(mdxFilter.dimension || '');
          setSelectedMembers(mdxFilter.members?.slice() ?? []);
          setMemberOpts([]);
        }, [isOpen]);

        const fetchDimensionMembers = async () => {
          if (!dimension) {
            setMemberOpts([]);
            return;
          }

          setLoading(true);
          try {
            const members = await fetchMembersForDimension(dimension);
            setMemberOpts(members);
          } catch (e) {
            console.error(e);
            UiMsg.ajaxError(null, e);
          } finally {
            setLoading(false);
          }
        };

        const processedDimensionOpts = useMemo(() => {
          return dimensionOpts.map((opt) => {
            const isCurrentOption =
              mdxFilter.dimension &&
              (opt.value === mdxFilter.dimension ||
                (opt.children?.some((childOpt) => childOpt.value === mdxFilter.dimension) ?? false));
            if (!isCurrentOption) {
              const isDisabled =
                mdxFilters.some((d) => d.dimension === opt.value) ||
                (opt.children?.some((childOpt) => mdxFilters.some((d) => d.dimension === childOpt.value)) ?? false);
              if (isDisabled) {
                opt = { ...opt };
                opt.disabled = isDisabled;
                if (opt.children) {
                  opt.children = opt.children.map((c) => ({
                    ...c,
                    disabled: true,
                  }));
                }
              }
            }
            return opt;
          });
        }, [dimensionOpts, mdxFilters, dimension]);

        useEffect(() => {
          if (!isOpen) return;

          fetchDimensionMembers();
        }, [isOpen, dimension]);

        const filteredMemberOpts = useMemo(() => {
          let result = memberOpts;
          if (search) {
            const s = search.toLowerCase();
            result = result.filter((m) => {
              return selectedMembers.includes(m.uniqueName) || `${m.name}${m.caption}`.toLowerCase().includes(s);
            });
          }
          return result;
        }, [memberOpts, search, selectedMembers]);

        return (
          <UiBlocker block={loading}>
            <BngField
              label={msg.t('filter.dimension')}
              inputComponent={BngSelectSearch}
              options={processedDimensionOpts}
              popperClassName="DimensionListFilterPopper"
              rootClassName="Dimension"
              labelClassName="fw-500"
              groupedOpts
              field={{ value: dimension, onChange: _.noop }}
              form={{
                setFieldValue: (name, value) => {
                  setDimension(value);
                  setSelectedMembers([]);
                },
              }}
            />

            <div>
              <label className="fw-500">{msg.t('filter.members')}</label>
              <div className="FilterMembers">
                <div>
                  <BngSearch
                    value={search}
                    onChange={(val) => setSearch(val)}
                    side="right"
                    maxLength="128"
                    inline
                    placeholder={msg.t('search')}
                    disabled={_.isEmpty(dimension)}
                  />
                </div>
                <div className="MembersContainer mt-2">
                  <FixedSizeList height={150} itemCount={filteredMemberOpts.length} itemSize={23} width="100%">
                    {({ index, style }) => {
                      const member = filteredMemberOpts[index];
                      const isSelected = selectedMembers.includes(member.uniqueName);
                      return (
                        <div style={style}>
                          <BngCheckbox
                            key={member.uniqueName}
                            className="no-wrap"
                            field={{
                              value: isSelected,
                              onChange: () => {
                                const copy = selectedMembers.slice();
                                if (isSelected) {
                                  copy.splice(copy.indexOf(member.uniqueName), 1);
                                } else {
                                  copy.push(member.uniqueName);
                                }
                                setSelectedMembers(copy);
                              },
                            }}
                            label={member.caption}
                            title={member.caption}
                            showTitleOnlyOnOverflow
                          />
                        </div>
                      );
                    }}
                  </FixedSizeList>
                </div>
              </div>
            </div>

            <div className="mt-2">
              <Button
                className={`bng-button save btn-small w-100`}
                onClick={() => {
                  onChange({ dimension: dimension || '', members: selectedMembers });
                  setDimension('');
                  setMemberOpts([]);
                  closeDropdown();
                }}
                disabled={!dimension || selectedMembers.length === 0}
              >
                {msg.t('apply')}
              </Button>
            </div>
          </UiBlocker>
        );
      }}
    />
  );
};
