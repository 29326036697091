import './ColumnConfigurationDialog.css';

import React, { Component } from 'react';

import ContextEnhancer from 'components/ContextEnhancer';
import AccordionList from 'components/ui/AccordionList';
import Accordion from 'components/ui/Accordion';
import { BngInput } from 'components/bng/form/BngInput';
import { BngSelectSearch } from 'components/bng/form/BngSelectSearch';
import BngInputColor from 'components/bng/form/BngInputColor';
import ButtonGroup from 'components/ui/button-group/ButtonGroup';
import BngCheckbox from 'components/bng/form/BngCheckbox';
import BngSwitch from 'components/bng/form/BngSwitch';
import {
  alignOptions,
  barOptions,
  cellBarColorValueTypeOptions,
  cellColorOptions,
  columnPinningOptions,
  fieldTypes,
  fontColors,
  formatOptions,
  imageFrameStyles,
  imageSizes,
  textColorOptions,
  textFormatOptions,
  totalizerOperators,
  urlFieldTypes,
  verticalAlignOptions,
} from 'components/bng/pages/bigTable/constants';
import ColorRange from 'components/bng/pages/bigTable/components/ColorRange';
import LineColorPicker from 'components/bng/pages/bigTable/components/LineColorPicker';
import GradientThemeSelector from 'components/bng/pages/bigTable/menus/GradientThemeSelector';
import BngInputSpinner from 'components/bng/form/BngInputSpinner';
import BngColorPickerDropdown from 'components/bng/colorPicker/BngColorPickerDropdown';
import { BngSlider } from 'components/bng/form/BngSlider';

class ColumnConfigurationDialog extends Component {

    static propTypes = {};

    static defaultProps = {
        column: {}
    };

    state = {
        loading: true,
        column: this.props.column,
        isChanging: false,
        imageHeight: this.props.column.imageConfig.height ?? 20,
    };

    translateOptions(options) {
        return options.map(o => ({
            value: o.value,
            label: this.props.context.msg.t(o.label),
        }))
    }

    onCellGradientChange(theme) {
        let cellColorType = this.props.column.cellColorType;
        let cellBarColorType = this.props.column.cellBarColorType;
        let next = null;

        if (cellColorType === 'gradient') {
            next = {cellColorGradients: theme.ranges};
            if (theme.textRanges) {
                next.textColorRanges = theme.textRanges;
            }
        } else if (cellColorType === 'ranges') {
            next = {cellColorRanges: theme.ranges};
        } else if (cellColorType === 'bar') {
            if (cellBarColorType === 'gradient') {
                next = {cellBarColorGradients: theme.ranges};
            } else if (cellBarColorType === 'ranges') {
                next = {cellBarColorRanges: theme.ranges};
            }
        }

        if (next !== null) {
            this.props.onChange({
                ...this.props.column,
                ...next
            });
        }
    }

    columnFormat = (type) => {
        switch (type) {
            case 'NUMERIC':
                return 'Measure';
            case 'DATE':
                return 'TimeDimension';
            case 'URL':
                return 'url';
            case 'IMAGE_URL':
                return 'image_url';
            default:
                return 'text'
        }
    }

    cellFormat = (next) => {
        switch (next.fieldType) {
            case 'NUMERIC':
                return next.format;
            case 'DATE':
                return 'time';
            case 'Url':
            case 'URL':
                return 'url';
            case 'IMAGE_URL':
                return 'image_url';
            default:
                return 'text'
        }
    }

    constructor(props) {
        super(props);
        this.changeQueue = [];
    }

    onChange(field, value) {
        let current = this.props.column;
        let next = {
            ...this.props.column,
            [field]: value,
            columnType: this.columnFormat(value),
        };

        if (field !== 'format') {
            next = {
                ...next,
                format: this.cellFormat(next),
                columnType: this.columnFormat(next.fieldType),
            }
        }

        if ((current.format !== 'currency' && next.format === 'currency')
            || (!current.format.startsWith('Accounting') && next.format.startsWith('Accounting'))) {
            next = {
                ...next,
                prefix: '',
                suffix: '',
                decimals: 2
            };
        }

        if (current.format !== 'percent' && next.format === 'percent') {
            next = {
                ...next,
                prefix: '',
                suffix: ' %',
                decimals: 2
            };
        }

        if ((current.format !== 'number' && next.format === 'number') ||
            (current.format !== 'text' && next.format === 'text') ||
            (current.format !== 'time' && next.format === 'time') ||
            (current.format !== 'url' && next.format === 'url') ||
            (current.format !== 'image_url' && next.format === 'image_url') ||
            (current.fieldType === 'TEXT' && next.fieldType === 'NUMERIC')) {
            next = {
                ...next,
                prefix: '',
                suffix: '',
                decimals: 0
            };
        }

        this.props.onChange(next);
    }

    debouncedOnChange = _.debounce(this.onChange, 500);

    onChangeDecimals(field, value) {
        if (this.state.isChanging) {
            this.changeQueue.push({field, value});
            return;
        }

        this.setState({ isChanging: true }, () => {
            this.onChange(field, value);

            if (this.changeQueue.length > 0) {
                const nextChange = this.changeQueue.shift();
                this.debouncedOnChange(nextChange.field, nextChange.value);
            } else {
                this.setState({isChanging: false});
            }
        });
    }

    onClose() {
        this.props.onClose();
    }

    renderCellColorConfiguration() {

        const {column} = this.props;

        return (
            <>
                {column.cellColorType === 'gradient' && (
                    <GradientThemeSelector
                        context={this.props.context}
                        onChange={(theme) => this.onCellGradientChange(theme)}
                    />
                )}

                <div style={{padding: 10}}>

                    <div className={"BngBigTable__columnsForm__titleDiv"}>
                        <label className="control-label">{this.props.context.msg.t('type')}</label>
                        <i className={`material-icons BngBigTable__columnsForm__help`}
                           onClick={() => window.open(this.props.context.msg.t('cell.color.type.support.link'), '_blank')}>{`help_outline`}</i>
                    </div>

                    <BngSelectSearch
                        options={this.translateOptions(cellColorOptions)}
                        form={{
                            setFieldValue: (name, t) => {
                                this.onChange('cellColorType', t)
                            }
                        }}
                        field={{value: column.cellColorType, onChange: _.noop}}
                        clearable={false}
                    />

                    {column.cellColorType === 'bar' &&
                    <>
                        <hr/>

                        <label
                            className="control-label">{this.props.context.msg.t('control')}</label>
                        <BngSelectSearch
                            options={this.translateOptions(barOptions)}
                            form={{setFieldValue: (name, t) => this.onChange('cellBarType', t)}}
                            field={{value: column.cellBarType, onChange: _.noop}}
                            clearable={false}
                        />

                        {column.cellBarType === 'fixed' && (
                            <>
                                <br/>
                                <label
                                    className="control-label">{this.props.context.msg.t('value')}</label>
                                <BngInput type="number" field={{
                                    onChange: (t) => this.onChange('cellBarFixedValue', t.target.value),
                                    value: column.cellBarFixedValue
                                }}/>
                            </>
                        )}

                        {(column.cellBarType === 'perc_measure' || column.cellBarType === 'measure') && (
                            <>
                                <br/>
                                <label
                                    className="control-label">{this.props.context.msg.t('measure')}</label>
                                <BngSelectSearch
                                    options={this.props.measureColumns}
                                    form={{setFieldValue: (name, t) => this.onChange('cellBarMeasureColumn', t)}}
                                    field={{
                                        value: column.cellBarMeasureColumn,
                                        onChange: _.noop
                                    }}
                                    clearable={false}
                                />
                            </>
                        )}

                        <hr/>

                        <label
                            className="control-label">{this.props.context.msg.t('bar_color')}</label>
                        <BngSelectSearch
                            options={this.translateOptions(textColorOptions)}
                            form={{setFieldValue: (name, t) => this.onChange('cellBarColorType', t)}}
                            field={{
                                value: column.cellBarColorType,
                                onChange: _.noop
                            }}
                            clearable={false}
                        />

                        <br/>

                        {(column.cellBarColorType === 'ranges' || column.cellBarColorType === 'gradient') && (
                            <>
                                <label
                                    className="control-label">{this.props.context.msg.t('use_values')}</label>
                                <BngSelectSearch
                                    options={this.translateOptions(cellBarColorValueTypeOptions)}
                                    form={{setFieldValue: (name, t) => this.onChange('cellBarColorValueType', t)}}
                                    field={{
                                        value: column.cellBarColorValueType,
                                        onChange: _.noop
                                    }}
                                    clearable={false}
                                />
                                <br/>
                            </>
                        )}

                        {column.cellBarColorType === 'fixed' &&
                        <>
                            <label
                                className="control-label">{this.props.context.msg.t('bar_color')}</label>
                            <BngInputColor
                                form={{setFieldValue: (name, t) => this.onChange('cellBarColor', t)}}
                                field={{
                                    value: column.cellBarColor,
                                    onChange: _.noop
                                }}
                                addTransparentOptions={false}
                            />
                        </>
                        }

                        {column.cellBarColorType === 'ranges' &&
                        <ColorRange
                            name={'cellBarColorRanges'}
                            defaultValue={true}
                            onChange={(v) => this.onChange('cellBarColorRanges', v)}
                            value={column.cellBarColorRanges}
                            addTransparentOptions={false}
                        />
                        }

                        {column.cellBarColorType === 'gradient' &&
                        <ColorRange
                            name={'cellBarColorGradients'}
                            onChange={(v) => this.onChange('cellBarColorGradients', v)}
                            value={column.cellBarColorGradients}
                            addTransparentOptions={false}
                        />
                        }

                    </>
                    }

                    <br/>

                    {column.cellColorType === 'ranges' &&
                    <ColorRange
                        name={'cellColorRanges'}
                        defaultValue={true}
                        onChange={(v) => this.onChange('cellColorRanges', v)}
                        value={column.cellColorRanges}
                        addTransparentOptions={false}
                    />
                    }

                    {column.cellColorType === 'gradient' &&
                    <ColorRange
                        name={'cellColorGradients'}
                        onChange={(v) => this.onChange('cellColorGradients', v)}
                        value={column.cellColorGradients}
                        addTransparentOptions={false}
                    />
                    }

                </div>
            </>
        );
    }

    renderTextColorConfiguration() {

        const {column} = this.props;

        return (
            <div style={{padding: 10}}>

                <div className={"BngBigTable__columnsForm__titleDiv"}>
                    <label className="control-label">{this.props.context.msg.t('type')}</label>
                    <i className={`material-icons BngBigTable__columnsForm__help`}
                       onClick={() => window.open(this.props.context.msg.t('font.color.type.support.link'), '_blank')}>{`help_outline`}</i>
                </div>

                <BngSelectSearch
                    options={this.translateOptions(textColorOptions)}
                    form={{
                        setFieldValue: (name, t) => {
                            this.onChange('textColorType', t)
                        }
                    }}
                    field={{
                        onChange: _ => this.onChange('textColorType', t),
                        value: column.textColorType
                    }}
                    clearable={false}
                />
                <br/>

                {column.textColorType === 'fixed' &&
                <LineColorPicker
                    label={this.props.context.msg.t('font.color')}
                    options={fontColors}
                    showCustom={true}
                    value={column.textColor}
                    onChange={(t) => this.onChange('textColor', t)}
                    addTransparentOptions={false}
                />
                }

                {column.textColorType === 'ranges' &&
                <ColorRange
                    name={'textColorRanges'}
                    defaultValue={true}
                    onChange={(v) => this.onChange('textColorRanges', v)}
                    value={column.textColorRanges}
                    addTransparentOptions={false}
                />
                }

                {column.textColorType === 'gradient' &&
                <ColorRange
                    name={'textColorGradients'}
                    onChange={(v) => this.onChange('textColorGradients', v)}
                    value={column.textColorGradients}
                    addTransparentOptions={false}
                />
                }

                {column.textColorType === 'automatic' &&
                <>
                    <label className="control-label">{this.props.context.msg.t('threshold')}</label>
                    <BngInputSpinner
                        min={0}
                        max={1}
                        step={0.05}
                        allowNegative={false}
                        value={column.textAutomaticThreshold}
                        onChange={val => this.onChange('textAutomaticThreshold', val)}
                        decimalScale={2}
                    />
                </>
                }

                <hr/>

            </div>

        )
    }

    render() {
        if (!this.props.open) {
            return null;
        }

        const {column, onReset} = this.props;
        const isUrl = ['IMAGE_URL', 'URL', 'Url'].find(t => t === column.fieldType || t === column.columnType);

        return (
            <div className={`AccordionWrapper BngBigTable__ColumnConfigurationDialog BngBigTable__menuTab ObjectRightMenuAccordion`}>

                <div className={`BngBigTable__ColumnConfigurationDialogHeader`}>
                    <div className={`BngBigTable__ColumnConfigurationDialogHeaderTitle`}>
                        <i className={'material-icons'}>{'view_list'}</i>
                        <span style={{fontSize: 15, marginLeft: 5}}>{this.props.context.msg.t('table')}</span>
                        <i className={'material-icons'}>{'chevron_right'}</i>
                        <span className={`BngBigTable__ColumnConfigurationDialogHeaderName`}>{column.title}</span>
                    </div>

                    <i className={'material-icons BngBigTable__ColumnConfigurationDialogHeaderBack'}
                       onClick={() => this.props.onClose()}>{'keyboard_backspace'}</i>
                </div>

                <div className={`BngBigTable__ColumnConfigurationDialogForm`}>

                    <div className="BngForm">

                        <AccordionList>
                            <Accordion title={(
                                <div style={{display: 'flex', justifyContent: 'space-between'}}>
                                    <span>{this.props.context.msg.t('format')}</span>
                                    <span
                                        onClick={() => onReset()}
                                        style={{
                                            color: '#005DFF',
                                            fontWeight: 500,
                                            cursor: 'pointer'
                                        }}>{this.props.context.msg.t('clear')}</span>
                                </div>
                            )} startOpen={true}>
                                <div style={{padding: 10}}>

                                    <label className="control-label">{this.props.context.msg.t('title')}</label>
                                    <BngInput field={{
                                        onChange: (t) => this.onChange('title', t.target.value),
                                        value: column.title
                                    }}/>

                                    <div className="mb-2">
                                        <label className="control-label">{this.props.context.msg.t('fieldType')}</label>
                                        <BngSelectSearch
                                            options={this.translateOptions(isUrl ? urlFieldTypes : fieldTypes)}
                                            form={{
                                                setFieldValue: (name, t) => {
                                                    this.onChange('fieldType', t)
                                                }
                                            }}
                                            field={{value: column.fieldType, onChange: _.noop}}
                                            clearable={false}
                                        />
                                    </div>
                                    {!isUrl && (
                                        <>
                                            <div className="BngBigTable__columnsForm__grid"
                                                 style={{marginTop: 0, marginBottom: 12}}>
                                                <div className="w-45">
                                                    {column.fieldType === 'NUMERIC' && (
                                                        <>
                                                            <label
                                                                className="control-label">{this.props.context.msg.t('format')}</label>
                                                            <BngSelectSearch
                                                                disabled={column.formatString === 'Hour'}
                                                                options={this.translateOptions(formatOptions)}
                                                                form={{
                                                                    setFieldValue: (name, t) => {
                                                                        this.onChange('format', t)
                                                                    }
                                                                }}
                                                                field={{
                                                                    value: column.formatString === 'Hour' ? 'time' : column.format,
                                                                    onChange: _.noop
                                                                }}
                                                                clearable={false}
                                                            />
                                                        </>
                                                    )}
                                                </div>
                                                <div className="w-45">
                                                    {(column.fieldType === 'NUMERIC') &&
                                                        <>
                                                            <label
                                                                className="control-label">{this.props.context.msg.t('decimals')}</label>
                                                            <BngInputSpinner
                                                    disabled={column.formatString === 'Hour' || column.format === 'text'}
                                                    disableAddRemoveButton={column.formatString === 'Hour' || column.format === 'text'}
                                                                min={0}
                                                                max={10}
                                                                step={1}
                                                                allowNegative={false}
                                                                value={column.decimals}
                                                                onChange={val => this.onChangeDecimals('decimals', val)}
                                                            />
                                                        </>
                                                    }
                                                </div>
                                            </div>
                                            <div className="BngBigTable__columnsForm__grid">
                                                <div className="w-45">
                                                    <label
                                                        className="control-label">{this.props.context.msg.t('prefix')}</label>
                                                    <BngInput field={{
                                                        onChange: (t) => this.onChange('prefix', t.target.value),
                                                        value: column.prefix
                                                    }}/>
                                                </div>
                                                <div className="w-45">
                                                    <label
                                                        className="control-label">{this.props.context.msg.t('suffix')}</label>
                                                    <BngInput field={{
                                                        onChange: (t) => this.onChange('suffix', t.target.value),
                                                        value: column.suffix
                                                    }}/>
                                                </div>
                                            </div>

                                            <div className="mb-2">
                                                <BngCheckbox
                                                    label={this.props.context.msg.t('suffix.prefix.spacing')}
                                                    field={{value: column.prefixSuffixSpacing}}
                                                    onChange= {_ => this.onChange('prefixSuffixSpacing', !column.prefixSuffixSpacing)}
                                                />
                                            </div>

                                            <div className="BngBigTable__columnsForm__grid">
                                                <div className="w-45 mb-10">
                                                    <BngCheckbox
                                                        label={this.props.context.msg.t('freeze')}
                                                        field={{value: column.pinned !== 'none'}}
                                                        onChange={_ => this.onChange('pinned', column.pinned === 'none' ? 'left' : 'none')}
                                                    />
                                                </div>
                                                <div className="w-45">
                                                    <ButtonGroup
                                                        className={`BngBigTable__ColumnConfigurationPinned`}
                                                        disabled={column.pinned === 'none'}
                                                        items={columnPinningOptions}
                                                        value={column.pinned}
                                                        onChange={(val) => this.onChange('pinned', val)}
                                                    />
                                                </div>
                                            </div>
                                        </>
                                    )}

                                </div>
                            </Accordion>

                            {!isUrl && (
                                <Accordion title={this.props.context.msg.t('text_style')} startOpen={false}>

                                    <div style={{padding: 10}}>

                                        {column.fieldType === 'TEXT' &&
                                            <div style={{marginBottom: 10}}>
                                                <BngCheckbox
                                                    label={this.props.context.msg.t('automatic_color')}
                                                    field={{value: column.textColorType === 'automatic'}}
                                                    onChange={_ => this.onChange('textColorType', column.textColorType === 'automatic' ? 'fixed' : 'automatic')}
                                                />

                                                {column.textColorType === 'fixed' && (
                                                    <LineColorPicker
                                                        label={this.props.context.msg.t('font.color')}
                                                        options={fontColors}
                                                        showCustom={true}
                                                        value={column.textColor}
                                                        onChange={(t) => this.onChange('textColor', t)}
                                                        addTransparentOptions={false}
                                                    />
                                                )}
                                            </div>
                                        }

                                        <div className="BngBigTable__columnsForm__grid">
                                            <div className="w-32">
                                                <ButtonGroup
                                                    multiple={true}
                                                    items={textFormatOptions}
                                                    value={column.textFormat}
                                                    onChange={(val) => this.onChange('textFormat', val)}
                                                />
                                            </div>
                                            <div className="w-32">
                                                <ButtonGroup
                                                    items={verticalAlignOptions}
                                                    value={column.verticalAlign}
                                                    onChange={(val) => this.onChange('verticalAlign', val)}
                                                />
                                            </div>
                                            <div className="w-32">
                                                <ButtonGroup
                                                    items={alignOptions}
                                                    value={column.align}
                                                    onChange={(val) => this.onChange('align', val)}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </Accordion>
                            )}


                            {column.fieldType === 'NUMERIC' && (
                                <>
                                    <Accordion
                                        startOpen={false}
                                        disabled={column.indicatorsType === 'none'}
                                        title={(
                                            <div style={{display: 'flex', justifyContent: 'space-between'}}>
                                                <span>{this.props.context.msg.t('indicators')}</span>

                                                <BngSwitch
                                                    id={`indicators_checkbox`}
                                                    verticalTitle={false}
                                                    checked={column.indicatorsType !== 'none'}
                                                    onChange={_ => this.onChange('indicatorsType', column.indicatorsType === 'none' ? 'manual' : 'none')}
                                                />

                                            </div>
                                        )}>

                                        {column.indicatorsType === 'manual' && (
                                            <div className={"BngBigTable__columnsForm__titleDiv"}
                                                 style={{paddingRight: 10}}>
                                                <p>{this.props.context.msg.t('cell.color')}</p>
                                                <i className={`material-icons BngBigTable__columnsForm__help`}
                                                   onClick={() => window.open(this.props.context.msg.t('cell.color.support.link'), '_blank')}>{`help_outline`}</i>
                                            </div>
                                        )}

                                        {(column.indicatorsType === 'manual' || column.indicatorsType === 'cell') && (
                                            <>
                                                {this.renderCellColorConfiguration()}
                                            </>
                                        )}

                                        <hr/>

                                        {column.indicatorsType === 'manual' && (
                                            <div className={"BngBigTable__columnsForm__titleDiv"}
                                                 style={{paddingRight: 10}}>
                                                <p>{this.props.context.msg.t('font.color')}</p>
                                                <i className={`material-icons BngBigTable__columnsForm__help`}
                                                   onClick={() => window.open(this.props.context.msg.t('font.color.support.link'), '_blank')}>
                                                    {`help_outline`}
                                                </i>
                                            </div>
                                        )}

                                        {(column.indicatorsType === 'manual' || column.indicatorsType === 'text') && (
                                            <>
                                                {this.renderTextColorConfiguration()}
                                            </>
                                        )}
                                    </Accordion>
                                    <Accordion
                                        startOpen={false}
                                        disabled={!column.totalizerConfig.enabled}
                                        title={(
                                            <div style={{display: 'flex', justifyContent: 'space-between'}}>
                                                <span>{this.props.context.msg.t('bigtable.totalizer.opt')}</span>
                                                <BngSwitch
                                                    id={`bigtable_totalizer_checkbox`}
                                                    verticalTitle={false}
                                                    checked={column.totalizerConfig.enabled}
                                                    onChange={() => this.onChange('totalizerConfig', {
                                                        ...column.totalizerConfig,
                                                        enabled: !column.totalizerConfig.enabled
                                                    })}
                                                />
                                            </div>
                                        )}>
                                        <BngSelectSearch
                                            options={this.translateOptions(
                                              Object.entries(totalizerOperators)
                                                .map(([value, props]) => ({value, label: props.label}))
                                            )}
                                            form={{
                                                setFieldValue: (name, operator) => {
                                                    this.onChange('totalizerConfig', {
                                                        ...column.totalizerConfig,
                                                        operator
                                                    });
                                                }
                                            }}
                                            field={{value: column.totalizerConfig.operator, onChange: _.noop}}
                                            clearable={false}
                                        />
                                    </Accordion>
                                </>
                            )}

                            {column.fieldType === 'IMAGE_URL' && (
                                <Accordion
                                    startOpen={false}
                                    disabled={!column.imageConfig.enabled}
                                    title={(
                                        <div style={{display: 'flex', justifyContent: 'space-between'}}>
                                            <span>{this.props.context.msg.t('bigtable.image.style')}</span>
                                            <BngSwitch
                                                id={`columnImageConfigEnabled`}
                                                verticalTitle={false}
                                                checked={column.imageConfig.enabled}
                                                onChange={() => this.onChange('imageConfig', {
                                                    ...column.imageConfig,
                                                    enabled: !column.imageConfig.enabled
                                                })}
                                            />

                                        </div>
                                    )}>
                                    <div className="BigTable__ImageUrlAccordionConfiguration">
                                        <div className={"BngBigTable__columnsForm__titleDiv"} style={{paddingRight: 10, margin: '15px 0 5px 0' }}>
                                            <span>{this.props.context.msg.t('bigtable.image.size')}</span>
                                        </div>
                                        <BngSelectSearch
                                            options={this.translateOptions(
                                                Object.entries(imageSizes)
                                                    .map(([value, props]) => ({value, label: props.label}))
                                            )}
                                            form={{
                                                setFieldValue: (name, size) => {
                                                    this.onChange('imageConfig', {
                                                        ...column.imageConfig,
                                                        size,
                                                        height: imageSizes[size].value
                                                    });
                                                }
                                            }}
                                            field={{value: column.imageConfig.size, onChange: _.noop}}
                                            clearable={false}
                                        />
                                        {this.props.column.imageConfig.size === 'CUSTOM' && (
                                            <div>
                                                <BngSlider field={{ value: column.imageConfig.height ?? 20 }}
                                                           form={{
                                                               setFieldValue: (field, value) => {
                                                                   this.onChange('imageConfig', {
                                                                       ...column.imageConfig,
                                                                       height: value
                                                                   })
                                                               }
                                                           }}
                                                           min={20}
                                                           max={200}
                                                           step={1}
                                                           showArrows={false}
                                                />
                                            </div>
                                        )}
                                        <div className={"BngBigTable__columnsForm__titleDiv"} style={{paddingRight: 10, margin: '15px 0 5px 0' }}>
                                            <span>{this.props.context.msg.t('bigtable.image.style')}</span>
                                        </div>
                                        <BngSelectSearch
                                            options={this.translateOptions(
                                                Object.entries(imageFrameStyles)
                                                    .map(([value, props]) => ({value, label: props.label}))
                                            )}
                                            form={{
                                                setFieldValue: (name, frameStyle) => {
                                                    this.onChange('imageConfig', {
                                                        ...column.imageConfig,
                                                        frameStyle,
                                                    });
                                                }
                                            }}
                                            field={{value: column.imageConfig.frameStyle, onChange: _.noop}}
                                            clearable={false}
                                        />
                                        <BngCheckbox
                                            label={this.props.context.msg.t('bigtable.image.frame.bord')}
                                            style={{padding: '15px 0 5px 0'}}
                                            field={{value: column.imageConfig.border}}
                                            onChange={_ => this.onChange('imageConfig', {
                                                ...column.imageConfig,
                                                border: !column.imageConfig.border
                                            })}
                                        />
                                        {column.imageConfig.border && (
                                            <div className="BigTalbe__BorderConfiguration" style={{display: 'flex', justifyContent: 'space-between'}}>
                                                <div style={{display: 'flex', flexDirection: 'column'}}>
                                                    <span>{this.props.context.msg.t('bigtable.image.frame.borderColor')}</span>
                                                    <BngColorPickerDropdown
                                                        color={{
                                                            colors: [{
                                                                color: column.imageConfig.borderColor,
                                                                opacity: 1,
                                                                position: 0,
                                                                id: 0,
                                                            }],
                                                            rotation: 0,
                                                        }}
                                                        onApply={(color) => this.onChange('imageConfig', {
                                                            ...column.imageConfig,
                                                            borderColor: color.colors[0].color
                                                        })}
                                                    />
                                                </div>
                                                <div style={{display: 'flex', flexDirection: 'column'}}>
                                                    <span>{this.props.context.msg.t('bigtable.image.frame.borderSize')}</span>
                                                    <BngInputSpinner
                                                        min={0}
                                                        max={10}
                                                        step={1}
                                                        style={{width: '40px'}}
                                                        allowNegative={false}
                                                        value={column.imageConfig.borderWidth}
                                                        onChange={val => this.onChange('imageConfig', {
                                                            ...column.imageConfig,
                                                            borderWidth: val
                                                        })}
                                                    />
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                </Accordion>
                            )}
                        </AccordionList>

                    </div>

                    <div style={{height: 60}}></div>

                </div>

            </div>

        );
    }
}

export default ContextEnhancer(ColumnConfigurationDialog);
