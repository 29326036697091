import styles from './BimQuerySqlEditor.module.css';

import React from 'react';
import { useFormikContext } from 'formik';

import { BngCodeMirror } from 'components/bng/form/BngCodeMirror';

export default function BimQuerySqlEditor() {
  const { values, setFieldValue } = useFormikContext();

  return (
    <div className={`BimQuerySqlEditor ${styles.BngCodeMirrorWrapper}`}>
      <BngCodeMirror field={{ value: values.sql, name: 'sql' }} form={{ setFieldValue }} mode="sql" />
    </div>
  );
}
