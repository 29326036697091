import styles from './AccStructuresTab.module.css';
import React, { useMemo, useState } from 'react';
import useFetchData from 'components/hooks/useFetchData';
import UiMsg from 'components/ui/UiMsg';
import useTranslation from 'components/hooks/useTranslation';
import Api from 'components/Api';
import UiBlocker from 'components/bng/ui/UiBlocker';
import BngTable from 'components/bng/ui/BngTable';
import Icon from 'components/ui/common/Icon';
import Utils from 'components/Utils';
import BngIconButton from 'components/bng/ui/BngIconButton';
import useBimContext from 'components/hooks/useBimContext';
import FilterOpts from 'components/bng/pages/common/filter/FilterOpts';
import { ProjectType } from 'components/bng/project/NewProjectDialog';
import FilterDropdown from 'components/bng/pages/common/filter/FilterDropdown';
import bngYup from 'components/bng/form/yup/BngYup';
import { TypeIcon } from 'components/bng/pages/admin/structures/StructuresPage';

const buildStructuresColumns = ({ t }) => {
  return [
    {
      key: 'id',
      label: t('id'),
      sortable: true,
      size: 20,
      colClassName: styles.colEllipsis,
      render: (row) => {
        return (
          <div>
            <span>{row.origin.id}</span>
          </div>
        );
      },
    },
    {
      key: 'name',
      label: t('name'),
      sortable: true,
      size: 100,
      render: (row) => {
        return (
          <div className={styles.userNameWrapper}>
            <Icon icon={row.origin.icon} className={styles.structureIcon} />
            <span>{row.origin.caption}</span>
          </div>
        );
      },
    },
    {
      key: 'type',
      label: t('type'),
      sortable: true,
      size: 100,
      render: (row) => {
        return <span>{t(row.origin.type)}</span>;
      },
    },
    {
      key: 'tableSize',
      label: t('size'),
      sortable: true,
      size: 120,
      render: (row) => (row.origin.tableSize ? Utils.fileSizeSI(row.origin.tableSize, 0) : ''),
    },
    {
      key: 'project',
      label: t('project'),
      sortable: true,
      size: 120,
      render: (row) => {
        return <span>{row.project.displayName}</span>;
      },
    },
    {
      key: 'dataUpdatedAt',
      label: t('updated'),
      sortable: true,
      size: 160,
      render: (row) => {
        const dataUpdatedAt = row.origin.dataUpdatedAt ? moment(row.origin.dataUpdatedAt) : undefined;
        return (
          <div className={styles.dataUpdateWrapper}>
            {dataUpdatedAt && (
              <div className={styles.dataUpdate} title={dataUpdatedAt.format('DD/MM/YYYY HH:mm:ss')}>
                <Icon icon={'access_time'} className={styles.dataUpdateIcon} />
                <span className={'mr-2 no-wrap'}>{dataUpdatedAt.fromNow()}</span>
              </div>
            )}
          </div>
        );
      },
    },
    {
      key: 'openProject',
      label: '',
      sortable: false,
      size: 10,
      render: (row) => {
        return (
          <BngIconButton
            onClick={() => {
              const url = Api.Bng.accessProjectUrl(row.project.name, '/pages/project-selection/dataconfig.iface');
              window.location.replace(url);
            }}
            icon={'open_in_new'}
            className={styles.openProjectButton}
          />
        );
      },
    },
  ];
};

const filterRows = ({ tableSortMode, structures, filters }) => {
  Object.entries(tableSortMode).forEach(([prop, direction]) => {
    if (direction === 'NONE') return;

    structures = _.orderBy(
      structures,
      [
        (row) => {
          let value = prop === 'project' ? row.project.displayName : row.origin[prop];
          return _.isString(value) ? value.toLowerCase() : value;
        },
      ],
      [direction.toLowerCase()]
    );
  });

  const { structureType, project, projectType } = filters;

  if (structureType) {
    structures = structures.filter((structure) => structure.origin.type === structureType);
  }

  if (project && project.length > 0) {
    structures = structures.filter((structure) => project.some((p) => p === structure.project.id.toString()));
  }

  if (projectType && projectType.length > 0) {
    structures = structures.filter((structure) => projectType.some((p) => p === structure.project.projectType));
  }

  return structures;
};

const getStructureType = (account) => (account?.countDataStructures ? '' : 'ANALYTICAL');

const StructureFilterDropdownSchema = bngYup((yup) => {
  return yup.object().shape({
    structureType: yup.array().of(yup.string()),
    project: yup.array().of(yup.string()),
    projectType: yup.array().of(yup.string().nullable()),
  });
});

const StructuresFilterDropdown = ({ rows = [], onChange = _.noop, initialValues = [], filters = {} }) => {
  const context = useBimContext();

  const typeOpts = FilterOpts({
    options: TypeIcon,
    hasIcon: true,
  });

  const projectOpts = FilterOpts({
    useIdAsValue: true,
    options: _.uniqBy(
      rows.map((structure) => {
        const project = structure.project;
        return { id: project.id, label: Utils.Project.displayName(project) };
      }),
      'id'
    ),
  });

  const projectTypeOpts = FilterOpts({
    options: ProjectType,
  });

  const filterFields = [
    {
      name: 'structureType',
      label: context.msg.t('type'),
      options: typeOpts,
      className: styles.structureTypeFilter,
    },
    {
      name: 'project',
      label: context.msg.t('project'),
      options: projectOpts,
      multiple: true,
    },
    {
      name: 'projectType',
      label: context.msg.t('project_type'),
      options: projectTypeOpts,
      multiple: true,
    },
  ];

  return (
    <FilterDropdown
      className={styles.AccStructuresTabFilter}
      fields={filterFields}
      dropdownSchema={StructureFilterDropdownSchema}
      initialFormValues={initialValues}
      onChange={onChange}
      selectedFilters={filters}
    />
  );
};

export default function AccStructuresTab({ account }) {
  const { t } = useTranslation();
  const [tableSortMode, setTableSortMode] = useState({ name: 'ASC' });
  const [filters, setFilters] = useState({
    structureType: getStructureType(account),
    project: null,
    projectType: ['Production'],
  });

  const { data: structures, isLoading } = useFetchData(async () => {
    try {
      const originsData = await Api.Account.fetchAccountOrigins(account.id);
      setFilters((currentFilters) => ({
        ...currentFilters,
        structureType: getStructureType(account),
      }));
      return originsData;
    } catch (e) {
      console.error('Error on fetch structures function()', e);
      UiMsg.error(t('error.fetching.data', [t('structures')]));
    }
  }, [account]);

  const filteredRows = useMemo(
    () =>
      filterRows({
        tableSortMode,
        structures,
        filters,
      }),
    [structures, tableSortMode, filters]
  );

  const cols = useMemo(() => buildStructuresColumns({ t }), []);

  return (
    <UiBlocker block={isLoading} className={`${styles.pageWrapper} h-100`}>
      <div className={`AccStructuresTab ${styles.AccStructuresTab}`}>
        <section className={styles.tableSection}>
          <div className={styles.tableTitleWrapper}>
            <span className={styles.sectionTitle}>{t('structure.details')}</span>
            <StructuresFilterDropdown
              onChange={(values) => {
                setFilters(values);
              }}
              initialValues={filters}
              rows={structures}
              filters={filters}
            />
          </div>
          <div className={styles.structuresTable}>
            <BngTable
              cols={cols}
              rows={filteredRows}
              stickyHeader={true}
              showEmptyAlert={true}
              emptyAlertProps={{ message: t('no.structures') }}
              sortHandler={(props) => {
                setTableSortMode({
                  [props.col.key]: props.currentSortMode.nextMode.name,
                });
              }}
              sortMode={tableSortMode}
            />
          </div>
        </section>
      </div>
    </UiBlocker>
  );
}
