import styles from './InviteUsersDialog.module.css';

import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Field, Form, Formik } from 'formik';

import Dialog from 'components/ui/Dialog';
import { DefaultDialogActions } from 'components/ui/FormUtils';

import { BngField } from 'components/bng/form/BngField';
import { bngYup } from 'components/bng/form/yup/BngYup';
import Icon from 'components/ui/common/Icon';
import UiMsg from 'components/ui/UiMsg';
import Api from 'components/Api';
import useBimContext from 'components/hooks/useBimContext';
import Utils from 'components/Utils';
import BngTable from 'components/bng/ui/BngTable';
import { buildProjectsTableColumns } from 'components/ui/accounts/tabs/users/RemoveUserDialog';
import ChangeRoleDropdown from 'components/bng/pages/admin/users/ChangeRoleDropdown';
import AddAdditionalDialog, { ADDITIONALS } from 'components/bng/accounts/additionals/AddAdditionalDialog';
import useFetchData from 'components/hooks/useFetchData';
import useReduxDispatch from 'components/hooks/useReduxDispatch';
import { MODALS } from 'components/ui/redux/Actions';

const UserEmailValidationSchema = bngYup((yup) =>
  yup.object().shape({
    email: yup.string().default('').min(1).emails(),
    role: yup.string().default(Utils.Project.roles.Viewer.name),
    projects: yup.array().of(yup.number()).default([]),
  })
);

export default function InviteUsersDialog({
  closeModal = _.noop,
  beforeClose = _.noop,
  multipleProjects = false,
  accountId,
}) {
  const context = useBimContext();
  const dispatch = useReduxDispatch();
  const [loading, setLoading] = useState(false);
  const [selectedEmails, setSelectedEmails] = useState([]);
  const [projects, setProjects] = useState([]);
  const formikRef = useRef();
  const emailFieldRef = useRef(null);

  const { data: availableUserSeats = 0, isLoading } = useFetchData(async () => {
    try {
      const { available, usage } = await Api.Account.fetchResourceUsage(accountId, ADDITIONALS.USERS.key);
      return available - usage;
    } catch (e) {
      console.error('Error on function fetchResourceUsage()', e);
    }
  }, [accountId]);

  const {data: enabledForBilling = false} = useFetchData(async () => {
    return await Api.Account.isAccountEnabledInBilling(accountId);
  })

  const selectEmail = async (values) => {
    if (enabledForBilling && availableUserSeats && selectedEmails.length + 1 > availableUserSeats) {
      dispatch(
        MODALS.open(AddAdditionalDialog, {
          additional: ADDITIONALS.USERS,
          useFeatureClassification: true,
        })
      );
      return;
    }

    const copy = selectedEmails.slice();
    // The regex gets the values.email string, which is something like "dog@dog.com, cat@cat.com; giraffe@giraffe.com" and
    // turns it into an array separated by the delimiters space, comma and semicolon
    values.email
      .match(/([\w.%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,5})/g)
      .map((email) => email.toLowerCase().trim())
      .forEach((email) => {
        if (copy.includes(email)) {
          UiMsg.warn(context.msg.t('email.already.selected'), '', { toastId: 'UsersInvite:UserAlreadySelected' });
          return;
        }
        copy.push(email);
      });

    setSelectedEmails(copy);
    formikRef.current.resetForm({
      values: {
        ...values,
        email: UserEmailValidationSchema.default().email,
      },
    });
  };

  const removeEmail = (email) => {
    const idx = selectedEmails.indexOf(email);
    if (idx !== -1) {
      const copy = selectedEmails.slice();
      copy.splice(idx, 1);
      setSelectedEmails(copy);
    }
  };

  const inviteSelectedUsers = async (values) => {
    setLoading(true);
    try {
      let errors = {};
      const projects = multipleProjects ? values.projects : [context.project.id];
      errors = await Api.Project.inviteUsers(selectedEmails, projects);

      const emailsWithError = Object.keys(errors);
      if (emailsWithError.length === 0) {
        UiMsg.ok(context.msg.t('project.invitation.sent'));
      } else {
        const message = emailsWithError.join(', ');
        UiMsg.ok(context.msg.t('project.invitation.sent.with.errors', [message]));
      }
      await beforeClose();
      closeModal();
    } catch (e) {
      setLoading(false);
      console.error('Error on InviteUsersDialog.inviteSelectedUsers', { selectedEmails }, e);
      UiMsg.ajaxError(null, e);
    }
  };

  const fetchAccountProjects = async () => {
    try {
      setLoading(true);
      const fetchedProjects = await Api.Account.findAccountProjects({
        accountId,
        role: Utils.Project.roles.Administrator.name,
        onlyProductionProjects: true,
      });
      setProjects(fetchedProjects);
    } catch (e) {
      console.error('Error on function fetchAccountProjects()', e);
      UiMsg.error(context.msg.t('account.projects.fetch.error'));
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (multipleProjects && accountId) {
      fetchAccountProjects();
    }
  }, []);

  return (
    <Dialog
      className={`InviteUsersDialog ${styles.InviteUsersDialog}`}
      loading={loading || isLoading}
      title={context.msg.t('add_users')}
      onClose={closeModal}
      contentFullWidth={true}
    >
      <Formik
        initialValues={UserEmailValidationSchema.default()}
        validationSchema={UserEmailValidationSchema}
        onSubmit={selectEmail}
        innerRef={formikRef}
      >
        {({ errors, touched, isValidating, values, submitForm, setFieldValue }) => {
          const validEmail = !errors.email && values.email.length !== 0;
          const currentRole = Utils.Project.roles[values.role];
          const disableSubmit = selectedEmails.length === 0 || (multipleProjects && values.projects.length === 0);
          const cols = useMemo(
            () =>
              buildProjectsTableColumns({
                context,
                projects,
                values,
                setFieldValue,
                reduced: true,
              }).filter((column) => !column.hide),
            [projects, values.projects]
          );

          if (errors.email && touched.email && isValidating) {
            UiMsg.warn(context.msg.t('attention'), errors.email);
          }

          return (
            <>
              <div className={styles.dialogDivisionTop} />

              <Dialog.Body>
                <Form className={`${styles.userForm} ${multipleProjects && styles.multiProject}`}>
                  {!multipleProjects && (
                    <img
                      src={`${Api.baseUrl()}/resources/images/new_user_image.svg`}
                      alt={context.msg.t('new.user.image')}
                      className={styles.img}
                      style={{ zIndex: 1 }}
                    />
                  )}
                  <div className={styles.fieldWrapper}>
                    <Field
                      name="email"
                      className={styles.emailField}
                      component={BngField}
                      asProps={{
                        maxLength: 5120,
                      }}
                      placeholder={context.msg.t('user.email.placeholder')}
                      showErrors={false}
                      required
                    >
                      <div
                        className={`${styles.iconWrapper} ${validEmail ? styles.iconValidated : ''}`}
                        onClick={validEmail ? submitForm : undefined}
                      >
                        <Icon icon="add" className={styles.addIcon} />
                      </div>
                      <div className={styles.emailsWrapper} ref={emailFieldRef}>
                        {selectedEmails.map((email) => {
                          return (
                            <div key={email} className={styles.selectedEmail} title={email}>
                              <span>{email}</span>
                              <Icon icon="close" onClick={() => removeEmail(email)} style={{ cursor: 'pointer' }} />
                            </div>
                          );
                        })}
                      </div>
                    </Field>

                    {multipleProjects && accountId && (
                      <>
                        <div className={styles.userRoleWrapper}>
                          <span>{context.msg.t('as.user.role')}</span>
                          <ChangeRoleDropdown
                            roles={Utils.Project.getRoles()}
                            currentRole={currentRole}
                            customButton={({ openDropdown }) => {
                              return (
                                <div className={`${styles.roleWrapper}`} onClick={openDropdown}>
                                  <Icon icon={currentRole.icon} />
                                  <span>{currentRole.name}</span>
                                </div>
                              );
                            }}
                            onChange={(role) => {
                              setFieldValue('role', role);
                            }}
                          />
                        </div>
                        <div className={styles.projectListWrapper}>
                          <span>{context.msg.t('to.projects')}</span>
                          <div
                            className={styles.projectTableWrapper}
                            style={{ height: `calc(90% - ${emailFieldRef.current?.offsetHeight || 0}px)` }}
                          >
                            <BngTable rows={projects} cols={cols} hideHeader={true} />
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                </Form>
              </Dialog.Body>

              <Dialog.Footer>
                <DefaultDialogActions
                  contentFullWidth={true}
                  context={context}
                  okLabel={'user.invite'}
                  submitting={loading}
                  disabled={disableSubmit}
                  title={disableSubmit && context.msg.t(multipleProjects ? 'one.email.and.project' : 'email.required')}
                  onClickSaveButton={async () => await inviteSelectedUsers(values)}
                  closeModal={closeModal}
                />
              </Dialog.Footer>
            </>
          );
        }}
      </Formik>
    </Dialog>
  );
}
