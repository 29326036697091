import "./FiltersDialog.css";
import React, {useEffect, useState} from 'react';

import ContextEnhancer from "components/ContextEnhancer";
import Dialog from "components/ui/Dialog";
import {connect} from "react-redux";
import Api from 'components/Api';
import UiMsg from "components/ui/UiMsg";

import OpConfirmation from 'components/ui/OpConfirmation';
import BngSearch from 'components/bng/ui/BngSearch';
import {BngIconButton} from "components/bng/ui/BngIconButton";
import {BngSelectSearch} from "components/bng/form/BngSelectSearch";
import {BngTable} from "components/bng/ui/BngTable";
import { MODALS } from 'components/ui/redux/Actions';
import CreateFilterDialog, {EVENT as FILTER_CHANGED_EVENT} from "components/ui/filter/CreateFilterDialog";
import useReduxDispatch from 'components/hooks/useReduxDispatch';
import useEventBus from "components/hooks/useEventBus";
import Utils from "components/Utils";
import {BngDialogActionButton} from "components/ui/Button";
import { checkAddonEnabled } from 'components/bng/accounts/addons/AddonDisabledDialog';
import AddonType from 'components/bng/accounts/AddonType';

const FiltersDialog = ({
                           context = {},
                           closeModal = _.noop,
                           projectId = null,
                       }) => {

    const dispatch = useReduxDispatch();
    const [loading, setLoading] = useState(true);
    const [filters, setFilters] = useState([])
    const [selectedStructure, setSelectedStructure] = useState({})
    const [searchTerm, setSearchTerm] = useState('');
    const [structures, setStructures] = useState([]);

    const visibleFilters = filters.filter(filter => {
        const structureName = selectedStructure?.name;
        if(structureName) {
            if(!filter.mdxFilters.some(mf => mf.datasource === structureName)) {
                return false;
            }
        }

        if(searchTerm) {
            const lowerSearch = searchTerm.toLowerCase();
            const caption = (filter.displayName || filter.name).toLowerCase();
            if(!filter.name.toLowerCase().includes(lowerSearch) && !caption.includes(lowerSearch)) {
                return false;
            }
        }

        return true;
    });

    useEffect(() => {
        (async () => {
            setLoading(true);
            try {
                await Promise.all([
                    fetchFilters(),
                    Api.executeExp(`#{filtersMB.setProject(${projectId})}`)
                ]);

                const structures = await Api.Project.findStructures(projectId);
                setStructures(structures.map((structure) => {
                    return {
                        value: structure.id,
                        label: structure.caption,
                        name: structure.name,
                        icon: structure.icon,
                    };
                }));

            } finally {
                setLoading(false);
            }
        })();

        // Used on br.com.sol7.bimachine.mdxfilter.controller.FiltersMB to update this React dialog after a JSF dialog closes
        window.__FiltersDialogUpdateCallback = async () => {
            try {
                await fetchFilters();
            } catch (e) {
                console.error(e);
            }
        }
        return () => {
            delete window.__FiltersDialogUpdateCallback;
        }
    }, []);

    useEventBus(FILTER_CHANGED_EVENT, () => {
        fetchFilters();
    }, []);

    const filterColumns = [
        {
            label: context.msg.t('id'),
            colClassName: 'filters-table-title-id',
            rowClassName: 'filterlist-table-td-id',
            render: row => row.id
        },
        {
            label: context.msg.t('name'),
            colClassName: 'filters-table-title-name',
            rowClassName: 'filterlist-table-td-text',
            render: row => row.name
        },
        {
            label: context.msg.t('caption'),
            colClassName: 'filters-table-title-name',
            rowClassName: 'filterlist-table-td-text',
            render: row => row.displayName
        },
        {
            label: context.msg.t('type'),
            colClassName: 'filters-table-title-props',
            rowClassName: 'filterlist-table-td-text',
            render: row => context.msg.t(row.type)
        },
        {
            label: context.msg.t('filter.combo.type'),
            colClassName: 'filters-table-title-props',
            rowClassName: 'filterlist-table-td-text',
            render: row => context.msg.t(row.filterType)
        },
        {
            label: context.msg.t('actions'),
            colClassName: 'filters-table-title-actions',
            rowClassName: 'filterlist-table-td-actions',
            render: row => renderActionButtons(row)
        },
    ];

    const renderActionButtons = (filter) => (
        <>

            <BngIconButton icon={filter.hasUserFilters ? 'lock' : 'lock_open'}
                           className="icon-filter-edit"
                           title={context.msg.t('user.filters')}
                           onClick={() => onUserFilter(filter)}/>
            <BngIconButton icon={'edit'}
                           className="icon-filter-edit"
                           title={context.msg.t('edit')}
                           onClick={() => onEdit(filter)}/>
            <BngIconButton icon="delete"
                           className="icon-filter-remove"
                           title={context.msg.t('remove')}
                           onClick={() => onDelete(filter)}/>
        </>
    );

    const fetchFilters = async () => {
        try {
            const allFilters = await Api.MdxGlobalFilter.findAll(projectId);
            allFilters.sort((a, b) => {
                return Utils.Strings.compareIgnoreCase(a, b);
            });
            setFilters(allFilters);
        } catch (e) {
            UiMsg.error(null, e);
        }
    }

    const onCreateFilter = async () => {
        dispatch(
            MODALS.open(CreateFilterDialog, {})
        );
    }

    const onDelete = async (filter) => {
        OpConfirmation({
            title: context.msg.t('attention'),
            message: context.msg.t('filter.delete.confirmation', filter.name),
            onConfirm: () => onConfirmDelete(filter),
            msg: context.msg
        });
    }

    const onConfirmDelete = async (filter) => {
        try {
            setLoading(true);
            await Api.MdxGlobalFilter.remove(filter.id);
            UiMsg.ok(context.msg.t('remove_success', context.msg.t('filter')));
            await fetchFilters();
        } catch (error) {
            UiMsg.error(null, error);
        } finally {
            setLoading(false);
        }
    }

    const onEdit = async (filter) => {
        dispatch(
            MODALS.open(CreateFilterDialog, {filterId: filter.id})
        );
    }

    const onUserFilter = async (filter) => {
        if (checkAddonEnabled(AddonType.USER_DATA_RESTRICT.key), true) {
            await Api.executeExp(`#{filtersMB.editUserFilterConfig(${filter.id})}`)
        }
    }

    const onStructureFilter = async (structureId) => {
        const selected = _.find(structures, {value: structureId});
        setSelectedStructure(selected);
        await fetchFilters(selected?.name);
    }

    return (
        <Dialog className="FiltersDialog xlarge"
                loading={loading}
                title={context.msg.t('filters')}
                onClose={closeModal}
                contentFullWidth={true}
                backdropClassName='FiltersDialogBackdrop'
        >
            <div className={'conection-dialog-division top'}/>

            <div className="filters-header">
                <BngDialogActionButton className="ml-5"
                                       icon="add_circle"
                                       onClick={onCreateFilter}>
                    {context.msg.t('new.filter')}
                </BngDialogActionButton>
                <BngSearch alwaysOpen={false}
                           className="filter-search"
                           onChange={setSearchTerm}
                />
                <BngSelectSearch
                    className="select-structure-field"
                    popperClassName="select-structure-field-popper"
                    emptyOption={context.msg.t('select_structure')}
                    options={structures}
                    form={{
                        setFieldValue: (name, value) => onStructureFilter(value)
                    }}
                    field={{value: selectedStructure?.value, onChange: _.noop}}
                />
            </div>

            <div className="scroll-bar-table-wrapper">
                <div className="scroll-bar-filters-table">
                    <BngTable cols={filterColumns}
                              rows={visibleFilters}
                              stickyHeader={true}
                    />
                </div>
            </div>
        </Dialog>
    )
}

export default connect()(ContextEnhancer(FiltersDialog));
