import './ObjectExecMonitorDetailsDialog.css';
import React, { useEffect, useState } from 'react';
import ContextEnhancer from 'components/ContextEnhancer';
import Dialog from 'components/ui/Dialog';
import { useLoadingListener } from 'components/bng/pages/newAnalysis/BngEnableAdvancedModeButton';
import { Tab, TabSet } from 'components/ui/TabSet';
import Api from 'components/Api';
import { Axios } from 'commonUtils';
import { DEFAULT_DATE_FORMAT } from 'components/bng/objectExecMonitor/ObjectExecMonitorDialog';

const ObjectExecMonitorDetailsDialog = ({
  open,
  closeModal,
  context: { msg },
  eventData,
}) => {
  const loadingListener = useLoadingListener();
  const [fetchedDetails, setFetchedDetails] = useState();
  useEffect(() => {
    (async () => {
      const result = await Api.ObjectExecMonitor.findDetails(eventData.id);
      setFetchedDetails(result);
    })();
  }, []);

  const details = [
    { label: msg.t('id'), value: `${eventData.id} (${eventData.eventId})` },
    { label: 'Ref', value: eventData.ref },
    {
      label: msg.t('object'),
      value() {
        const link = Axios.getUri({
          url: `${Api.baseUrl()}/load.iface`,
          params: { content: eventData.path },
        });

        return (
          <a href={link}>
            {fetchedDetails?.caption || '-'} ({eventData.path})
          </a>
        );
      },
    },
    {
      label: msg.t('project'),
      value() {
        const project = fetchedDetails?.project;
        if (project) {
          return `${project.id} - ${project.displayName} (${project.name})`;
        }
        return eventData.projectName;
      },
    },
    {
      label: msg.t('data.structure'),
      value() {
        const inMemory = fetchedDetails?.inMemory;
        if (inMemory) {
          const tableSize = ((inMemory.tableSize ?? 0) / 1048576).toFixed(2);
          return `${inMemory.id} - ${inMemory.caption} (${
            inMemory.name
          }) - ${msg.t('size')}: ${tableSize}MB`;
        }
        return eventData.datasource;
      },
    },
    {
      label: msg.t('user'),
      value() {
        const user = fetchedDetails?.user;
        if (user) {
          return `${user.id} - ${user.displayName} (${user.email})`;
        }
        return eventData.userId;
      },
    },
    {
      label: msg.t('start.date'),
      value: eventData.createdAt.format(DEFAULT_DATE_FORMAT),
    },
    {
      label: msg.t('end.date'),
      value: eventData.finishedAt?.format(DEFAULT_DATE_FORMAT) || '-',
    },
    {
      label: msg.t('duration'),
      value: moment
        .duration(eventData.createdAt.diff(eventData.finishedAt || moment()))
        .humanize(),
    },
    { label: msg.t('error'), value: msg.t(`${!!eventData.error}`) },
    { label: msg.t('locked'), value: msg.t(`${eventData.block}`) },
    { label: msg.t('result.size'), value: eventData.resultSize },
  ];

  return (
    <Dialog
      contentFullWidth={true}
      className="ObjectExecMonitorDetailsDialog large"
      open={open}
      title={msg.t('ObjectExecMonitorDetailsDialog.title')}
      loading={loadingListener.loading}
      onClose={closeModal}
      newDialogLayout
    >
      <Dialog.Body>
        <TabSet internal>
          <Tab label={msg.t('details')} icon="info">
            {details.map((d, i) => {
              return (
                <div key={i}>
                  <b>{d.label}:</b>{' '}
                  {typeof d.value === 'function' ? d.value() : d.value}
                </div>
              );
            })}
          </Tab>
          {eventData.mdxQuery && (
            <Tab label={msg.t('mdx')} icon="article">
              <pre dangerouslySetInnerHTML={{ __html: eventData.mdxQuery }} />
            </Tab>
          )}
          {eventData.error && (
            <Tab label={msg.t('error')} icon="error">
              <pre dangerouslySetInnerHTML={{ __html: eventData.error }} />
            </Tab>
          )}
        </TabSet>
      </Dialog.Body>
    </Dialog>
  );
};

export default ContextEnhancer(ObjectExecMonitorDetailsDialog);
