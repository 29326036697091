import styles from './ConnectionsStep.module.css';
import stepsCommonsStyles from './StepsCommonStyles.module.css';

import React, { useMemo, useState } from 'react';
import { useFormikContext } from 'formik';

import { ContentContainer } from 'components/bng/ui/ContentContainer';
import { BngTable } from 'components/bng/ui/BngTable';
import { ScrollContainer } from 'components/ui/ScrollContainer';
import { BngDropdown } from 'components/bng/ui/BngDropdown';
import { MODALS } from 'components/ui/redux/Actions';
import Api from 'components/Api';
import BngEmpty from 'components/bng/ui/BngEmpty';
import useReduxDispatch from 'components/hooks/useReduxDispatch';
import FormConnectionDialog from 'components/ui/in-memory/FormConnectionDialog';
import OpConfirmation from 'components/ui/OpConfirmation';
import BngSearch from 'components/bng/ui/BngSearch';
import Icon from 'components/ui/common/Icon';
import useTranslation from 'components/hooks/useTranslation';
import Utils from 'components/Utils';
import useAsyncEffect from 'components/hooks/useAsyncEffect';

function ConnectionStep({ projectId }) {
  const { t } = useTranslation();
  const dispatch = useReduxDispatch();
  const { values, setFieldValue } = useFormikContext();
  const [search, setSearch] = useState('');

  useAsyncEffect({
    onMount: async () => {
      if (!_.isEmpty(values.connections)) {
        return;
      }
      const connections = await Api.Connection.findAll(projectId);
      connections.forEach((c) => {
        delete c.id;
        c._id = Utils.randomId();
      });
      setFieldValue('connections', connections);
    },
  });

  const updateConnection = (connection) => {
    connection = {
      ...connection,
      _id: connection._id ?? Utils.randomId(),
      name: connection.name,
      type: {
        name: connection.type,
        jdbcUrl: connection.url,
      },
    };

    const connections = values.connections.slice();
    if (!connection._id) {
      connections.push(connection);
    } else {
      const idx = connections.findIndex((c) => c._id === connection._id);
      if (idx === -1) {
        connections.push(connection);
      } else {
        connections.splice(idx, 1, connection);
      }
    }

    setFieldValue('connections', connections);
  };

  const openFormConnectionDialog = (row) => {
    dispatch(
      MODALS.open(FormConnectionDialog, {
        connectionId: row?._id,
        connectionData: row,
        updateConnection,
        forReplication: true,
      })
    );
  };

  const removeItem = (row) => {
    OpConfirmation({
      title: t('attention'),
      message: t('delete.confirmation.message'),
      onConfirm: () => deleteConnection(row),
    });
  };

  const deleteConnection = async (row) => {
    const temp = values.connections.filter((c) => c._id !== row._id);
    setFieldValue('connections', temp);
  };

  const colDefs = useMemo(() => {
    return [
      {
        label: t('name'),
        render: (row) => {
          return <div className="flex-center-items">{row.name}</div>;
        },
        size: '20%',
      },
      {
        label: t('url'),
        render: (row) => {
          return <div className="flex-center-items">{row.url}</div>;
        },
        size: '30%',
      },
      {
        label: t('database'),
        render: (row) => {
          return <div className="flex-center-items">{row.type?.name || row.type}</div>;
        },
        size: '15%',
      },
      {
        label: t('action'),
        render: (row) => {
          return (
            <BngDropdown
              options={[
                {
                  icon: 'edit',
                  label: t('edit'),
                  onClick: () => openFormConnectionDialog(row),
                },
                {
                  icon: 'remove',
                  label: t('remove'),
                  onClick: () => removeItem(row),
                },
              ]}
              overDialog
            />
          );
        },
        size: '8%',
      },
    ];
  }, [values]);

  let filteredConnections = values.connections ?? [];

  if (search) {
    filteredConnections = filteredConnections.filter((item) => {
      const name = item.name.toUpperCase();
      const upperSearch = search.toUpperCase();
      const database = (item.database ?? item.type?.name ?? '').toUpperCase();
      return name.includes(upperSearch) || database.includes(upperSearch);
    });
  }

  return (
    <ContentContainer className={`ConnectionStep ${stepsCommonsStyles.ContentContainerStyle}`}>
      <div className={`Filter flex-center-items ${styles.FilterWrapper}`}>
        <BngSearch alwaysOpen={false} className="ConnectionStepSearch" onChange={(val) => setSearch(val ?? '')} />
        <div className={`new-connection-btn ${styles.NewConnectionButton}`} onClick={() => openFormConnectionDialog()}>
          <Icon icon="add_circle" />
          <div style={{ padding: '0 5px' }}>{t('new.connection')}</div>
        </div>
      </div>
      <div>
        <ScrollContainer className="TableContainer">
          <BngEmpty
            showMessage={true}
            title={t('permission.dialog.public.message')}
            isEmpty={_.isEmpty(filteredConnections)}
            img={`${Api.baseUrl()}/resources/images/notes/empty.png`}
          >
            <BngTable
              className={'table-striped'}
              rowKeyBuilder={(row) => row._id}
              rows={filteredConnections}
              cols={colDefs}
            />
          </BngEmpty>
        </ScrollContainer>
      </div>
    </ContentContainer>
  );
}

export default ConnectionStep;
