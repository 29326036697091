"use strict";

import {Axios} from "commonUtils";

const $ = jQuery;


class FilterApi {

    dynamicPeriodicities = (grouped = true) => {
        return Promise.resolve(
            $.getJSON(`/spr/filters/dynamic-periodicities?grouped=${grouped}`)
        );
    };

    findMembers = async (filterId, dashboard) => {
        const {data} = await Axios.post(`/spr/filters/${filterId}/members`, {dashboard});
        return data;
    };

}

export default FilterApi;